import React from "react";
import PdfPrescricao from "./PdfPrescricao";

function PdfPrescricaoWrapper({ showPreviousPage, prescricaoData }) {
  const showPreviousP = () => {
    showPreviousPage();
  };

  return (
    <div>
      <PdfPrescricao
        prescricaoData={prescricaoData}
        showPreviousPage={showPreviousP}
      />
    </div>
  );
}

export default PdfPrescricaoWrapper;
