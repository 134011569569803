import moment from "moment";

const dataGridData = (data, nomeCliente) => {
  const rows = data.map((dt) => {
    return {
      orcamento: dt.orcamento,
      id: dt.id,
      contaId: dt.contaId,
      nome: dt.entrada
        ? `Entrada Orçamento${
            dt.orcamento.codigo ? " " + dt.orcamento.codigo : ""
          } - ${nomeCliente}`
        : `Parcela Orçamento${
            dt.orcamento.codigo ? " " + dt.orcamento.codigo : ""
          } - ${nomeCliente}`,
      data: moment(dt.dataLimitePagamento).format("DD-MM-YYYY"),
      valor:
        dt.quantiaPorPagar > 0
          ? `${dt.quantiaPorPagar.toLocaleString("pt-BR")}$00`
          : `${dt.quantiaPaga.toLocaleString("pt-BR")}$00`,
      receber: "receber",
      estado: dt.estado,
      metodoPagamento: dt.metodoPagamento,
      nrParcela: dt.nrParcela,
      dataLimitePagamento: dt.dataLimitePagamento,
      dataPagamento: dt.dataPagamento,
      parcelaPaga: dt.parcelaPaga,
      documentoEletronico: dt.documentoEletronico,
      cancelamentosDocumentosEletronicos: dt.cancelamentosDocumentosEletronicos,
      nrRecibo: dt.nrRecibo,
    };
  });

  return rows;
};

export default dataGridData;
