import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfPrescricaoStyle.scss";
import moment from "moment/moment";

const printStyles = `
    @media print {
      @page {
        size: 148mm 210mm;
      }
    }
  `;

function PdfPrescricao({ prescricaoData, showPreviousPage }) {
  const { cliente, clinica, medico, data } = prescricaoData;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Prescrição-${prescricaoData.cliente.nome}_${moment(
      new Date()
    ).format("DD-MM-YYYY")}`,
    onAfterPrint: () => {
      showPreviousPage();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  let tempQtddExt = data.map((dt) => Number(dt.quantidade));

  tempQtddExt.forEach((qt, i) => {
    switch (qt) {
      case 1:
        tempQtddExt[i] = "Uma";
        break;
      case 2:
        tempQtddExt[i] = "Duas";
        break;
      case 3:
        tempQtddExt[i] = "Três";
        break;
      case 4:
        tempQtddExt[i] = "Quatro";
        break;
      case 5:
        tempQtddExt[i] = "Cinco";
        break;
      case 6:
        tempQtddExt[i] = "Seis";
        break;
      case 7:
        tempQtddExt[i] = "Sete";
        break;
      case 8:
        tempQtddExt[i] = "Oito";
        break;
      case 9:
        tempQtddExt[i] = "Nove";
        break;
      case 10:
        tempQtddExt[i] = "Dez";
        break;
      default:
        return qt;
    }
  });

  return (
    <>
      <div className="botoes-pdf">
        <button onClick={showPreviousPage}>Voltar</button>
        <button onClick={handlePrint}>Imprimir</button>
      </div>

      <div ref={componentRef} className="pdf-wrapper">
        <style>{printStyles}</style>
        <div className="republicaDeCv">
          <img
            src="/images/republica-cv.png"
            alt=""
            className="republicaDeCv__img"
          />
          <span className="republicaDeCv__text">
            República de Cabo Verde - Receita Médica
          </span>
        </div>
        <div className="pdf-body">
          {/* TOP */}
          <div className="pdf-prescricao__cabecalho">
            <div className="pdf-prescricao__cabecalho--left">
              <img
                className="pdf-prescricao__logo-clinica"
                src={`${process.env.REACT_APP_BACKEND_LINK}/${clinica.logotipo}`}
                alt=""
              />
              <span>{clinica.nome ? clinica.nome : ""}</span>
              <span>
                {medico.name}/{medico.profissao}
              </span>
              <span>
                Tel:{clinica.contacto ? clinica.contacto : ""}
                {clinica.contacto2 && `/${clinica.contacto2}`}
              </span>
              <span>{clinica.endereco}</span>
              <span>NIF:{clinica.nif}</span>
            </div>
            <div className="pdf-prescricao__cabecalho--right">
              <span className="pdf-prescricao__cabecalho--right__title">
                Identificação do Utente
              </span>
              <div className="pdf-prescricao__cabecalho--right__campo">
                <span className="boldSpan">Nome:</span>
                <span className="normalSpan">{cliente.nome}</span>
              </div>
              <div className="pdf-prescricao__cabecalho--right__campo">
                <span className="boldSpan">Data Nascimento:</span>
                <span className="normalSpan">{cliente.data_nasc}</span>
                <span className="boldSpan" style={{ marginLeft: "10px" }}>
                  Telefone:
                </span>
                <span className="normalSpan">{cliente.contacto}</span>
              </div>
              <div className="pdf-prescricao__cabecalho--right__campo">
                <span className="boldSpan">Endreço: </span>
                <span className="normalSpan">{cliente.endereco}</span>
              </div>
              <div className="pdf-prescricao__cabecalho--right__campo">
                <span className="boldSpan">Entidade Responsável:</span>
                <span className="normalSpan">________________</span>
                <span
                  className="boldSpan"
                  style={{ marginLeft: `${cliente.responsavel ? "10px" : 0}` }}
                >
                  Nr Beneficiário:
                </span>
                <span className="normalSpan">
                  {cliente.nr_beneficiario
                    ? cliente.nr_beneficiario
                    : "____________"}
                </span>
              </div>
            </div>
          </div>
          {/* MIDDLE */}
          <div className="pdf-prescricao__middle">
            <div className="pdf-prescricao__middle--left">
              <div className="smallDiv--1st">
                <div className="tinyDiv">
                  <img
                    className="pdf-prescricao__rx"
                    src="/images/rx-prescricao.jpeg"
                    alt=""
                  />
                </div>
                <div className="smallDiv--1st-txt">
                  Denominação Comum Internacional (DCI) da substância ativa,
                  dosagem, forma farmacêutica, posologia, quantidade total a ser
                  fornecida e dimensão da embalagem.
                </div>
              </div>
              {data.map((dt, index) => (
                <div
                  className="pdf-prescricao__middle__data pdf-prescricao__middle__data--itemPrescricao"
                  key={`pdf-prescricao__middle__data-${index}`}
                >
                  <div>{`${
                    dt.medicamento.nome ? dt.medicamento.nome : dt.medicamento
                  } - ${dt.tipo}`}</div>

                  <div className="pdf-prescricao__middle__data--2">{`${
                    dt.posologia
                  } durante ${dt.duracao} ${
                    dt.duracao > 1 ? " dias" : " dia"
                  }`}</div>
                </div>
              ))}
            </div>
            <div className="pdf-prescricao__middle--right">
              <div className="pdf-prescricao__middle--right-number">
                <div className="smallDiv smallDiv--top">Nr.</div>
                {data.map((dt, index) => (
                  <div
                    className="pdf-prescricao__middle__data pdf-prescricao__middle__data--center"
                    key={`pdf-prescricao__middle__data--center1-${index}`}
                  >
                    {dt.quantidade}
                  </div>
                ))}
              </div>
              <div className="pdf-prescricao__middle--right-extenso">
                <div className="smallDiv smallDiv--top">Extenso</div>
                {tempQtddExt.map((qt, index) => (
                  <div
                    className="pdf-prescricao__middle__data pdf-prescricao__middle__data--center"
                    key={`pdf-prescricao__middle__data--center2-${index}`}
                  >
                    {qt}{" "}
                    {data[index].tipo === "Frasco" ||
                    data[index].tipo === "Xarope"
                      ? "Frasco"
                      : "Emb."}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* BOTOM */}
          <div className="pdf-prescricao__bottom">
            <div className="pdf-prescricao__bottom--left">
              <div className="pdf-prescricao__bottom--left--1">
                <div className="smallDiv smallDiv--bold smallDiv--bottom">
                  Médico
                </div>
                <div className="div__carimbo">
                  <span className="pdf-prescricao__bottom-top-span">
                    Carimbo ou Vinheta
                  </span>
                </div>
                <div className="assinaturaEData">
                  <hr />
                  <span className="assinatura">Assinatura</span>
                  <span className="data">
                    Data: {moment(new Date()).format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
              <div className="pdf-prescricao__bottom--left--2">
                <div className="smallDiv smallDiv--bold smallDiv--bottom">
                  Farmácia
                </div>
                <div className="div__carimbo">
                  <span className="pdf-prescricao__bottom-top-span">
                    Carimbo
                  </span>
                </div>
                <div className="assinaturaEData">
                  <hr />
                  <span className="assinatura">Assinatura Farmacêutico:</span>
                  <span className="data">Data:......../......../........</span>
                </div>
              </div>
            </div>
            <div className="pdf-prescricao__bottom--right">
              <div
                className="smallDiv smallDiv--bold smallDiv--bottom"
                style={{ width: "100%" }}
              >
                Utente
              </div>
              <span className="pdf-prescricao__bottom--right__span1">
                Declaro que manifestei o Direito de Opção
              </span>
              <span className="pdf-prescricao__bottom--right__span2">
                Assinatura
              </span>
            </div>
          </div>
          {/* RODAPÉ */}
          <div className="pdf-prescricao__rodape">
            <span className="pdf-prescricao__rodape--bold">Validade:</span>
            <span className="pdf-prescricao__rodape--normal">30 DIAS</span>
          </div>
        </div>
        <div className="pdf-prescricao-rodape--2">
          <span className="pdf-prescricao-rodape--2__item">
            <span className="pdf-prescricao--overline">
              Processado por HEALTH TECH CV
            </span>
          </span>
          <span className="pdf-prescricao-rodape--2__item">
            2368 | Série - Nº 81 «BO» DA REPUBLICA DE CABO VERDE - 31 DE
            DEZEMBRO 2014
          </span>
        </div>
      </div>
    </>
  );
}

export default PdfPrescricao;
