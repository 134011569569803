import { React, useEffect, useState, useContext } from "react";
import "../../../../shared/css/Datatable.scss";
import { Link, useNavigate } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";

import ErrorModal from "../../../../shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { localeText } from "../../../../shared/util/datatableTranslations";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

//Mui icons //ver
import EditIcon from "@mui/icons-material/Edit"; //Edit icon
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";

//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";
import toast from "react-hot-toast";
import { useLoading } from "../../../../shared/context/LoadingContext";

import "../../../../shared/css/Datatable.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

function Datatable() {
  const { startLoading, stopLoading } = useLoading();
  const [data, setData] = useState();
  const { error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [fotoUser, setFotoUser] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/clinica/associadospopulated/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        let usersFiltered = [];

        responseData.users.forEach((user) => {
          usersFiltered.push({
            ...user,
            role: user?.role?.role?.role,
          });
        });

        setData(usersFiltered);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro buscando os dado dos utilizadores. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchUsers();
  }, [sendRequest, auth.role, clinica, auth.token]);

  const handleOpen = (foto) => {
    setOpen(true);
    setFotoUser(foto);
  };

  const handleClose = () => setOpen(false);

  const handleDesativar = (id) => {
    let erro = "";
    const userFiltrado = data.filter((u) => u.id === id)[0];
    if (
      userFiltrado.role === "Dentista" ||
      userFiltrado.role === "Médico/Administrador"
    ) {
      userFiltrado.secretarias.forEach((sec) => {
        if (sec.medicos.length <= 1) {
          erro =
            "Este utilizador não pode ser desativado por ter uma secretária associada apenas a ele.";
        }
      });
    }

    if (userFiltrado.role === "Secretária(o)") {
      userFiltrado.medicos.forEach((med) => {
        if (med.secretarias.length <= 1) {
          erro =
            "Este utilizador não pode ser desativado por ter um médico associado apenas a ele.";
        }
      });
    }

    if (erro) {
      toast.error(erro);
    } else {
      const desativarUsers = async () => {
        startLoading();
        try {
          if (
            userFiltrado.role === "Dentista" ||
            userFiltrado.role === "Médico/Administrador"
          ) {
            userFiltrado.secretarias.forEach((secretaria) => {
              const atualizarMedicoEmSecretaria = async () => {
                let novaListaMedicos = secretaria.medicos.filter(
                  (m) => m !== id
                );
                const formData = new FormData();
                formData.append("medicos", JSON.stringify(novaListaMedicos));
                await sendRequest(
                  `${process.env.REACT_APP_BACKEND_LINK}/api/users/${secretaria.id}`,
                  "PATCH",
                  formData,
                  {
                    Authorization: "Bearer " + auth.token,
                  }
                );
              };
              atualizarMedicoEmSecretaria();
            });
          }

          if (userFiltrado.role === "Secretária(o)") {
            userFiltrado.medicos.forEach((medico) => {
              const atualizarSecretariaEmMedico = async () => {
                let novaListaSecretarias = medico.secretarias.filter(
                  (s) => s !== id
                );
                const formData = new FormData();
                formData.append(
                  "secretarias",
                  JSON.stringify(novaListaSecretarias)
                );
                await sendRequest(
                  `${process.env.REACT_APP_BACKEND_LINK}/api/users/${medico.id}`,
                  "PATCH",
                  formData,
                  {
                    Authorization: "Bearer " + auth.token,
                  }
                );
              };
              atualizarSecretariaEmMedico();
            });
          }

          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativardesativar/${id}`,
            "PATCH",
            {},
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          setData(
            data.filter((el) => {
              return el.id !== id;
            })
          );

          toast.success("Usuário desativado com sucesso");
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro desativando o usuario. Por favor, tente novamente."
          );
        } finally {
          stopLoading();
        }
      };
      desativarUsers();
    }
  };

  const userColumns = [
    {
      field: "user",
      headerName: "User",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={
                params.row.image
                  ? `${process.env.REACT_APP_BACKEND_LINK}/${params.row.image}`
                  : "/images/fallBackPicture.png"
              }
              alt="avatar"
              onClick={handleOpen.bind(
                null,
                params.row.image
                  ? `${process.env.REACT_APP_BACKEND_LINK}/${params.row.image}`
                  : "/images/fallBackPicture.png"
              )}
            />
            {params.row.username}
          </div>
        );
      },
    },
    { field: "name", headerName: "nome", flex: 6 },
    {
      field: "email",
      headerName: "Email",
      flex: 6,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 4,
    },
    {
      field: "contacto",
      headerName: "Contacto",
      flex: 2,
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 1.5,
      renderCell: (params) => {
        const content =
          params.row.role !== "Médico/Administrador" &&
          params.row.role !== "Administrador Não Médico" ? (
            <div className="dots__menu__popup">
              <Link
                to={`/users/${params.row.id}/edit`}
                style={{ textDecoration: "none" }}
              >
                <div className="popOverMenu--option">
                  <span>Editar</span>
                  <EditIcon className="popOverMenu--option__icon" />
                </div>
              </Link>

              <Popconfirm
                title={`Desativação de Utilizador`}
                description={`Pretende desativar o utilizador ${params.row.name}?`}
                icon={<ErrorIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={handleDesativar.bind(null, params.row.id)}
              >
                <div className="popOverMenu--option">
                  <span>Desativar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            </div>
          ) : params.row.id === auth.userId ? (
            <div className="dots__menu__popup">
              <Link
                to={`/users/${params.row.id}/edit`}
                style={{ textDecoration: "none" }}
              >
                <div className="popOverMenu--option">
                  <span>Editar</span>
                  <EditIcon className="popOverMenu--option__icon" />
                </div>
              </Link>

              <Popconfirm
                title={`Desativação de Utilizador`}
                description={`Pretende desativar o utilizador ${params.row.name}?`}
                icon={<ErrorIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={handleDesativar.bind(null, params.row.id)}
              >
                <div className="popOverMenu--option">
                  <span>Desativar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            </div>
          ) : (
            <div></div>
          );

        return (
          <div className="cellAction">
            <Space wrap>
              <Popover content={content} trigger="click" placement="bottom">
                <div className="dots__menu">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Popover>
            </Space>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {!error && data && (
        <>
          <div className="datatable caixa">
            {(auth.role === "Médico/Administrador" ||
              auth.role === "Administrador Não Médico") && (
              <div className="datatable__add-button-div">
                <span
                  className="blue-button"
                  onClick={() => navigate("/users/new")}
                >
                  Adicionar Utilizador
                </span>
              </div>
            )}
            <DataGrid
              autoWidth
              className="datagrid"
              rows={data}
              columns={userColumns.concat(actionColumn)}
              autoPageSize
              localeText={localeText}
            />
          </div>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={fotoUser} alt="" className="fotoModal" />
        </Box>
      </Modal>
    </>
  );
}

export default Datatable;
