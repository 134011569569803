import React, { useState, useEffect, useContext } from "react";
import "./ModalEditarStock.scss";
import InputNumber from "../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../shared/components/inputs/InputText/InputText";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import toast from "react-hot-toast";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLoading } from "../../../shared/context/LoadingContext";

function ModalEditarStock({
  handleCloseModalEditar,
  sendRequest,
  dadosProduto,
  stock,
  atualizarStock,
  open,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [nomeProduto, setNomeProduto] = useState(dadosProduto.produto);
  const [qtdIdeal, setQtdIdeal] = useState(dadosProduto.qtdIdeal);
  const [categoria, setCategoria] = useState(dadosProduto.categoriaProduto);
  const [categoriasStock, setCategoriasStock] = useState();

  const clinica = useContext(ClinicaContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCategoriasStock = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCategoriasStock(responseData.categoriasStock);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro buscando os dados das categorias de stock. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchCategoriasStock();
  }, [sendRequest, clinica.clinica._id, auth.token]);

  const handleNomeChange = (value) => {
    setNomeProduto(value);
  };

  const handleQtddIdealChange = (value) => {
    setQtdIdeal(value);
  };

  const handleCategoriaChange = (e) => {
    setCategoria(e.target.value);
  };

  const handleSubmit = async () => {
    startLoading();
    //Atualizando produtos
    try {
      const formData = new FormData();
      formData.append("categoriaStock", categoria);
      formData.append("produto", nomeProduto);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/produtos/${dadosProduto.produtoID}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const formData2 = new FormData();
      formData2.append("qtdIdeal", qtdIdeal);
      formData2.append("produtoId", dadosProduto.produtoID);

      const resultado = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/produto/${stock.id}`,
        "PATCH",
        formData2,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      atualizarStock(resultado.stock, "Produto editado com sucesso");
    } catch (err) {
      toast.error("Erro ao editar produto");
      console.error("err", err);
    } finally {
      stopLoading();
      handleCloseModalEditar();
    }
  };

  const preHandleSubmit = () => {
    let mensagemDeErro = "";
    if (!nomeProduto) {
      mensagemDeErro = "Por favor introduza o nome do Produto!";
      return;
    }
    if (!qtdIdeal) {
      mensagemDeErro = "Por favor preencha Introduza a quantidade ideal!";
      return;
    }

    if (!categoria) {
      mensagemDeErro = "Por favor introduza a categoria de Stock do produto!";
      return;
    }

    if (mensagemDeErro) {
      setValidationError(mensagemDeErro);
    } else if (!mensagemDeErro && validationError) {
      setValidationError("");
    }

    if (!isSubmitting && !mensagemDeErro) {
      setIsSubmitting(true);
      handleSubmit();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModalEditar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box " id="modal__editarStock">
          <h1 className="fluxo-atendimento__modal__titulo">Editar Produto</h1>
          <div className="stockEditar__container">
            <div className="stockEditar__container__data">
              <InputText
                initialValue={nomeProduto}
                handleChange={handleNomeChange}
                label="Nome do Produto"
                notEmpty={true}
              />

              {categoriasStock && (
                <div className="customInputContainer">
                  <select
                    onChange={handleCategoriaChange}
                    className="customInputSelect"
                    value={categoria}
                  >
                    {categoriasStock &&
                      categoriasStock.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.categoria}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">
                    Categoria Stock
                  </label>
                </div>
              )}

              <InputNumber
                initialValue={qtdIdeal}
                handleChange={handleQtddIdealChange}
                min={1}
                notEmpty={true}
                label="Qtd Ideal"
              />
            </div>

            {validationError && (
              <div className="modal__validationErrors__container">
                {validationError}
              </div>
            )}
            <div className="fluxo-atendimento__modal__bottom_btns">
              <span
                className="cancel-btn"
                onClick={handleCloseModalEditar}
                style={{ display: "block" }}
              >
                Fechar
              </span>

              <span
                disabled={isSubmitting}
                className="blue-button"
                onClick={preHandleSubmit}
              >
                {isSubmitting ? "Guardando..." : "Guardar"}
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditarStock;
