import React from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalListaClientesFinancas.scss";
function ModalListaClientesFinancas({
  open,
  handleCloseModal,
  clientes,
  handleChooseCliente,
}) {
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box"
        id="modal__listaClientesFinancas"
      >
        <h1 className="fluxo-atendimento__modal__titulo">Lista de Clientes</h1>

        <div className="modalListaClientesFinancas__container">
          <div className="modalListaClientesFinancas__container__row">
            <span className="modalListaClientesFinancas__container__header">
              Nome
            </span>
            <span className="modalListaClientesFinancas__container__header">
              Nif
            </span>
            <span className="modalListaClientesFinancas__container__header">
              Bi
            </span>
            <span className="modalListaClientesFinancas__container__header">
              Ação
            </span>
          </div>

          {clientes.map((c, index) => (
            <div
              key={index}
              className="modalListaClientesFinancas__container__row"
            >
              <span className="modalListaClientesFinancas__container__item">
                {c.Name}
              </span>
              <span className="modalListaClientesFinancas__container__item">
                {c.TaxId}
              </span>
              <span className="modalListaClientesFinancas__container__item">
                {c.BI}
              </span>
              <div className="modalListaClientesFinancas__container__item">
                <span
                  className="modalListaClientesFinancas__container__btnEscolher"
                  onClick={handleChooseCliente.bind(null, c)}
                >
                  Escolher
                </span>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <span
            className="cancel-btn"
            onClick={handleCloseModal}
            style={{ display: "block" }}
          >
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalListaClientesFinancas;
