import { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import InputText from "../../../shared/components/inputs/InputText/InputText";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../categoriasFinanceiro/CategoriasFinanceiro.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { Popconfirm } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import PageviewIcon from "@mui/icons-material/Pageview";
import toast from "react-hot-toast";
import codigoPaises from "../../clientes/components/codigoPaises";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { useLoading } from "../../../shared/context/LoadingContext";

function ClientesFornecedores({ sendRequest, clinicaId }) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nomeClienteFornecedor, setNomeClienteFornecedor] = useState("");
  const [nifClienteFornecedor, setNifClienteFornecedor] = useState("");
  const [contactoClienteFornecedor, setContactoClienteFornecedor] =
    useState("");
  const [enderecoClienteFornecedor, setEnderecoClienteFornecedor] =
    useState("");
  const [emailClienteFornecedor, setEmailClienteFornecedor] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [idClienteFornecedor, setIdClienteFornecedor] = useState(null);
  const [clientesFornecedores, setClientesFornecedores] = useState([]);
  const [mensagemDeErro, setMensagemDeErro] = useState("");
  const [pais, setPais] = useState({ nome: "CABO VERDE", codigo: "CV" });
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchClientesFornecedores = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clientesfornecedores/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setClientesFornecedores(responseData.clientesFornecedores);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro na busca dos dados dos clientes fornecedores. Por favor tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchClientesFornecedores();
  }, [auth.token, clinicaId, sendRequest]);

  const nomeClienteFornecedorChangeHandler = (valor) => {
    setNomeClienteFornecedor(valor);
  };

  const nifClienteFornecedorChangeHandler = (valor) => {
    setNifClienteFornecedor(valor);
  };

  const contactoClienteFornecedorChangeHandler = (valor) => {
    setContactoClienteFornecedor(valor);
  };

  const enderecoClienteFornecedorChangeHandler = (valor) => {
    setEnderecoClienteFornecedor(valor);
  };

  const emailClienteFornecedorChangeHandler = (valor) => {
    setEmailClienteFornecedor(valor);
  };

  // Custom option rendering function
  const renderOption = (props, option) => (
    <Box
      component="span"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "8px",
      }}
      {...props}
    >
      <Typography variant="subtitle1">{option.nome}</Typography>
      <Typography variant="body2" color="textSecondary">
        {option.codigo}
      </Typography>
    </Box>
  );

  const guardarClienteFornecedor = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("nome", nomeClienteFornecedor);
      formData.append("contacto", contactoClienteFornecedor);
      formData.append("nif", nifClienteFornecedor);
      formData.append("email", emailClienteFornecedor);
      formData.append("endereco", enderecoClienteFornecedor);
      formData.append("pais", JSON.stringify(pais));
      formData.append("clinica", clinicaId);

      const clienteFornecedorCriada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientesfornecedores/`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setClientesFornecedores((current) => [
        ...current,
        clienteFornecedorCriada.clienteFornecedor,
      ]);

      resetarValores();

      setOpenModal(false);
      setIdClienteFornecedor(null);

      toast.success("Cliente/Fornecedor criado com sucesso");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro guardando os dados. Por favor tente novamente."
      );
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const editarClienteFornecedor = async (id) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("nome", nomeClienteFornecedor);
      formData.append("contacto", contactoClienteFornecedor);
      formData.append("nif", nifClienteFornecedor);
      formData.append("email", emailClienteFornecedor);
      formData.append("endereco", enderecoClienteFornecedor);
      formData.append("pais", JSON.stringify(pais));

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientesfornecedores/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const clienteFornecedorCopy = [...clientesFornecedores];
      const clientesFornecedoresModificadas = clienteFornecedorCopy.map(
        (clf) => {
          if (clf.id === id) {
            return {
              id: id,
              nome: nomeClienteFornecedor,
              contacto: contactoClienteFornecedor,
              nif: nifClienteFornecedor,
              endereco: enderecoClienteFornecedor,
              pais: pais,
            };
          } else {
            return clf;
          }
        }
      );

      setClientesFornecedores([...clientesFornecedoresModificadas]);

      resetarValores();
      setIdClienteFornecedor(null);
      setOpenModal(false);

      toast.success("Cliente/Fornecedor modificado com sucesso");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro guardando os dados. Por favor tente novamente."
      );
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const preEditarClienteFornecedor = async (id) => {
    setIdClienteFornecedor(id);
    const clienteFornecedorFiltrado = clientesFornecedores.filter(
      (cat) => cat.id === id
    )[0];

    setNomeClienteFornecedor(clienteFornecedorFiltrado.nome);
    setContactoClienteFornecedor(clienteFornecedorFiltrado.contacto);
    setNifClienteFornecedor(clienteFornecedorFiltrado.nif);
    setEnderecoClienteFornecedor(clienteFornecedorFiltrado.endereco);
    setEmailClienteFornecedor(clienteFornecedorFiltrado.email);
    setPais(clienteFornecedorFiltrado.pais);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setMensagemDeErro("");
    setIdClienteFornecedor(null);
    setOpenModal(false);
    resetarValores();
  };

  const resetarValores = () => {
    setNomeClienteFornecedor("");
    setNifClienteFornecedor("");
    setContactoClienteFornecedor("");
    setEmailClienteFornecedor("");
    setEnderecoClienteFornecedor("");
    setPais({ nome: "CABO VERDE", codigo: "CV" });
  };

  const desativarClienteFornecedor = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientesFornecedores/desativar/${id}`,
        "PATCH",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setClientesFornecedores((current) =>
        current.filter((cat) => cat.id !== id)
      );

      toast.success("Cliente/Fornecedor desativado com sucesso");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro desativando o cliente fornecedor. Por favor tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const validateTelefoneField = (value) => {
    const digitPattern = /^\d{7}$/;
    return digitPattern.test(value.replace(/\s/g, ""));
  };

  const beforeSubmit = (idClienteFornecedor) => {
    let haErro = false;

    if (
      !nomeClienteFornecedor ||
      nomeClienteFornecedor === "" ||
      !contactoClienteFornecedor ||
      contactoClienteFornecedor === "" ||
      !nifClienteFornecedor ||
      nifClienteFornecedor === "" ||
      !enderecoClienteFornecedor ||
      enderecoClienteFornecedor === "" ||
      !pais
    ) {
      setMensagemDeErro("Preencha todos os campos assinalados com *");
      haErro = true;
    } else if (!validateTelefoneField) {
      setMensagemDeErro(
        "O contacto deve ser apenas valores numéricos de 7 digitos e sem espaços"
      );
    } else {
      setMensagemDeErro("");
    }

    if (!isSubmitting && !haErro) {
      setIsSubmitting(true);
      if (idClienteFornecedor) {
        editarClienteFornecedor(idClienteFornecedor);
      } else {
        guardarClienteFornecedor();
      }
    }
  };

  const findClientInfo = async () => {
    startLoading();
    if (pais.nome !== "CABO VERDE") {
      toast.error("Procura por nif só para Cabo Verde");
    } else {
      if (!nifClienteFornecedor || nifClienteFornecedor === "") {
        toast.error("Preencha o campo do nif");
      } else {
        const formData = new FormData();
        formData.append("nif", nifClienteFornecedor);

        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          if (response.payload.length === 0) {
            toast.error("Nenhum cliente encontrado");
          } else if (response.payload.length === 1) {
            toast.success("Cliente encontrado");
            setNomeClienteFornecedor(response.payload[0].Name);
            setNifClienteFornecedor(response.payload[0].TaxId);
          }
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro buscando os dados do cliente fornecedor. Por favor tente novamente."
          );
        } finally {
          stopLoading();
        }
      }
    }
  };

  return (
    <>
      <div className="categoriasGeral__container">
        {auth.perm.includes("c-cx") && (
          <div
            className="categoriasGeral__container__btn-adicionar"
            style={{ width: "240px" }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={(e) => setOpenModal(true)}
            >
              Novo Cliente/Fornecedor
            </Button>
          </div>
        )}

        {clientesFornecedores &&
          clientesFornecedores.map((clienteFornecedor, index) => (
            <div
              className="categoriasFinanceiro__container__categoria"
              key={index}
            >
              <div className="categoriasFinanceiro__container__categoria--1">
                {clienteFornecedor.nome}
              </div>
              <div className="categoriasFinanceiro__container__categoria--2">
                {auth.perm.includes("d-cx") && (
                  <Popconfirm
                    title={`Desativar ClienteFornecedor`}
                    description={`Pretende desativar essa clienteFornecedor?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={desativarClienteFornecedor.bind(
                      null,
                      clienteFornecedor.id
                    )}
                    className="popConfirm--biggerPadding"
                  >
                    <span>Desativar</span>
                  </Popconfirm>
                )}
                {auth.perm.includes("u-cx") && (
                  <span
                    onClick={preEditarClienteFornecedor.bind(
                      null,
                      clienteFornecedor.id
                    )}
                  >
                    Editar
                  </span>
                )}
              </div>
            </div>
          ))}

        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="fluxo-atendimento__modal__box adicionar-categoria__modal"
            id="modal__adicionar-categoria"
          >
            <h1 className="fluxo-atendimento__modal__titulo">
              ClienteFornecedor
            </h1>
            <div
              className="adicionar-categoria__modal__container"
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <InputText
                className="adicionar-categoria__modal__container--span2"
                label="Nome do Cliente/Fornecedor *"
                initialValue={nomeClienteFornecedor}
                handleChange={nomeClienteFornecedorChangeHandler}
                id="1"
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "90% 10%",
                  gap: 0,
                }}
              >
                <InputText
                  className="adicionar-categoria__modal__container--span2"
                  label="Nif do Cliente/Fornecedor *"
                  initialValue={nifClienteFornecedor}
                  handleChange={nifClienteFornecedorChangeHandler}
                  id="2"
                />
                <PageviewIcon
                  style={{
                    fontSize: "63px",
                    cursor: "pointer",
                    fill: "#175c93",
                  }}
                  onClick={findClientInfo}
                />
              </div>

              <InputText
                className="adicionar-categoria__modal__container--span2"
                label="Contacto do Cliente/Fornecedor *"
                initialValue={contactoClienteFornecedor}
                handleChange={contactoClienteFornecedorChangeHandler}
                id="3"
              />

              <FormControl
                style={{ width: "100%" }}
                className="adicionar-categoria__modal__container--span2"
              >
                <Autocomplete
                  id="treatment-autocomplete"
                  value={pais}
                  onChange={(e, value) => setPais(value)}
                  options={codigoPaises}
                  getOptionLabel={(option) =>
                    `${option.nome} - ${option.codigo}`
                  }
                  renderOption={renderOption}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pais"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>

              <InputText
                className="adicionar-categoria__modal__container--span2"
                label="Endereco do Cliente/Fornecedor *"
                initialValue={enderecoClienteFornecedor}
                handleChange={enderecoClienteFornecedorChangeHandler}
                id="4"
              />

              <InputText
                className="adicionar-categoria__modal__container--span2"
                label="Email do Cliente/Fornecedor"
                initialValue={emailClienteFornecedor}
                handleChange={emailClienteFornecedorChangeHandler}
                id="5"
              />
            </div>

            {mensagemDeErro && (
              <div className="erroContainer__categoria-clienteFornecedor">
                {mensagemDeErro}
              </div>
            )}

            <div className="fluxo-atendimento__modal__bottom_btns">
              <span
                className="cancel-btn"
                onClick={handleModalClose}
                style={{ display: "block" }}
              >
                Fechar
              </span>

              <span
                disabled={isSubmitting}
                className="blue-button"
                onClick={
                  idClienteFornecedor
                    ? beforeSubmit.bind(null, idClienteFornecedor)
                    : beforeSubmit.bind(null, null)
                }
              >
                {idClienteFornecedor
                  ? isSubmitting
                    ? "Guardando"
                    : "Guardar"
                  : isSubmitting
                  ? "Guardando"
                  : "Guardar"}
              </span>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default ClientesFornecedores;
