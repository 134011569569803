const codigoPaises = [
  { codigo: "AF", nome: "AFGHANISTAN" },
  { codigo: "AL", nome: "ALBANIA" },
  { codigo: "DZ", nome: "ALGERIA" },
  { codigo: "AS", nome: "AMERICAN SAMOA" },
  { codigo: "AD", nome: "ANDORRA" },
  { codigo: "AO", nome: "ANGOLA" },
  { codigo: "AI", nome: "ANGUILLA" },
  { codigo: "AQ", nome: "ANTARCTICA" },
  { codigo: "AG", nome: "ANTIGUA AND BARBUDA" },
  { codigo: "AR", nome: "ARGENTINA" },
  { codigo: "AM", nome: "ARMENIA" },
  { codigo: "AW", nome: "ARUBA" },
  { codigo: "AU", nome: "AUSTRALIA" },
  { codigo: "AT", nome: "AUSTRIA" },
  { codigo: "AZ", nome: "AZERBAIJAN" },
  { codigo: "BS", nome: "BAHAMAS" },
  { codigo: "BH", nome: "BAHRAIN" },
  { codigo: "BD", nome: "BANGLADESH" },
  { codigo: "BB", nome: "BARBADOS" },
  { codigo: "BY", nome: "BELARUS" },
  { codigo: "BE", nome: "BELGIUM" },
  { codigo: "BZ", nome: "BELIZE" },
  { codigo: "BJ", nome: "BENIN" },
  { codigo: "BM", nome: "BERMUDA" },
  { codigo: "BT", nome: "BHUTAN" },
  { codigo: "BO", nome: "BOLIVIA" },
  { codigo: "BA", nome: "BOSNIA AND HERZEGOVINA" },
  { codigo: "BW", nome: "BOTSWANA" },
  { codigo: "BR", nome: "BRAZIL" },
  { codigo: "IO", nome: "BRITISH INDIAN OCEAN TERRITORY" },
  { codigo: "VG", nome: "BRITISH VIRGIN ISLANDS" },
  { codigo: "BN", nome: "BRUNEI" },
  { codigo: "BG", nome: "BULGARIA" },
  { codigo: "BF", nome: "BURKINA FASO" },
  { codigo: "BI", nome: "BURUNDI" },
  { codigo: "KH", nome: "CAMBODIA" },
  { codigo: "CM", nome: "CAMEROON" },
  { codigo: "CA", nome: "CANADA" },
  { codigo: "CV", nome: "CABO VERDE" },
  { codigo: "KY", nome: "CAYMAN ISLANDS" },
  { codigo: "CF", nome: "CENTRAL AFRICAN REPUBLIC" },
  { codigo: "TD", nome: "CHAD" },
  { codigo: "CL", nome: "CHILE" },
  { codigo: "CN", nome: "CHINA" },
  { codigo: "CX", nome: "CHRISTMAS ISLAND" },
  { codigo: "CC", nome: "COCOS ISLANDS" },
  { codigo: "CO", nome: "COLOMBIA" },
  { codigo: "KM", nome: "COMOROS" },
  { codigo: "CK", nome: "COOK ISLANDS" },
  { codigo: "CR", nome: "COSTA RICA" },
  { codigo: "HR", nome: "CROATIA" },
  { codigo: "CU", nome: "CUBA" },
  { codigo: "CW", nome: "CURACAO" },
  { codigo: "CY", nome: "CYPRUS" },
  { codigo: "CZ", nome: "CZECH REPUBLIC" },
  { codigo: "CD", nome: "DEMOCRATIC REPUBLIC OF THE CONGO" },
  { codigo: "DK", nome: "DENMARK" },
  { codigo: "DJ", nome: "DJIBOUTI" },
  { codigo: "DM", nome: "DOMINICA" },
  { codigo: "DO", nome: "DOMINICAN REPUBLIC" },
  { codigo: "TL", nome: "EAST TIMOR" },
  { codigo: "EC", nome: "ECUADOR" },
  { codigo: "EG", nome: "EGYPT" },
  { codigo: "SV", nome: "EL SALVADOR" },
  { codigo: "GQ", nome: "EQUATORIAL GUINEA" },
  { codigo: "ER", nome: "ERITREA" },
  { codigo: "EE", nome: "ESTONIA" },
  { codigo: "ET", nome: "ETHIOPIA" },
  { codigo: "FK", nome: "FALKLAND ISLANDS" },
  { codigo: "FO", nome: "FAROE ISLANDS" },
  { codigo: "FJ", nome: "FIJI" },
  { codigo: "FI", nome: "FINLAND" },
  { codigo: "FR", nome: "FRANCE" },
  { codigo: "PF", nome: "FRENCH POLYNESIA" },
  { codigo: "GA", nome: "GABON" },
  { codigo: "GM", nome: "GAMBIA" },
  { codigo: "GE", nome: "GEORGIA" },
  { codigo: "DE", nome: "GERMANY" },
  { codigo: "GH", nome: "GHANA" },
  { codigo: "GI", nome: "GIBRALTAR" },
  { codigo: "GR", nome: "GREECE" },
  { codigo: "GL", nome: "GREENLAND" },
  { codigo: "GD", nome: "GRENADA" },
  { codigo: "GU", nome: "GUAM" },
  { codigo: "GT", nome: "GUATEMALA" },
  { codigo: "GG", nome: "GUERNSEY" },
  { codigo: "GN", nome: "GUINEA" },
  { codigo: "GW", nome: "GUINEA-BISSAU" },
  { codigo: "GY", nome: "GUYANA" },
  { codigo: "HT", nome: "HAITI" },
  { codigo: "HN", nome: "HONDURAS" },
  { codigo: "HK", nome: "HONG KONG" },
  { codigo: "HU", nome: "HUNGARY" },
  { codigo: "IS", nome: "ICELAND" },
  { codigo: "IN", nome: "INDIA" },
  { codigo: "ID", nome: "INDONESIA" },
  { codigo: "IR", nome: "IRAN" },
  { codigo: "IQ", nome: "IRAQ" },
  { codigo: "IE", nome: "IRELAND" },
  { codigo: "IM", nome: "ISLE OF MAN" },
  { codigo: "IL", nome: "ISRAEL" },
  { codigo: "IT", nome: "ITALY" },
  { codigo: "CI", nome: "IVORY COAST" },
  { codigo: "JM", nome: "JAMAICA" },
  { codigo: "JP", nome: "JAPAN" },
  { codigo: "JE", nome: "JERSEY" },
  { codigo: "JO", nome: "JORDAN" },
  { codigo: "KZ", nome: "KAZAKHSTAN" },
  { codigo: "KE", nome: "KENYA" },
  { codigo: "KI", nome: "KIRIBATI" },
  { codigo: "XK", nome: "KOSOVO" },
  { codigo: "KW", nome: "KUWAIT" },
  { codigo: "KG", nome: "KYRGYZSTAN" },
  { codigo: "LA", nome: "LAOS" },
  { codigo: "LV", nome: "LATVIA" },
  { codigo: "LB", nome: "LEBANON" },
  { codigo: "LS", nome: "LESOTHO" },
  { codigo: "LR", nome: "LIBERIA" },
  { codigo: "LY", nome: "LIBYA" },
  { codigo: "LI", nome: "LIECHTENSTEIN" },
  { codigo: "LT", nome: "LITHUANIA" },
  { codigo: "LU", nome: "LUXEMBOURG" },
  { codigo: "MO", nome: "MACAU" },
  { codigo: "MK", nome: "MACEDONIA" },
  { codigo: "MG", nome: "MADAGASCAR" },
  { codigo: "MW", nome: "MALAWI" },
  { codigo: "MY", nome: "MALAYSIA" },
  { codigo: "MV", nome: "MALDIVES" },
  { codigo: "ML", nome: "MALI" },
  { codigo: "MT", nome: "MALTA" },
  { codigo: "MH", nome: "MARSHALL ISLANDS" },
  { codigo: "MR", nome: "MAURITANIA" },
  { codigo: "MU", nome: "MAURITIUS" },
  { codigo: "YT", nome: "MAYOTTE" },
  { codigo: "MX", nome: "MEXICO" },
  { codigo: "FM", nome: "MICRONESIA" },
  { codigo: "MD", nome: "MOLDOVA" },
  { codigo: "MC", nome: "MONACO" },
  { codigo: "MN", nome: "MONGOLIA" },
  { codigo: "ME", nome: "MONTENEGRO" },
  { codigo: "MS", nome: "MONTSERRAT" },
  { codigo: "MA", nome: "MOROCCO" },
  { codigo: "MZ", nome: "MOZAMBIQUE" },
  { codigo: "MM", nome: "MYANMAR" },
  { codigo: "NA", nome: "NAMIBIA" },
  { codigo: "NR", nome: "NAURU" },
  { codigo: "NP", nome: "NEPAL" },
  { codigo: "NL", nome: "NETHERLANDS" },
  { codigo: "AN", nome: "NETHERLANDS ANTILLES" },
  { codigo: "NC", nome: "NEW CALEDONIA" },
  { codigo: "NZ", nome: "NEW ZEALAND" },
  { codigo: "NI", nome: "NICARAGUA" },
  { codigo: "NE", nome: "NIGER" },
  { codigo: "NG", nome: "NIGERIA" },
  { codigo: "NU", nome: "NIUE" },
  { codigo: "KP", nome: "NORTH KOREA" },
  { codigo: "MP", nome: "NORTHERN MARIANA ISLANDS" },
  { codigo: "NO", nome: "NORWAY" },
  { codigo: "OM", nome: "OMAN" },
  { codigo: "PK", nome: "PAKISTAN" },
  { codigo: "PW", nome: "PALAU" },
  { codigo: "PS", nome: "PALESTINE" },
  { codigo: "PA", nome: "PANAMA" },
  { codigo: "PG", nome: "PAPUA NEW GUINEA" },
  { codigo: "PY", nome: "PARAGUAY" },
  { codigo: "PE", nome: "PERU" },
  { codigo: "PH", nome: "PHILIPPINES" },
  { codigo: "PN", nome: "PITCAIRN" },
  { codigo: "PL", nome: "POLAND" },
  { codigo: "PT", nome: "PORTUGAL" },
  { codigo: "PR", nome: "PUERTO RICO" },
  { codigo: "QA", nome: "QATAR" },
  { codigo: "CG", nome: "REPUBLIC OF THE CONGO" },
  { codigo: "RE", nome: "REUNION" },
  { codigo: "RO", nome: "ROMANIA" },
  { codigo: "RU", nome: "RUSSIA" },
  { codigo: "RW", nome: "RWANDA" },
  { codigo: "BL", nome: "SAINT BARTHELEMY" },
  { codigo: "SH", nome: "SAINT HELENA" },
  { codigo: "KN", nome: "SAINT KITTS AND NEVIS" },
  { codigo: "LC", nome: "SAINT LUCIA" },
  { codigo: "MF", nome: "SAINT MARTIN" },
  { codigo: "PM", nome: "SAINT PIERRE AND MIQUELON" },
  { codigo: "VC", nome: "SAINT VINCENT AND THE GRENADINES" },
  { codigo: "WS", nome: "SAMOA" },
  { codigo: "SM", nome: "SAN MARINO" },
  { codigo: "ST", nome: "SAO TOME AND PRINCIPE" },
  { codigo: "SA", nome: "SAUDI ARABIA" },
  { codigo: "SN", nome: "SENEGAL" },
  { codigo: "RS", nome: "SERBIA" },
  { codigo: "SC", nome: "SEYCHELLES" },
  { codigo: "SL", nome: "SIERRA LEONE" },
  { codigo: "SG", nome: "SINGAPORE" },
  { codigo: "SX", nome: "SINT MAARTEN" },
  { codigo: "SK", nome: "SLOVAKIA" },
  { codigo: "SI", nome: "SLOVENIA" },
  { codigo: "SB", nome: "SOLOMON ISLANDS" },
  { codigo: "SO", nome: "SOMALIA" },
  { codigo: "ZA", nome: "SOUTH AFRICA" },
  { codigo: "KR", nome: "SOUTH KOREA" },
  { codigo: "SS", nome: "SOUTH SUDAN" },
  { codigo: "ES", nome: "SPAIN" },
  { codigo: "LK", nome: "SRI LANKA" },
  { codigo: "SD", nome: "SUDAN" },
  { codigo: "SR", nome: "SURINAME" },
  { codigo: "SJ", nome: "SVALBARD AND JAN MAYEN" },
  { codigo: "SZ", nome: "SWAZILAND" },
  { codigo: "SE", nome: "SWEDEN" },
  { codigo: "CH", nome: "SWITZERLAND" },
  { codigo: "SY", nome: "SYRIA" },
  { codigo: "TW", nome: "TAIWAN" },
  { codigo: "TJ", nome: "TAJIKISTAN" },
  { codigo: "TZ", nome: "TANZANIA" },
  { codigo: "TH", nome: "THAILAND" },
  { codigo: "TG", nome: "TOGO" },
  { codigo: "TK", nome: "TOKELAU" },
  { codigo: "TO", nome: "TONGA" },
  { codigo: "TT", nome: "TRINIDAD AND TOBAGO" },
  { codigo: "TN", nome: "TUNISIA" },
  { codigo: "TR", nome: "TURKEY" },
  { codigo: "TM", nome: "TURKMENISTAN" },
  { codigo: "TC", nome: "TURKS AND CAICOS ISLANDS" },
  { codigo: "TV", nome: "TUVALU" },
  { codigo: "VI", nome: "U.S. VIRGIN ISLANDS" },
  { codigo: "UG", nome: "UGANDA" },
  { codigo: "UA", nome: "UKRAINE" },
  { codigo: "AE", nome: "UNITED ARAB EMIRATES" },
  { codigo: "GB", nome: "UNITED KINGDOM" },
  { codigo: "US", nome: "UNITED STATES" },
  { codigo: "UY", nome: "URUGUAY" },
  { codigo: "UZ", nome: "UZBEKISTAN" },
  { codigo: "VU", nome: "VANUATU" },
  { codigo: "VA", nome: "VATICAN" },
  { codigo: "VE", nome: "VENEZUELA" },
  { codigo: "VN", nome: "VIETNAM" },
  { codigo: "WF", nome: "WALLIS AND FUTUNA" },
  { codigo: "EH", nome: "WESTERN SAHARA" },
  { codigo: "YE", nome: "YEMEN" },
  { codigo: "ZM", nome: "ZAMBIA" },
  { codigo: "ZW", nome: "ZIMBABWE" },
  { codigo: " ", nome: "" },
];

export default codigoPaises;
