import { useState, useEffect } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";

import InputNumber from "../../../../../../shared/components/inputs/InputNumber/InputNumber";

import "./ElementoParcela.scss";

function ElementoParcela({
  dataLimitePagamento,
  quantiaPorPagar,
  indiceParcela,
  totalParcelas,
  entrada,
  entradaChange,
  parcelasValueChange,
  dataLimitePagamentoChange,
  comEntrada,
}) {
  const [vencimento, setVencimento] = useState(dataLimitePagamento);
  const [divida, setDivida] = useState(Number(quantiaPorPagar));

  useEffect(() => {
    setDivida(Number(quantiaPorPagar));
    setVencimento(dataLimitePagamento);
  }, [quantiaPorPagar, dataLimitePagamento]);

  const vencimentoChangeHandler = (value) => {
    setVencimento(value);
    dataLimitePagamentoChange(indiceParcela, value);
  };

  const parcelasValueChangeHandler = (value) => {
    setDivida(value);
    parcelasValueChange(indiceParcela, value);
  };

  const entradaChangeHandler = (value) => {
    setDivida(value);
    entradaChange(value);
  };

  return (
    <div className="aprovar-orcamento__modal__parcela-container">
      {entrada && (
        <span className="aprovar-orcamento__modal__parcela-entradaSpan">
          Entrada de
        </span>
      )}

      {indiceParcela >= 0 && !entrada && comEntrada && totalParcelas >= 1 && (
        <TextField
          disabled
          id="outlined-disabled"
          label={`Parcela`}
          value={`${indiceParcela} de ${totalParcelas}`}
          className="aprovar-orcamento__modal__indice-parcela"
        />
      )}

      {indiceParcela >= 0 && !entrada && !comEntrada && totalParcelas > 1 && (
        <TextField
          disabled
          id="outlined-disabled"
          label={`Parcela`}
          value={`${indiceParcela + 1} de ${totalParcelas}`}
          className="aprovar-orcamento__modal__indice-parcela"
        />
      )}

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          className="aprovar-orcamento__modal__datePicker"
          label="Vencimento*"
          inputFormat="DD-MM-YYYY"
          value={vencimento}
          onChange={vencimentoChangeHandler}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      {entrada ? (
        <InputNumber
          min={1}
          handleChange={entradaChangeHandler}
          initialValue={divida}
          label="Valor*"
          notEmpty={true}
        />
      ) : (
        <InputNumber
          min={1}
          handleChange={parcelasValueChangeHandler}
          initialValue={divida}
          label="Valor*"
          notEmpty={true}
        />
      )}
    </div>
  );
}

export default ElementoParcela;
