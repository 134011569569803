import { useRef, useEffect, useState, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfOrcamentoStyle.scss";
import moment from "moment/moment";
import { AuthContext } from "../../../../context/auth-context";

import { useHttpClient } from "../../../../hooks/http-hook";

function PdfOrcamentoInps({ orcamentoData, showSingleP }) {
  const [idProtese, setIdProtese] = useState([]);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCategoriaTratamento = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriastratamento/clinica/${orcamentoData.clinica.id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const protese = responseData.categoriasTratamento.filter(
          (t) => t.categoria === "Prótese Dentária"
        )[0];

        setIdProtese(protese._id);
      } catch (err) {}
    };
    fetchCategoriaTratamento();
  }, [auth.token, sendRequest, orcamentoData.clinica.id]);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Orçamento-${orcamentoData.cliente.nome}_${moment(
      new Date()
    ).format("DD-MM-YYYY")}`,
    onAfterPrint: () => {
      showSingleP();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  orcamentoData.serviceList.forEach((sl) => {
    if (!sl.faces) {
      sl = { ...sl, faces: "" };
    }
  });

  let totalBeneficiarioSemProtese = 0;
  let totalInpsSemProtese = 0;
  let totalBeneficiarioComProtese = 0;
  let totalInpsComProtese = 0;

  orcamentoData.serviceList.forEach((el) => {
    if (el.tratamento.categoria === idProtese) {
      totalBeneficiarioComProtese += el.tratamento.preco_beneficiario;
      totalInpsComProtese += el.tratamento.comparticipacao_inps;
    } else {
      totalBeneficiarioSemProtese += el.tratamento.preco_beneficiario;
      totalInpsSemProtese += el.tratamento.comparticipacao_inps;
    }
  });

  return (
    <>
      <div ref={componentRef} className="pdf-orcamento-wrapper">
        <div className="pdf-orcamento-cabecalho">
          <div className="pdf-orcamento-cabecalho-left">
            <img
              src={`${process.env.REACT_APP_BACKEND_LINK}/${orcamentoData.clinica.logotipo}`}
              alt=""
            />
          </div>

          <div className="pdf-orcamento-cabecalho-right">
            <span>{orcamentoData.clinica.nome}</span>
            <span>
              {orcamentoData.medico_responsavel.genero === "masculino"
                ? "Dr. "
                : "Dra. "}
              {orcamentoData.medico_responsavel.name}/
              {orcamentoData.medico_responsavel.profissao}
            </span>
            <span>Telef: {orcamentoData.clinica.contacto}</span>
            <span>{orcamentoData.clinica.endereco}</span>
            <span>NIF: {orcamentoData.clinica.nif}</span>
          </div>
        </div>
        <div className="pdf-orcamento-dadosEOdontograma">
          <div className="pdf-orcamento-outrosDados">
            <h1 className="pdf-orcamento-outrosDados__titulo">
              Prescrição/Orçamento Estomatológico
            </h1>
            <hr className="pdf-orcamento-outrosDados__line" />
            <div className="pdf-orcamento-outrosDados__dadosDuplos">
              <div className="pdf-orcamento-outrosDados__dadosSimples">
                <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                  Nº:{" "}
                </span>
                <span>{`${
                  orcamentoData.numero
                }/${new Date().getFullYear()}`}</span>
              </div>
              <div className="pdf-orcamento-outrosDados__dadosSimples">
                <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                  DATA:{" "}
                </span>
                <span>{moment().format("DD/MM/YYYY")}</span>
              </div>
            </div>
            <div className="pdf-orcamento-outrosDados__dadosSimples">
              <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                NOME DO PACIENTE:{" "}
              </span>
              <span>{orcamentoData.cliente.nome}</span>
            </div>
            <div className="pdf-orcamento-outrosDados__dadosSimples">
              <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                NIF:{" "}
              </span>
              <span>
                {orcamentoData.cliente.nif ? orcamentoData.cliente.nif : ""}
              </span>
            </div>
            <div className="pdf-orcamento-outrosDados__dadosSimples">
              <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                CATEGORIA DE BENEFICIÁRIO:{" "}
              </span>
              <span>INPS</span>
            </div>
            <hr className="pdf-orcamento-outrosDados__line" />
          </div>
          <div className="odontograma-container">
            <img src="/images/odontograma.png" alt="" />
          </div>
        </div>
        <div className="divTabela">
          <table>
            <tbody>
              <tr className="headingLine">
                <th className="pdf-orc-left">Cod.</th>
                <th className="pdf-orc-left">Designação</th>
                <th>Qtd.</th>
                <th className="pdf-orc-center">Dentes</th>
                <th>Pr. Unit.</th>
                <th>Beneficiário</th>
                <th>Inps</th>
              </tr>
              {orcamentoData.serviceList.map((el, i) => (
                <tr
                  className={`${
                    i === 0 ? "primeiraLinha normalLine" : "normalLine"
                  }`}
                  key={i}
                >
                  <td className="pdf-orc-left">{el.tratamento.codigo}</td>
                  <td className="pdf-orc-left">
                    {el.tratamento.designacao} {" " + el.faces}
                  </td>
                  <td>1</td>
                  <td className="pdf-orc-center">{el.dente}</td>

                  <td>
                    {Intl.NumberFormat("pt-BR").format(
                      el.tratamento.preco_segurado
                    )}
                  </td>

                  <td>
                    {Intl.NumberFormat("pt-BR").format(
                      el.tratamento.preco_beneficiario
                    )}
                  </td>

                  <td>
                    {Intl.NumberFormat("pt-BR").format(
                      el.tratamento.comparticipacao_inps
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="orcamento-rodape">
          <hr className="orcamento-rodape__linha" />
          <div className="orcamento-rodape--left">
            <span className="orcamento-rodape--left__text">O Clínico</span>
            <hr className="orcamento-rodape--left__hr" />
          </div>
          <div className="orcamento-rodape--right">
            <div className="orcamento-rodape__div">
              <span className="orcamento-rodape--right__lineTitle">
                Total Orçamento
              </span>
              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(
                  totalBeneficiarioComProtese + totalBeneficiarioSemProtese
                )}
              </span>

              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(
                  totalInpsComProtese + totalInpsSemProtese
                )}
              </span>
            </div>
            <hr className="orcamento-rodape__linha orcamento-rodape__linha--pequena" />
            <div className="orcamento-rodape__div">
              <span className="orcamento-rodape--right__lineTitle">
                Total Tratamentos Dentários
              </span>
              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(totalBeneficiarioSemProtese)}
              </span>

              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(totalInpsSemProtese)}
              </span>
            </div>
            <hr className="orcamento-rodape__linha orcamento-rodape__linha--pequena" />
            <div className="orcamento-rodape__div">
              <span className="orcamento-rodape--right__lineTitle">
                Total Próteses Dentárias
              </span>
              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(totalBeneficiarioComProtese)}
              </span>

              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(totalInpsComProtese)}
              </span>
            </div>
            <hr className="orcamento-rodape__linha orcamento-rodape__linha--pequena" />
          </div>
        </div>
      </div>
    </>
  );
}

export default PdfOrcamentoInps;
