import { useState, useEffect } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";
import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalFiltrosDespesas.scss";

function ModalFiltrosDespesas({
  openModalFiltros,
  handleCloseModalFiltros,
  handleFiltrar,
  despesas,
  filtrosAplicados,
  filtroCaixa,
  filtroCategoria,
  filtroMetodoPagamento,
  clinicaId,
  auth,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [categoria, setCategoria] = useState(
    filtroCategoria ? filtroCategoria : "todas"
  );
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState(filtroCaixa ? filtroCaixa : "todas");
  const [caixas, setCaixas] = useState([]);
  const [radioPagas, setRadioPagas] = useState(
    filtrosAplicados.includes("pagas") ? true : false
  );
  const [radioNPagas, setRadioNPagas] = useState(
    filtrosAplicados.includes("naoPagas") ? true : false
  );
  const [emAtraso, setEmAtraso] = useState(
    filtrosAplicados.includes("emAtraso") ? true : false
  );
  const [showEmAtraso, setShowEmAtraso] = useState(
    !filtrosAplicados.includes("pagas") ? true : false
  );
  const [comComprovante, setComComprovante] = useState(
    filtrosAplicados.includes("comComprovante") ? true : false
  );
  const [showComComprovante, setShowComComprovante] = useState(
    !filtrosAplicados.includes("naoPagas") ? true : false
  );

  const { sendRequest } = useHttpClient();

  const formasPagamento = [
    "Todos",
    "Dinheiro",
    "Cheque",
    // "Cheque Visado",
    // "Transferência de crédito",
    "Internet banking",
    // "Cartão do banco",
    // "Débito direto da conta bancária",
    "Cartão de crédito",
    // "Cartão de débito",
  ];
  const [metodoPagamento, setMetodoPagamento] = useState(
    filtroMetodoPagamento ? filtroMetodoPagamento : "Todos"
  );

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/despesa/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCategorias(
          responseData.categoriasFinanceiro.map((cat) => {
            return { id: cat.id, value: cat.categoria };
          })
        );
      } catch (err) {
        console.error("err", err);
      }
    };

    const fetchCaixas = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCaixas(
          responseData.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
      } catch (err) {
        console.error("err", err);
      }
    };
    fetchCaixas();
    fetchCategorias();
  }, [auth.token, clinicaId, sendRequest]);

  useEffect(() => {
    setOpenModal(openModalFiltros);
  }, [openModalFiltros]);

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const handlePagasChange = (e) => {
    setRadioPagas(e.target.checked);
    setRadioNPagas(!e.target.checked);
    setEmAtraso(false);
    setShowEmAtraso(false);
    setShowComComprovante(true);
  };

  const handleNPagasChange = (e) => {
    setRadioNPagas(e.target.checked);
    setRadioPagas(!e.target.checked);
    setShowEmAtraso(true);
    setShowComComprovante(false);
    setComComprovante(false);
  };

  const filtrarModalHandler = () => {
    let despesasFiltradas = [];
    despesas.forEach((despesa) => {
      if (
        (despesa.despesaPaga && radioPagas) ||
        (!despesa.despesaPaga && radioNPagas) ||
        (!radioPagas && !radioNPagas)
      ) {
        if (
          (!despesa.despesaPaga &&
            emAtraso &&
            moment(despesa.dataLimitePagamento).isBefore(moment(), "day")) ||
          (!emAtraso &&
            !despesa.despesaPaga &&
            !moment(despesa.dataLimitePagamento).isBefore(moment(), "day")) ||
          (despesa.despesaPaga && !emAtraso) ||
          (!despesa.despesaPaga && !emAtraso)
        ) {
          if (
            (despesa.despesaPaga &&
              (despesa.caixa._id === caixa || caixa === "todas") &&
              (despesa.metodoPagamento === metodoPagamento ||
                metodoPagamento === "Todos") &&
              (despesa.categoria._id === categoria || categoria === "todas")) ||
            (caixa === "todas" &&
              categoria === "todas" &&
              metodoPagamento === "Todos")
          ) {
            despesasFiltradas.push({ ...despesa });
          }
        }
      }
    });

    const filtrosApli = [];
    radioPagas && filtrosApli.push("pagas");
    radioNPagas && filtrosApli.push("naoPagas");
    emAtraso && filtrosApli.push("emAtraso");
    comComprovante && filtrosApli.push("comComprovante");
    emAtraso && filtrosApli.push("emAtraso");

    handleFiltrar(
      despesasFiltradas,
      filtrosApli,
      caixa,
      categoria,
      metodoPagamento,
      "",
      "modalFiltros"
    );
    handleCloseModalFiltros();
  };

  const resetModalHandler = () => {
    handleFiltrar(despesas, [], null, null, null, "", "modalFiltros");
    handleCloseModalFiltros();
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalFiltros}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box" id="filtros__modal">
          <div className="filtros__modal__header">
            <h1>Filtrar por</h1>
            <span onClick={resetModalHandler}>Resetar</span>
          </div>
          <div className="filtros__modal__container">
            <div className="modalFiltros__radios">
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="estadoRadios"
                  id="radioPagas"
                  checked={radioPagas}
                  onChange={handlePagasChange}
                />
                <label htmlFor="radioPagas">Pagas</label>
              </div>
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="estadoRadios"
                  id="radioNPagas"
                  checked={radioNPagas}
                  onChange={handleNPagasChange}
                />
                <label htmlFor="radioNPagas">Não Pagas</label>
              </div>
            </div>

            <div className="modalFiltros__checkboxes2">
              {showComComprovante && (
                <div className="modalFiltros__checkboxes__container">
                  <input
                    type="checkbox"
                    name=""
                    id="comprovante"
                    checked={comComprovante}
                    onChange={(e) => setComComprovante(e.target.checked)}
                  />
                  <label htmlFor="comprovante">Com recibo/comprovante</label>
                </div>
              )}

              {showEmAtraso && (
                <div className="modalFiltros__checkboxes__container">
                  <input
                    type="checkbox"
                    name=""
                    id="emAtraso"
                    checked={emAtraso}
                    onChange={(e) => setEmAtraso(e.target.checked)}
                  />
                  <label htmlFor="emAtraso">Em atraso</label>
                </div>
              )}
            </div>
            {radioPagas && (
              <div className="modalFiltros__selects">
                {caixas && caixa && (
                  <div className="customInputContainer">
                    <select
                      onChange={(e) => setCaixa(e.target.value)}
                      className="customInputSelect"
                      value={caixa}
                    >
                      <option key={0} value="todas">
                        Todas
                      </option>
                      {caixas &&
                        caixa &&
                        caixas.map((item, index) => (
                          <option key={index + 1} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                    </select>
                    <label className="customInputLabelSelect">Caixa</label>
                  </div>
                )}

                <InputSelect
                  label="Metodo de pagamento"
                  initialValue={metodoPagamento}
                  handleChange={metodoPagamentoChangeHandler}
                  items={formasPagamento}
                />

                {categorias && categoria && (
                  <div className="customInputContainer">
                    <select
                      onChange={(e) => setCategoria(e.target.value)}
                      className="customInputSelect"
                      value={categoria}
                    >
                      <option key={0} value="todas">
                        Todas
                      </option>
                      {categorias &&
                        categoria &&
                        categorias.map((item, index) => (
                          <option key={index + 1} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                    </select>
                    <label className="customInputLabelSelect">
                      Categoria Despesa
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalFiltros}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span className="blue-button" onClick={filtrarModalHandler}>
              Filtrar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalFiltrosDespesas;
