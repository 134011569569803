import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function SugestaoForm({
  handleAdicionarSugestao,
  sugestaoModalOpen,
  closeModalSugestao,
}) {
  const [open, setOpen] = useState(sugestaoModalOpen);
  const [titulo, setTitulo] = useState("");
  const [textoImpresso, setTextoImpresso] = useState("");

  useEffect(() => {
    setOpen(sugestaoModalOpen);
  }, [sugestaoModalOpen]);

  const handleClose = () => {
    closeModalSugestao();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Adicionar Sugestão</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="sugestaoTitulo"
            label="Titulo"
            type="text"
            fullWidth
            onChange={(e) => setTitulo(e.target.value)}
          />

          <TextField
            margin="dense"
            id="sugestaoTextoImpresso"
            label="Texto"
            type="text"
            fullWidth
            multiline
            rows={4}
            onChange={(e) => setTextoImpresso(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={handleAdicionarSugestao.bind(null, titulo, textoImpresso)}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SugestaoForm;
