import { useState, useEffect, useContext } from "react";
import moment from "moment";
import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";

//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import "./ModalDespesa.scss";

function ModalPagarDespesa({
  OpenModalPagarDespesa,
  handleCloseModalPagarDespesa,
  sendRequest,
  updateDespesas,
  dadosModalPagarDespesa,
  auth,
  clinicaId,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(OpenModalPagarDespesa);
  const [dataPagamento, setDataPagamento] = useState(moment());
  const formasPagamento = [
    "Dinheiro",
    "Cheque",
    "Cheque Visado",
    "Transferência de crédito",
    "Internet banking",
    "Cartão do banco",
    "Débito direto da conta bancária",
    "Cartão de crédito",
    "Cartão de débito",
  ];
  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);
  const [comprovativo, setComprovativo] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setOpenModal(OpenModalPagarDespesa);
  }, [OpenModalPagarDespesa]);

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const guardarDespesaHandler = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("dataPagamento", dataPagamento);
      formData.append("metodoPagamento", metodoPagamento);
      if (comprovativo) {
        const modifiedFile = new File(
          [comprovativo],
          `${clinicaId}-${comprovativo.name}`,
          { type: comprovativo.type }
        );
        formData.append("comprovativo", modifiedFile);
      }
      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/despesas/pagar/${dadosModalPagarDespesa.id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      dadosModalPagarDespesa.metodoPagamento = metodoPagamento;
      dadosModalPagarDespesa.dataPagamento = dataPagamento;
      dadosModalPagarDespesa.despesaPaga = true;

      updateDespesas(dadosModalPagarDespesa, "atualizar");
      toast.success("Despesa paga com sucesso");
    } catch (err) {
      console.error("err", err);

      toast.error(
        "Ocorreu um erro guardando os dados. Por favor tente novamente."
      );
    } finally {
      setIsSubmitting(false);
      stopLoading();
      handleCloseModalPagarDespesa();
    }
  };

  const preGuardarDespesaHandler = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      guardarDespesaHandler();
    }
  };

  const handleFileChange = (event) => {
    // Check file size (7MB limit)
    const maxSizeInBytes = 7 * 1024 * 1024; // 3MB in bytes
    const file = event.target.files[0];
    // Check file extension
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
    if (!allowedExtensions.test(file.name)) {
      setErrorMessage(`O ficheiro "${file.name}" tem uma extensão inválida.`);
    } else if (file.size > maxSizeInBytes) {
      setErrorMessage(
        `O ficheiro "${file.name}" excede o tamanho máximo permitido (7MB).`
      );
    } else {
      setErrorMessage("");
      setComprovativo(file);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalPagarDespesa}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-despesa__modal"
          id="modal__adicionar-despesa"
        >
          <h1 className="fluxo-atendimento__modal__titulo">Pagar despesa</h1>
          <div className="adicionar-despesa__modal__container">
            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={dadosModalPagarDespesa.descricao}
                className="customInput inputNumber"
                id="descricao"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="descricao">
                Descrição
              </label>
            </div>

            <div className="customInputContainer adicionar-despesa__modal__container--span1">
              <input
                type="text"
                defaultValue={dadosModalPagarDespesa.valor}
                className="customInput inputNumber"
                id="valor"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="valor">
                Valor
              </label>
            </div>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data de vencimento*"
                inputFormat="DD-MM-YYYY"
                value={dadosModalPagarDespesa.dataLimitePagamento}
                renderInput={(params) => <TextField {...params} />}
                disabled
                readOnly
                onChange={() => {}}
              />
            </LocalizationProvider>

            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={dadosModalPagarDespesa.categoria.categoria}
                className="customInput inputNumber"
                id="categoria"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="categoria">
                Categoria
              </label>
            </div>

            <div className="customInputContainer adicionar-despesa__modal__container--span1">
              <input
                type="text"
                defaultValue={dadosModalPagarDespesa.caixa.caixa}
                className="customInput inputNumber"
                id="caixa"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="caixa">
                Caixa
              </label>
            </div>
            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={dadosModalPagarDespesa.observacao}
              className="adicionar-despesa__modal__container__textarea"
              readOnly
              style={{ color: "#88959e" }}
            ></textarea>
          </div>

          <div
            className="adicionar-despesa__modal__pagamento"
            style={{ marginTop: 25, marginBottom: 25 }}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data do Pagamento*"
                inputFormat="DD-MM-YYYY"
                value={dataPagamento}
                onChange={(value) => setDataPagamento(value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <InputSelect
              label="Forma de pagamento"
              initialValue={metodoPagamento}
              handleChange={metodoPagamentoChangeHandler}
              items={formasPagamento}
            />
          </div>

          {comprovativo && (
            <div className="despesas--comprovativo-selecionado">
              Comprovativo Selecionado: {comprovativo.name}
            </div>
          )}

          <div>
            <input
              type="file"
              style={{ display: "none" }}
              id="fileInput"
              onChange={handleFileChange}
            />
            <label htmlFor="fileInput" className="anexar-comprovativo">
              {comprovativo ? "Substituir comprovativo" : "Anexar comprovativo"}
            </label>
          </div>

          <div className="despesas--regras-ficheiro">
            <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
              O Tamanho máximo do upload é 7MB
            </span>
            <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
              Formatos aceites: .pdf, .png, .jpg e .jpeg
            </span>
          </div>

          {errorMessage && (
            <div className="modal-mensagem-de-erro">
              <span>{errorMessage}</span>
            </div>
          )}

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalPagarDespesa}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting || errorMessage}
              className="blue-button"
              onClick={preGuardarDespesaHandler}
            >
              {isSubmitting ? "Pagando..." : "Pagar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalPagarDespesa;
