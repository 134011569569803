import React from "react";
import { Link } from "react-router-dom";
import "./Tooltip.scss";

const Tooltip = ({ event, onEdit, handleApagarAgendamento, onClose }) => {
  // const canEdit =
  //   !event.extendedProps.isEvaluation &&
  //   !event.extendedProps.inTransition &&
  //   !event.extendedProps.isAttended &&
  //   !event.extendedProps.isMissed &&
  //   !event.extendedProps.isCompromisso;
  const canDelete =
    !event.extendedProps.isAttended && !event.extendedProps.isMissed;

  const handleBeforeApagarAgendamento = (event) => {
    handleApagarAgendamento(event);
    onClose();
  };
  return (
    <div className="tooltip">
      <h3 className="tooltip__title">
        {event.extendedProps.clientId ? (
          <React.Fragment>
            <Link
              to={`/clientes/${event.extendedProps.clientId}`}
              className="tooltip__client-link"
            >
              {event.title.split(" - ")[0]}
            </Link>
            {" - "}
            {event.title.split(" - ")[1]}
          </React.Fragment>
        ) : (
          event.title
        )}
      </h3>
      <p className="tooltip__time">
        Início: {event.start.toLocaleTimeString()}
      </p>
      <p className="tooltip__time">Fim: {event.end.toLocaleTimeString()}</p>
      <div className="tooltip__actions">
        {/* {canEdit && (
          <button
            onClick={() => onEdit(event)}
            className="tooltip__action-btn tooltip__action-btn--edit"
          >
            Editar
          </button>
        )} */}
        {canDelete && (
          <button
            onClick={handleBeforeApagarAgendamento.bind(null, event)}
            className="tooltip__action-btn tooltip__action-btn--delete"
          >
            Excluir
          </button>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
