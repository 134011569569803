// useWebSocket.js
import { useEffect, useRef, useCallback, useContext } from "react";
import { ClinicaContext } from "../context/clinica-context";

const useWebSocket = (url, onMessage, dependencies) => {
  const socketRef = useRef(null);
  const clinicaId = useContext(ClinicaContext).clinica._id;

  const connectWebSocket = useCallback(() => {
    socketRef.current = new WebSocket(url);

    socketRef.current.addEventListener("message", (event) => {
      const parsedSocketData = JSON.parse(event.data);
      // Filter the documents based on the clinicaId
      if (
        parsedSocketData.tabela === "urgencias" &&
        parsedSocketData.tipoDeOperacao === "delete"
      ) {
        onMessage(parsedSocketData);
      } else {
        if (parsedSocketData.documento.clinica === clinicaId) {
          onMessage(parsedSocketData);
        }
      }
    });

    socketRef.current.addEventListener("close", () => {
      setTimeout(connectWebSocket, 0);
    });
  }, [url, onMessage, clinicaId]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      socketRef.current.close();
    };
  }, [connectWebSocket, ...dependencies]);
};

export default useWebSocket;
