// LoadingSpinner.js
import React from "react";
import { useLoading } from "../../context/LoadingContext";
import "./LoadingSpinner.css"; // Ensure to create a CSS file for spinner styles

const LoadingSpinner = () => {
  const { loading } = useLoading();

  if (!loading) return null;

  return (
    <div className="loading-spinner">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
