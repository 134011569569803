import React, { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";

import "./LucroDaClinica.scss";
function LucroDaClinica({ sendRequest, clinica, auth }) {
  const [lucro, setLucro] = useState(0);
  useEffect(() => {
    const fetchLucro = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/dashadministrador/lucro/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLucro(responseData.lucro);
      } catch (err) {
        console.error("err", err);
      }
    };
    fetchLucro();
  }, [auth.token, clinica.clinica._id, sendRequest]);

  return (
    <div className="caixa dasboard__middle__Earnings">
      <span className="box-title">Lucro da clínica</span>
      {lucro && (
        <div className="lucro-box">
          <div className="lucro-data">
            <span
              className={`lucro ${
                lucro.totalReceitasEsteMes +
                  lucro.totalEntradasEsteMes -
                  lucro.totalDespesasEsteMes <
                0
                  ? "lucro--red"
                  : ""
              }`}
            >
              $
              {(
                lucro.totalReceitasEsteMes +
                lucro.totalEntradasEsteMes -
                lucro.totalDespesasEsteMes
              )
                //pt-BR pra separador de milhares com ponto em vez de virgula
                .toLocaleString("pt-BR")}
            </span>
            <span className="lucro-description">Este mês até agora</span>
          </div>
          <hr />
          <div className="lucro-data">
            <span
              className={`lucro ${
                lucro.totalReceitasMesPassado +
                  lucro.totalEntradasMesPassado -
                  lucro.totalDespesasMesPassado <
                0
                  ? "lucro--red"
                  : ""
              }`}
            >
              $
              {(
                lucro.totalReceitasMesPassado +
                lucro.totalEntradasMesPassado -
                lucro.totalDespesasMesPassado
              ).toLocaleString("pt-BR")}
            </span>
            <span className="lucro-description">Mês passado</span>
          </div>
        </div>
      )}

      <Link to={`financeiro`} className="link__dashboard">
        <div className="button-more-div">
          <span className="button-more">Mais</span>
          <span className="more-arrow"> {">"} </span>
        </div>
      </Link>
    </div>
  );
}

export default memo(LucroDaClinica);
