import { useState, useEffect } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";

//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalFiltros.scss";

function ModalFiltros({
  openModalFiltros,
  handleCloseModalFiltros,
  handleFiltrar,
  despesas,
  entradas,
  contasCorrentes,
  filtrosAplicados,
  filtroCaixa,
  filtroCategoria,
  filtroMetodoPagamento,
  clinicaId,
  auth,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [categoria, setCategoria] = useState(
    filtroCategoria ? filtroCategoria : "todas"
  );
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState(filtroCaixa ? filtroCaixa : "todas");
  const [caixas, setCaixas] = useState([]);

  const [emitido, setEmitido] = useState(
    filtrosAplicados.includes("emitido") ? true : false
  );

  const [radioDespesas, setRadioDespesas] = useState(
    filtrosAplicados.includes("despesas") ? true : false
  );

  const [radioParcelas, setRadioParcelas] = useState(
    filtrosAplicados.includes("parcelas") ? true : false
  );

  const [radioEntradas, setRadioEntradas] = useState(
    filtrosAplicados.includes("entradas") ? true : false
  );

  const { sendRequest } = useHttpClient();

  const formasPagamento = [
    "Todos",
    "Dinheiro",
    "Cheque",
    // "Cheque Visado",
    // "Transferência de crédito",
    "Internet banking",
    // "Cartão do banco",
    // "Débito direto da conta bancária",
    "Cartão de crédito",
    // "Cartão de débito",
  ];
  const [metodoPagamento, setMetodoPagamento] = useState(
    filtroMetodoPagamento ? filtroMetodoPagamento : "Todos"
  );

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCategorias(
          responseData.categoriasFinanceiro.map((cat) => {
            return { id: cat.id, value: cat.categoria };
          })
        );
      } catch (err) {
        console.error("err", err);
      }
    };

    const fetchCaixas = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCaixas(
          responseData.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
      } catch (err) {
        console.error("err", err);
      }
    };
    fetchCaixas();
    fetchCategorias();
  }, [auth.token, clinicaId, sendRequest]);

  useEffect(() => {
    setOpenModal(openModalFiltros);
  }, [openModalFiltros]);

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const filtrarModalHandler = () => {
    //Contas Correntes///////////////////////////////////////
    let contasCorrentesFiltradas = [];
    if (radioParcelas || (!radioDespesas && !radioParcelas && !radioEntradas)) {
      contasCorrentes.forEach((cc, index) => {
        contasCorrentesFiltradas[index] = { ...cc };
        let novasContas = [];
        cc.contas.forEach((conta, indiceConta) => {
          novasContas[indiceConta] = { ...conta };
          novasContas[indiceConta].parcelas = [];
          let novasParcelas = [];
          conta.parcelas.forEach((parcela) => {
            if (
              ((parcela.caixa === caixa || caixa === "todas") &&
                (parcela.metodoPagamento === metodoPagamento ||
                  metodoPagamento === "Todos") &&
                (parcela.categoria === categoria || categoria === "todas")) ||
              (caixa === "todas" &&
                categoria === "todas" &&
                metodoPagamento === "Todos")
            ) {
              if (
                (parcela.documentoEletronico?.succeeded === true && emitido) ||
                !emitido
              )
                novasParcelas.push({ ...parcela });
            }
          });
          novasContas[indiceConta].parcelas = [...novasParcelas];
        });
        contasCorrentesFiltradas[index].contas = [...novasContas];
      });
    } else {
      contasCorrentesFiltradas = [];
    }

    //////////////////////////////////////
    //Despesas////////////////////////////
    let despesasFiltradas = [];
    if (radioDespesas || (!radioDespesas && !radioParcelas && !radioEntradas)) {
      despesas.forEach((despesa) => {
        if (
          (despesa.despesaPaga &&
            (despesa.caixa._id === caixa || caixa === "todas") &&
            (despesa.metodoPagamento === metodoPagamento ||
              metodoPagamento === "Todos") &&
            (despesa.categoria._id === categoria || categoria === "todas")) ||
          (caixa === "todas" &&
            categoria === "todas" &&
            metodoPagamento === "Todos")
        ) {
          despesasFiltradas.push({ ...despesa });
        }
      });
    } else {
      despesasFiltradas = [];
    }

    //////////////////////////////////////
    //Entradas////////////////////////////
    let entradasFiltradas = [];
    if (radioEntradas || (!radioDespesas && !radioParcelas && !radioEntradas)) {
      entradas.forEach((entrada) => {
        if (
          (entrada.entradaRecebida &&
            (entrada.caixa._id === caixa || caixa === "todas") &&
            (entrada.metodoPagamento === metodoPagamento ||
              metodoPagamento === "Todos") &&
            (entrada.categoria._id === categoria || categoria === "todas")) ||
          (caixa === "todas" &&
            categoria === "todas" &&
            metodoPagamento === "Todos")
        ) {
          if (
            (entrada.documentoEletronico?.succeeded === true && emitido) ||
            !emitido
          )
            entradasFiltradas.push({ ...entrada });
        }
      });
    } else {
      entradasFiltradas = [];
    }

    const filtrosApli = [];
    emitido && filtrosApli.push("emitido");
    radioDespesas && filtrosApli.push("despesas");
    radioEntradas && filtrosApli.push("entradas");
    radioParcelas && filtrosApli.push("parcelas");

    handleFiltrar(
      contasCorrentesFiltradas,
      despesasFiltradas,
      entradasFiltradas,
      filtrosApli,
      caixa,
      categoria,
      metodoPagamento,
      "modalFiltros"
    );
    handleCloseModalFiltros();
  };

  const resetModalHandler = () => {
    handleFiltrar(
      contasCorrentes,
      despesas,
      entradas,
      [],
      null,
      null,
      null,
      "modalFiltros"
    );
    handleCloseModalFiltros();
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalFiltros}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box" id="filtros__modal">
          <div className="filtros__modal__header">
            <h1>Filtrar por</h1>
            <span onClick={resetModalHandler}>Resetar</span>
          </div>
          <div className="filtros__modal__container">
            <div className="modalFiltros__checkboxes">
              <div className="modalFiltros__checkboxes__container">
                <input
                  type="checkbox"
                  name=""
                  id="Parcelas"
                  checked={radioParcelas}
                  onChange={(e) => {
                    setRadioParcelas(e.target.checked);
                  }}
                />
                <label htmlFor="Parcelas">Parcelas</label>
              </div>

              <div className="modalFiltros__checkboxes__container">
                <input
                  type="checkbox"
                  name=""
                  id="Entradas"
                  checked={radioEntradas}
                  onChange={(e) => {
                    setRadioEntradas(e.target.checked);
                  }}
                />
                <label htmlFor="Entradas">Entradas</label>
              </div>

              <div className="modalFiltros__checkboxes__container">
                <input
                  type="checkbox"
                  name=""
                  id="Despesas"
                  checked={radioDespesas}
                  onChange={(e) => {
                    setRadioDespesas(e.target.checked);
                  }}
                />
                <label htmlFor="Despesas">Despesas</label>
              </div>
            </div>

            <div className="modalFiltros__checkboxes2">
              <div className="modalFiltros__checkboxes__container">
                <input
                  type="checkbox"
                  name=""
                  id="comprovante"
                  checked={emitido}
                  onChange={(e) => setEmitido(e.target.checked)}
                />
                <label htmlFor="comprovante">Emitidos</label>
              </div>
            </div>
            <div className="modalFiltros__selects">
              {caixas && caixa && (
                <div className="customInputContainer">
                  <select
                    onChange={(e) => setCaixa(e.target.value)}
                    className="customInputSelect"
                    value={caixa}
                  >
                    <option key={0} value="todas">
                      Todas
                    </option>
                    {caixas &&
                      caixa &&
                      caixas.map((item, index) => (
                        <option key={index + 1} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">Caixa</label>
                </div>
              )}

              <InputSelect
                label="Metodo de pagamento"
                initialValue={metodoPagamento}
                handleChange={metodoPagamentoChangeHandler}
                items={formasPagamento}
              />

              {categorias && categoria && (radioDespesas || radioEntradas) && (
                <div className="customInputContainer">
                  <select
                    onChange={(e) => setCategoria(e.target.value)}
                    className="customInputSelect"
                    value={categoria}
                  >
                    <option key={0} value="todas">
                      Todas
                    </option>
                    {categorias &&
                      categoria &&
                      categorias.map((item, index) => (
                        <option key={index + 1} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">Categoria</label>
                </div>
              )}
            </div>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalFiltros}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span className="blue-button" onClick={filtrarModalHandler}>
              Filtrar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalFiltros;
