import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { generateExcelFile } from "../../../shared/components/excelUtils/exportExcel";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import toast from "react-hot-toast";
import dataGridData from "./components/datagridData";
import { useLoading } from "../../../shared/context/LoadingContext";
//Modais
import ModalVerEntrada from "./components/modalEntrada/ModalVerEntrada";
import ModalEntrada from "./components/modalEntrada/ModalEntrada";
import ModalEditarEntrada from "./components/modalEntrada/ModalEditarEntrada";
import ModalReceberFinanceiro from "./components/ModalReceberFinanceiro";
import ModalProcedimentos from "../../clientes/single/components/Debitos/components/ModalProcedimentos/ModalProcedimentos";
import ModalMotivoDve from "../../clientes/single/components/Debitos/components/ModalMotivoDve/ModalMotivoDve";
//ICONS
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import ModalVerReceita from "../../clientes/single/components/Debitos/components/ModalVerPagamento/ModalVerReceita";
import FiltrosReceitas from "./components/filtrosReceitas/FiltrosReceitas";

//MUI
import Button from "@mui/material/Button";
import ModalReceberEntrada from "./components/modalEntrada/ModalReceberEntrada";
// import ModalMotivoEvento from "./components/ModalMotivoEvento/ModalMotivoEvento";
import ModalMotivoDveReembolso from "./components/ModalMotivoDveReembolso/ModalMotivoDveReembolso";

function Receitas({
  clinicaId,
  auth,
  showFatura,
  showRce,
  dadosClinica,
  dadosRecibo,
}) {
  const { startLoading, stopLoading } = useLoading();

  const { sendRequest } = useHttpClient();
  const [rows, setRows] = useState([]);
  const [modalData, setModalData] = useState({});
  const [contasCorrentes, setContasCorrentes] = useState();
  const [contasCorrentesAbsolut, setContasCorrentesAbsolut] = useState([]);
  const [contasCorrentesParaTextSearch, setContasCorrentesParaTextSearch] =
    useState([]);
  const [entradas, setEntradas] = useState([]);
  const [entradasAbsolut, setEntradasAbsolut] = useState([]);
  const [entradasParaTextSearch, setEntradasParaTextSearch] = useState([]);
  const [entradaId, setEntradaId] = useState();
  const [openModal, setOpenModal] = useState("");
  const [filtrosAplicados, setFiltrosAplicados] = useState([]);
  const [filtroCaixa, setFiltroCaixa] = useState();
  const [filtroCategoria, setFiltroCategoria] = useState();
  const [filtroMetodoPagamento, setFiltroMetodoPagamento] = useState();
  const [filtroTextoSearch, setFiltroTextoSearch] = useState("");
  const [dadosModalMotivoDve, setDadosModalMotivoDve] = useState({
    open: false,
  });

  const [financialData, setFinancialData] = useState({
    totalRecebido: 0,
    totalReceber: 0,
    totalPrevistoReceber: 0,
    saldo: 0,
    saldoTotalPrevisto: 0,
  });

  const [dataFiltro, setDataFiltro] = useState({
    dataInicial: moment().format("DD/MM/YYYY"),
    dataFinal: moment().format("DD/MM/YYYY"),
  });

  //Dados para modal
  const [cliente, setCliente] = useState("");

  const fetchContasCorrentesEntrada = useCallback(
    async (startDate, endDate) => {
      startLoading();
      try {
        const formData = new FormData();
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);

        const requests = [];
        let responseDataCc = { contasCorrentes: [] };
        let responseDataEntradas = { entradas: [] };

        if (auth.perm.includes("r-rec")) {
          requests.push(
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/daterange/${clinicaId}`,
              "PATCH",
              formData,
              { Authorization: "Bearer " + auth.token }
            )
          );
        }

        if (auth.perm.includes("r-ent")) {
          requests.push(
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clinica/daterange/${clinicaId}`,
              "PATCH",
              formData,
              { Authorization: "Bearer " + auth.token }
            )
          );
        }

        const responses = await Promise.all(requests);

        if (auth.perm.includes("r-rec")) {
          responseDataCc = responses.shift();
        }

        if (auth.perm.includes("r-ent")) {
          responseDataEntradas = responses.shift();
        }

        setContasCorrentes(responseDataCc.contasCorrentes);
        setContasCorrentesAbsolut(responseDataCc.contasCorrentes);
        setEntradas(responseDataEntradas.entradas);
        setEntradasAbsolut(responseDataEntradas.entradas);
        setContasCorrentesParaTextSearch(responseDataCc.contasCorrentes);
        setEntradasParaTextSearch(responseDataEntradas.entradas);

        handleFiltrar(
          responseDataCc.contasCorrentes,
          responseDataEntradas.entradas,
          [],
          null,
          null,
          null,
          "",
          {
            dataInicial: moment(startDate).format("DD/MM/YYYY"),
            dataFinal: moment(endDate).format("DD/MM/YYYY"),
          }
        );
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        toast.error(
          "Ocorreu um erro ao carregar os dados. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    },
    [auth.perm, auth.token, clinicaId, sendRequest]
  );

  useEffect(() => {
    fetchContasCorrentesEntrada(
      new Date().toISOString(),
      new Date().toISOString()
    );
  }, []);

  useEffect(() => {
    let dataParaDataGrid = [];
    let recebido = 0;
    let porReceber = 0;

    if (contasCorrentes) {
      contasCorrentes.forEach((contaCorrente) => {
        contaCorrente.contas.forEach((conta) => {
          if (conta.aprovado) {
            conta.parcelas.forEach((parcela) => {
              recebido += parcela.quantiaPaga;
              porReceber += parcela.quantiaPorPagar;
              dataParaDataGrid.push({
                tipo: "receita",
                cliente: contaCorrente.cliente.nome,
                idCliente: contaCorrente.cliente._id,
                dadosCliente: contaCorrente.cliente,
                idContaCorrente: contaCorrente._id,
                orcamento: conta.orcamento,
                id: parcela._id,
                contaId: conta._id,
                dataLimitePagamento: parcela.dataLimitePagamento,
                dataPagamento: parcela.dataPagamento,
                quantiaPorPagar: parcela.quantiaPorPagar,
                quantiaPaga: parcela.quantiaPaga,
                metodoPagamento: parcela.metodoPagamento,
                entrada: parcela.entrada,
                nrParcela: parcela.nrParcela,
                parcelaPaga: parcela.parcelaPaga,
                estado: parcela.parcelaPaga
                  ? "Pago"
                  : moment()
                      .startOf("day")
                      .isAfter(
                        moment(parcela.dataLimitePagamento).startOf("day")
                      )
                  ? "Em atraso"
                  : "",
                documentoEletronico: parcela.documentoEletronico,
                nrRecibo: parcela.nrRecibo,
                cancelamentosDocumentosEletronicos:
                  parcela.cancelamentosDocumentosEletronicos,
              });
            });
          }
        });
      });
    }

    if (entradas && entradas.length > 0) {
      entradas.forEach((entrada) => {
        if (entrada.entradaRecebida && entrada.valor) {
          recebido += entrada.valor;
        } else {
          porReceber += entrada.valor;
        }
        dataParaDataGrid.push({
          tipo: "entrada",
          tipoEntrada: entrada.tipo,
          id: entrada.id,
          cliente: entrada.cliente,
          dataPagamento: entrada.dataPagamento,
          dataLimitePagamento: entrada.dataLimitePagamento,
          valor: entrada.valor,
          metodoPagamento: entrada.metodoPagamento,
          descricao: entrada.descricao,
          comprovativo: entrada.comprovativo,
          documentoEletronico: entrada.documentoEletronico,
          entradaRecebida: entrada.entradaRecebida,
          faturaEReciboEletronicos: entrada.faturaEReciboEletronicos,
          cancelamentosDocumentosEletronicos:
            entrada.cancelamentosDocumentosEletronicos,
        });
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) =>
        new Date(a.dataPagamento ? a.dataPagamento : a.dataLimitePagamento) -
        new Date(b.dataPagamento ? b.dataPagamento : b.dataLimitePagamento)
    );

    setFinancialData({
      totalRecebido: recebido.toLocaleString("pt-BR"),
      totalReceber: porReceber.toLocaleString("pt-BR"),
    });

    setRows(dataGridData(dataParaDataGrid));
  }, [contasCorrentes, entradas]);

  //A funcao verifica o nif e compara com o nome retornado para ver se é identico
  const checkNifError = async (nif, nome, nomePais) => {
    let erro;
    if (nomePais !== "CABO VERDE") {
      erro = false;
    } else {
      const formData = new FormData();
      nif && formData.append("nif", nif);
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        if (response.payload.length === 0 || response.payload.length > 1) {
          erro = true;
        } else if (response.payload.length === 1) {
          if (response.payload[0].Name.toLowerCase() === nome.toLowerCase())
            erro = false;
          else erro = true;
        }
      } catch (err) {
        console.error("err", err);
      }
    }
    return erro;
  };

  const checkIfEntradaBelongToDateRange = (entradaAVerificar) => {
    // Set the locale to ensure correct interpretation of DD/MM/YYYY
    moment.locale("pt"); // This locale uses DD/MM/YYYY by default
    // Parse dates with the correct format
    const startOfDay = moment(dataFiltro.dataInicial, "DD/MM/YYYY", true);
    const endOfDay = moment(dataFiltro.dataFinal, "DD/MM/YYYY", true);
    const dataInicial = startOfDay.startOf("day");
    const dataFinal = endOfDay.endOf("day");

    let isBetweenDataPagamento = false;
    let isBetweenDataLimite = false;

    if (entradaAVerificar.dataPagamento) {
      if (
        moment(entradaAVerificar.dataPagamento).isBetween(
          dataInicial,
          dataFinal,
          "day",
          "[]"
        )
      ) {
        isBetweenDataPagamento = true;
      }
    } else if (entradaAVerificar.dataLimitePagamento) {
      if (
        moment(entradaAVerificar.dataLimitePagamento).isBetween(
          dataInicial,
          dataFinal,
          "day",
          "[]"
        )
      ) {
        isBetweenDataLimite = true;
      }
    }

    return isBetweenDataLimite || isBetweenDataPagamento;
  };

  const filterContasCorrentes = (
    contaCorrenteRetornada,
    startDate,
    endDate
  ) => {
    // Set the locale to ensure correct interpretation of DD/MM/YYYY
    moment.locale("pt"); // This locale uses DD/MM/YYYY by default

    // Parse dates with the correct format
    const start = moment(startDate, "DD/MM/YYYY", true);
    const end = moment(endDate, "DD/MM/YYYY", true);

    // Check if dates are valid
    if (!start.isValid() || !end.isValid()) {
      return null; // or handle the error as appropriate for your application
    }

    const startOfDay = start.startOf("day");
    const endOfDay = end.endOf("day");

    const filteredContas = contaCorrenteRetornada.contas
      .map((conta) => {
        const filteredParcelas = conta.parcelas.filter((parcela) => {
          const dataPagamento = parcela.dataPagamento
            ? moment(parcela.dataPagamento)
            : null;
          const dataLimitePagamento = parcela.dataLimitePagamento
            ? moment(parcela.dataLimitePagamento)
            : null;

          let isPagamentoInRange = false;
          let isLimiteInRange = false;
          if (dataPagamento) {
            isPagamentoInRange =
              dataPagamento &&
              dataPagamento.isBetween(startOfDay, endOfDay, null, "[]");
          } else if (dataLimitePagamento) {
            isLimiteInRange =
              dataLimitePagamento &&
              dataLimitePagamento.isBetween(startOfDay, endOfDay, null, "[]");
          }
          return isPagamentoInRange || isLimiteInRange;
        });

        return {
          ...conta,
          parcelas: filteredParcelas,
        };
      })
      .filter((conta) => conta.parcelas.length > 0);

    const contaCorrenteOnlyTimeFilter = {
      id: contaCorrenteRetornada._id,
      _id: contaCorrenteRetornada._id,
      clinica: contaCorrenteRetornada.clinica,
      cliente: contaCorrenteRetornada.cliente,
      contas: filteredContas,
      created_at: contaCorrenteRetornada.created_at,
      updatedAt: contaCorrenteRetornada.updatedAt,
      __v: contaCorrenteRetornada.__v,
    };

    ///////////////////////////////
    let contaCorrenteModalFiltered = { ...contaCorrenteOnlyTimeFilter };

    //Primeiro verificamos se existe algo filtrado da modal
    if (filtrosAplicados && filtrosAplicados.length > 0) {
      if (
        filtrosAplicados.includes("parcelas") ||
        (!filtrosAplicados.includes("parcelas") &&
          !filtrosAplicados.includes("entradas"))
      ) {
        let novasContas = [];
        contaCorrenteOnlyTimeFilter.contas.forEach((conta, indiceConta) => {
          novasContas[indiceConta] = { ...conta };
          novasContas[indiceConta].parcelas = [];
          let novasParcelas = [];
          conta.parcelas.forEach((parcela) => {
            if (
              (parcela.parcelaPaga && filtrosAplicados.includes("pagas")) ||
              (!parcela.parcelaPaga && filtrosAplicados.includes("naoPagas")) ||
              (!filtrosAplicados.includes("pagas") &&
                !filtrosAplicados.includes("naoPagas"))
            ) {
              if (
                (!parcela.parcelaPaga &&
                  filtrosAplicados.includes("emAtraso") &&
                  moment(parcela.dataLimitePagamento).isBefore(
                    moment(),
                    "day"
                  )) ||
                (!filtrosAplicados.includes("emAtraso") &&
                  !parcela.parcelaPaga &&
                  !moment(parcela.dataLimitePagamento).isBefore(
                    moment(),
                    "day"
                  )) ||
                (parcela.parcelaPaga &&
                  !filtrosAplicados.includes("emAtraso")) ||
                (!parcela.parcelaPaga && !filtrosAplicados.includes("emAtraso"))
              ) {
                if (
                  (parcela.parcelaPaga &&
                    (parcela.caixa === filtroCaixa ||
                      filtroCaixa === "todas") &&
                    (parcela.metodoPagamento === filtroMetodoPagamento ||
                      filtroMetodoPagamento === "Todos") &&
                    (parcela.categoria === filtroCategoria ||
                      filtroCategoria === "todas")) ||
                  (filtroCaixa === "todas" &&
                    filtroCategoria === "todas" &&
                    filtroMetodoPagamento === "Todos")
                ) {
                  if (
                    (parcela.documentoEletronico?.succeeded === true &&
                      filtrosAplicados.includes("emitido")) ||
                    !filtrosAplicados.includes("emitido")
                  ) {
                    novasParcelas.push({ ...parcela });
                  }
                }
              }
            }
          });
          novasContas[indiceConta].parcelas = [...novasParcelas];
        });
        contaCorrenteModalFiltered.contas = [...novasContas];
      }
    }

    const lowerCaseText = filtroTextoSearch.toLowerCase();
    let contaCorrenteTextFiltered = { ...contaCorrenteModalFiltered };

    //Se a procura for por cliente ou se simplismente não houver texto de procura, a conta corrente resultante é o filtro da modal
    if (
      filtroTextoSearch &&
      !contaCorrenteModalFiltered.cliente.nome
        .toLowerCase()
        .includes(lowerCaseText)
    ) {
      //Se a procura não for por cliente, procuramos os dados das parcelas
      let novasContas = [];
      contaCorrenteModalFiltered.contas.forEach((conta) => {
        let novaConta = { ...conta };
        let novasParcelas = [];
        conta.parcelas.forEach((parcela) => {
          if (
            moment(parcela.dataLimitePagamento)
              .format("DD-MM-YYYY")
              .includes(lowerCaseText) ||
            parcela.quantiaPorPagar.toString() === lowerCaseText ||
            parcela.quantiaPaga.toString() === lowerCaseText ||
            (parcela.dataPagamento &&
              moment(parcela.dataPagamento)
                .format("DD-MM-YYYY")
                .includes(lowerCaseText))
          )
            novasParcelas.push({ ...parcela });
        });
        novaConta.parcelas = [...novasParcelas];
        novasContas.push(novaConta);
      });
      contaCorrenteTextFiltered.contas = [...novasContas];
    }

    //////////////////////////////////////////////////////////////
    return {
      contaCorrenteAbsolut: contaCorrenteOnlyTimeFilter,
      contaCorrente: contaCorrenteModalFiltered,
      contaCorrenteParaTextSearch: contaCorrenteTextFiltered,
    };
  };

  const filterEntrada = (entradaRetornada) => {
    let isAbsolut = false;
    let isModalFiltered = false;
    let isTextFiltered = false;

    if (checkIfEntradaBelongToDateRange(entradaRetornada)) {
      isAbsolut = true;
    }

    //Se a entrada não é absolut, ou seja não pertence ao alcance das datas, não deve aparecer
    if (isAbsolut) {
      if (filtrosAplicados && filtrosAplicados.length > 0) {
        if (
          filtrosAplicados.includes("entradas") ||
          (!filtrosAplicados.includes("parcelas") &&
            !filtrosAplicados.includes("entradas"))
        )
          if (
            (entradaRetornada.entradaRecebida &&
              filtrosAplicados.includes("pagas")) ||
            (!entradaRetornada.entradaRecebida &&
              filtrosAplicados.includes("naoPagas")) ||
            (!filtrosAplicados.includes("pagas") &&
              !filtrosAplicados.includes("naoPagas"))
          )
            if (
              (!entradaRetornada.entradaRecebida &&
                filtrosAplicados.includes("emAtraso") &&
                moment(entradaRetornada.dataLimitePagamento).isBefore(
                  moment(),
                  "day"
                )) ||
              (!filtrosAplicados.includes("emAtraso") &&
                !entradaRetornada.entradaRecebida &&
                !moment(entradaRetornada.dataLimitePagamento).isBefore(
                  moment(),
                  "day"
                )) ||
              (entradaRetornada.entradaRecebida &&
                !filtrosAplicados.includes("emAtraso")) ||
              (!entradaRetornada.entradaRecebida &&
                !filtrosAplicados.includes("emAtraso"))
            )
              if (
                (entradaRetornada.entradaRecebida &&
                  (entradaRetornada.caixa._id === filtroCaixa ||
                    filtroCaixa === "todas") &&
                  (entradaRetornada.metodoPagamento === filtroMetodoPagamento ||
                    filtroMetodoPagamento === "Todos") &&
                  (entradaRetornada.categoria._id === filtroCategoria ||
                    filtroCategoria === "todas")) ||
                (filtroCaixa === "todas" &&
                  filtroCategoria === "todas" &&
                  filtroMetodoPagamento === "Todos")
              )
                if (
                  (entradaRetornada.documentoEletronico?.succeeded === true &&
                    filtrosAplicados.includes("emitido")) ||
                  !filtrosAplicados.includes("emitido")
                )
                  isModalFiltered = true;
      } else {
        isModalFiltered = true;
      }
    }

    if (isModalFiltered) {
      if (filtroTextoSearch) {
        const lowerCaseText = filtroTextoSearch.toLowerCase();

        if (
          entradaRetornada.categoria.categoria
            .toLowerCase()
            .includes(lowerCaseText) ||
          entradaRetornada.caixa.caixa.toLowerCase().includes(lowerCaseText) ||
          entradaRetornada.descricao?.toLowerCase().includes(lowerCaseText) ||
          entradaRetornada.metodoPagamento
            ?.toLowerCase()
            .includes(lowerCaseText) ||
          entradaRetornada.cliente?.nome
            ?.toLowerCase()
            .includes(lowerCaseText) ||
          entradaRetornada.clienteFornecedor?.nome
            ?.toLowerCase()
            .includes(lowerCaseText) ||
          moment(entradaRetornada?.dataPagamento)
            .format("DD-MM-YYYY")
            .includes(lowerCaseText) ||
          entradaRetornada.valor.toString() === lowerCaseText
        )
          isTextFiltered = true;
      } else {
        isTextFiltered = true;
      }
    }

    //////////////////////////////////////////////////////////////
    return {
      isAbsolut: isAbsolut,
      isModalFiltered: isModalFiltered,
      isTextFiltered: isTextFiltered,
    };
  };

  const handleCloseModal = useCallback(() => {
    setOpenModal("");
    setModalData("");
    setDadosModalMotivoDve({});
  }, []);

  const desativarEntradaHandler = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/desativar/${id}`,
        "PATCH",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const tempEntradas = [...entradas];
      const tempEntradasAbsolut = [...entradasAbsolut];
      const tempEntradasParaTextSearch = [...entradasParaTextSearch];

      const indexEntrada = tempEntradas.findIndex(
        (entrada) => entrada._id === id
      );

      const indexEntradaAbsolut = tempEntradasAbsolut.findIndex(
        (entrada) => entrada._id === id
      );

      const indexEntradaParaTextSearch = tempEntradasParaTextSearch.findIndex(
        (entrada) => entrada._id === id
      );

      tempEntradas.splice(indexEntrada, 1);
      tempEntradasAbsolut.splice(indexEntradaAbsolut, 1);
      tempEntradasParaTextSearch.splice(indexEntradaParaTextSearch, 1);
      setEntradas([...tempEntradas]);
      setEntradasAbsolut([...tempEntradasAbsolut]);
      setEntradasParaTextSearch([...tempEntradasParaTextSearch]);

      toast.success("Entrada apagada com sucesso!");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro na desativação da entrada. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const verEntradaHandler = (id) => {
    const entradaFiltrada = entradas.filter((entr) => entr.id === id)[0];
    setModalData(entradaFiltrada);
    setOpenModal("verEntrada");
  };

  const editarEntradaHandler = (id) => {
    setEntradaId(id);
    setOpenModal("editarEntrada");
  };

  const handleOpenModalProcedimentos = (orcamento) => {
    setModalData(orcamento);
    setOpenModal("procedimentos");
  };

  const handleOpenModalReceber = (
    idParcela,
    valorParcela,
    idContaCorrente,
    nomeCliente,
    idCliente
  ) => {
    setModalData({
      open: "receber",
      idContaCorrente: idContaCorrente,
      idParcela: idParcela,
      valorParcela: valorParcela,
      nomeCliente: nomeCliente,
      idCliente: idCliente,
      startDate: dataFiltro.dataInicial,
      endDate: dataFiltro.dataFinal,
    });
  };

  const handleOpenModalReceberEntrada = (id) => {
    const entradaFiltada = entradas.filter((e) => e.id === id)[0];
    setModalData(entradaFiltada);
    setOpenModal("receberEntrada");
  };

  const verReceitaHandler = (id, idConta, idContaCorrente) => {
    const contaCorrenteFiltrada = contasCorrentes.filter(
      (ctCrt) => ctCrt._id === idContaCorrente
    )[0];

    const contaFiltrada = contaCorrenteFiltrada.contas.filter(
      (conta) => conta._id === idConta
    )[0];
    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela._id === id
    )[0];
    const dadosReceita = {
      metodoPagamento: parcelaFiltrada.metodoPagamento,
      valorPago: parcelaFiltrada.quantiaPaga,
      dataRecebimento: parcelaFiltrada.dataPagamento,
      observacao: parcelaFiltrada.observacao,
      caixa: parcelaFiltrada.caixa,
    };
    if (parcelaFiltrada.cartao) dadosReceita.cartao = parcelaFiltrada.cartao;
    if (parcelaFiltrada.metodoPagamento === "Cheque") {
      dadosReceita.nrCheque = parcelaFiltrada.nrCheque;
      dadosReceita.dataCheque = parcelaFiltrada.dataCheque;
      dadosReceita.contaCliente = parcelaFiltrada.nrContaCliente;
      dadosReceita.banco = parcelaFiltrada.banco;
    }
    setModalData(dadosReceita);
    setOpenModal("verReceita");
    setCliente(contaCorrenteFiltrada.cliente.nome);
  };

  //Filtra os dados da conta corrente, conta e parcela a ser cancelada e
  //Verifica se existe a necessidade de escolher um motivo para o cancelamento
  //da dve, verificando se existe um doc eletronico enviado e abrindo a modal do motivo
  const beforeCancelarRecebimento = (id, idConta, idContaCorrente) => {
    const contaCorrenteFiltrada = contasCorrentes.filter(
      (c) => c._id === idContaCorrente
    )[0];
    const filteredConta = contaCorrenteFiltrada.contas.filter(
      (conta) => conta._id === idConta
    )[0];
    const oldParcela = filteredConta.parcelas.filter(
      (parcela) => parcela._id === id
    )[0];

    if (oldParcela.documentoEletronico) {
      //Codigo para obter o motivo
      setDadosModalMotivoDve({ open: "parcela", id, idConta, idContaCorrente });
    } else {
      handleCancelarRecebimento(id, idConta, idContaCorrente);
    }
  };

  const handleCancelarRecebimento = async (id, idConta, idContaCorrente) => {
    startLoading();
    try {
      const contaCorrenteFound = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/single/${idContaCorrente}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      const contaCorrenteFiltrada = contaCorrenteFound.contaCorrente;

      const filteredConta = contaCorrenteFiltrada.contas.filter(
        (conta) => conta._id === idConta
      )[0];

      const oldParcela = filteredConta.parcelas.filter(
        (parcela) => parcela._id === id
      )[0];

      let respostaDVE;

      if (oldParcela.documentoEletronico) {
        if (!contaCorrenteFiltrada.cliente.nif) {
          toast.error("O cliente não possui um nif");
        } else if (!contaCorrenteFiltrada.cliente.contacto) {
          toast.error("O cliente não possui um número de telefone");
        } else if (!contaCorrenteFiltrada.cliente.endereco) {
          toast.error("O cliente não possui um endereço");
        } else {
          const formData = new FormData();
          formData.append("clinicaId", clinicaId);
          formData.append("nomeCliente", contaCorrenteFiltrada.cliente.nome);
          formData.append("nifCliente", contaCorrenteFiltrada.cliente.nif);
          formData.append("valor", oldParcela.quantiaPaga);
          formData.append("descricaoPagamento", "Tratamento dentário");
          formData.append(
            "contactoCliente",
            contaCorrenteFiltrada.cliente.contacto
          );
          formData.append(
            "moradaCliente",
            contaCorrenteFiltrada.cliente.endereco
          );
          formData.append(
            "codigoPaisCliente",
            contaCorrenteFiltrada.cliente.pais.codigo
          );
          formData.append("metodoPagamento", oldParcela.metodoPagamento);
          formData.append("tipoDocEletronico", "DVE");
          formData.append("usuarioEmissor", auth.userId);
          formData.append(
            "IUDCancelar",
            oldParcela.documentoEletronico.entryName.replace(/\.xml$/, "")
          );
          formData.append("contaCorrente", contaCorrenteFiltrada._id);
          formData.append("conta", filteredConta._id);
          formData.append("parcela", oldParcela._id);
          formData.append("online", true);

          try {
            respostaDVE = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            toast
              .error(
                "Ocorreu um erro na emissão do documento eletrónico. Por favor, tente novamente"
              )
              .console.error("err", err);
          }
        }
      }

      let newParcela;

      //Se o envio do DVE for bem sucedido ou se não existia FRE, a parcela fica atualizada como não paga
      if (
        (respostaDVE?.respostaEfatura?.responses[0]?.succeeded &&
          oldParcela.documentoEletronico) ||
        !oldParcela.documentoEletronico
      ) {
        newParcela = {
          id: oldParcela._id,
          _id: oldParcela._id,
          dataLimitePagamento: oldParcela.dataLimitePagamento,
          parcelaPaga: false,
          quantiaPaga: 0,
          quantiaPorPagar: oldParcela.quantiaPorPagar + oldParcela.quantiaPaga,
        };
      } else {
        newParcela = { ...oldParcela };
      }

      //Atualizando os campos que têm key value pairs de cancelamentos caso havia documento eletronico na parcela antiga
      if (oldParcela.documentoEletronico) {
        if (oldParcela.cancelamentosDocumentosEletronicos) {
          newParcela.cancelamentosDocumentosEletronicos = [
            ...oldParcela.cancelamentosDocumentosEletronicos,
            {
              documento: oldParcela.documentoEletronico._id,
              DVE: respostaDVE.respostaDocumentoEletronico._id,
            },
          ];
        } else {
          newParcela.cancelamentosDocumentosEletronicos = [
            {
              documento: oldParcela.documentoEletronico._id,
              DVE: respostaDVE.respostaDocumentoEletronico._id,
            },
          ];
        }
      }

      if (oldParcela.nrParcela) newParcela.nrParcela = oldParcela.nrParcela;
      if (oldParcela.entrada) newParcela.entrada = oldParcela.entrada;

      const newParcelas = filteredConta.parcelas.map((parcela) => {
        if (parcela._id === newParcela._id) {
          return newParcela;
        } else {
          return parcela;
        }
      });

      filteredConta.parcelas = newParcelas;
      //A conta fica liquidada a false caso houver sucesso no envio do documento
      //Que resulta no posteriro cancelamento do doc eletronico
      if (
        (respostaDVE?.respostaEfatura?.responses[0]?.succeeded &&
          oldParcela.documentoEletronico) ||
        !oldParcela.documentoEletronico
      ) {
        filteredConta.contaLiquidada = false;
      }

      const nrParcelasPagas = filteredConta.parcelas.filter(
        (parcela) => parcela.parcelaPaga
      ).length;

      //Aqui devo fazer o update independentemente se houve ou não erro pois se houve erro no envio de dfe, ainda assim necessito guardar o doc
      //eletronico com erro na parcela
      const formData = new FormData();
      formData.append("contas", JSON.stringify(contaCorrenteFiltrada.contas));
      let contaCorrenteRetornada;
      try {
        contaCorrenteRetornada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrenteFiltrada._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro atualizando a conta corrente. Por favor, tente novamente."
        );
      }

      updateContaCorrente(contaCorrenteRetornada.contaCorrente, null, null);

      /////////////////////////////////////////////////////

      //Atualizar o plano de tratamento para que este não possa ser apagado
      //Caso essa for a primeria parcela a ser paga. Se não, isso quer dizer que
      //O campo canDelete já está a true e nao necessita ser atualizado

      if (nrParcelasPagas === 0) {
        let planoTratamento;
        try {
          planoTratamento = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/orcamento/${filteredConta.orcamento._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro atualizando os dados do plano de tratamento. Por favor, tente novamente."
          );
        }

        const formData = new FormData();
        formData.append("canDelete", true);
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/candelete/${planoTratamento.planosTratamento[0]._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      if (respostaDVE?.respostaEfatura?.responses[0]?.succeeded) {
        toast.success(
          `Cancelamento efetuado! ${
            respostaDVE?.respostaEfatura?.responses[0]?.succeeded
              ? " DVE enviado com sucesso!"
              : ""
          } `
        );
      } else if (
        respostaDVE?.respostaEfatura?.responses[0]?.succeeded === false
      ) {
        toast.error(
          `Erro ao efetuar cancelamento. DVE com erro: ${respostaDVE?.respostaEfatura?.responses[0]?.messages[0].description}`
        );
      } else {
        toast.success(`Cancelamento efetuado com Sucesso!`);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleBeforeCancelarRecebimentoEntrada = (
    id,
    enviarDocCancelamento
  ) => {
    const entradaFiltrada = entradas.filter((e) => e.id === id)[0];
    if (entradaFiltrada.tipoEntrada !== "reembolso") {
      handleCancelarRecebimentoEntrada(entradaFiltrada, enviarDocCancelamento);
    } else {
      if (enviarDocCancelamento) {
        setDadosModalMotivoDve({ open: true, entrada: entradaFiltrada });
      } else {
        handleCancelarRecebimentoEntrada(entradaFiltrada);
      }
    }
  };

  const handleCancelarRecebimentoEntrada = async (
    entradaFiltrada,
    enviarDocCancelamento,
    motivoDve
  ) => {
    startLoading();
    try {
      let novaEntrada = entradaFiltrada;
      let resposta;

      if (enviarDocCancelamento) {
        const formData = new FormData();
        formData.append("clinicaId", clinicaId);
        formData.append("nomeCliente", entradaFiltrada.clienteFornecedor.nome);
        formData.append("nifCliente", entradaFiltrada.clienteFornecedor.nif);
        formData.append("valor", entradaFiltrada.valor);
        formData.append(
          "descricaoPagamento",
          entradaFiltrada.descricao &&
            entradaFiltrada.tipoEntrada !== "reembolso"
            ? entradaFiltrada.descricao
            : "Reembolso"
        );

        formData.append(
          "contactoCliente",
          entradaFiltrada.clienteFornecedor.contacto
        );
        formData.append(
          "moradaCliente",
          entradaFiltrada.clienteFornecedor.endereco
        );
        formData.append(
          "codigoPaisCliente",
          entradaFiltrada.clienteFornecedor.pais.codigo
        );
        formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);

        formData.append("dataPagamento", entradaFiltrada.dataPagamento);

        formData.append(
          "tipoDocEletronico",
          entradaFiltrada.tipoEntrada === "reembolso" ? "DVE" : "NCE"
        );

        formData.append("IssueReasonCode", JSON.stringify(motivoDve));

        formData.append("usuarioEmissor", auth.userId);
        formData.append("online", true);
        formData.append(
          "IUDCancelar",
          entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
        );
        formData.append("entrada", entradaFiltrada._id);

        let resposta;
        try {
          resposta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          toast.error(
            "Ocorreu um erro emitindo o documento eletrónico. Por favor, tente novamente."
          );
          console.error("err", err);
        }

        // Atualizar o documento eletronico na entrada na base de dados
        const formData2 = new FormData();

        //Atualizar a array de cancelamentos de docmento eletronico
        formData2.append(
          "documentoCancelamento",
          resposta.respostaDocumentoEletronico._id
        );
        formData2.append(
          "documentoEletronico",
          entradaFiltrada.documentoEletronico.id
        );

        novaEntrada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposenvionce/${entradaFiltrada.id}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }

      //Agora atualizamos o campo entrada recebida para false caso o envio do NCE Ocorrer ou caso não houver necessidade de enviar NCE
      if (
        (enviarDocCancelamento &&
          resposta.respostaEfatura.responses[0].succeeded) ||
        !enviarDocCancelamento
      ) {
        let result;
        try {
          result = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/anularPagamento/${entradaFiltrada._id}`,
            "PATCH",
            {},
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          toast.error(
            "Ocorreu um erro atualizando os dados do anulamento do pagamento da entrada. Por favor, tente novamente."
          );
          console.error("err", err);
        }

        //Se anulação de pagamento
        if (result.success) {
          updateEntradas(
            {
              ...novaEntrada,
              metodoPagamento: null,
              dataPagamento: null,
              comprovativo: null,
              entradaRecebida: false, //A entrada fica como nao recebida
              documentoEletronico: null,
              cancelamentosDocumentosEletronicos:
                novaEntrada.cancelamentosDocumentosEletronicos,
            },
            "atualizar"
          );

          toast.success("Pagamento revertido com sucesso");
        } else {
          //A entrada continua recebida
          updateEntradas(
            {
              ...novaEntrada,
              documentoEletronico: null,
              cancelamentosDocumentosEletronicos:
                novaEntrada.cancelamentosDocumentosEletronicos,
            },
            "atualizar"
          );
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  //DFE
  //--------------------------------------------------------------

  //Emitir Fre para Entradas
  const emitirDocEletronicoReembolso = async (
    entrada,
    idEntrada,
    tipoDocumento
  ) => {
    startLoading();
    try {
      let entradaFiltrada;
      if (entrada) {
        entradaFiltrada = entrada;
      } else {
        entradaFiltrada = entradas.filter((e) => e._id === idEntrada);
      }

      if (!entradaFiltrada.cliente.nif && tipoDocumento !== "TVE") {
        toast.error("O cliente não possui um nif");
      } else if (!entradaFiltrada.cliente.contacto) {
        toast.error("O cliente não possui um número de telefone");
      } else if (!entradaFiltrada.cliente.endereco) {
        toast.error("O cliente não possui um endereço");
      } else if (!entradaFiltrada.cliente.pais) {
        toast.error("O cliente não possui um pais");
      } else if (
        tipoDocumento === "TVE"
          ? false
          : await checkNifError(
              entradaFiltrada.cliente.nif,
              entradaFiltrada.cliente.nome,
              entradaFiltrada.cliente.pais.nome
            )
      ) {
        toast.error("nif incomaptivel com o nome do cliente");
      } else {
        const formData = new FormData();
        formData.append("clinicaId", clinicaId);
        if (tipoDocumento !== "TVE") {
          formData.append("nomeCliente", entradaFiltrada.cliente.nome);
          formData.append("nifCliente", entradaFiltrada.cliente.nif);
          formData.append("contactoCliente", entradaFiltrada.cliente.contacto);
          formData.append("moradaCliente", entradaFiltrada.cliente.endereco);
          formData.append(
            "codigoPaisCliente",
            entradaFiltrada.cliente.pais.codigo
          );
        }

        formData.append("valor", entradaFiltrada.quantiaPaga);
        formData.append("descricaoPagamento", "Reembolso");
        formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);
        formData.append("tipoDocEletronico", tipoDocumento);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("entrada", entradaFiltrada._id);
        formData.append("online", true);

        let resposta;
        try {
          resposta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro emitindo o documento eletrónico. Por favor tente novamente."
          );
        }

        const formData2 = new FormData();
        formData2.append(
          "documentoEletronico",
          resposta.respostaDocumentoEletronico._id
        );
        let novaEntrada;

        try {
          novaEntrada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/documentoeletronico/${entradaFiltrada.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "ocorreu um erro atualizando os dados do documento eletrónico na entrada. Por favor tente novamente."
          );
        }

        updateEntradas(
          {
            ...entradaFiltrada,
            documentoEletronico: novaEntrada.entrada.documentoEletronico,
          },
          "atualizar"
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  //Emitir FRE e DVE
  const emitirDocumentoFiscalParaDebitosClientes = async (
    tipoDocumento,
    parc,
    cc
  ) => {
    if (!cc.cliente.nif && tipoDocumento !== "TVE") {
      toast.error("O cliente não possui um nif");
    } else if (!cc.cliente.contacto) {
      toast.error("O cliente não possui um número de telefone");
    } else if (!cc.cliente.endereco) {
      toast.error("O cliente não possui um endereço");
    } else if (!cc.cliente.pais) {
      toast.error("O cliente não possui um pais");
    } else if (
      tipoDocumento === "TVE"
        ? false
        : await checkNifError(
            cc.cliente.nif,
            cc.cliente.nome,
            cc.cliente.pais.nome
          )
    ) {
      toast.error("nif incomaptivel com o nome do cliente");
    } else {
      startLoading();
      try {
        let contaFiltrada;
        cc.contas.forEach((conta) => {
          conta.parcelas.forEach((p) => {
            if (p._id === parc._id) contaFiltrada = conta;
          });
        });

        const formData = new FormData();
        formData.append("clinicaId", clinicaId);

        if (tipoDocumento !== "TVE") {
          formData.append("nomeCliente", cc.cliente.nome);
          formData.append("nifCliente", cc.cliente.nif);
          formData.append("contactoCliente", cc.cliente.contacto);
          formData.append("moradaCliente", cc.cliente.endereco);
          formData.append("codigoPaisCliente", cc.cliente.pais.codigo);
        }

        formData.append("valor", parc.quantiaPaga);
        formData.append("descricaoPagamento", "Tratamento dentário");

        formData.append("metodoPagamento", parc.metodoPagamento);
        formData.append("tipoDocEletronico", tipoDocumento);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("contaCorrente", cc._id);
        formData.append("conta", contaFiltrada._id);
        formData.append("parcela", parc._id);
        formData.append("online", true);
        if (tipoDocumento === "DVE")
          formData.append(
            "IUDCancelar",
            parc.documentoEletronico.entryName.replace(/\.xml$/, "")
          );

        let resposta;
        try {
          resposta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          toast.error(
            "Ocorreu um erro emitindo o documento eletrónico. Por favor, tente novamente."
          );
          console.error("err", err);
        }
        if (tipoDocumento === "DVE") {
          const formData2 = new FormData();
          const novasContas = [...cc.contas];
          cc.contas.forEach((c) => {
            c.parcelas.forEach((p) => {
              if (p._id === parc._id) {
                if (p.cancelamentosDocumentosEletronicos) {
                  p.cancelamentosDocumentosEletronicos.push({
                    DVE: resposta.respostaDocumentoEletronico._id,
                    documento: parc.documentoEletronico._id,
                  });
                } else {
                  p.cancelamentosDocumentosEletronicos = [
                    {
                      DVE: resposta.respostaDocumentoEletronico._id,
                      documento: parc.documentoEletronico._id,
                    },
                  ];
                }
                p.documentoEletronico = null;
              }
            });
          });
          formData2.append("contas", JSON.stringify(novasContas));
          let novaContaCorrente;
          try {
            novaContaCorrente = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${cc._id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            toast.error(
              "Ocorreu um erro atualizando os dados da conta corrente. Por favor, tente novamente."
            );
            console.error("err", err);
          }

          updateContaCorrente(novaContaCorrente.contaCorrente, null, null);
        } else {
          // Atualizar o documento eletronico na parcela na base de dados
          const novasContas = cc.contas;
          novasContas.forEach((c) => {
            c.parcelas.forEach((p) => {
              if (p._id === parc._id)
                p.documentoEletronico =
                  resposta.respostaDocumentoEletronico._id;
            });
          });
          const formData2 = new FormData();
          formData2.append("contas", JSON.stringify(novasContas));
          let novaContaCorrente;
          try {
            novaContaCorrente = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${cc._id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            toast.error(
              "Ocorreu um erro atualizando o documento eletrónico na conta corrente. Por favor, tente novamente."
            );
            console.error("err", err);
          }

          updateContaCorrente(novaContaCorrente.contaCorrente, null, null);
        }
        if (resposta.respostaEfatura?.responses[0]?.succeeded) {
          toast.success(tipoDocumento + "Emitido com sucesso");
        } else {
          toast.error(
            "Erro ao emitir" +
              tipoDocumento +
              ". " +
              resposta.respostaEfatura.responses[0].messages[0].description
          );
        }
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    }
  };

  //Emitir FTE ou NCE
  const emitirDocumentoFiscalParaEntradas = async (id, tipo, action) => {
    startLoading();
    try {
      let entradaFiltrada;
      //Caso eu passe a entrada em vez do id
      if (id?._id) {
        entradaFiltrada = id;
      } else {
        entradaFiltrada = entradas.filter((e) => e.id === id)[0];
      }

      //Enviamos o dfe escolhid. NCE ou FTE. Os dados enviados dependem do tipo de documento escolhido
      const formData = new FormData();
      formData.append("clinicaId", clinicaId);
      formData.append("nifCliente", entradaFiltrada.clienteFornecedor.nif);
      formData.append("valor", entradaFiltrada.valor);
      formData.append("descricaoPagamento", entradaFiltrada.descricao);
      formData.append("nomeCliente", entradaFiltrada.clienteFornecedor.nome);
      formData.append(
        "contactoCliente",
        entradaFiltrada.clienteFornecedor.contacto
      );
      formData.append(
        "moradaCliente",
        entradaFiltrada.clienteFornecedor.endereco
      );
      formData.append(
        "codigoPaisCliente",
        entradaFiltrada.clienteFornecedor.pais.codigo
      );
      formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);

      if (tipo !== "NCE")
        formData.append("dataPagamento", entradaFiltrada.dataPagamento);

      formData.append(
        "dataLimitePagamento",
        entradaFiltrada.dataLimitePagamento
      );

      formData.append("tipoDocEletronico", tipo);
      formData.append("usuarioEmissor", auth.userId);
      formData.append("entrada", entradaFiltrada._id);
      formData.append("online", true);

      if (tipo === "NCE")
        formData.append(
          "IUDCancelar",
          entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
        );

      let resposta;
      try {
        resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro emitindo o documento eletrónico. Por favor, tente novamente."
        );
      }

      // Atualizar o documento eletronico na entrada na base de dados
      const formData2 = new FormData();
      if (tipo === "NCE") {
        formData2.append("nce", resposta.respostaDocumentoEletronico._id);
        formData2.append(
          "documentoEletronico",
          entradaFiltrada.documentoEletronico.id
        );

        let novaEntrada;
        try {
          novaEntrada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposenvionce/${entradaFiltrada.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          toast.error(
            "Ocorreu um erro atualizando os dados da entrada. Por favor, tente novamente."
          );
          console.error("err", err);
        }

        updateEntradas(
          {
            ...entradaFiltrada,
            documentoEletronico: null,
            cancelamentosDocumentosEletronicos:
              novaEntrada.entrada.cancelamentosDocumentosEletronicos,
          },
          "atualizar"
        );
      } else if (tipo === "FTE") {
        formData2.append(
          "documentoEletronico",
          resposta.respostaDocumentoEletronico._id
        );

        let novaEntrada;
        try {
          novaEntrada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/documentoeletronico/${entradaFiltrada.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          toast.error(
            "Ocorreu um erro atualizando o documento eletrónico na entrada. Por favor, tente novamente."
          );
          console.error("err", err);
        }

        if (action === "atualizar") {
          updateEntradas(
            {
              ...entradaFiltrada,
              documentoEletronico: novaEntrada.entrada.documentoEletronico,
            },
            "atualizar"
          );
        } else {
          updateEntradas({
            ...entradaFiltrada,
            documentoEletronico: novaEntrada.entrada.documentoEletronico,
          });
        }
      }
      if (resposta.respostaEfatura.responses[0].succeeded) {
        toast.success(tipo + " emitido com sucesso");
      } else {
        toast.error(
          "Erro ao emitir" +
            tipo.resposta +
            ". " +
            resposta.respostaEfatura.responses[0].messages[0].description
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  //Emitir RCE
  const emitirRCEHandler = async (idEntrada, receberEEmitir) => {
    startLoading();
    try {
      const entradaFiltrada = entradas.filter((en) => en._id === idEntrada)[0];
      const formData = new FormData();
      formData.append("clinicaId", clinicaId);
      formData.append("nifCliente", entradaFiltrada.clienteFornecedor.nif);
      formData.append("valor", entradaFiltrada.valor);
      formData.append("nomeCliente", entradaFiltrada.clienteFornecedor.nome);
      formData.append(
        "contactoCliente",
        entradaFiltrada.clienteFornecedor.contacto
      );
      formData.append(
        "moradaCliente",
        entradaFiltrada.clienteFornecedor.endereco
      );
      formData.append(
        "codigoPaisCliente",
        entradaFiltrada.clienteFornecedor.pais.codigo
      );
      formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);
      formData.append("tipoDocEletronico", "RCE");
      formData.append("usuarioEmissor", auth.userId);
      formData.append("entrada", idEntrada);
      formData.append("online", true);

      formData.append(
        "IUDReceber",
        entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
      );

      formData.append(
        "innerDocumentNumber",
        entradaFiltrada.documentoEletronico.nrDocumento
      );
      let resposta;
      try {
        resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        toast.error(
          "Ocorreu um erro emitindo o documento eletrónico. Por favor, tente novamente."
        );
        console.error("err", err);
      }

      //Atualizar os dados da entrada
      const formData2 = new FormData();
      formData2.append("rce", resposta.respostaDocumentoEletronico._id);
      formData2.append("fte", entradaFiltrada.documentoEletronico._id);

      let novaEntrada;
      try {
        novaEntrada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposenviorce/${entradaFiltrada.id}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        toast.error(
          "Ocorreu um erro atualizando os dados da entrada. Por favor, tente novamente."
        );
        console.error("err", err);
      }

      updateEntradas(novaEntrada.entrada, "atualizar");

      if (resposta.respostaEfatura.responses[0].succeeded) {
        toast.success(
          `${
            receberEEmitir ? "Entrada Recebida e " : ""
          } RCE Emitido com sucesso`
        );
      } else {
        toast.error(
          "Erro ao emitir RCE" +
            resposta.respostaEfatura.responses[0].messages[0].description
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  // const beforeDVEReceita = (idCc, idConta, idPar) => {
  //   const contaCorrenteFiltrada = contasCorrentes.filter(
  //     (conta) => conta._id === idCc
  //   )[0];

  //   const contaFiltrada = contaCorrenteFiltrada.contas.filter(
  //     (conta) => conta._id === idConta
  //   )[0];

  //   const parcelaFiltrada = contaFiltrada.parcelas.filter(
  //     (parcela) => parcela._id === idPar
  //   )[0];

  //   emitirDocumentoFiscalParaDebitosClientes(
  //     "DVE",
  //     parcelaFiltrada,
  //     contaCorrenteFiltrada
  //   );
  // };

  const handleBeforeEmitirDocEletronico = (
    id,
    idContaCorrente,
    idConta,
    tipoDocumento
  ) => {
    const contaCorrenteFiltrada = contasCorrentes.filter(
      (conta) => conta._id === idContaCorrente
    )[0];

    const contaFiltrada = contaCorrenteFiltrada.contas.filter(
      (conta) => conta._id === idConta
    )[0];

    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela._id === id
    )[0];

    emitirDocumentoFiscalParaDebitosClientes(
      tipoDocumento,
      parcelaFiltrada,
      contaCorrenteFiltrada
    );
  };

  const gerarPdfFaturaEletronicaHandler = async (
    id,
    entradaOuReceita,
    outrosDados
  ) => {
    let dadosFiltrados;

    if (entradaOuReceita === "entrada") {
      dadosFiltrados = entradas.filter((e) => e.id === id)[0];
    } else if (entradaOuReceita === "receita") {
      const contaCorrenteFiltrada = contasCorrentes.filter(
        (c) => c._id === outrosDados.idContaCorrente
      )[0];
      const contaFiltrada = contaCorrenteFiltrada.contas.filter(
        (c) => c._id === outrosDados.contaId
      )[0];
      dadosFiltrados = contaFiltrada.parcelas.filter((p) => p._id === id)[0];
    }
    showFatura({
      show: true,
      clinica: dadosClinica,
      cliente: {
        nome:
          entradaOuReceita === "entrada"
            ? dadosFiltrados.clienteFornecedor.nome
            : outrosDados.dadosCliente.nome,
        endereco:
          entradaOuReceita === "entrada"
            ? dadosFiltrados.clienteFornecedor.endereco
            : outrosDados.dadosCliente.endereco,
        contacto:
          entradaOuReceita === "entrada"
            ? dadosFiltrados.clienteFornecedor.contacto
            : outrosDados.dadosCliente.contacto,
      },
      metodoPagamento: dadosFiltrados.metodoPagamento,
      designacao:
        entradaOuReceita === "entrada"
          ? dadosFiltrados.descricao
          : "Tratamento Dentário",
      valor:
        entradaOuReceita === "entrada"
          ? dadosFiltrados.valor
          : dadosFiltrados.quantiaPaga,
      nrDocumento: dadosFiltrados.documentoEletronico.nrDocumento,
      dataDocumento: dadosFiltrados.documentoEletronico.authorizedDateTime,
      dataVencimento: dadosFiltrados.dataLimitePagamento,
      entryName: dadosFiltrados.documentoEletronico.entryName,
      serie: dadosFiltrados.documentoEletronico.serie,
      tipoDocumento: dadosFiltrados.documentoEletronico.tipoDocumento,
      usuarioEmissor: dadosFiltrados.documentoEletronico.usuarioEmissor?.name,
    });
  };

  const gerarPdfRceHandler = async (id) => {
    let entradaFiltrada;

    entradaFiltrada = entradas.filter((e) => e.id === id)[0];
    showRce({
      show: true,
      clinica: dadosClinica,
      cliente: {
        nome: entradaFiltrada.clienteFornecedor.nome,
        endereco: entradaFiltrada.clienteFornecedor.endereco,
        contacto: entradaFiltrada.clienteFornecedor.contacto,
      },
      metodoPagamento: entradaFiltrada.metodoPagamento,
      dataPagamento: entradaFiltrada.dataPagamento,
      designacao: entradaFiltrada.descricao,
      valor: entradaFiltrada.valor,
      nrDocumento: entradaFiltrada.faturaEReciboEletronicos.rce.nrDocumento,
      dataDocumento:
        entradaFiltrada.faturaEReciboEletronicos.rce.authorizedDateTime,
      entryName: entradaFiltrada.faturaEReciboEletronicos.rce.entryName,
      serie: entradaFiltrada.faturaEReciboEletronicos.rce.serie,
      tipoDocumento: entradaFiltrada.faturaEReciboEletronicos.rce.tipoDocumento,
      referencia: entradaFiltrada.faturaEReciboEletronicos.fte.entryName,
      usuarioEmissor:
        entradaFiltrada.faturaEReciboEletronicos.rce.usuarioEmissor?.name,
    });
  };

  const updateEntradas = (entradaRetornada, action, emit) => {
    if (emit === "emitirFTE") {
      emitirDocumentoFiscalParaEntradas(entradaRetornada, "FTE", action);
    } else if (emit === "emitirRCE") {
      emitirRCEHandler(entradaRetornada._id, true);
    } else if (emit === "emitirFRE") {
      emitirDocEletronicoReembolso(null, entradaRetornada._id, "FRE");
    } else if (!action) {
      //Inserir
      const filterEntradasResults = filterEntrada(entradaRetornada);

      const tempEntradas = [...entradas];
      const tempEntradasAbsolut = [...entradasAbsolut];
      const tempEntradasParaTextSearch = [...entradasParaTextSearch];

      tempEntradas.push(entradaRetornada);
      tempEntradasAbsolut.push(entradaRetornada);
      tempEntradasParaTextSearch.push(entradaRetornada);

      if (filterEntradasResults.isAbsolut) {
        setEntradasAbsolut([...tempEntradasAbsolut]);
      }
      if (filterEntradasResults.isModalFiltered) {
        setEntradasParaTextSearch([...tempEntradasParaTextSearch]);
      }
      if (filterEntradasResults.isTextFiltered) {
        setEntradas([...tempEntradas]);
      }
    } else {
      //Atualizar
      //Se for a atualizacao de uma entrada, verificamos se ela pertence ao alcance de datas da mesma forma.
      //Se sim mantemo-la, se não, removemo-la
      const filterEntradasResults = filterEntrada(entradaRetornada);

      let tempEntradasAbsolut;
      let tempEntradas;
      let tempEntradasParaTextSearch;

      if (filterEntradasResults.isAbsolut) {
        tempEntradasAbsolut = entradasAbsolut.map((e) => {
          if (e._id === entradaRetornada._id) {
            return entradaRetornada;
          } else {
            return e;
          }
        });
      } else {
        tempEntradasAbsolut = entradasAbsolut.filter(
          (e) => e._id !== entradaRetornada._id
        );
      }

      if (filterEntradasResults.isModalFiltered) {
        tempEntradasParaTextSearch = entradasParaTextSearch.map((e) => {
          if (e._id === entradaRetornada._id) {
            return entradaRetornada;
          } else {
            return e;
          }
        });
      } else {
        tempEntradasParaTextSearch = entradasParaTextSearch.filter(
          (e) => e._id !== entradaRetornada._id
        );
      }

      if (filterEntradasResults.isTextFiltered) {
        tempEntradas = entradas.map((e) => {
          if (e._id === entradaRetornada._id) {
            return entradaRetornada;
          } else {
            return e;
          }
        });
      } else {
        tempEntradas = entradas.filter((e) => e._id !== entradaRetornada._id);
      }

      setEntradas([...tempEntradas]);
      setEntradasAbsolut([...tempEntradasAbsolut]);
      setEntradasParaTextSearch([...tempEntradasParaTextSearch]);
    }
  };

  const handleExportar = () => {
    const sheetData = rows.map((r) => {
      return {
        descricao: r.descricao
          ? r.descricao
          : `${r.nome}${r.nrParcela ? " - Parcela " + r.nrParcela : ""}`,
        cliente: r.cliente,
        valor: r.valor,
        tipo: r.tipo,
        estado: r.estado,
        data_Pagamento: r.dataPagamento
          ? moment(r.dataPagamento).format("DD-MM-YYYY")
          : "-",
        dataLimitePagamento: r.dataLimitePagamento
          ? moment(r.dataLimitePagamento).format("DD-MM-YYYY")
          : "-",
        metodoPagamento: r.metodoPagamento ? r.metodoPagamento : "-",
      };
    });

    generateExcelFile(
      `HistoricoFinanceiro ${moment().format("DD-MM-YYYY")}.xlsx`,
      "Financeiro",
      sheetData
    );
  };

  const handleFiltrar = (
    newContasCorrentes,
    newEntradas,
    filtros,
    filtroCai,
    filtroCat,
    filtroMet,
    textoSearch,
    data
  ) => {
    if (newContasCorrentes) {
      setContasCorrentes([...newContasCorrentes]);
    }
    if (newEntradas) {
      setEntradas([...newEntradas]);
    }

    if (data === "modalFiltros") {
      setEntradasParaTextSearch([...newEntradas]);
      setContasCorrentesParaTextSearch([...newContasCorrentes]);
    }

    if (data !== "textSearch") {
      setFiltrosAplicados(filtros);
      setFiltroCaixa(filtroCai);
      setFiltroCategoria(filtroCat);
      setFiltroMetodoPagamento(filtroMet);
    }
    setFiltroTextoSearch(textoSearch);

    if (data !== "modalFiltros" && data !== "textSearch") setDataFiltro(data);
  };

  const beforeGerarReciboHandler = async (idContaCorrente, id, idConta) => {
    startLoading();
    try {
      const contaCorrenteFiltrada = contasCorrentes.filter(
        (c) => c._id === idContaCorrente
      )[0];

      const contaFiltrada = contaCorrenteFiltrada.contas.filter(
        (conta) => conta._id === idConta
      )[0];

      const parcelaFiltrada = contaFiltrada.parcelas.filter(
        (parcela) => parcela._id === id
      )[0];

      let nrRecibo = 1;

      if (parcelaFiltrada.nrRecibo) {
        nrRecibo = parcelaFiltrada.nrRecibo;
        gerarPdfReciboHandler(
          parcelaFiltrada,
          nrRecibo,
          contaCorrenteFiltrada.cliente
        );
      } else {
        try {
          const nrDocumentos = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;
        } catch (err) {
          toast.error(
            "Ocorreu um erro buscando os dados da numeracao de documentos eletrónicos. Por favor, tente novamente."
          );
          console.error("err", err);
        }

        //Atualizar o numero de recibo

        const formData = new FormData();
        formData.append("valor", nrRecibo);
        formData.append("tipoDocumento", "recibo");
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro atualizando os dados da numeração de documentos eletrónicos. Por favor tente novamente."
          );
        }

        try {
          const novasContas = contaCorrenteFiltrada.contas;

          novasContas.forEach((c) => {
            c.parcelas.forEach((p) => {
              if (p._id === parcelaFiltrada._id) p.nrRecibo = nrRecibo;
            });
          });

          const formData = new FormData();
          formData.append("contas", JSON.stringify(contaFiltrada));
          let contaCorrenteRetornada;
          try {
            contaCorrenteRetornada = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrenteFiltrada._id}`,
              "PATCH",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
            toast.error(
              "Ocorreu um erro atualizando a conta corrente. Por favor tente novamente."
            );
          }
          updateContaCorrente(contaCorrenteRetornada.contaCorrente, null, null);
        } catch (err) {
          console.error("err", err);
        }

        gerarPdfReciboHandler(
          parcelaFiltrada,
          nrRecibo,
          contaCorrenteFiltrada.cliente
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const gerarPdfReciboHandler = async (
    parcelaFiltrada,
    nrRecibo,
    clienteData
  ) => {
    dadosRecibo({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: parcelaFiltrada.quantiaPaga,
      nrRecibo: nrRecibo,
    });
  };

  const beforeEmitirEvento = (idEntrada) => {
    setModalData(idEntrada);
    setOpenModal("evento");
  };
  // const handleEmitirEvento = async (idEntrada, motivo) => {};

  const actionColumn = [
    {
      field: "inOrOut",
      headerName: "",
      width: 30,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <CallReceivedIcon className="financeiro__container__cabecalho__totais--icon_div--receita" />
            )}

            {params.row.tipo === "entrada" && (
              <CallReceivedIcon className="financeiro__container__cabecalho__totais--icon_div--receita" />
            )}
          </>
        );
      },
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.tipo === "receita" && (
              <span
                className={
                  params.row.parcelaPaga && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.parcelaPaga &&
                      moment(params.row.dataLimitePagamento) < moment()
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.parcelaPaga && params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </span>
            )}

            {params.row.tipo === "entrada" && (
              <div
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.entradaRecebida && params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 5,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoNome">
                <span
                  className={
                    params.row.parcelaPaga && params.row.dataPagamento
                      ? "spanCellPago"
                      : !params.row.parcelaPaga &&
                        moment(params.row.dataLimitePagamento) < moment()
                      ? "spanCellAtraso"
                      : ""
                  }
                >
                  {params.row.nome}{" "}
                  <Link
                    to={`/clientes/${params.row.idCliente}`}
                    className="link-cliente-receita"
                  >
                    {" "}
                    {params.row.cliente}
                  </Link>
                </span>
                {params.row.nrParcela && (
                  <Tooltip
                    title={`Parcelado em ${params.row.nrParcela[2]} vezes`}
                  >
                    <IconButton>
                      <span className="nrParcela">{params.row.nrParcela}</span>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Ver lista de procedimentos">
                  <IconButton
                    onClick={handleOpenModalProcedimentos.bind(
                      null,
                      params.row.orcamento
                    )}
                  >
                    <ErrorIcon className="muiIconVerOrcamento" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.descricao}
                {params.row.cliente && (
                  <>
                    {" - "}
                    <Link
                      to={`/clientes/${params.row.cliente._id}`}
                      className="link-cliente-receita"
                    >
                      {params.row.cliente.nome}
                    </Link>
                  </>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoEstado">
                <span
                  className={`debitos__container__estado ${
                    params.row.estado === "Em atraso"
                      ? "debitos__container__estado--atraso"
                      : params.row.estado === "Pago"
                      ? "debitos__container__estado--pago"
                      : ""
                  }`}
                >
                  {params.row.metodoPagamento
                    ? params.row.metodoPagamento
                    : params.row.estado}
                </span>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div className="cellDebitoEstado">
                <span
                  className={
                    "debitos__container__estado debitos__container__estado--pago"
                  }
                >
                  {params.row.metodoPagamento}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellAction">
                <span
                  className={`debitos__container__valor ${
                    params.row.estado === "Em atraso"
                      ? "debitos__container__valor--atraso"
                      : params.row.estado === "Pago"
                      ? "debitos__container__valor--pago"
                      : ""
                  }`}
                >
                  {params.row.valor}
                </span>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <span
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.valor}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellDebitoStatus">
            {params.row.estado === "Pago" && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip title="Parcela Paga">
                  <CheckCircleIcon />
                </Tooltip>
              </span>
            )}

            {params.row.tipo === "entrada" && params.row.entradaRecebida && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip title="Entrada Recebida">
                  <CheckCircleIcon />
                </Tooltip>
              </span>
            )}

            {params.row.documentoEletronico?.succeeded === true && (
              <Tooltip
                title={`${params.row.documentoEletronico?.tipoDocumento} enviado`}
              >
                <div className="debitos__container__receber debitos__container__receber--pago">
                  <ArticleOutlinedIcon />
                </div>
              </Tooltip>
            )}

            {params.row.documentoEletronico?.succeeded === false && (
              <span className="debitos__container__receber debitos__container__receber--atraso">
                <Tooltip
                  title={`${params.row.documentoEletronico?.tipoDocumento} com erro. -> ${params.row.documentoEletronico?.messages[0]?.description}`}
                >
                  <ArticleOutlinedIcon />
                </Tooltip>
              </span>
            )}

            {params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
              params.row.cancelamentosDocumentosEletronicos[
                params.row.cancelamentosDocumentosEletronicos?.length - 1
              ]?.DVE?.succeeded === true && (
                <span className="debitos__container__receber debitos__container__receber--pago">
                  <Tooltip
                    title={
                      <React.Fragment>
                        {params.row.cancelamentosDocumentosEletronicos?.length +
                          "cancelamentos efetuados."}
                        <br />
                        {params.row.cancelamentosDocumentosEletronicos.map(
                          (c, index) => (
                            <React.Fragment key={index}>
                              {index > 0 && <br />}
                              {c.documento.entryName.split(".")[0]}
                            </React.Fragment>
                          )
                        )}
                      </React.Fragment>
                    }
                  >
                    <ArticleOutlinedIcon />
                  </Tooltip>
                </span>
              )}

            {params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
              params.row.cancelamentosDocumentosEletronicos[
                params.row.cancelamentosDocumentosEletronicos?.length - 1
              ]?.DVE?.succeeded === false && (
                <span className="debitos__container__receber debitos__container__receber--atraso">
                  <Tooltip
                    title={
                      <React.Fragment>
                        DVE com erro:
                        <br />
                        {params.row.cancelamentosDocumentosEletronicos[
                          params.row.cancelamentosDocumentosEletronicos.length -
                            1
                        ].DVE.messages.map((m, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && (
                              <>
                                <br />
                                <br />
                              </>
                            )}
                            {m.description}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    }
                  >
                    <ArticleOutlinedIcon />
                  </Tooltip>
                </span>
              )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => {
        let content;
        if (params.row.tipo === "receita" && params.row.parcelaPaga) {
          content = (
            <div className="dots__menu__popup">
              {/* Receitas pagas */}
              <div
                className="popOverMenu--option"
                onClick={verReceitaHandler.bind(
                  null,
                  params.row.id,
                  params.row.contaId,
                  params.row.idContaCorrente
                )}
              >
                <span>Ver</span>
                <RemoveRedEyeIcon className="popOverMenu--option__icon" />
              </div>

              {/* Pdf DFE */}
              {/*PDF FRE - apenas se o documento fiscal enviado for bem sucedido  */}
              {params.row.documentoEletronico?.succeeded && (
                <div
                  className="popOverMenu--option"
                  onClick={gerarPdfFaturaEletronicaHandler.bind(
                    null,
                    params.row.id,
                    "receita",
                    {
                      dadosCliente: params.row.dadosCliente,
                      idContaCorrente: params.row.idContaCorrente,
                      contaId: params.row.contaId,
                    }
                  )}
                >
                  <span>
                    Gerar PDF {params.row.documentoEletronico.tipoDocumento}
                  </span>
                  <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {/* GERAR PDF RCE apenas se o documento fiscal enviado for bem sucedido  */}
              {params.row.faturaEReciboEletronicos?.rce?.succeeded && (
                <div
                  className="popOverMenu--option"
                  onClick={gerarPdfFaturaEletronicaHandler.bind(
                    null,
                    params.row.id,
                    "entrada",
                    {
                      dadosCliente: params.row.dadosCliente,
                      idContaCorrente: params.row.idContaCorrente,
                      contaId: params.row.contaId,
                    }
                  )}
                >
                  <span>Gerar PDF RCE</span>
                  <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {/* EMITIR FRE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
              {!params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.DVE?.succeeded === true)) && (
                  <Popconfirm
                    title="Fatura Recibo Eletrónico"
                    description={`Pretende emitir a FRE?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={handleBeforeEmitirDocEletronico.bind(
                      null,
                      params.row.id,
                      params.row.idContaCorrente,
                      params.row.contaId,
                      "FRE"
                    )}
                  >
                    <div className="popOverMenu--option">
                      Emitir FRE
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* EMITIR FRE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
              {!params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.DVE?.succeeded === true)) && (
                  <Popconfirm
                    title="Talão de Venda Eletrónico"
                    description={`Pretende emitir a TVE?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={handleBeforeEmitirDocEletronico.bind(
                      null,
                      params.row.id,
                      params.row.idContaCorrente,
                      params.row.contaId,
                      "TVE"
                    )}
                  >
                    <div className="popOverMenu--option">
                      Emitir TVE
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* RECIBO INTERNO - Parcela paga (no If lá acima), com dinheiro, orcamento particular, sem documentoEletronico com ou sem erro e sem cancelamento com erro */}
              {params.row.orcamento.beneficio === "Particular" &&
                params.row.metodoPagamento === "Dinheiro" &&
                !params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.DVE?.succeeded === false)) && (
                  <Popconfirm
                    title="Recibo Interno"
                    description={`Pretende emitir um recibo Interno?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={beforeGerarReciboHandler.bind(
                      null,
                      params.row.idContaCorrente,
                      params.row.id,
                      params.row.contaId
                    )}
                  >
                    <div className="popOverMenu--option">
                      {params.row.nrRecibo ? "Ver " : "Emitir "} Recibo Interno
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Emitir DVE OU CANCELAR RECEBIMENTO - apenas para ftes e fres, caso o documento enviado obteve sucesso e se caso houver um envio de DVE, esse foi bem sucedido */}
              {/* {params.row.documentoEletronico?.succeeded &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.DVE?.succeeded === true)) && (
                  <Popconfirm
                    title="Nota de devolução - DVE"
                    description={`Pretende cancelar a FRE com essa DVE?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={beforeDVEReceita.bind(
                      null,
                      params.row.idContaCorrente,
                      params.row.contaId,
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir DVE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )} */}

              {/* Exatamento como o anterior. Caso exista documentos eletronicos ou cancelamentos, são verificados como o anterior */}
              {((!params.row.documentoEletronico &&
                (!params.row.cancelamentosDocumentosEletronicos ||
                  params.row.cancelamentosDocumentosEletronicos?.length ===
                    0)) ||
                ((params.row.documentoEletronico?.succeeded ||
                  !params.row.documentoEletronico) &&
                  (params.row.cancelamentosDocumentosEletronicos?.length ===
                    0 ||
                    (params.row.cancelamentosDocumentosEletronicos?.length >
                      0 &&
                      params.row.cancelamentosDocumentosEletronicos[
                        params.row.cancelamentosDocumentosEletronicos?.length -
                          1
                      ]?.DVE?.succeeded === true)))) &&
                auth.perm.includes("canc-deb") && (
                  <Popconfirm
                    title="Cancelar Recebimento"
                    description={`Pretende cancelar o recebimento de ${
                      params.row.nome
                    } ${
                      params.row.documentoEletronico?.tipoDocumento === "FRE"
                        ? "e enviar uma DVE para o anulação da FRE?"
                        : "?"
                    }`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={beforeCancelarRecebimento.bind(
                      null,
                      params.row.id,
                      params.row.contaId,
                      params.row.idContaCorrente
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Cancelar recebimento</span>
                      <SettingsBackupRestoreIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}
            </div>
          );

          // Entradas
        } else if (params.row.tipo === "entrada") {
          content = (
            <div className="dots__menu__popup">
              {/* VER ENTRADA */}
              <div
                className="popOverMenu--option"
                onClick={verEntradaHandler.bind(null, params.row.id)}
              >
                <span>Ver</span>
                <RemoveRedEyeIcon className="popOverMenu--option__icon" />
              </div>
              {/* EDITAR ENTRADA - apenas entradas que não possuem documento fiscal, cujo envio resultou em erro ou que nao estao pagas, não pode editar um documento com um envio de nce com erro*/}
              {(!params.row.documentoEletronico ||
                params.row.documentoEletronico?.succeeded === false) &&
                !params.row.entradaRecebida &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                auth.perm.includes("u-ent") && (
                  <div
                    className="popOverMenu--option"
                    onClick={editarEntradaHandler.bind(null, params.row.id)}
                  >
                    <span>Editar</span>
                    <EditIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {/* GERAR PDF FTE - se existe documento eletronico e foi bem sucedido */}
              {params.row.documentoEletronico?.succeeded && (
                <div
                  className="popOverMenu--option"
                  onClick={gerarPdfFaturaEletronicaHandler.bind(
                    null,
                    params.row.id,
                    "entrada"
                  )}
                >
                  <span>
                    Gerar PDF{" "}
                    {params.row.tipoEntrada === "reembolso" ? "FRE" : "FTE"}
                  </span>
                  <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {/* GERAR PDF RCE - se existe RCE e foi bem sucedido */}
              {params.row.faturaEReciboEletronicos?.rce?.succeeded &&
                !params.row.tipoEntrada === "reembolso" && (
                  <div
                    className="popOverMenu--option"
                    onClick={gerarPdfRceHandler.bind(
                      null,
                      params.row.id,
                      "entrada"
                    )}
                  >
                    <span>Gerar PDF RCE</span>
                    <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {/* EMITIR FTE - Não pode existir documento eletronico enviado com ou sem erro, não pode existir cancelamento com erro e a entrada não pode estar paga*/}
              {!params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                !params.row.entradaRecebida &&
                params.row.tipoEntrada !== "reembolso" && (
                  <Popconfirm
                    title="Fatura Eletrónica - FTE"
                    description={`Confirma a emissão?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocumentoFiscalParaEntradas.bind(
                      null,
                      params.row.id,
                      "FTE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir FTE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/*Emitir Fre para reembolsos*/}
              {!params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                params.row.entradaRecebida &&
                params.row.tipoEntrada === "reembolso" && (
                  <Popconfirm
                    title="Fatura Recibo Eletrónico - FRE"
                    description={`Confirma a emissão?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocEletronicoReembolso.bind(
                      null,
                      null,
                      params.row.id,
                      "FRE"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir FRE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/*Emitir Fre para reembolsos*/}
              {!params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                params.row.entradaRecebida &&
                params.row.tipoEntrada === "reembolso" && (
                  <Popconfirm
                    title="Talão de Venda Eletrónico - TVE"
                    description={`Confirma a emissão?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocEletronicoReembolso.bind(
                      null,
                      null,
                      params.row.id,
                      "TVE"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir TVE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Emitir documentoCancelamento - apenas para ftes, caso o documento enviado obteve sucesso e se caso houver um envio de NCE, esse foi bem sucedido */}
              {params.row.documentoEletronico?.succeeded &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                (!params.row.faturaEReciboEletronicos ||
                  params.row.faturaEReciboEletronicos?.length === 0) &&
                params.row.tipoEntrada !== "reembolso" && (
                  <Popconfirm
                    title="Nota de Crédito - NCE"
                    description={`Pretende cancelar a FTE com essa NCE?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocumentoFiscalParaEntradas.bind(
                      null,
                      params.row.id,
                      "NCE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir NCE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/*CANCELAR RECEBIMENTO 1 - apenas para FTES, caso a entrada estiver paga, o documento enviado obteve sucesso e se caso houver um envio de NCE, esse foi bem sucedido */}
              {params.row.entradaRecebida &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                (!params.row.faturaEReciboEletronicos ||
                  params.row.faturaEReciboEletronicos?.length === 0) && (
                  <Popconfirm
                    title="Cancelar Recebimento"
                    description={`Pretende cancelar o recebimento da entrada - ${
                      params.row.descricao
                    } ${
                      params.row.documentoEletronico?.succeeded
                        ? `e cancelar o documento eletrónico emitido`
                        : ""
                    }?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={handleBeforeCancelarRecebimentoEntrada.bind(
                      null,
                      params.row.id,
                      params.row.documentoEletronico?.succeeded ? true : false //Enviar nce caso houver um fte enviado
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Cancelar Recebimento</span>
                      <SettingsBackupRestoreIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Emitir RCE - se a entrada estiver recebida e não houver nenhum erro nem no documento fiscal, no recibos eletronicos. (PENSAR EM CASO DE ERRO NO CANCELAMENTO) */}
              {params.row.entradaRecebida &&
                params.row.documentoEletronico?.succeeded &&
                (!params.row.faturaEReciboEletronicos ||
                  params.row.faturaEReciboEletronicos?.length === 0) && (
                  <Popconfirm
                    title="Recibo Eletrónico - RCE"
                    description={`Pretende enviar o RCE?`}
                    icon={<ErrorIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirRCEHandler.bind(null, params.row.id, null)}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir RCE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Deve haver um recibo emitido com sucesso e a entrada deve estar recebida */}
              {params.row.entradaRecebida &&
                params.row.faturaEReciboEletronicos?.rce?.succeeded &&
                params.row.tipoEntrada !== "reembolso" && (
                  <Popconfirm
                    title="Evento Eletrónico"
                    description={`Pretende Anular o Recibo da Entrada?`}
                    icon={<ErrorIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={beforeEmitirEvento.bind(
                      null,
                      params.row.id,
                      null
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Anular Recibo</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Comprovativo */}
              {params.row.comprovativo && params.row.comprovativo.ficheiro && (
                <a
                  href={`${process.env.REACT_APP_BACKEND_LINK}/${params.row.comprovativo.ficheiro}`}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="popOverMenu--option">
                    <span>Baixar Comprovativo</span>
                    <DownloadIcon className="popOverMenu--option__icon" />
                  </div>
                </a>
              )}

              {/* DESATIVAR ENTRADA -  se não houver nenum tipo de documento fiscal com ou sem erro e a entrada não estiver recebida */}
              {!params.row.documentoEletronico?.succeeded &&
                (!params.row.cancelamentosDocumentosEletronicos ||
                  params.row.cancelamentosDocumentosEletronicos?.length ===
                    0) &&
                (!params.row.faturaEReciboEletronicos ||
                  params.row.faturaEReciboEletronicos?.length === 0) &&
                !params.row.entradaRecebida &&
                auth.perm.includes("d-ent") && (
                  <Popconfirm
                    title="Desativar Entrada"
                    description={`Pretende desativar a entrada - ${params.row.descricao}?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={desativarEntradaHandler.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Desativar</span>
                      <DeleteIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}
            </div>
          );
        }

        return (
          <>
            {/* RECEBER - Receitas nao pagas */}
            {params.row.tipo === "receita" &&
              params.row.estado !== "Pago" &&
              auth.perm.includes("receb-rec") && (
                <span
                  className={`debitos__container__receber ${
                    params.row.estado === "Em atraso" &&
                    "debitos__container__receber--atraso"
                  }`}
                  onClick={handleOpenModalReceber.bind(
                    null,
                    params.row.id,
                    params.row.valor,
                    params.row.idContaCorrente,
                    params.row.cliente,
                    params.row.idCliente
                  )}
                >
                  Receber
                </span>
              )}

            {/* RECEBER - Entradas nao pagas */}
            {params.row.tipo === "entrada" &&
              !params.row.entradaRecebida &&
              ((params.row.documentoEletronico &&
                params.row.tipoEntrada !== "reembolso") ||
                params.row.tipoEntrada === "reembolso") &&
              auth.perm.includes("rec-ent") && (
                <span
                  className={`debitos__container__receber ${
                    params.row.estado === "Em atraso" &&
                    "debitos__container__receber--atraso"
                  }`}
                  onClick={handleOpenModalReceberEntrada.bind(
                    null,
                    params.row.id
                  )}
                >
                  Receber
                </span>
              )}

            {content && (
              <Popover content={content} trigger="click" placement="bottom">
                <div className="dots__menu">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Popover>
            )}
          </>
        );
      },
    },
  ];

  const updateContaCorrente = (
    novaContaCorrente,
    dadosEmicaoDocumento,
    tipoDocumento
  ) => {
    setOpenModal("");

    //Se houver dados de emissao, as funcoes irão chamar essa funcao de updateContaCorrente Novamente
    //Porém sem essa variavel de dados de emissao, e aí, simplismente filtraremos a conta corrente
    //resultante dessas operacoes
    if (dadosEmicaoDocumento) {
      const contaCorrenteFiltrada = contasCorrentes.filter(
        (c) => c._id === dadosEmicaoDocumento.IdcontaCorrente
      )[0];

      const contaFiltrada = contaCorrenteFiltrada.contas.filter(
        (c) => c._id === dadosEmicaoDocumento.Idconta
      )[0];

      const parcelaFiltrada = contaFiltrada.parcelas.filter(
        (p) => p._id === dadosEmicaoDocumento.IdParcela
      )[0];

      if (tipoDocumento === "FRE") {
        emitirDocumentoFiscalParaDebitosClientes(
          "FRE",
          parcelaFiltrada,
          contaCorrenteFiltrada
        );
      } else {
        beforeGerarReciboHandler(
          dadosEmicaoDocumento.IdcontaCorrente,
          dadosEmicaoDocumento.IdParcela,
          dadosEmicaoDocumento.Idconta
        );
      }
    } else {
      const contasCorrentesComDiferenteFiltros = filterContasCorrentes(
        novaContaCorrente,
        dataFiltro.dataInicial,
        dataFiltro.dataFinal
      );
      const tempContasCorrentesAbsolut = contasCorrentesAbsolut.map((cc) => {
        if (cc._id !== novaContaCorrente._id) {
          return cc;
        } else return contasCorrentesComDiferenteFiltros.contaCorrenteAbsolut;
      });

      const tempContasCorrentes = contasCorrentes.map((cc) => {
        if (cc._id !== novaContaCorrente._id) {
          return cc;
        } else return contasCorrentesComDiferenteFiltros.contaCorrente;
      });
      const tempContasCorrentesParaTextSearch =
        contasCorrentesParaTextSearch.map((cc) => {
          if (cc._id !== novaContaCorrente._id) {
            return cc;
          } else
            return contasCorrentesComDiferenteFiltros.contaCorrenteParaTextSearch;
        });

      setContasCorrentesAbsolut([...tempContasCorrentesAbsolut]);
      setContasCorrentes([...tempContasCorrentes]);
      setContasCorrentesParaTextSearch([...tempContasCorrentesParaTextSearch]);
    }
  };

  return (
    <div className="financeiro__container caixa">
      <div className="financeiro__adicionarEFiltros">
        <FiltrosReceitas
          contasCorrentesAbsolut={contasCorrentesAbsolut}
          entradasAbsolut={entradasAbsolut}
          contasCorrentes={contasCorrentes}
          entradas={entradas}
          entradasParaTextSearch={entradasParaTextSearch}
          contasCorrentesParaTextSearch={contasCorrentesParaTextSearch}
          handleFiltrar={handleFiltrar}
          handleExportar={handleExportar}
          filtrosAplicados={filtrosAplicados}
          filtroCaixa={filtroCaixa}
          filtroCategoria={filtroCategoria}
          filtroMetodoPagamento={filtroMetodoPagamento}
          filtroTextoSearch={filtroTextoSearch}
          clinicaId={clinicaId}
          auth={auth}
          fetchContasCorrentesEntrada={fetchContasCorrentesEntrada}
        />

        <div>
          {auth.perm.includes("c-ent") && (
            <Button
              variant="contained"
              color="success"
              id="basic-button"
              onClick={(e) => setOpenModal("entrada")}
            >
              + Adicionar Entrada
            </Button>
          )}
        </div>
      </div>

      <div className="financeiro__container__cabecalho">
        <div className="financeiro__container__cabecalho__totais">
          <div className="financeiro__container__cabecalho__totais--recebido">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--recebido">
              <div className="financeiro__container__cabecalho__totais--icon_div">
                <span className="financeiro__container__cabecalho__totais--titulos">
                  Total Recebido
                </span>
              </div>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--recebido--valor_principal">
                  {financialData.totalRecebido}$00
                </span>
              </div>
            </div>
          </div>

          <div className="financeiro__container__cabecalho__totais--saldo">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--saldo">
              <span className="financeiro__container__cabecalho__totais--titulos">
                Total a Receber
              </span>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--saldo--valor_principal">
                  {financialData.totalReceber}$00
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {rows && (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={actionColumn}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div>
      )}
      {modalData.open === "receber" && (
        <ModalReceberFinanceiro
          open={openModal === "receber"}
          handleCloseModalReceber={handleCloseModal}
          modalData={modalData}
          updateContaCorrente={updateContaCorrente}
          sendRequest={sendRequest}
          filterContasCorrentes={filterContasCorrentes}
        />
      )}

      {openModal === "procedimentos" && (
        <ModalProcedimentos
          open={openModal === "procedimentos"}
          handleCloseModalProcedimentos={handleCloseModal}
          modalProcedimentosData={modalData}
        />
      )}

      {openModal === "verReceita" && (
        <ModalVerReceita
          open={openModal === "verReceita"}
          handleCloseModalVerReceita={handleCloseModal}
          dadosDaReceita={modalData}
          nomeCliente={cliente}
        />
      )}

      {openModal === "verEntrada" && (
        <ModalVerEntrada
          openModalVerEntrada={openModal === "verEntrada"}
          handleCloseModalVerEntrada={handleCloseModal}
          dadosModalVerEntrada={modalData}
        />
      )}

      {openModal === "entrada" && (
        <ModalEntrada
          openModalEntrada={openModal === "entrada"}
          sendRequest={sendRequest}
          handleCloseModalEntrada={handleCloseModal}
          clinicaId={clinicaId}
          auth={auth}
          updateEntradas={updateEntradas}
          emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
        />
      )}

      {openModal === "editarEntrada" && (
        <ModalEditarEntrada
          openModalEditarEntrada={openModal === "editarEntrada"}
          sendRequest={sendRequest}
          handleCloseModalEditarEntrada={handleCloseModal}
          clinicaId={clinicaId}
          auth={auth}
          updateEntradas={updateEntradas}
          entradaId={entradaId}
          emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
        />
      )}

      {openModal === "receberEntrada" && (
        <ModalReceberEntrada
          openModalReceberEntrada={openModal === "receberEntrada"}
          sendRequest={sendRequest}
          handleCloseModalReceberEntrada={handleCloseModal}
          auth={auth}
          updateEntradas={updateEntradas}
          dadosModalReceberEntrada={modalData}
          clinicaId={clinicaId}
          emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
        />
      )}

      {dadosModalMotivoDve.open === "parcela" && (
        <ModalMotivoDve
          open={dadosModalMotivoDve.open}
          handleCloseModal={handleCloseModal}
          modalData={dadosModalMotivoDve}
          handleCancelarRecebimento={handleCancelarRecebimento}
        />
      )}

      {dadosModalMotivoDve.open === "reembolso" && (
        <ModalMotivoDveReembolso
          open={dadosModalMotivoDve.open}
          handleCloseModal={handleCloseModal}
          modalData={dadosModalMotivoDve}
          handleCancelarRecebimentoEntrada={handleCancelarRecebimentoEntrada}
        />
      )}

      {/* {openModal === "evento" && modalData && (
        <ModalMotivoEvento
          open={openModal === "evento"}
          handleCloseModal={handleCloseModal}
          idEntrada={modalData}
          handleEmitirEvento={handleEmitirEvento}
        />
      )} */}
    </div>
  );
}

export default Receitas;
