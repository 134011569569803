import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfAtestadoStyle.scss";
import moment from "moment/moment";

const printStyles = `
    @media print {
      @page {
        size: 148mm 210mm;
      }
    }
  `;

function PdfAtestado({ atestadoData, showPreviousPage }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Atestado-${atestadoData.cliente.nome}_${moment(
      new Date()
    ).format("DD-MM-YYYY")}`,
    onAfterPrint: () => {
      showPreviousPage();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  return (
    <>
      <div ref={componentRef} className="pdf-wrapper">
        <style>{printStyles}</style>
        <div className="pdf-atestado-body">
          {/* TOP */}
          <div className="pdf-atestado__cabecalho">
            <div className="pdf-atestado__cabecalho--left">
              <img
                src={`${process.env.REACT_APP_BACKEND_LINK}/${atestadoData.clinica.logotipo}`}
                alt=""
              />
            </div>
            <div className="pdf-atestado__cabecalho--right">
              <span className="pdf-atestado__cabecalho--right--dark">
                {atestadoData.clinica.nome ? atestadoData.clinica.nome : ""}
              </span>
              <span className="pdf-atestado__cabecalho--right--dark">
                {atestadoData.medico.name}/{atestadoData.medico.profissao}
              </span>
              <span>
                Tel:
                {atestadoData.clinica.contacto
                  ? atestadoData.clinica.contacto
                  : ""}
                {atestadoData.clinica.contacto2 &&
                  `/${atestadoData.clinica.contacto2}`}
              </span>
              <span>{atestadoData.clinica.endereco}</span>
              <span className="pdf-atestado__cabecalho--right--dark">
                NIF:{atestadoData.clinica.nif}
              </span>
            </div>
          </div>
          {/* MIDDLE */}
          <div className="pdf-atestado__middle">
            <h2 className="pdf-atestado__middle__title">
              atestado de comparecimento
            </h2>

            <span>
              Atestamos para fins{" "}
              <span className="pdf-atestado--dark">
                {atestadoData.finalidade}
              </span>
              , que{" "}
              <span className="pdf-atestado--dark">
                {atestadoData.cliente.nome}
              </span>{" "}
              esteve presente nesta clínica para o tratamento odontológico:
            </span>

            {atestadoData.tipo_dependente === "Do próprio" && (
              <div className="pdf-atestado__middle__checkboxContainer">
                <input
                  type="checkbox"
                  className="pdf-atestado__middle__checkboxContainer__checkbox"
                  defaultChecked={atestadoData.tipo_dependente === "Do próprio"}
                />{" "}
                <span className="pdf-atestado__middle__checkboxContainer__label">
                  Do próprio
                </span>
              </div>
            )}
            {atestadoData.tipo_dependente === "Do seu dependente" && (
              <div className="pdf-atestado__middle__checkboxContainer">
                <input
                  type="checkbox"
                  className="pdf-atestado__middle__checkboxContainer__checkbox"
                  defaultChecked={
                    atestadoData.tipo_dependente === "Do seu dependente"
                  }
                />{" "}
                <span className="pdf-atestado__middle__checkboxContainer__label">
                  Do seu dependente{" "}
                  <span className="pdf-atestado--dark">
                    {atestadoData.nome_dependente}
                  </span>
                </span>
              </div>
            )}

            <span>
              No dia{" "}
              <span className="pdf-atestado--dark">
                {moment(atestadoData.data_atestado).format("DD-MM-YYYY")}
              </span>
              ,
              {(atestadoData.tipo === "Comparecimento" ||
                atestadoData.tipo === "Dias e Comparecimento") && (
                <>
                  {" "}
                  no horário das{" "}
                  <span className="pdf-atestado--dark">
                    {moment(atestadoData.hora_inicial).format("HH:mm")}
                  </span>{" "}
                  às{" "}
                  <span className="pdf-atestado--dark">
                    {moment(atestadoData.hora_final).format("HH:mm")}
                  </span>
                </>
              )}{" "}
              {(atestadoData.tipo === "Dias" ||
                atestadoData.tipo === "Dias e Comparecimento") && (
                <>
                  e deve ficar em repouso por{" "}
                  <span className="pdf-atestado--dark">
                    {atestadoData.dias * 24} horas
                  </span>
                  .
                </>
              )}
            </span>

            <div className="pdf-atestado__middle__data-assinatura">
              <span className="pdf-atestado__middle__data-assinatura--data">
                {atestadoData.clinica.ilha}, {moment().format("DD")} de{" "}
                {moment().format("MM")} de {moment().format("YYYY")}
              </span>
              <span className="pdf-atestado__middle__data-assinatura--assinatura">
                _____________________________________________________________
              </span>
            </div>
            <span className="pdf-atestado__middle__data-assinatura--carimbo">
              (Assinatura e Carimbo)
            </span>
          </div>

          {/* RODAPÉ */}
          <div className="pdf-atestado__rodape">
            <span>
              Processado por{" "}
              <span className="pdf-atestado--dark"> HEALTH TECH CV</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PdfAtestado;
