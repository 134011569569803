import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import "./PieChartAdministrativo.scss";

function PieChartAdministrativo({ data, COLORS }) {
  return (
    <div className="pieCharAdministrativoContainer">
      <PieChart width={350} height={300}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          cx="50%"
          cy="50%"
          label
          outerRadius={80}
          fill="#8884d8"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              cx={500}
              cy={200}
              innerRadius={40}
              outerRadius={80}
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
}

export default PieChartAdministrativo;
