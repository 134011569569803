import React, { useState, useEffect, memo } from "react";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import "./ModalOrcamentosHoje.scss";
import { Link } from "react-router-dom";

import { useHttpClient } from "../../../../../shared/hooks/http-hook";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function ModalOrcamentosHoje({
  openModal,
  handleClose,
  orcamentosHoje,
  clinicaId,
  auth,
}) {
  const [rows, setRows] = useState([]);
  const [data, setData] = useState(moment());

  const { sendRequest } = useHttpClient();

  useEffect(() => {
    const tempRows = [];

    orcamentosHoje.forEach((or, index) => {
      tempRows.push({
        id: or.cliente._id + index,
        idCliente: or.cliente._id,
        nome: or.cliente.nome,
        medico: or.medico_responsavel.name,
        total: or.total.toLocaleString("pt-Br") + "$00",
      });
    });
    setRows(tempRows);
  }, [orcamentosHoje]);

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <span>
            <Link
              to={`/clientes/${params.row.idCliente}-orcamento`}
              className="modalProcedimentosPorAgendar__link"
            >
              {params.row.nome}
            </Link>
          </span>
        );
      },
    },
    {
      field: "medico",
      headerName: "Medico",
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="marcacoesAdministrativo__nome__medico__span">
            {params.row.medico}
          </span>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="marcacoesAdministrativo__nome__medico__span">
            {params.row.total}
          </span>
        );
      },
    },
  ];

  const searchByDate = async (value) => {
    setData(value);
    try {
      const formData = new FormData();
      formData.append("data", moment(value).format("YYYY-MM-DD"));

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/orcamentosbydate/${clinicaId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const tempRows = [];

      responseData.orcamentos.forEach((or, index) => {
        tempRows.push({
          id: or.cliente._id + index,
          idCliente: or.cliente._id,
          nome: or.cliente.nome,
          medico: or.medico_responsavel.name,
          total: or.total.toLocaleString("pt-Br") + "$00",
        });
      });
      setRows(tempRows);
    } catch (err) {
      console.error("err", err);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box"
          id="modalProcedimentosPorAgendar"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Orcamentos de {moment(data).format("DD-MM-YYYY")}
          </h1>
          <div className="modalProcedimentosPorAgendar__container">
            <div>
              <div className="relatorio__datasContainer__datePickerContainer">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  className="adicionar-despesa__modal__container--span1"
                >
                  <DesktopDatePicker
                    className="valorDataCaixa__fields--field muiDatePicker"
                    label="Data*"
                    inputFormat="DD-MM-YYYY"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={searchByDate}
                    value={data}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {rows && (
              <div style={{ height: "80vh", width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                />
              </div>
            )}
          </div>
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleClose}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default memo(ModalOrcamentosHoje);
