import React, { useState, useContext, useEffect } from "react";
import "./ModalStockRetirada.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputNumber from "../../../shared/components/inputs/InputNumber/InputNumber";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
function ModalStockRetirada({
  handleCloseModalStockRetirada,
  stock,
  sendRequest,
  open,
  atualizarStock,
  stockRetirada,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quantidade, setQuantidade] = useState(1);
  const [responsaveisMovimento, setResponsaveisMovimento] = useState([]);
  const [responsavelMovimento, setResponsavelMovimento] = useState();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    const fetchFuncionariosAndUser = async () => {
      startLoading();
      try {
        let grabbers = [];

        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro buscando os dados dos funcionários. Por favor, tente novamente."
          );
        }

        responseData.funcionarios.forEach((fun) => {
          let fullName = `${fun.nome}${fun.cargo ? ` - ${fun.cargo}` : ""}`;
          if (fullName.endsWith(" - undefined")) {
            fullName = fun.nome;
          }

          grabbers.push({
            id: fun.id,
            nome: `${fun.nome}${fun.cargo ? ` - ${fun.cargo}` : ""}`,
            type: "Funcionario",
          });
        });

        let responseData2;
        try {
          responseData2 = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro buscando os dados dos usuários da clínica. Por favor, tente novamente."
          );
        }

        responseData2.users.forEach((user) => {
          let fullName = `${user.name}${
            user.profissao ? ` - ${user.profissao}` : ""
          }`;
          if (fullName.endsWith(" - undefined")) {
            fullName = user.name;
          }

          grabbers.push({
            id: user.id,
            nome: fullName,
            type: "User",
          });
        });

        setResponsaveisMovimento(grabbers);
        setResponsavelMovimento(grabbers[0].id);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchFuncionariosAndUser();
  }, [sendRequest, clinica.clinica._id, auth.token]);

  const changeResponsavelMovimento = (e) => {
    setResponsavelMovimento(e.target.value);
  };

  const changeQuantidade = (value) => {
    setQuantidade(value);
  };

  const submit = async () => {
    startLoading();
    try {
      const filteredStockIndex = stock.stock.findIndex(
        (st) => st.id === stockRetirada.id
      );

      const stockCopy = [...stock.stock];

      stockCopy[filteredStockIndex] = {
        ...stockCopy[filteredStockIndex],
        quantidade: Number(stockRetirada.quantidade) - Number(quantidade),
      };

      const roleResponsavelMovimento = responsaveisMovimento.filter(
        (autho) => autho.id === responsavelMovimento
      )[0].type;

      const movimentos = [
        {
          produto: stockRetirada.produto.id,
          beneficiario: auth.userId,
          quantidade: quantidade,
          data: new Date(),
          saida: true,
        },
      ];

      if (roleResponsavelMovimento === "User") {
        movimentos[0].responsavelMovimentoUser = responsavelMovimento;
      } else {
        movimentos[0].responsavelMovimentoFuncionario = responsavelMovimento;
      }

      //Marcar o produto como retirado
      if (!stockRetirada.produto.comRetirada) {
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/produtos/marcarretirada/${stockRetirada.produto.id}`,
            "PATCH",
            {},
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro marcando o produto como retirado. Por favor, tente novamente."
          );
        }
      }

      let stockReturned;
      try {
        const formData = new FormData();
        formData.append("stockDaClinica", JSON.stringify(stockCopy));
        formData.append("historicoMovimentos", JSON.stringify(movimentos));

        stockReturned = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/${stock.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        toast.error("Ocorreu um erro atualizando os dados do stock.");
        console.error("err", err);
      }

      atualizarStock(stockReturned.stock, "Retirada efetuada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
      handleCloseModalStockRetirada();
    }
  };

  const preSubmitHandler = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submit();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModalStockRetirada}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box retirarDoStock__modal"
        id="modal__cinquentaPorCento"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Retirar produto do stock
        </h1>

        <div className="retirarDoStock__container">
          <span className="retirarDoStock__container__nomeProduto">
            {stockRetirada.produto.produto}
          </span>
          <div className="retirarDoStock__container__row">
            <InputNumber
              initialValue={1}
              label="Quantidade a retirar"
              handleChange={changeQuantidade}
              min={1}
              max={stockRetirada.quantidade}
              notEmpty={true}
            />

            <div className="customInputContainer">
              <select
                onChange={changeResponsavelMovimento}
                className="customInputSelect"
                value={responsavelMovimento}
              >
                {responsaveisMovimento &&
                  responsavelMovimento &&
                  responsaveisMovimento.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
              </select>
              <label className="customInputLabelSelect">Profissional*</label>
            </div>
          </div>
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalStockRetirada}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting}
              className="blue-button"
              onClick={preSubmitHandler}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </span>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalStockRetirada;
