import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import { CheckCircle, Info, Error, Close } from "@mui/icons-material";

import Backdrop from "./Backdrop";
import "./ModalInfo.scss";

const ModalOverlay = (props) => {
  const content = (
    <div className={`modal-info-container modal-info-container__${props.icon}`}>
      <div className="modal-info " style={props.style}>
        <div className="modal-info_icon">
          {props.icon === "success" && (
            <CheckCircle sx={{ color: "#2bde3f", fontSize: 30 }} />
          )}
          {props.icon === "info" && (
            <Info sx={{ color: "#1d72f3", fontSize: 30 }} />
          )}
          {props.icon === "error" && (
            <Error sx={{ color: "#e91e63", fontSize: 30 }} />
          )}
        </div>
        <div className="modal-info_content">
          <h2>{props.title}</h2>
          <p>{props.message}</p>
        </div>
        <div className="modal-info_close">
          <Close
            onClick={props.onCancel}
            sx={{ color: "#454545", cursor: "pointer" }}
            id="modal-info_close-btn"
          >
            Close
          </Close>
        </div>
      </div>
      {props.form && (
        <div className="modal-info-form">
          <button
            className="modal-info-btn modal-info-btn-cancel"
            onClick={props.onCancel}
          >
            Cancelar
          </button>

          <button
            className="modal-info-btn modal-info-btn-confirm"
            onClick={props.onConfirm}
          >
            Confirmar
          </button>
        </div>
      )}
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

const ModalInfo = (props) => {
  // const [temporizador, setTemporizador] = useState(true);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTemporizador(false);
  //   }, 3000);
  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={300}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default ModalInfo;
