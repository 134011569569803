import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import "./ModalStockHistoricoEntradas.scss";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

//MUI DATATABLE
import { DataGrid } from "@mui/x-data-grid";
import { generateExcelFile } from "../../../shared/components/excelUtils/exportExcel";
import ArticleIcon from "@mui/icons-material/Article";
import { AuthContext } from "../../../shared/context/auth-context";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
const columns = [
  { field: "produto", headerName: "Produto", flex: 4 },
  { field: "qtdAdicionada", headerName: "Qtd Adicionada", flex: 1 },
  { field: "respMovimento", headerName: "Adicionada por", flex: 1 },
  { field: "data", headerName: "Data", flex: 1 },
];

function ModalStockHistoricoEntradas({
  handleCloseModalStockHistoricoEntradas,
  sendRequest,
  open,
  clinicaId,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [rows, setRows] = useState([]);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchStock = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/clinica/entradas/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const tempRows = [];
        responseData.stock &&
          responseData.stock.historicoMovimentos.forEach((mv) => {
            if (!mv.saida) {
              tempRows.push({
                id: mv.id,
                produto: mv.produto.produto,
                qtdAdicionada: mv.quantidade,
                respMovimento:
                  mv.responsavelMovimentoUser &&
                  mv.responsavelMovimentoUser.name,
                data: moment(mv.data).format("DD-MM-YYYY HH:mm"),
              });
            }
          });

        tempRows.sort(
          (a, b) =>
            moment(b.data, "DD-MM-YYYY HH:mm").toDate() -
            moment(a.data, "DD-MM-YYYY HH:mm").toDate()
        );

        setRows(tempRows);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro buscando as entradas do stock. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchStock();
  }, [sendRequest, clinicaId, auth.token]);

  const handleExportHistoricoEntradas = () => {
    const sheetData = rows.map((r) => {
      return {
        data: r.data,
        produto: r.produto,
        adicionado_por: r.respMovimento,
        quantidade_adicionada: r.qtdAdicionada,
      };
    });

    generateExcelFile(
      `Historico entradas ${moment().format("DD-MM-YYYY")}.xlsx`,
      "Stock",
      sheetData
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModalStockHistoricoEntradas}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box"
        id="historicoEntradasModal__modal"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Historico de Entradas
        </h1>
        <div className="historico__dataFilter__container">
          <div
            className="financeiro__filtros__filtrar"
            onClick={handleExportHistoricoEntradas}
          >
            <ArticleIcon />
            <span>Exportar Excel</span>
          </div>
        </div>
        <div className="historicoEntradasModal__container">
          {rows && (
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </div>
          )}
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalStockHistoricoEntradas}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalStockHistoricoEntradas;
