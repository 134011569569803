import "../../../../shared/css/ElementoPlanoOrcamentoAvaliacao.scss";

function ElementoOrcamento({ procedimento }) {
  return (
    <>
      <div>
        <span className="input spanOrcamentado">
          {procedimento.tratamento.designacao}
        </span>
        <div className="elementoOrcamento__facesEDentes">
          <span className="spanOrcamentado">
            {procedimento.dente}
            {procedimento.faces.length > 0
              ? ` - ${procedimento.faces.join(", ")}`
              : ""}
          </span>
        </div>
      </div>

      <span className="input"></span>

      <span className="input"></span>

      <span className="input"></span>

      <span className="input spanOrcamentado">Orçamentado</span>
    </>
  );
}

export default ElementoOrcamento;
