import { useState, useEffect } from "react";
import ElementoParcela from "./ElementoParcela";
import moment from "moment/moment";
import uuid from "react-uuid";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalAprovarOrcamento.scss";

function ModalAprovarOrcamento({
  open,
  modalData,
  handleCloseModalAprovarOrcamento,
  gerarPlanoTratamento,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(open);
  const [parcelas, setParcelas] = useState(modalData.parcelas);
  const [nrParcelas, setNrParcelas] = useState(modalData.nrParcelas);
  const [totalParcelas, setTotalParcelas] = useState();
  const [mensagemErro, setMensagemErro] = useState("");
  const [showParcelarOrcamento, setShowParcelarOrcamento] = useState(false);

  useEffect(() => {
    if (modalData.parcelas) {
      if (modalData.parcelas[0] && modalData.parcelas[0].entrada) {
        setShowParcelarOrcamento(true);
      } else if (modalData.nrParcelas > 1) {
        setShowParcelarOrcamento(true);
      } else {
        setShowParcelarOrcamento(false);
      }
    }
  }, [modalData]);

  useEffect(() => {
    let total = 0;
    if (modalData && modalData.parcelas) {
      modalData.parcelas.forEach((parcela) => {
        total += Number(parcela.quantiaPorPagar);
      });

      setTotalParcelas(total);
    }
  }, [modalData]);
  useEffect(() => {
    setOpenModal(open);
    setParcelas(modalData.parcelas);
    setNrParcelas(modalData.nrParcelas);
  }, [open, modalData]);

  const nrParcelasChange = (e) => {
    if (mensagemErro) setMensagemErro("");
    //Caso não o usuario apagar todo o valor do input de nr de parcelas
    //o numero de parcelas ficará em 1
    let novoNrParcelas = 1;
    if (e.target.value) novoNrParcelas = Number(e.target.value);
    let newParcelas = [];

    if (parcelas[0].entrada) {
      newParcelas.push({
        ...parcelas[0],
      });

      let tempTotalParcelas = 0;
      for (let i = 1; i <= novoNrParcelas; i++) {
        if (
          i === novoNrParcelas &&
          modalData.total - parcelas[0].quantiaPorPagar - tempTotalParcelas >
            Math.floor(
              (modalData.total - parcelas[0].quantiaPorPagar) / novoNrParcelas
            )
        ) {
          newParcelas.push({
            id: uuid(),
            dataLimitePagamento: moment().add(i * 5, "day"),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar:
              modalData.total - parcelas[0].quantiaPorPagar - tempTotalParcelas,
          });
        } else {
          tempTotalParcelas += Math.floor(
            (modalData.total - parcelas[0].quantiaPorPagar) / novoNrParcelas
          );
          newParcelas.push({
            id: uuid(),
            dataLimitePagamento: moment().add(i * 5, "day"),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: Math.floor(
              (modalData.total - parcelas[0].quantiaPorPagar) / novoNrParcelas
            ),
          });
        }
      }
    } else {
      let tempTotalParcelas = 0;
      for (let i = 1; i <= novoNrParcelas; i++) {
        if (
          i === novoNrParcelas &&
          modalData.total - tempTotalParcelas >
            Math.floor(modalData.total / novoNrParcelas)
        ) {
          newParcelas.push({
            id: uuid(),
            dataLimitePagamento: moment().add(i * 5, "day"),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: modalData.total - tempTotalParcelas,
          });
        } else {
          tempTotalParcelas += Math.floor(modalData.total / novoNrParcelas);
          newParcelas.push({
            id: uuid(),
            dataLimitePagamento: moment().add(i * 5, "day"),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: Math.floor(modalData.total / novoNrParcelas),
          });
        }
      }
    }

    let tempTotalParcelas = 0;
    newParcelas.forEach((parc) => {
      tempTotalParcelas += Number(parc.quantiaPorPagar);
    });

    setTotalParcelas(tempTotalParcelas);

    setParcelas(newParcelas);
    setNrParcelas(Number(novoNrParcelas));
  };

  const dataLimitePagamentoChange = (indice, valor) => {
    if (mensagemErro) setMensagemErro("");
    const parcelasCopy = parcelas;
    parcelasCopy[indice].dataLimitePagamento = valor;
    setParcelas([...parcelasCopy]);

    //Isso é reutilização de uma função com o unico objetivo de
    //Atualizar o valor das parcelas após mudar o valor de entrada
  };

  const entradaChange = (valor) => {
    if (mensagemErro) setMensagemErro("");
    const parcelasCopy = parcelas;
    if (valor > modalData.total) {
      parcelasCopy[0].quantiaPorPagar = valor;
    } else {
      parcelasCopy[0].quantiaPorPagar = valor;
      setParcelas([...parcelasCopy]);
      //Isso é reutilização de uma função com o unico objetivo de
      //Atualizar o valor das parcelas após mudar o valor de entrada
      nrParcelasChange({ target: { value: nrParcelas } });
    }
  };

  const parcelasValueChange = (indice, valor) => {
    if (mensagemErro) setMensagemErro("");
    const parcelasCopy = parcelas;
    parcelasCopy[indice].quantiaPorPagar = valor;

    //Calculamos o total de parcelas até a parcela modificada, incluindo a entrada
    if (indice + 1 < parcelasCopy.length) {
      let totalAteEssaParcela = 0;
      for (let i = 0; i <= indice; i++) {
        totalAteEssaParcela += Number(parcelasCopy[i].quantiaPorPagar);
      }

      //Caso O total for menor que a soma das parcelas até a parcela modificada,
      //Fazemos o calculo das parcelas restantes e dividimos
      //O restante a essas parcelas
      if (totalAteEssaParcela < modalData.total) {
        const valorRestante = Number(modalData.total) - totalAteEssaParcela;
        const nrParcelasRestantes = Number(parcelasCopy.length) - (indice + 1);
        const paraDistribuir = Math.floor(valorRestante / nrParcelasRestantes);
        const valorParcelaFinal =
          valorRestante - nrParcelasRestantes * paraDistribuir + paraDistribuir;

        for (let i = indice + 1; i < parcelasCopy.length; i++) {
          if (i + 1 === parcelasCopy.length) {
            parcelasCopy[i].quantiaPorPagar = paraDistribuir;
          } else {
            parcelasCopy[i].quantiaPorPagar = valorParcelaFinal;
          }
        }
      } else {
        for (let i = indice + 1; i < parcelasCopy.length; i++) {
          if (i + 1 === parcelasCopy.length) {
            parcelasCopy[i].quantiaPorPagar = 0;
          } else {
            parcelasCopy[i].quantiaPorPagar = 0;
          }
        }
      }
    }

    setParcelas([...parcelasCopy]);

    let tempTotalParcelas = 0;
    parcelasCopy.forEach((parc) => {
      tempTotalParcelas += Number(parc.quantiaPorPagar);
    });

    setTotalParcelas(tempTotalParcelas);
    //Isso é reutilização de uma função com o unico objetivo de
    //Atualizar o valor das parcelas após mudar o valor de entrada
  };

  const showParcelarOrcamentoHandler = (e) => {
    setShowParcelarOrcamento(e.target.checked);
    const copyParcelas = parcelas;
    if (e.target.checked === true) {
      //Parcelar
      if (copyParcelas[0].entrada) {
        setParcelas([
          { ...copyParcelas[0] },
          {
            id: uuid(),
            dataLimitePagamento: moment().add(5, "day"),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: modalData.total - copyParcelas[0].quantiaPorPagar,
          },
        ]);
        setNrParcelas(1);
      } else {
        setParcelas([
          {
            id: uuid(),
            dataLimitePagamento: moment().add(5, "day"),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: Math.floor(modalData.total / 2),
          },
          {
            id: uuid(),
            dataLimitePagamento: moment().add(10, "day"),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: modalData.total - Math.floor(modalData.total / 2),
          },
        ]);
        setNrParcelas(2);
      }
      //Nao parcelar
    } else {
      if (copyParcelas[0].entrada) {
        setParcelas([
          {
            id: uuid(),
            dataLimitePagamento: moment(),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: modalData.total,
            entrada: true,
          },
        ]);
        setNrParcelas(0);
      } else {
        setParcelas([
          {
            id: uuid(),
            dataLimitePagamento: moment().add(5, "day"),
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: modalData.total,
          },
        ]);
        setNrParcelas(1);
      }
    }
    setTotalParcelas(modalData.total);
  };

  const preSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      const parcelasFinal = [...parcelas];
      let erro = false;
      if (totalParcelas < modalData.total) {
        erro = true;
        setMensagemErro(
          "A soma dos valores é inferior ao valor total do orçamento."
        );
      } else if (totalParcelas > modalData.total) {
        erro = true;
        setMensagemErro(
          "A soma dos valores é superior ao valor total do orçamento."
        );
      } else {
        erro = false;
        setMensagemErro("");
      }

      parcelasFinal.forEach((parcela, index) => {
        if (index !== 0 && parcela.quantiaPorPagar <= 0) {
          setMensagemErro("Preencha todas as parcelas com valor superior a 0.");
          erro = true;
          return;
        }
      });
      if (parcelasFinal[0].entrada && parcelasFinal[0].quantiaPorPagar === 0) {
        //Removemos a entrada caso ela for atribuida o valor de 0
        parcelasFinal.shift();
      }

      if (!erro) {
        let nrParcela = 1;
        let parcelasLength = parcelasFinal.length;
        parcelasFinal.forEach((parcela) => {
          if (parcela.entrada) parcelasLength -= 1;
          else {
            parcela["nrParcela"] = `${nrParcela}/${parcelasLength}`;
            nrParcela += 1;
          }
          delete parcela.id;
        });

        gerarPlanoTratamento(modalData, parcelasFinal);
      } else {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModalAprovarOrcamento}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box aprovar-orcamento__modal"
        id="modal__aprovar-orcamento"
      >
        <h1 className="fluxo-atendimento__modal__titulo">Aprovar Orçamento</h1>
        <span className="aprovar-orcamento__modal__regras-aprovacao">
          Ao aprovar esse orçamento, o plano de tratamento é criado
          automaticamente na aba Planos Tratamento e os valores são lançados em
          Débito.
        </span>
        <div className="aprovar-orcamento__modal__valor-orcamento-container">
          Valor Total do Orçamento:{" "}
          <span className="aprovar-orcamento__modal__valor-orcamento-span">
            {modalData.total}$00
          </span>{" "}
          {/* {parcelas &&
            ((parcelas[0].entrada && nrParcelas > 0) ||
              (!parcelas[0].entrada && nrParcelas > 1)) && ( */}
          <>
            - Soma dos valores:{" "}
            <span className="aprovar-orcamento__modal__valor-orcamento-span">
              {totalParcelas}$00
            </span>
          </>
          {/* )} */}
        </div>

        {parcelas && parcelas[0].entrada && (
          <div className="aprovar-orcamento__modal__entrada-container">
            <ElementoParcela
              dataLimitePagamento={parcelas[0].dataLimitePagamento}
              quantiaPorPagar={parcelas[0].quantiaPorPagar}
              indiceParcela={0}
              entrada={true}
              entradaChange={entradaChange}
              dataLimitePagamentoChange={dataLimitePagamentoChange}
              parcelasValueChange={parcelasValueChange}
            />
          </div>
        )}
        <div className="aprovar-orcamento__modal__descricao-container">
          {parcelas && parcelas[0].entrada && (
            <>
              Com saldo de
              <span className="aprovar-orcamento__modal__saldo">
                {" "}
                {modalData.total - parcelas[0].quantiaPorPagar}$00{" "}
              </span>
            </>
          )}

          <div className="parcelarOrcamentoDiv">
            <label htmlFor="" className="parcelarOrcamento">
              <input
                type="checkbox"
                className="checkbox"
                onChange={showParcelarOrcamentoHandler}
                checked={showParcelarOrcamento}
              />
              Parcelar Orcamento
            </label>
            {showParcelarOrcamento && (
              <div className="parcelarOrcamentoDivHorizontal">
                {parcelas && parcelas[0].entrada
                  ? "parcelado em"
                  : "Parcelar em"}

                <input
                  type="number"
                  className="aprovar-orcamento__modal__nrParcelas"
                  value={nrParcelas}
                  onChange={nrParcelasChange}
                  min={1}
                />
                {nrParcelas === 1 ? "vez" : "vezes"}
              </div>
            )}
          </div>
        </div>

        {/* Caso houver valor de entrada */}
        {parcelas &&
          parcelas[0].entrada &&
          parcelas.map(
            (parcela, index) =>
              !parcela.entrada && (
                <ElementoParcela
                  key={index}
                  dataLimitePagamento={parcela.dataLimitePagamento}
                  quantiaPorPagar={parcela.quantiaPorPagar}
                  indiceParcela={index}
                  totalParcelas={nrParcelas}
                  entradaChange={entradaChange}
                  dataLimitePagamentoChange={dataLimitePagamentoChange}
                  parcelasValueChange={parcelasValueChange}
                  comEntrada={true}
                />
              )
          )}

        {/* Caso não houver valor de entrada */}
        {parcelas &&
          !parcelas[0].entrada &&
          parcelas.map((parcela, index) => (
            <ElementoParcela
              key={index}
              dataLimitePagamento={parcela.dataLimitePagamento}
              quantiaPorPagar={parcela.quantiaPorPagar}
              indiceParcela={index}
              totalParcelas={nrParcelas}
              parcelasValueChange={parcelasValueChange}
              dataLimitePagamentoChange={dataLimitePagamentoChange}
              comEntrada={false}
            />
          ))}

        {mensagemErro && (
          <div className="fluxo-atendimento__modal__erros">{mensagemErro}</div>
        )}
        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleCloseModalAprovarOrcamento}
            style={{ display: "block" }}
          >
            Fechar
          </span>
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={preSubmit}
            style={{ display: "block" }}
          >
            {isSubmitting ? "Aprovando..." : "Aprovar"}
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalAprovarOrcamento;
