import { useState, useEffect } from "react";
import moment from "moment";
import InputNumber from "../../../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../../../shared/components/inputs/InputText/InputText";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";
import Autocomplete from "@mui/material/Autocomplete";

import "./ModalEntrada.scss";

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  // "Cheque Visado",
  // "Transferência de crédito",
  "Internet banking",
  // "Cartão do banco",
  // "Débito direto da conta bancária",
  "Cartão de crédito",
  // "Cartão de débito",
];

function ModalEditarEntrada({
  openModalEditarEntrada,
  handleCloseModalEditarEntrada,
  sendRequest,
  clinicaId,
  entradaId,
  updateEntradas,
  auth,
  emitirDocEletronicoReembolso,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalEditarEntrada);
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState();
  const [caixas, setCaixas] = useState([]);
  const [dataLimitePagamento, setDataLimitePagamento] = useState(
    moment().add(1, "M")
  );
  const [entrada, setEntrada] = useState();
  const [clientesFornecedores, setClientesFornecedores] = useState([]);
  const [clienteFornecedor, setClienteFornecedor] = useState();
  const [tipoEntrada, setTipoEntrada] = useState("clienteFornecedor");
  const [cliente, setCliente] = useState();
  const [clientesAutocomplete, setClientesAutocomplete] = useState([]);
  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [
          categoriasResponse,
          caixasResponse,
          entradaResponse,
          clientesFornecedoresResponse,
          clientesResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/entrada/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/${entradaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientesfornecedores/clinica/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/nomeidmoradacontactonif/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process categorias
        const categoriasFormatted = categoriasResponse.categoriasFinanceiro.map(
          (cat) => ({
            id: cat.id,
            value: cat.categoria,
          })
        );
        setCategorias(categoriasFormatted);
        setCategoria(categoriasFormatted[0]?.id);

        // Process caixas
        const caixasFormatted = caixasResponse.caixas.map((cai) => ({
          id: cai.id,
          value: cai.caixa,
        }));
        setCaixas(caixasFormatted);
        setCaixa(caixasFormatted[0]?.id);

        // Process entrada
        const { entrada } = entradaResponse;
        setTipoEntrada(entrada.tipo);
        setCliente({
          id: entrada.cliente?.id,
          label: entrada.cliente?.nome,
        });
        setClienteFornecedor(entrada.clienteFornecedor?.id);
        setCategoria(entrada.categoria.id);
        setCaixa(entrada.caixa.id);
        setValor(entrada.valor);
        setDescricao(entrada.descricao);
        setObservacao(entrada.observacao || "");
        setDataLimitePagamento(entrada.dataLimitePagamento);
        setEntrada(entrada);

        // Process clientesFornecedores
        setClientesFornecedores(
          clientesFornecedoresResponse.clientesFornecedores.map((cf) => ({
            id: cf.id,
            value: cf.nome,
          }))
        );

        // Process clientes
        setClientesAutocomplete(
          clientesResponse.clientes.map((c) => ({ id: c._id, label: c.nome }))
        );
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        toast.error(
          "Ocorreu um erro ao carregar os dados. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [clinicaId, sendRequest, entradaId, auth.token]);

  useEffect(() => {
    setOpenModal(openModalEditarEntrada);
  }, [openModalEditarEntrada]);

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const valorChangeHandler = (value) => {
    setValor(value);
  };

  const editarEntradaHandler = async (emit) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("tipoEntrada", tipoEntrada);

      formData.append("observacoes", observacao);
      formData.append("descricao", descricao.trim());
      formData.append("valor", valor);
      formData.append("categoria", categoria);

      if (tipoEntrada !== "reembolso") {
        formData.append(
          "clienteFornecedor",
          clienteFornecedor.id ? clienteFornecedor.id : clienteFornecedor
        );
        formData.append("dataLimitePagamento", dataLimitePagamento);
      }

      if (tipoEntrada === "reembolso") {
        formData.append("cliente", cliente.id);
        formData.append("entradaRecebida", true);
        formData.append("caixa", caixa);
        formData.append("metodoPagamento", metodoPagamento);
      }

      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
      const entradaAtualizada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/${entradaId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      if (emit) {
        if (tipoEntrada === "clienteFornecedor") {
          updateEntradas(entradaAtualizada.entrada, "atualizar", emit);
        } else {
          emitirDocEletronicoReembolso(entradaAtualizada.entrada, null, "FRE");
        }
      } else {
        if (tipoEntrada === "clienteFornecedor") {
          toast.success("Entrada atualizada com sucesso!");
        } else {
          toast.success("Entrada atualizada e recebida com sucesso!");
        }
        updateEntradas(entradaAtualizada.entrada, "atualizar");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      handleCloseModalEditarEntrada();
      setIsSubmitting(false);
    }
  };

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const preGuardarEntradaHandler = (e, emit) => {
    if (valor === 0) {
      toast.error("O valor da entrada não pode ser 0");
    } else if (!descricao || descricao.trim() === "") {
      toast.error("Introduza a descrição");
    } else if (tipoEntrada === "reembolso" && !cliente) {
      toast.error("Escolha o Cliente");
    } else if (tipoEntrada !== "reembolso" && !clienteFornecedor) {
      toast.error("Escolha o Cliente/Fornecedor");
    } else if (!isSubmitting) {
      setIsSubmitting(true);
      editarEntradaHandler(emit);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalEditarEntrada}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-entrada__modal"
          id="modal__adicionar-entrada"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Editar entrada {tipoEntrada === "reembolso" && " - Reembolso"}
          </h1>
          {/* {entrada && ( */}
          <div className="adicionar-entrada__modal__container">
            <InputText
              className="adicionar-entrada__modal__container--span2"
              label="Descrição *"
              initialValue={descricao}
              handleChange={descricaoChangeHandler}
            />

            {clientesFornecedores &&
              clienteFornecedor &&
              tipoEntrada !== "reembolso" && (
                <div className="customInputContainer adicionar-entrada__modal__container--span2">
                  <select
                    onChange={(e) => setClienteFornecedor(e.target.value)}
                    className="customInputSelect"
                    value={clienteFornecedor}
                  >
                    {clientesFornecedores &&
                      clienteFornecedor &&
                      clientesFornecedores.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">
                    Cliente/Fornecedor
                  </label>
                </div>
              )}

            {tipoEntrada === "reembolso" && clientesAutocomplete && cliente && (
              <Autocomplete
                className="customInputContainer adicionar-entrada__modal__container--span2 entrada__autocomplete"
                disablePortal
                id="combo-box-demo"
                options={clientesAutocomplete}
                value={cliente}
                onChange={(e, newValue) => setCliente(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Cliente" />
                )}
              />
            )}

            <InputNumber
              className="adicionar-entrada__modal__container--span1"
              label="Valor *"
              initialValue={valor}
              handleChange={valorChangeHandler}
            />

            {tipoEntrada === "clienteFornecedor" && (
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-entrada__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data limite pagamento*"
                  inputFormat="DD-MM-YYYY"
                  value={dataLimitePagamento}
                  onChange={(value) => setDataLimitePagamento(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}

            {categorias && categoria && (
              <div className="customInputContainer adicionar-entrada__modal__container--span2">
                <select
                  onChange={(e) => setCategoria(e.target.value)}
                  className="customInputSelect"
                  value={categoria}
                >
                  {categorias &&
                    categoria &&
                    categorias.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Categoria</label>
              </div>
            )}

            {tipoEntrada === "reembolso" && (
              <InputSelect
                className="adicionar-entrada__modal__container--span1"
                label="Forma de pagamento"
                initialValue={metodoPagamento}
                handleChange={metodoPagamentoChangeHandler}
                items={formasPagamento}
              />
            )}

            {caixas && caixa && tipoEntrada === "reembolso" && (
              <div className="customInputContainer adicionar-entrada__modal__container--span2">
                <select
                  onChange={(e) => setCaixa(e.target.value)}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
              </div>
            )}

            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
              className="adicionar-entrada__modal__container__textarea"
            ></textarea>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalEditarEntrada}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            {tipoEntrada === "reembolso" && (
              <>
                <span
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={preGuardarEntradaHandler}
                >
                  {isSubmitting ? "Recebendo..." : "Receber"}
                </span>
                {/* Não dá para colocar guardar e emitir fre pois não posso colocar o campo metodoPagamento e dataPagamento numa modal que o usuário pode simplismente estar a guardar para não receber*/}
                <span
                  disabled={isSubmitting}
                  className={"blue-button"}
                  onClick={preGuardarEntradaHandler.bind(
                    null,
                    null,
                    "emitirFRE"
                  )}
                >
                  {isSubmitting ? "Recebendo..." : "Receber e emitir fre"}
                </span>
              </>
            )}

            {tipoEntrada === "clienteFornecedor" && (
              <>
                <span
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={preGuardarEntradaHandler}
                >
                  {isSubmitting ? "Guardando..." : "Guardar"}
                </span>
                {/* Para emitir FTE, não pode existir documento eletronico enviado, a entrada não pode estar recebida. Estas condicoes são necessarias para a abertura dessa modal então não é necessário a verificação delas */}
                {/* A condicao que verificamos é que não pode existir cancelamento com erro */}
                {entrada &&
                  (entrada?.cancelamentosDocumentosEletronicos?.length === 0 ||
                    (entrada?.cancelamentosDocumentosEletronicos?.length > 0 &&
                      entrada?.cancelamentosDocumentosEletronicos[
                        entrada.cancelamentosDocumentosEletronicos.length - 1
                      ]?.NCE?.succeeded === true))}
                <span
                  disabled={isSubmitting}
                  className={"blue-button"}
                  onClick={preGuardarEntradaHandler.bind(
                    null,
                    null,
                    "emitirFTE"
                  )}
                >
                  {isSubmitting ? "Guardando..." : "Guardar e emitir fte"}
                </span>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditarEntrada;
