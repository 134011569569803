import { useState, useRef } from "react";

import moment from "moment";
import "../../../receitas/components/filtrosReceitas/FiltrosReceitas.scss";

//MUI
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function FiltrosDfe({ dfes, handleFiltrar }) {
  const [mostrarDatas, setMostrarDatas] = useState(false);
  const [dataInicial, setDataInicial] = useState(moment().startOf("day"));
  const [dataFinal, setDataFinal] = useState(moment().endOf("day"));
  const periodoRef = useRef(null);

  const comparaDatas = (data, data1, data2) => {
    const periodo = periodoRef.current.value;
    if (periodo === "hoje") {
      return moment.utc(data).isSame(moment().startOf("day"), "d");
    }
    if (periodo === "semana") {
      const startOfWeek = moment().startOf("week");
      const endOfWeek = moment().endOf("week");
      return moment.utc(data).isBetween(startOfWeek, endOfWeek);
    }
    if (periodo === "mes") {
      const startOfMonth = moment().startOf("month");
      const endOfMonth = moment().endOf("month");
      return moment.utc(data).isBetween(startOfMonth, endOfMonth);
    }
    if (periodo === "-mes") {
      const startOfLastMonth = moment().subtract(1, "months").startOf("month");
      const endOfLastMonth = moment().subtract(1, "months").endOf("month");
      return moment.utc(data).isBetween(startOfLastMonth, endOfLastMonth);
    }
    if (periodo === "-30d") {
      const startOfLast30Days = moment().startOf("day").subtract(30, "days");
      return moment.utc(data).isBetween(startOfLast30Days, moment());
    }
    if (periodo === "+30d") {
      const today = moment();
      const endOfNext30Days = moment().startOf("day").add(30, "days");
      return moment.utc(data).isBetween(today, endOfNext30Days);
    }

    if (periodo === "periodo") {
      if (data1.startOf("day").isAfter(data2.endOf("day"))) {
        return false;
      } else {
        const startDate = data1.startOf("day");
        const endDate = data2.endOf("day");
        return moment.utc(data).isBetween(startDate, endDate);
      }
    }
  };

  const filtrarPorData = (data1, data2) => {
    if (periodoRef.current.value === "todos") return dfes;
    else {
      let dfesFiltrados = [];
      dfes.forEach((dfe) => {
        if (comparaDatas(dfe.created_at, data1, data2)) {
          dfesFiltrados.push({ ...dfe });
        }
      });
      return dfesFiltrados;
    }
  };

  const searchHandler = (data1 = null, data2 = null) => {
    const dfeFiltrado = filtrarPorData(data1, data2);
    handleFiltrar(dfeFiltrado);
  };

  const escolherPeriodoHandler = (e) => {
    if (e.target.value === "periodo" && !mostrarDatas) setMostrarDatas(true);
    if (e.target.value !== "periodo" && mostrarDatas) setMostrarDatas(false);
    searchHandler(dataInicial, dataFinal);
  };

  const changeDatas = (identificacao, valor) => {
    if (identificacao === "dataInicial") {
      setDataInicial(valor);
      searchHandler(valor, dataFinal);
    } else {
      setDataFinal(valor);
      searchHandler(dataInicial, valor);
    }
  };

  return (
    <div className="financeiro__filtros">
      <span className="financeiro__filtros__texto">Exibindo DNRE</span>
      <select name="" id="" onChange={escolherPeriodoHandler} ref={periodoRef}>
        <option value="hoje">de hoje</option>
        <option value="todos">todos</option>
        <option value="semana">dessa semana</option>
        <option value="mes">desse mês</option>
        <option value="-mes">do mês passado</option>
        <option value="-30d">dos últimos 30 dias</option>
        <option value="+30d">dos próximos 30 dias</option>
        <option value="periodo">escolher período</option>
      </select>

      {mostrarDatas && dataInicial && dataFinal && (
        <div className="financeiro__filtros__periodoContainer">
          <div className="financeiro__filtros__periodoContainer__dataInicioContainer financeiro__filtros__periodoContainer__dataContainer">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Inicial*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => changeDatas("dataInicial", value)}
                value={dataInicial}
              />
            </LocalizationProvider>
          </div>
          <div className="financeiro__filtros__periodoContainer__dataFimContainer financeiro__filtros__periodoContainer__dataContainer">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Final*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => changeDatas("dataFinal", value)}
                value={dataFinal}
              />
            </LocalizationProvider>
          </div>
        </div>
      )}
    </div>
  );
}

export default FiltrosDfe;
