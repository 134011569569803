import { useState, useEffect } from "react";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
////////////////////////////////
import ModalStockFirstStep from "./ModalStockFirstStep";
import ModalStockCadastrar from "./ModalStockCadastrar";
import ModalStockUpdate from "./ModalStockUpdate";
import toast from "react-hot-toast";
import "./ModalAdicionarNoStock.scss";
import ModalImportXlsStock from "./ModalImportXlsStock";
import { useLoading } from "../../../shared/context/LoadingContext";
function ModalAdicionarNoStock({
  open,
  handleCloseModalAdicionarStock,
  atualizarStock,
  sendRequest,
  clinicaId,
  auth,
  stock,
  categoriasStock,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [show, setShow] = useState("firstStep");
  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    const fetchProdutos = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/produtos/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        if (responseData.produtos) setProdutos(responseData.produtos);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchProdutos();
  }, [sendRequest, clinicaId, auth.token]);

  const tipoEntradaHandler = (tipo) => {
    setShow(tipo);
  };

  const handleSubmitEntradaStock = async (dados) => {
    startLoading();
    const produtos = dados.map((prod) => {
      return {
        produto: prod.produto,
        categoriaStock: prod.categoriaStock,
        quantidade: prod.quantidade,
        quantidadeIdeal: prod.quantidadeIdeal,
      };
    });
    try {
      const formData = new FormData();
      formData.append("clinica", clinicaId);
      formData.append("produtos", JSON.stringify(produtos));
      formData.append("responsavelMovimentoUser", auth.userId);
      formData.append("criadoPor", auth.userId);

      const stockReturned = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      atualizarStock(stockReturned.stock, "Produto(s) adicionado(s) ao stock");
    } catch (err) {
      toast.error("Erro ao adicionar produtos ao stock");
      console.error("err", err);
    } finally {
      handleCloseModalAdicionarStock();
      stopLoading();
    }
  };

  const handleSubmitUpdateStock = async (stockDaClinica, movimentos) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("stockDaClinica", JSON.stringify(stockDaClinica));
      formData.append("historicoMovimentos", JSON.stringify(movimentos));
      formData.append("responsavelMovimentoUser", auth.userId);

      const stockReturned = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/${stock.id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      atualizarStock(stockReturned.stock, "Quantias atualizadas com sucesso");
    } catch (err) {
      toast.error("Erro ao alterar quantidades dos produtos");
      console.error("err", err);
    } finally {
      stopLoading();
      handleCloseModalAdicionarStock();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModalAdicionarStock}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionarNoStock__modal"
          id={
            show === "porCadastrar"
              ? "modal__adicionarNoStock__modal"
              : "modal__adicionarNoStockGeral"
          }
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            {show === "firstStep" && "Escolha a operação"}
            {show === "cadastrado" && "Alterando produto no stock"}
            {show === "porCadastrar" && "Entrada no stock"}
            {show === "import" && "Importar xls"}
          </h1>
          {show === "firstStep" && (
            <ModalStockFirstStep
              tipoEntradaHandler={tipoEntradaHandler}
              produtos={produtos}
            />
          )}
          {show === "porCadastrar" && (
            <ModalStockCadastrar
              handleCloseModalAdicionarStock={handleCloseModalAdicionarStock}
              handleSubmitEntradaStock={handleSubmitEntradaStock}
              sendRequest={sendRequest}
            />
          )}
          {show === "cadastrado" && (
            <ModalStockUpdate
              stock={stock}
              handleCloseModalAdicionarStock={handleCloseModalAdicionarStock}
              handleSubmitUpdateStock={handleSubmitUpdateStock}
              categoriasStock={categoriasStock}
            />
          )}
          {show === "import" && (
            <ModalImportXlsStock
              stock={stock}
              handleCloseModalImportStock={handleCloseModalAdicionarStock}
              sendRequest={sendRequest}
              produtos={produtos}
              atualizarStock={atualizarStock}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ModalAdicionarNoStock;
