import { useState, useEffect } from "react";
import moment from "moment";
import InputNumber from "../../../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../../../shared/components/inputs/InputText/InputText";
import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";
import { Link } from "react-router-dom";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import "./ModalDespesa.scss";

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  "Cheque Visado",
  "Transferência de crédito",
  "Internet banking",
  "Cartão do banco",
  "Débito direto da conta bancária",
  "Cartão de crédito",
  "Cartão de débito",
];

function ModalDespesa({
  openModalDespesa,
  handleCloseModalDespesa,
  sendRequest,
  clinicaId,
  updateDespesas,
  auth,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalDespesa);
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState();
  const [caixas, setCaixas] = useState([]);
  const [dataLimitePagamento, setDataLimitePagamento] = useState(moment());
  const [comprovativo, setComprovativo] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [despesaPaga, setDespesaPaga] = useState(false);
  // const [despesaRecorrente, setDespesaRecorrente] = useState(false);
  const [dataPagamento, setDataPagamento] = useState(moment());

  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [categoriasResponse, caixasResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/despesa/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process categorias
        const categoriasFormatted = categoriasResponse.categoriasFinanceiro.map(
          (cat) => ({
            id: cat.id,
            value: cat.categoria,
          })
        );
        setCategorias(categoriasFormatted);
        setCategoria(categoriasFormatted[0]?.id);

        // Process caixas
        const caixasFormatted = caixasResponse.caixas.map((cai) => ({
          id: cai.id,
          value: cai.caixa,
        }));
        setCaixas(caixasFormatted);
        setCaixa(caixasFormatted[0]?.id);
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        toast.error(
          "Ocorreu um erro ao carregar as categorias e caixas. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [auth.token, clinicaId, sendRequest]);

  useEffect(() => {
    setOpenModal(openModalDespesa);
  }, [openModalDespesa]);

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const valorChangeHandler = (value) => {
    setValor(value);
  };

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const guardarDespesaHandler = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("clinica", clinicaId);
      formData.append("observacoes", observacao ? observacao : "");
      formData.append("descricao", descricao);
      formData.append("valor", valor);
      formData.append("dataLimitePagamento", dataLimitePagamento);
      formData.append("categoria", categoria);
      formData.append("caixa", caixa);
      formData.append("criadoPor", auth.userId);
      if (comprovativo) {
        const modifiedFile = new File(
          [comprovativo],
          `${clinicaId}-${comprovativo.name}`,
          { type: comprovativo.type }
        );
        formData.append("comprovativo", modifiedFile);
      }

      if (despesaPaga) {
        formData.append("dataPagamento", dataPagamento);
        formData.append("metodoPagamento", metodoPagamento);
        formData.append("despesaPaga", true);
      } else {
        formData.append("despesaPaga", false);
      }

      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
      const despesaAdicionada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/despesas`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      updateDespesas(despesaAdicionada.despesa);
      toast.success("Despesa adicionada com sucesso");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro guardando os dados da despesa. Por favor tente novamente."
      );
    } finally {
      setIsSubmitting(false);
      stopLoading();
      handleCloseModalDespesa();
    }
  };

  const preGuardarDespesaHandler = () => {
    if (valor === 0) {
      toast.error("O valor da despesa não pode ser 0");
    } else if (!isSubmitting) {
      setIsSubmitting(true);
      guardarDespesaHandler();
    }
  };

  const handleFileChange = (event) => {
    // Check file size (7MB limit)
    const maxSizeInBytes = 7 * 1024 * 1024; // 3MB in bytes
    const file = event.target.files[0];
    // Check file extension
    const allowedExtensions = /(\.jpg|\.jpeg|\.png\.pdf|)$/i;
    if (!allowedExtensions.test(file.name)) {
      setErrorMessage(`O ficheiro "${file.name}" tem uma extensão inválida.`);
    } else if (file.size > maxSizeInBytes) {
      setErrorMessage(
        `O ficheiro "${file.name}" excede o tamanho máximo permitido (7MB).`
      );
    } else {
      setErrorMessage("");
      setComprovativo(file);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalDespesa}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-despesa__modal"
          id="modal__adicionar-despesa"
        >
          <h1 className="fluxo-atendimento__modal__titulo">Nova despesa</h1>
          <div className="adicionar-despesa__modal__container">
            <InputText
              className="adicionar-despesa__modal__container--span2"
              label="Descrição *"
              initialValue={descricao}
              handleChange={descricaoChangeHandler}
            />
            <InputNumber
              className="adicionar-despesa__modal__container--span1"
              label="Valor *"
              initialValue={valor}
              handleChange={valorChangeHandler}
            />
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data de vencimento*"
                inputFormat="DD-MM-YYYY"
                value={dataLimitePagamento}
                onChange={(value) => setDataLimitePagamento(value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {categorias && categoria && (
              <div className="customInputContainer adicionar-despesa__modal__container--span2">
                <select
                  onChange={(e) => setCategoria(e.target.value)}
                  className="customInputSelect"
                  value={categoria}
                >
                  {categorias &&
                    categoria &&
                    categorias.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Categoria</label>
                <Link to={`../../configuracoes/categoriasFinanceiro`}>
                  <ControlPointIcon className="customInputPlusSign" />
                </Link>
              </div>
            )}
            {caixas && caixa && (
              <div className="customInputContainer adicionar-despesa__modal__container--span2">
                <select
                  onChange={(e) => setCaixa(e.target.value)}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
                <Link to={`../../configuracoes/caixas`}>
                  <ControlPointIcon className="customInputPlusSign" />
                </Link>
              </div>
            )}

            {/* <div className="adicionar-despesa__modal__container__checkbox-recorrente__container">
              <input
                type="checkbox"
                name=""
                id="ja-foi-pago-checkbox"
                onChange={(e) => setDespesaRecorrente(e.target.checked)}
                checked={despesaRecorrente}
              />
              <label htmlFor="ja-foi-pago-checkbox">
                Essa despesa se repete
              </label>
            </div> */}

            {/* {despesaRecorrente && (
              <>
                <div
                  className="customInputContainer adicionar-despesa__modal__container--span1"
                  style={{ gridColumn: "1/2" }}
                >
                  <select
                    onChange={(e) => setTipoRepeticao(e.target.value)}
                    className="customInputSelect"
                    value={tipoRepeticao}
                  >
                    <option key="1" value="Mensalmente">
                      Mensalmente
                    </option>
                    <option key="2" value="Bimestralmente">
                      Bimestralmente
                    </option>
                    <option key="3" value="Semanalmente">
                      Semanalmente
                    </option>
                    <option key="4" value="Quinzenalmente">
                      Quinzenalmente
                    </option>
                  </select>
                  <label className="customInputLabelSelect">
                    Tipo de repetição
                  </label>
                </div>
              </>
            )} */}

            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
              className="adicionar-despesa__modal__container__textarea"
            ></textarea>
          </div>

          <div className="adicionar-despesa__modal__container__checkbox-pago__container">
            <input
              type="checkbox"
              name=""
              id="ja-foi-pago-checkbox"
              onChange={(e) => setDespesaPaga(e.target.checked)}
              checked={despesaPaga}
            />
            <label htmlFor="ja-foi-pago-checkbox">
              Essa despesa já foi paga
            </label>
          </div>

          {despesaPaga && (
            <>
              <div className="adicionar-despesa__modal__pagamento">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  className="adicionar-despesa__modal__container--span1"
                >
                  <DesktopDatePicker
                    className="valorDataCaixa__fields--field muiDatePicker"
                    label="Data do Pagamento*"
                    inputFormat="DD-MM-YYYY"
                    value={dataPagamento}
                    onChange={(value) => setDataPagamento(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <InputSelect
                  label="Forma de pagamento"
                  initialValue={metodoPagamento}
                  handleChange={metodoPagamentoChangeHandler}
                  items={formasPagamento}
                />
              </div>

              {comprovativo && (
                <div className="despesas--comprovativo-selecionado">
                  Comprovativo Selecionado: {comprovativo.name}
                </div>
              )}
              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="fileInput"
                  onChange={handleFileChange}
                />
                <label htmlFor="fileInput" className="anexar-comprovativo">
                  {comprovativo
                    ? "Substituir comprovativo"
                    : "Anexar comprovativo"}
                </label>
              </div>

              <div className="despesas--regras-ficheiro">
                <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                  O Tamanho máximo do upload é 7MB
                </span>
                <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                  Formatos aceites: .pdf, .png, .jpg e .jpeg
                </span>
              </div>
            </>
          )}

          {errorMessage && (
            <div className="modal-mensagem-de-erro">
              <span>{errorMessage}</span>
            </div>
          )}

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalDespesa}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting || errorMessage}
              className="blue-button"
              onClick={preGuardarDespesaHandler}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalDespesa;
