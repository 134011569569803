import React, { useState, useEffect, useContext } from "react";
import "./ModalStockCadastrar.scss";
import uuid from "react-uuid";
import InputNumber from "../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../shared/components/inputs/InputText/InputText";

import { ClinicaContext } from "../../../shared/context/clinica-context";

//MUI
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../../../shared/context/auth-context";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

function ModalStockCadastrar({
  handleSubmitEntradaStock,
  handleCloseModalAdicionarStock,
  sendRequest,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoriasStock, setCategoriasStock] = useState();
  const [fixarCategoria, setFixarCategoria] = useState(false);
  const [dadosProdutos, setDadosProdutos] = useState();
  const [validationError, setValidationError] = useState("");

  const clinica = useContext(ClinicaContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCategoriasStock = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCategoriasStock(responseData.categoriasStock);
        setDadosProdutos([
          {
            id: 0,
            produto: "",
            categoriaStock: responseData.categoriasStock[0].id,
            quantidade: 1,
            quantidadeIdeal: 1,
          },
        ]);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro buscando os dados das categorias de stock. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchCategoriasStock();
  }, [sendRequest, clinica.clinica._id, auth.token]);

  const handleNomeChange = (id, value) => {
    const dadosProdutoCopy = [...dadosProdutos];
    const filteredProduto = dadosProdutoCopy.filter(
      (prod) => prod.id === id
    )[0];
    filteredProduto.produto = value;
    setDadosProdutos([...dadosProdutoCopy]);
  };

  const handleQtddChange = (id, value) => {
    const dadosProdutoCopy = [...dadosProdutos];
    const filteredProduto = dadosProdutoCopy.filter(
      (prod) => prod.id === id
    )[0];
    filteredProduto.quantidade = value;
    setDadosProdutos([...dadosProdutoCopy]);
  };

  const handleQtddIdealChange = (id, value) => {
    const dadosProdutoCopy = [...dadosProdutos];
    const filteredProduto = dadosProdutoCopy.filter(
      (prod) => prod.id === id
    )[0];
    filteredProduto.quantidadeIdeal = value;
    setDadosProdutos([...dadosProdutoCopy]);
  };

  const handleDelete = (id) => {
    setDadosProdutos((current) => {
      return current.filter((produto) => produto.id !== id);
    });
  };

  const handleAdicionar = () => {
    if (fixarCategoria) {
      setDadosProdutos((current) => {
        return [
          ...current,
          {
            id: uuid(),
            categoriaStock: current[current.length - 1].categoriaStock,
            produto: "",
            quantidade: 1,
            quantidadeIdeal: 1,
          },
        ];
      });
    } else {
      setDadosProdutos((current) => {
        return [
          ...current,
          {
            id: uuid(),
            produto: "",
            categoriaStock: categoriasStock[0].id,
            quantidade: 1,
            quantidadeIdeal: 1,
          },
        ];
      });
    }
  };

  const preHandleSubmitEntradaStock = (dados) => {
    let mensagemDeErro = "";
    dados.forEach((dado) => {
      if (!dado.produto) {
        mensagemDeErro =
          "Por favor preencha todos os campos de Nome do Produto!";
        return;
      }
      if (!dado.quantidade) {
        mensagemDeErro =
          "Por favor preencha todos os campos de Quantidade do produto a inserir!";
        return;
      }
      if (!dado.quantidadeIdeal) {
        mensagemDeErro =
          "Por favor preencha todos os campos de Quantidade ideal do produto a inserir!";
        return;
      }
      if (!dado.categoriaStock) {
        mensagemDeErro =
          "Por favor preencha todos os campos de Categoria de stock!";
        return;
      }
    });
    if (mensagemDeErro) {
      setValidationError(mensagemDeErro);
    } else if (!mensagemDeErro && validationError) {
      setValidationError("");
    }

    if (!isSubmitting && !mensagemDeErro) {
      setIsSubmitting(true);
      handleSubmitEntradaStock(dados);
    }
  };

  const categoriasStockChange = (id, e) => {
    const dadosProdutoCopy = [...dadosProdutos];
    const filteredProduto = dadosProdutoCopy.filter(
      (prod) => prod.id === id
    )[0];
    filteredProduto.categoriaStock = e.target.value;
    setDadosProdutos([...dadosProdutoCopy]);
  };

  return (
    <div className="stockCadastrar__container">
      {dadosProdutos &&
        dadosProdutos.map((produto, index) => (
          <div className="stockCadastrar__container__row" key={index}>
            <InputText
              initialValue={""}
              handleChange={handleNomeChange.bind(null, produto.id)}
              label="Nome do Produto"
              notEmpty={true}
            />

            {categoriasStock && (
              <div className="customInputContainer">
                <select
                  onChange={categoriasStockChange.bind(null, produto.id)}
                  className="customInputSelect"
                  defaultValue={produto.categoriaStock}
                >
                  {categoriasStock &&
                    categoriasStock.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.categoria}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">
                  Categoria Stock
                </label>
              </div>
            )}

            <InputNumber
              initialValue={1}
              handleChange={handleQtddChange.bind(null, produto.id)}
              min={1}
              notEmpty={true}
              label="Quantidade"
            />
            <InputNumber
              initialValue={1}
              handleChange={handleQtddIdealChange.bind(null, produto.id)}
              min={1}
              notEmpty={true}
              label="Qtd Ideal"
            />
            {produto.id !== 0 && (
              <DeleteIcon
                className="stockCadastrar__container__delete-btn"
                onClick={handleDelete.bind(null, produto.id)}
              />
            )}
          </div>
        ))}
      <div className="stockCadastrar__container__adicionar-btn__container">
        <div className="stockCadastrar__container__adicionar-btn__container__fixarCategoria">
          <input
            id="fixarCategoriaCheckbox"
            type="checkbox"
            className="checkbox"
            checked={fixarCategoria}
            onChange={(e) => setFixarCategoria(e.target.checked)}
          />
          <label htmlFor="fixarCategoriaCheckbox">Fixar Categoria</label>
        </div>
        <span
          className="stockCadastrar__container__adicionar-btn"
          onClick={handleAdicionar}
        >
          Adicionar outro produto
        </span>
      </div>

      {validationError && (
        <div className="modal__validationErrors__container">
          {validationError}
        </div>
      )}
      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalAdicionarStock}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        <span
          disabled={isSubmitting}
          className="blue-button"
          onClick={preHandleSubmitEntradaStock.bind(null, dadosProdutos)}
        >
          {isSubmitting ? "Adicionando..." : "Adicionar ao Stock"}
        </span>
      </div>
    </div>
  );
}

export default ModalStockCadastrar;
