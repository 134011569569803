import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import numeroParaExtenso from "../../../util/numeroPorExtenso";
import "./PdfReciboStyle.scss";
import moment from "moment/moment";

const printStyles = `
    @media print {
      @page {
        size: 210mm 148mm;
      }
    }
  `;

// size: 148mm 210mm;

function PdfRecibo({ reciboData, showPreviousPage }) {
  const componentRef = useRef();
  const emExtenso = numeroParaExtenso(reciboData.valor);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Recibo-${reciboData.cliente.nome}_${moment(
      new Date()
    ).format("DD-MM-YYYY")}`,
    onAfterPrint: () => {
      showPreviousPage();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);
  return (
    <>
      <div className="botoes-pdf">
        <button onClick={showPreviousPage}>Voltar</button>
        <button onClick={handlePrint}>Imprimir</button>
      </div>

      <div ref={componentRef} className="pdf-wrapper">
        <div className="pdf-recibo-cabecalho">
          <div className="pdf-recibo-cabecalho-left">
            <img
              src={`${process.env.REACT_APP_BACKEND_LINK}/${reciboData.clinica.logotipo}`}
              alt=""
            />

            <span>{reciboData.clinica.nome}</span>
            <span>Telef: {reciboData.clinica.contacto}</span>
            <span>{reciboData.clinica.endereco}</span>
            <span>NIF: {reciboData.clinica.nif}</span>
          </div>
          <div className="pdf-recibo-cabecalho-right">
            <span className="pdf-recibo-cabecalho-right__reciboTitle">
              Recibo
            </span>
            <div className="pdf-recibo-cabecalho-right__nrValor">
              <span className="pdf-recibo-cabecalho-right__nr">
                Nº 0{reciboData.nrRecibo}
              </span>
              <span className="pdf-recibo-cabecalho-right__valor">
                Valor: {reciboData.valor.toLocaleString("PT-BR")}$00
              </span>
            </div>
          </div>
        </div>

        <style>{printStyles}</style>
        <div className="pdf-recibo-body">
          <div className="pdf-recibo-body__row">
            <span>Recebemos de</span> <span>{reciboData.cliente.nome}</span>
          </div>

          <div className="pdf-recibo-body__row pdf-recibo-body__row--colored">
            <span>a quantia de</span>{" "}
            <span className="pdf-recibo-body__row__valor">
              {/* {reciboData.valor.toLocaleString("PT-BR")}$00 */}
              {emExtenso} escudos
            </span>
          </div>

          <div className="pdf-recibo-body__row">
            <span>proveniente de</span> <span>tratamento dentário</span>
          </div>

          <div className="pdf-recibo-body__dataAssinaturaContainer">
            <div className="pdf-recibo-body__dataAssinaturaContainer__item pdf-recibo-body__dataAssinaturaContainer__item--2">
              <span>Data:</span> <span>{moment().format("DD/MM/YYYY")}</span>
            </div>
            <div className="pdf-recibo-body__dataAssinaturaContainer__item">
              <span>Assinatura</span> <hr />
            </div>
          </div>

          <div className="pdf-recibo__rodape">Powered by: HealthTech CV</div>
        </div>
      </div>
    </>
  );
}

export default PdfRecibo;
