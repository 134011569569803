import { React, useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import ErrorModal from "../../../../shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { localeText } from "../../../../shared/util/datatableTranslations";

import { useLoading } from "../../../../shared/context/LoadingContext";
//Mui icons

import DeleteIcon from "@mui/icons-material/Delete";
//Delete icon
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";

import "../../../../shared/css/Datatable.scss";
import toast from "react-hot-toast";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";

const PAGESIZE = 10;
function Datatable() {
  const { startLoading, stopLoading } = useLoading();

  const [data, setData] = useState();
  const [allData, setAllData] = useState();
  const { error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientes = async () => {
      startLoading();
      try {
        let responseData;
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/datagrid/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setData(responseData.clientes);
        setAllData(responseData.clientes);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro na busca dos clientes. Por favor tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchClientes();

    // const fetchOtherClientes = async () => {
    //   try {
    //     let responseData;
    //     responseData = await sendRequest(
    //       `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/datagrid/${clinica.clinica._id}`,
    //       "GET",
    //       null,
    //       {
    //         Authorization: "Bearer " + auth.token,
    //       }
    //     );
    //     setAllData(responseData.clientes);
    //     setData(responseData.clientes);
    //   } catch (err) {
    //     console.error("err", err);
    //     toast.error(
    //       "Busca de clientes falhou. Recarregue a página e tente novamente."
    //     );
    //   }
    // };
    // fetchOtherClientes();
  }, [sendRequest, clinica, auth.token]);

  const handleDesativar = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/ativardesativar/${id}`,
        "PATCH",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setData(
        data.filter((el) => {
          return el.id !== id;
        })
      );
      toast.success("Cliente eliminado com sucesso!");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro na desativação do cliente. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const clienteColumns = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 7,
      renderCell: (params) => {
        return (
          <span className="nomeDataTable">
            {auth.perm.includes("r-cli") && (
              <Link to={`/clientes/${params.row.id}`}>{params.row.nome}</Link>
            )}
            {!auth.perm.includes("r-cli") && params.row.nome}
          </span>
        );
      },
    },
    {
      field: "contacto",
      headerName: "Contacto",
      flex: 3,
    },
    {
      field: "data_nasc",
      headerName: "Data Nascimento",
      flex: 3,
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 1.5,
      renderCell: (params) => {
        const content = (
          <div className="dots__menu__popup">
            {auth.perm.includes("u-cli") && (
              <Link
                to={`/clientes/${params.row.id}/edit`}
                style={{ textDecoration: "none" }}
              >
                <div className="popOverMenu--option">
                  <span>Editar</span>
                  <EditIcon className="popOverMenu--option__icon" />
                </div>
              </Link>
            )}

            {auth.perm.includes("d-cli") && (
              <Popconfirm
                title={`Excluir Cliente`}
                description={`Pretende desativar o cliente ${params.row.nome}?`}
                icon={<ErrorIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={handleDesativar.bind(null, params.row.id)}
              >
                <div className="popOverMenu--option">
                  <span>Desativar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );

        return (
          <div className="cellAction">
            <Space wrap>
              <Popover content={content} trigger="click" placement="bottom">
                <div className="dots__menu">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Popover>
            </Space>
          </div>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    if (allData) {
      const result = allData.filter((dt) =>
        dt.nome.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setData(result);
    }
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />

      <div className="datatable caixa">
        {auth.perm.includes("c-cli") && (
          <div className="datatable__add-button-div datatable__add-button-div--clientes">
            <div className="search__datatable__container">
              <span>Procurar</span>
              <input
                type="search"
                className="search__datatable__input"
                placeholder="Procurar"
                onChange={searchHandler}
              />
            </div>

            <span
              className="blue-button"
              onClick={() => navigate("/clientes/new")}
            >
              Adicionar Cliente
            </span>
          </div>
        )}

        {!error && data && (
          <DataGrid
            autoWidth
            className="datagrid"
            rows={data}
            columns={clienteColumns.concat(actionColumn)}
            pageSize={PAGESIZE}
            rowsPerPage={PAGESIZE}
            localeText={localeText}
          />
        )}
      </div>
    </>
  );
}

export default Datatable;
