import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
//MUI MODAL
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
//Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function UrgenciasModal({
  urgencias,
  handleClose,
  openModalUrgencia,
  atendidoConfirmation,
}) {
  return (
    <div>
      {" "}
      {urgencias && (
        //Modal Urgencia
        <Modal
          open={openModalUrgencia}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div className="modalUrgenciaList">
              <span className="urgencia-list-title">Paciente</span>
              <span className="urgencia-list-title">Hora de Chegada</span>
              <span className="urgencia-list-title">Ações</span>

              {urgencias.map((ur, index) => (
                <React.Fragment key={index}>
                  <Link to={`clientes/${ur.cliente.id}`}>
                    <span>{ur.cliente.nome}</span>
                  </Link>
                  <span>{moment(ur.created_at).format("HH:mm:ss")}</span>
                  <span
                    className="blue-button btn-atendido"
                    onClick={atendidoConfirmation.bind(
                      null,
                      ur.id,
                      ur.cliente.nome
                    )}
                  >
                    Atendido
                  </span>
                </React.Fragment>
              ))}
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default UrgenciasModal;
