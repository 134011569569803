import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const [image, setImage] = useState("");
  const [clinica, setClinica] = useState(null);
  const [permissoes, setPermissoes] = useState(null);
  const [usuariosAssociados, setUsuariosAssociados] = useState(null);
  const [nome, setNome] = useState(null);

  const login = useCallback(
    (
      uid,
      token,
      image,
      role,
      clinica,
      usuariosAssociados,
      permissoes,
      nome,
      expirationDate
    ) => {
      setToken(token);
      setUserId(uid);
      setImage(image);
      setUserRole(role);
      setClinica(clinica);
      setUsuariosAssociados(usuariosAssociados);
      setPermissoes(permissoes);
      setNome(nome);
      //generating a date object that corresponds to 1hour
      const tokenExpDate =
        expirationDate || new Date(new Date().getTime() + 10000 * 60 * 60);
      setTokenExpirationDate(tokenExpDate);
      //can only write text so we use json stringify to convert the objects to text
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          image: image,
          role: role,
          clinica: clinica,
          usuariosAssociados: usuariosAssociados,
          perm: permissoes,
          nome: nome,
          //a special kind of string that stores all the important date information that can
          //be converted to a date object later
          expiration: tokenExpDate.toISOString(),
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setImage(null);
    setUserRole(null);
    setClinica(null);
    setUsuariosAssociados(null);
    setPermissoes(null);
    setNome(null);
    localStorage.removeItem("userData");
  }, []);

  //Controll de login time
  useEffect(() => {
    if (token && tokenExpirationDate) {
      //because setTimeout Wants the duration in miliseconds we calculate it here
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  //react will mount this component, run the code below use effect and the run use Effect
  //
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.image,
        storedData.role,
        storedData.clinica,
        storedData.usuariosAssociados,
        storedData.perm,
        storedData.nome,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return {
    token,
    login,
    logout,
    userId,
    image,
    userRole,
    clinica,
    usuariosAssociados,
    permissoes,
    nome,
  };
};
