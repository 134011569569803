import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../shared/context/auth-context";
import { Collapse } from "antd";
import moment from "moment";
import ModalAdicionarEvolucao from "./ModalAdicionarEvolucao/ModalAdicionarEvolucao";
import ModalEditarEvolucao from "./ModalEditarEvolucao/ModalEditarEvolucao";
import ModalEvolucao from "./ModalEvolucao";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModalInfo from "../../../../../shared/components/UIElements/ModalInfo";
import toast from "react-hot-toast";
import "./Historico.scss";
import { useLoading } from "../../../../../shared/context/LoadingContext";

function Historico({ clienteId, sendRequest, clienteData }) {
  const { startLoading, stopLoading } = useLoading();

  const [planosTratamento, setPlanosTratamento] = useState();
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [openModalEvolucao, setOpenModalEvolucao] = useState(false);
  const [openModalEditarEvolucao, setOpenModalEditarEvolucao] = useState(false);
  const [evolucoes, setEvolucoes] = useState();
  const [dadosEvolucaoAEditar, setDadosEvolucaoAEditar] = useState();
  const auth = useContext(AuthContext);

  const { Panel } = Collapse;

  useEffect(() => {
    setEvolucoes(clienteData.evolucoes);
  }, [clienteData]);

  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  useEffect(() => {
    const fetchPlanosTratamentoByCliente = async () => {
      startLoading();
      try {
        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/cliente/populatemedico/${clienteId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro na busca dos planos de tratamento do cliente. Por favor tente novamente."
          );
        }

        const temp = responseData.planosTratamento.sort((a, b) => {
          return new Date(b.data_inicio) - new Date(a.data_inicio);
        });

        temp.forEach((plano) => {
          if (plano.data_inicio) {
            plano.data_inicio = moment(plano.data_inicio).format("DD-MM-YYYY");
          }
          if (plano.data_fim) {
            plano.data_fim = moment(plano.data_fim).format("DD-MM-YYYY");
          }
        });

        //Filtrando por medico conforme o usuario
        //DEPOIS VERIFICAR OUTROS USUARIOS
        let tempPlanosTratamento = [];

        if (auth.role === "Médico/Administrador" || auth.role === "Dentista") {
          tempPlanosTratamento = responseData.planosTratamento.filter(
            (pl) => pl.medico_responsavel.id === auth.userId
          );
        } else if (auth.role === "Secretária(o)") {
          responseData.planosTratamento.forEach((pl) => {
            auth.usuariosAssociados.forEach((medico) => {
              if (medico.id === pl.medico_responsavel.id)
                tempPlanosTratamento.push(pl);
            });
          });
        } else if (auth.role === "Administrador Não Médico") {
          tempPlanosTratamento = [...responseData.planosTratamento];
        }

        setPlanosTratamento(tempPlanosTratamento);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchPlanosTratamentoByCliente();
  }, [sendRequest, clienteId, auth]);

  const handleShowEvolucao = (proc) => {
    setDataModal(proc);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setDataModal();
    setShowModal(false);
  };

  const handleOpenModalAdicionarEvolucao = () => {
    setOpenModalEvolucao(true);
  };

  const handleCloseModalEvolucao = () => {
    setOpenModalEvolucao(false);
    setOpenModalEditarEvolucao(false);
    setDadosEvolucaoAEditar({});
  };

  const handleModalSubmitSuccess = (evolu) => {
    setOpenModalEvolucao(false);
    setEvolucoes((current) => [...current, evolu]);
  };

  const handleModalEditarSuccess = (evolu) => {
    setOpenModalEditarEvolucao(false);

    setEvolucoes((prevItems) =>
      prevItems.map((item) => (item.id === evolu.id ? { ...evolu } : item))
    );
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  const handleApagarEvolucao = async (idEvolucao) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Apagar Evolução!`,
      message: `Tem a certeza que pretende apagar a evolução?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: apagarEvolucao.bind(null, idEvolucao),
    });
  };

  const apagarEvolucao = async (idEvolucao) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/apagarevolucao/${idEvolucao}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      toast.success("Evolução eliminada com sucesso");
      setEvolucoes((current) => current.filter((c) => c.id !== idEvolucao));
    } catch (err) {
      toast.error("Erro ao eliminar a evolução");
      console.error(err);
    } finally {
      stopLoading();
      handleModalCancel();
    }
  };

  const handleOpenModalEditar = async (idEvolucao) => {
    setDadosEvolucaoAEditar(evolucoes.find((ev) => ev.id === idEvolucao));
    setOpenModalEditarEvolucao(true);
  };

  return (
    <>
      <ModalInfo {...modalProps} />
      <ModalAdicionarEvolucao
        handleCloseModalEvolucao={handleCloseModalEvolucao}
        handleModalSubmitSuccess={handleModalSubmitSuccess}
        open={openModalEvolucao}
        cliente={{ nome: clienteData.nome, id: clienteData.id }}
        sendRequest={sendRequest}
        auth={auth}
      />
      {dadosEvolucaoAEditar && (
        <ModalEditarEvolucao
          handleCloseModalEvolucao={handleCloseModalEvolucao}
          handleModalEditarSuccess={handleModalEditarSuccess}
          open={openModalEditarEvolucao}
          cliente={{ nome: clienteData.nome, id: clienteData.id }}
          sendRequest={sendRequest}
          auth={auth}
          dadosEvolucao={dadosEvolucaoAEditar}
        />
      )}
      {planosTratamento && planosTratamento.length > 0 && (
        <div className="historicoContainer caixa">
          <Collapse defaultActiveKey={["0"]}>
            {planosTratamento.map((pl, index) => (
              <Panel
                header={
                  <div className="collapse__title">
                    <span className="collapse__title__text">
                      {`Plano de Tratamento - ${
                        pl.data_inicio ? pl.data_inicio : "?"
                      } - ${pl.data_fim ? pl.data_fim : "?"}`}
                    </span>
                  </div>
                }
                key={index}
              >
                <div className="historico__colapse-container" key={index}>
                  <div className="historico__colapse-container__row--header">
                    <span>Data Inicio</span>
                    <span>Procedimento</span>
                    <span>Sessões</span>
                    <span>Dente</span>
                    <span>Faces</span>
                    <span>Status</span>
                  </div>

                  {pl.procedimentos.map(
                    (pr, index) =>
                      pr.sessoes.length > 0 && (
                        <div
                          className="historico__colapse-container__row caixa"
                          key={index}
                        >
                          <div className="historico__colapse-container__row__item">
                            <span>
                              {pr.data_inicio_execucao
                                ? moment(pr.data_inicio_execucao).format(
                                    "DD-MM-YYYY"
                                  )
                                : moment(
                                    pr.sessoes[0].data_inicio_execucao
                                  ).format("DD-MM-YYYY")}
                            </span>
                          </div>
                          <div className="historico__colapse-container__row__item">
                            <span
                              className="historico__colapse-container__row__item--tratamento"
                              onClick={handleShowEvolucao.bind(null, pr)}
                            >
                              {pr.tratamento.designacao}
                            </span>
                            <span>
                              {pr.medico.genero === "feminino"
                                ? "Dra. " + pr.medico.name
                                : "Dr. " + pr.medico.name}
                            </span>
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {pr.sessoes ? pr.sessoes.length : 1}
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {pr.dente}
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {pr.faces.join(" ,")}
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {pr.procedimento_completo
                              ? "Finalizado"
                              : pr.sessoes.length > 0
                              ? "Em Andamento"
                              : ""}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      )}

      <div className="adicionarEvolucaoButtonContainer">
        <span
          className="blue-button"
          onClick={handleOpenModalAdicionarEvolucao}
        >
          Adicionar Evolução
        </span>
      </div>

      {evolucoes && (
        <div className="evolucaoClienteSolo__container">
          {evolucoes.map((ev, index) => (
            <div className="caixa evolucaoItemContainer" key={index}>
              <div className="evolucaoClienteSolo">
                <span className="evolucao-descricao">{ev.evolucao}</span>
                <div>
                  <span className="evolucao-datas">
                    {moment(ev.data_inicio_execucao).format("DD/MM/YYYY HH:mm")}
                  </span>{" "}
                  {"  -  "}
                  <span className="evolucao-datas">
                    {moment(ev.data_fim_execucao).format("HH:mm")} -{" "}
                    {ev.medico ? ev.medico.name : ""}
                  </span>
                </div>
              </div>
              <div className="evolucao-btns">
                {ev.medico?._id === auth.userId && (
                  <>
                    <Tooltip title="Apagar">
                      <IconButton
                        onClick={handleApagarEvolucao.bind(null, ev._id)}
                        className="modalEvolucao__container__btns__icon-btn"
                      >
                        <DeleteIcon className="evolucao-btns__btn" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton
                        onClick={handleOpenModalEditar.bind(null, ev._id)}
                        className="modalEvolucao__container__btns__icon-btn"
                      >
                        <EditIcon className="evolucao-btns__btn" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {showModal && dataModal && (
        <ModalEvolucao
          data={dataModal}
          openModalEvolucao={showModal}
          handleCloseModal={handleCloseModal}
          auth={auth}
        />
      )}
    </>
  );
}

export default Historico;
