import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt";

import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { useHttpClient } from "../../../../shared/hooks/http-hook";

import AppointmentForm from "./componentsFullCallendar/AppointmentForm";
import Tooltip from "./componentsFullCallendar/Tooltip";
import ModalInfo from "../../../../shared/components/UIElements/ModalInfo";

import "./Datatable.scss";

import { useLoading } from "../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

function Datatable({ medicos }) {
  const { startLoading, stopLoading } = useLoading();

  const [clients, setClients] = useState([]);
  const [clientesProcedimento, setClientesProcedimento] = useState([]);
  const [events, setEvents] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [tooltipEvent, setTooltipEvent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [selectedDate, setSelectedDate] = useState(null);
  const [agenda, setAgenda] = useState(null);
  const [agendamentosEmTransicao, setAgendamentosEmTransicao] = useState([]);
  const [compromissos, setCompromissos] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [allCategoriasTratamento, setAllCategoriasTratamento] = useState([]);
  const [procedimentosPorMarcar, setProcedimentosPorMarcar] = useState([]);
  const [duracaoConsulta, setDuracaoConsulta] = useState(30);
  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  const clickRef = useRef(null);
  const tooltipRef = useRef(null);

  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const medicoId = useParams().medicoId;

  useEffect(() => {
    setEvents([]);
    const fetchData = async () => {
      try {
        if (medicos) {
          let temp = medicos.filter((m) => m.id === medicoId);
          setDuracaoConsulta(temp[0].duracaoConsulta);
        } else {
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/${medicoId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          setDuracaoConsulta(response.user.duracaoConsulta);
        }

        startLoading();
        const [
          proceduresResponse,
          agendaResponse,
          compromissosResponse,
          agendamentosEmTransicaoResponse,
          clientesResponse,
          categoriasTratamentoResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/procedimentos/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/compromissos/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/agendaavaliacao/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriastratamento/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          ),
        ]);

        const agendaMarcacoes = agendaResponse.agenda.marcacoes || [];
        const compromissosMarcacoes =
          compromissosResponse.compromisso.marcacoes || [];
        const agendamentosEmTransicaoMarcacoes =
          agendamentosEmTransicaoResponse.agenda.marcacoes || [];
        setAgenda(agendaResponse.agenda);
        setCompromissos(compromissosResponse.compromisso);
        setAgendamentosEmTransicao(agendamentosEmTransicaoResponse.agenda);
        setClients(clientesResponse.clientes);
        setAllCategoriasTratamento(
          categoriasTratamentoResponse.categoriasTratamento.map((c) => ({
            id: c._id,
            label: c.categoria,
          }))
        );

        const formattedEvents = [
          ...formatAppointments(agendaMarcacoes),
          ...formatCompromissos(compromissosMarcacoes),
          ...formatTransitionAppointments(agendamentosEmTransicaoMarcacoes),
        ];

        let nrClientesNomesIguais = 0;
        const tempClientes = [];
        const tempData = [];

        //Procedimentos por marcar
        try {
          proceduresResponse.planosTratamento.forEach((pl) => {
            const procedimentosParaEsseMedico = pl.procedimentos.filter(
              (pr) => pr.medico === medicoId && !pr.procedimento_completo
            );

            procedimentosParaEsseMedico.forEach((pr) => {
              tempData.push({
                id: crypto.randomUUID(),
                id_plano: pl.id,
                cliente: {
                  id: pl.cliente._id,
                  label: pl.cliente.nome,
                },
                startDate: pr.data_inicio_execucao
                  ? new Date(pr.data_inicio_execucao)
                  : null,
                endDate: pr.data_fim_execucao
                  ? new Date(pr.data_fim_execucao)
                  : null,
                title:
                  pl.cliente.nome + " - " + pr.tratamento.categoria.categoria,
                procedimento: {
                  id: pr.tratamento.categoria.id,
                  label: pr.tratamento.categoria.categoria,
                },
                filaEspera: false,
                atendido: false,
                faltou: false,
                emTransicao: false,
              });
            });

            //Clientes para a agenda
            //Se houverem procedimentos para esse medico, adicionamos os clientes à lista de clientes
            //Para não repetir os clientes, antes de adiciona-los à lista, verificamos se o id deles já existe na lista

            if (procedimentosParaEsseMedico.length > 0) {
              if (
                tempClientes.filter((c) => c.id === pl.cliente.id).length === 0
              ) {
                const existemProcedimentosDoClientePorMarcar =
                  procedimentosParaEsseMedico.filter(
                    (pr) => !pr.data_inicio_execucao
                  ).length;

                if (existemProcedimentosDoClientePorMarcar) {
                  if (
                    tempClientes.filter((c) => c.label === pl.cliente.nome)
                      .length > 0
                  ) {
                    nrClientesNomesIguais += 1;

                    tempClientes.push({
                      id: pl.cliente._id,
                      label: `${pl.cliente.nome}${"\u200B".repeat(
                        nrClientesNomesIguais
                      )}`,
                    });
                  } else {
                    tempClientes.push({
                      id: pl.cliente._id,
                      label: pl.cliente.nome,
                    });
                  }
                }
              }
            }
          });
        } catch (err) {
          console.error("err", err);
        }

        setProcedimentosPorMarcar(tempData);
        setClientesProcedimento(tempClientes);
        setEvents(formattedEvents);
      } catch (err) {
        toast.error(
          "Erro na busca dos agendamentos. Por favor tente novamente fazendo refresh na página."
        );
        console.error(err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, auth.token, clinica.clinica._id, medicos, medicoId]);

  const formatAppointments = (appointments) => {
    return appointments.map((appointment) => ({
      id: appointment._id,
      title: ` ${appointment.cliente.nome.split(" ")[0]} ${
        appointment.cliente.nome.split(" ")[
          appointment.cliente.nome.split(" ").length - 1
        ]
      } - ${
        appointment.avaliacao
          ? "Avaliação"
          : appointment.categoriaTratamento?.categoria
      }`,
      start: new Date(appointment.data_inicio_execucao),
      end: new Date(appointment.data_fim_execucao),
      backgroundColor: appointment.avaliacao
        ? "#FFF8EE"
        : appointment.atendido
        ? "#F2FAF5"
        : appointment.faltou
        ? "#FFF3F6"
        : "#F4F7FF",
      borderColor: appointment.avaliacao
        ? "#FFCF2E"
        : appointment.atendido
        ? "#39B93A"
        : appointment.faltou
        ? "#D96C60"
        : "#0871EF",
      textColor: appointment.avaliacao
        ? "#FFCF2E"
        : appointment.atendido
        ? "#39B93A"
        : appointment.faltou
        ? "#D96C60"
        : "#0871EF",
      extendedProps: {
        clientId: appointment.cliente._id,
        procedureId: appointment.categoriaTratamento?._id,
        isEvaluation: appointment.avaliacao,
        isAttended: appointment.atendido,
        isMissed: appointment.faltou,
        inTransition: false,
        isCompromisso: false,
        planoId: appointment.planoTratamento,
      },
    }));
  };

  const formatCompromissos = (compromissos) => {
    return compromissos.map((compromisso) => ({
      id: compromisso._id,
      title: " " + compromisso.compromisso,
      start: new Date(compromisso.data_inicio_execucao),
      end: new Date(compromisso.data_fim_execucao),
      backgroundColor: "#F3F3F3",
      borderColor: "#868A8F",
      textColor: "#868A8F",
      extendedProps: {
        isCompromisso: true,
      },
    }));
  };

  const formatTransitionAppointments = (appointments) => {
    return appointments.map((appointment) => ({
      id: appointment._id,
      title: ` ${appointment.cliente.nome.split(" ")[0]} ${
        appointment.cliente.nome.split(" ")[
          appointment.cliente.nome.split(" ").length - 1
        ]
      } - ${appointment.categoriaTratamento.categoria}`,
      start: new Date(appointment.data_inicio_execucao),
      end: new Date(appointment.data_fim_execucao),
      backgroundColor: "#F4EEFE",
      borderColor: "#724CB4",
      textColor: "#724CB4",
      extendedProps: {
        clientId: appointment.cliente._id,
        procedureId: appointment.categoriaTratamento._id,
        inTransition: true,
      },
    }));
  };

  const isEventEditable = (event) => {
    return !(
      event.extendedProps.isEvaluation ||
      event.extendedProps.inTransition ||
      event.extendedProps.isAttended ||
      event.extendedProps.isMissed ||
      event.extendedProps.isCompromisso
    );
  };

  const handleEventDrop = async (dropInfo) => {
    const { event } = dropInfo;

    // if (!isEventEditable(event)) {
    //   dropInfo.revert();
    //   return;
    // }

    const tipoAgendamento = event.extendedProps.inTransition
      ? "agendamentosemtransicao"
      : event.extendedProps.isCompromisso
      ? "compromissos"
      : "agenda";

    try {
      const formData = new FormData();
      formData.append("data_inicio_execucao", event.start);
      formData.append("data_fim_execucao", event.end);
      formData.append(
        "agendaId",
        event.extendedProps.inTransition
          ? agendamentosEmTransicao.id
          : event.extendedProps.isCompromisso
          ? compromissos.id
          : agenda.id
      );

      startLoading();
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/${tipoAgendamento}/alterardata/${event.id}`,
        "PATCH",
        formData,
        { Authorization: "Bearer " + auth.token }
      );
      stopLoading();

      // Update local state
      setEvents((prevEvents) =>
        prevEvents.map((e) =>
          e.id === event.id ? { ...e, start: event.start, end: event.end } : e
        )
      );
    } catch (err) {
      stopLoading();
      toast.error(
        "Houve um erro ao efetuar a remarcação. Por favor, tente novamente."
      );
      dropInfo.revert();
    }
  };

  const handleDateClick = (arg) => {
    if (clickRef.current && arg.date.getTime() === clickRef.current.getTime()) {
      // Double click detected
      setSelectedDate(arg.date);
      setIsFormOpen(true);
      setSelectedEvent(null); // Clear any previously selected event
      clickRef.current = null;
    } else {
      // First click
      clickRef.current = arg.date;
      setTimeout(() => {
        clickRef.current = null;
      }, 300);
    }
  };

  const handleEventClick = (info) => {
    if (
      clickRef.current &&
      info.event.start.getTime() === clickRef.current.getTime()
    ) {
      // Double click detected on an event
      if (isEventEditable(info.event)) {
        setSelectedDate(info.event.start);
        setSelectedEvent(info.event);
        setIsFormOpen(true);
      }
      clickRef.current = null;
    } else {
      // First click - show tooltip
      const rect = info.el.getBoundingClientRect();
      setTooltipPosition({
        x: rect.left + window.scrollX,
        y: rect.bottom + window.scrollY,
      });
      setTooltipEvent(info.event);

      // Set up for potential double click
      clickRef.current = info.event.start;
      setTimeout(() => {
        clickRef.current = null;
      }, 300);
    }
  };

  const handleCloseTooltip = () => {
    setTooltipEvent(null);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setSelectedDate(null);
  };

  const handleEditEvent = (event) => {
    // Implement edit logic
  };

  const handleDeleteEvent = async (event) => {
    try {
      let path = "agenda";
      let agendaId = agenda.id;

      if (event.extendedProps.inTransition) {
        path = "agendamentosemtransicao";
        agendaId = agendamentosEmTransicao._id;
      } else if (event.extendedProps.isCompromisso) {
        path = "compromissos";
        agendaId = compromissos._id;
      }

      const formData = new FormData();
      formData.append("agendaId", agendaId);

      startLoading();
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/${path}/eliminarmarcacao/${event.id}`,
        "PATCH",
        formData,
        { Authorization: "Bearer " + auth.token }
      );

      stopLoading();
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
      handleCloseTooltip();
    } catch (err) {
      stopLoading();
      toast.error(
        "Houve um erro ao eliminar a marcação. Por favor tente novamente."
      );
    }
    handleModalCancel();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        handleCloseTooltip();
      }
    };

    if (tooltipEvent) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipEvent]);

  const updateEvents = (event, eventId) => {
    if (!eventId) {
      setEvents((prevEvents) => [...prevEvents, event]);
    } else {
      setEvents((prevEvents) =>
        prevEvents.map((ev) =>
          ev.id === eventId ? { id: ev.id, ...event } : ev
        )
      );
    }
    handleCloseForm();
  };

  const handleApagarAgendamento = async (event) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Apagar Agendamento!`,
      message: `Tem a certeza que pretende apagar o Agendamento?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: handleDeleteEvent.bind(null, event),
    });
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  return (
    <div className="datatable-container" style={{ backgroundColor: "white" }}>
      <ModalInfo {...modalProps} />
      {events && (
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          // plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          locale={ptLocale}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,timeGridDay",
            // right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          buttonText={{
            today: "Esta Semana",
            // month: "Mês",
            week: "Semana",
            day: "Dia",
          }}
          height="auto"
          slotDuration={`00:${duracaoConsulta}:00`}
          slotLabelInterval={`00:${duracaoConsulta}`}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          slotMinTime="08:00:00"
          slotMaxTime="20:00:00"
          allDaySlot={false}
          events={events}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
          hiddenDays={[0]}
          firstDay={1}
          editable={true}
          eventDrop={handleEventDrop}
          eventResize={handleEventDrop}
          eventStartEditable={isEventEditable}
          eventDurationEditable={isEventEditable}
        />
      )}
      {tooltipEvent && (
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
          }}
        >
          <Tooltip
            event={tooltipEvent}
            onEdit={handleEditEvent}
            handleApagarAgendamento={handleApagarAgendamento}
            onClose={handleCloseTooltip}
          />
        </div>
      )}
      {agenda && (
        <AppointmentForm
          isOpen={isFormOpen}
          onClose={handleCloseForm}
          date={selectedDate}
          event={selectedEvent}
          clients={clients}
          clientesProcedimento={clientesProcedimento}
          allCategoriasTratamento={allCategoriasTratamento}
          procedimentosPorMarcar={procedimentosPorMarcar}
          auth={auth}
          sendRequest={sendRequest}
          clinicaId={clinica.clinica._id}
          agendaId={agenda.id}
          updateEvents={updateEvents}
          medicoId={medicoId}
          duracaoConsulta={duracaoConsulta}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      )}
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default Datatable;
