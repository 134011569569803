import "./ColorPicker.scss";

function ColorPicker({ colorChangeHandler, color }) {
  return (
    <>
      <input
        type="color"
        onChange={colorChangeHandler}
        value={color}
        className="colorPicker-input"
      />
    </>
  );
}

export default ColorPicker;
