import PdfOrcamentoInps from "./PdfOrcamentoInps";

function PdfOrcamentoInpsWrapper({ showSinglePage, orcamentoData }) {
  const showSingleP = () => {
    showSinglePage();
  };
  return (
    <div>
      <PdfOrcamentoInps
        orcamentoData={orcamentoData}
        showSingleP={showSingleP}
      />
    </div>
  );
}

export default PdfOrcamentoInpsWrapper;
