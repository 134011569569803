import "./ModalStockFirstStep.scss";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";

function ModalStockFirstStep({ tipoEntradaHandler, produtos }) {
  return (
    <div className="stock1stStep__container">
      <div
        className="stock1stStep__container__1"
        onClick={tipoEntradaHandler.bind(null, "porCadastrar")}
      >
        <ShoppingCartIcon className="stock1stStep__container__icon" />
        <span className="stock1stStep__container__title">
          É um produto que não tenho cadastrado
        </span>
        <span className="stock1stStep__container__subtitle">
          Eu ainda não tenho esse produto cadastrado no meu stock
        </span>
      </div>
      {produtos && produtos.length > 0 && (
        <div
          className="stock1stStep__container__2"
          onClick={tipoEntradaHandler.bind(null, "cadastrado")}
        >
          <HomeIcon className="stock1stStep__container__icon" />
          <span className="stock1stStep__container__title">
            É um produto que já tenho cadastrado
          </span>
          <span className="stock1stStep__container__subtitle">
            Eu preciso aumentar a quantidade desse produto no meu stock
          </span>
        </div>
      )}

      <div
        className="stock1stStep__container__3"
        onClick={tipoEntradaHandler.bind(null, "import")}
      >
        <ArticleIcon className="stock1stStep__container__icon" />
        <span className="stock1stStep__container__title">
          É um xls que pretendo importar
        </span>
        <span className="stock1stStep__container__subtitle">
          Eu preciso adicionar/atualizar vários produtos apartir de uma lista em
          excel
        </span>
      </div>
    </div>
  );
}

export default ModalStockFirstStep;
