const validarPrescricao = (serviceList) => {
  let errorMessage = null;

  serviceList.forEach((service) => {
    if (
      (!service.medicamento ||
        !service.duracao ||
        !service.posologia ||
        !service.tipo ||
        !service.qtdd) &&
      service.show
    )
      errorMessage = "Preencha todos os campos de cada prescrição";
  });
  return errorMessage;
};

export default validarPrescricao;
