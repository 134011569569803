import { useEffect, useState } from "react";
import "../../../../shared/css/ElementoPlanoOrcamentoAvaliacao.scss";

import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const TIPOS_MEDICAMENTOS = [
  "Ampola",
  "Caixa",
  "Cápsula",
  "Comprimido",
  "Frasco",
  "Pacote",
  "Pomada",
  "Solução",
  "Tubo",
  "Xarope",
];

function ElementoPrescricao(props) {
  const [medicamento, setMedicamento] = useState(props.medicamento);
  const [quantidade, setQuantidade] = useState(props.qtdd);
  const [posologia, setPosologia] = useState(props.posologia);
  const [duracao, setDuracao] = useState(props.duracao);
  const [tipo, setTipo] = useState(props.tipo);

  useEffect(() => {
    setMedicamento(props.medicamento ? props.medicamento : null);
    setQuantidade(props.qtdd);
    setDuracao(props.duracao);
    setTipo(props.tipo);
    setPosologia(props.posologia);
  }, [
    props.medicamento,
    props.posologia,
    props.qtdd,
    props.duracao,
    props.tipo,
  ]);

  const medicamentoChangeHandler = (e, value) => {
    setMedicamento(value);
    props.medicamentoChangeHandler(props.id, value);
  };

  const duracaoChangeHandler = (e) => {
    setDuracao(e.target.value);
    props.duracaoChangeHandler(props.id, e.target.value);
  };

  const posologiaChangeHandler = (e) => {
    setPosologia(e.target.value);
    props.posologiaChangeHandler(props.id, e.target.value);
  };

  const qtddChangeHandler = (e) => {
    const value = e.target.value;

    // Only update if the input is a number or empty
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setQuantidade(value);
      props.qtddChangeHandler(props.id, value);
    }
  };

  const tipoChangeHandler = (e) => {
    setTipo(e.target.value);
    props.tipoChangeHandler(props.id, e.target.value);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        id="medicamentos"
        options={props.medicamentos}
        style={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Medicamento" />}
        onChange={medicamentoChangeHandler}
        onInputChange={medicamentoChangeHandler}
        value={medicamento}
      />

      <TextField
        id="duracao"
        label="Duração"
        variant="outlined"
        style={{ width: "100%" }}
        onChange={duracaoChangeHandler}
        value={duracao}
      />

      <TextField
        id="qtdd"
        label="Quantidade"
        variant="outlined"
        style={{ width: "100%" }}
        onChange={qtddChangeHandler}
        value={quantidade}
      />

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-controlled-open-select-label">Tipo</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tipo}
          label="Tipo"
          onChange={tipoChangeHandler}
        >
          {TIPOS_MEDICAMENTOS.map((t, index) => (
            <MenuItem value={t} key={index}>
              {t}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        id="posologia"
        label="Posologia"
        variant="outlined"
        style={{ width: "100%" }}
        onChange={posologiaChangeHandler}
        value={posologia}
      />

      {props.id !== "1" && (
        <Tooltip title="Remover">
          <button
            className="btn-acoes-avaliacao btn-delete"
            onClick={() => props.removeService(props.id)}
          >
            <DeleteIcon className="mdi mdi-delete" />
            <DeleteForeverIcon className="mdi mdi-delete-empty" />
          </button>
        </Tooltip>
      )}
    </>
  );
}

export default ElementoPrescricao;
