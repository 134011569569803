import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { AuthContext } from "../../../../../shared/context/auth-context";
//MUI
import { DataGrid } from "@mui/x-data-grid";
import dataGridData from "./components/datagridData";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
/////////////////////////////////////////////////////
import ModalReceber from "./components/ModalReceber";
import ModalProcedimentos from "./components/ModalProcedimentos/ModalProcedimentos";
import ModalVerReceita from "./components/ModalVerPagamento/ModalVerReceita";
import ModalMotivoDve from "./components/ModalMotivoDve/ModalMotivoDve";
import "./Debitos.scss";
import { useLoading } from "../../../../../shared/context/LoadingContext";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";
import toast from "react-hot-toast";
import { ClinicaContext } from "../../../../../shared/context/clinica-context";
import ModalEditarDesconto from "./components/ModalEditarDesconto/ModalEditarDesconto";

//Mui button dropdown
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function Debitos({
  clienteId,
  sendRequest,
  nomeCliente,
  dadosFatura,
  clienteData,
  dadosRecibo,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [totalRecebido, setTotalRecebido] = useState(0);
  const [totalReceber, setTotalReceber] = useState(0);
  const [rows, setRows] = useState([]);
  const [contaCorrente, setContaCorrente] = useState();
  const [valor, setValor] = useState(0);
  const [idParcela, setIdParcela] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [mostrarRecebidos, setMostrarRecebidos] = useState(true);
  const [dadosClinica, setDadosClinica] = useState();
  const [dadosModalMotivoDve, setdadosModalMotivoDve] = useState({
    open: false,
  });

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [contaCorrenteResponse, clinicaResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/orcamento/cliente/${clienteId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        setContaCorrente(contaCorrenteResponse.contaCorrente);
        setDadosClinica(clinicaResponse.clinica);
      } catch (err) {
        toast.error(
          "Erro ao buscar dados da conta corrente. Por favor, tente novamente."
        );
        console.error("Erro ao buscar dados da conta corrente:", err);
        // Adicione aqui um tratamento de erro adequado, como exibir uma mensagem para o usuário
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [clienteId, sendRequest, clinica, auth.token]);

  useEffect(() => {
    if (contaCorrente && contaCorrente.contas) {
      const contasNaoLiquidadas = contaCorrente.contas;

      let recebido = 0;
      let porReceber = 0;
      let dataParaDataGrid = [];
      contasNaoLiquidadas.forEach((conta) => {
        if (conta.aprovado && conta.orcamento.ativo) {
          conta.parcelas.forEach((parcela) => {
            if (!mostrarRecebidos && parcela.parcelaPaga) return;
            recebido += parcela.quantiaPaga;
            porReceber += parcela.quantiaPorPagar;
            dataParaDataGrid.push({
              orcamento: conta.orcamento,
              id: parcela.id,
              contaId: conta._id,
              dataLimitePagamento: parcela.dataLimitePagamento,
              dataPagamento: parcela.dataPagamento,
              quantiaPorPagar: parcela.quantiaPorPagar,
              quantiaPaga: parcela.quantiaPaga,
              metodoPagamento: parcela.metodoPagamento,
              entrada: parcela.entrada,
              nrParcela: parcela.nrParcela,
              parcelaPaga: parcela.parcelaPaga,
              documentoEletronico: parcela.documentoEletronico,
              estado: parcela.parcelaPaga
                ? "Pago"
                : moment()
                    .startOf("day")
                    .isAfter(moment(parcela.dataLimitePagamento).startOf("day"))
                ? "Em atraso"
                : "",
              nrRecibo: parcela.nrRecibo,
              cancelamentosDocumentosEletronicos:
                parcela.cancelamentosDocumentosEletronicos,
            });
          });
        }
      });

      dataParaDataGrid = dataParaDataGrid.sort(
        (a, b) =>
          new Date(a.dataLimitePagamento) - new Date(b.dataLimitePagamento)
      );
      setTotalRecebido(recebido.toLocaleString("pt-BR"));
      setTotalReceber(porReceber.toLocaleString("pt-BR"));
      setRows(dataGridData(dataParaDataGrid, nomeCliente));
    }
  }, [contaCorrente, nomeCliente, mostrarRecebidos]);

  //Mui Button adicionar
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);
  const handleClickDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  //A funcao verifica o nif e compara com o nome retornado para ver se é identico
  const checkNifError = async (nif, nome, nomePais) => {
    let erro;
    if (nomePais !== "CABO VERDE") {
      erro = false;
    } else {
      const formData = new FormData();
      nif && formData.append("nif", nif);
      startLoading();
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        if (response.payload.length === 0 || response.payload.length > 1) {
          erro = true;
        } else if (response.payload.length === 1) {
          if (response.payload[0].Name.toLowerCase() === nome.toLowerCase())
            erro = false;
          else erro = true;
        }
      } catch (error) {
        toast.error(
          "Occorreu na busca pelo nif do cliente. Por favor tente novamente"
        );
        console.error("error", error);
      } finally {
        stopLoading();
      }
    }
    return erro;
  };

  const handleOpenModalReceber = (idParcela, valorParcela) => {
    setOpenModal("receber");
    setValor(valorParcela);
    setIdParcela(idParcela);
  };

  const updateContaCorrente = (
    novaContaCorrente,
    dadosEmicaoDocumento,
    tipoDocumento
  ) => {
    setContaCorrente({ ...novaContaCorrente });

    if (dadosEmicaoDocumento) {
      const contaFiltrada = novaContaCorrente.contas.filter(
        (c) => c._id === dadosEmicaoDocumento.Idconta
      )[0];

      const parcelaFiltrada = contaFiltrada.parcelas.filter(
        (p) => p._id === dadosEmicaoDocumento.IdParcela
      )[0];

      if (tipoDocumento === "FRE") {
        emitirDocumentoFiscal("FRE", parcelaFiltrada);
      } else {
        beforeGerarReciboHandler(
          dadosEmicaoDocumento.IdParcela,
          dadosEmicaoDocumento.Idconta
        );
      }
    }
  };

  const handleOpenModalProcedimentos = (orcamento) => {
    setModalData(orcamento);
    setOpenModal("procedimentos");
  };

  const handleCloseModal = () => {
    setOpenModal("");
    setdadosModalMotivoDve({ open: false });
  };

  const beforeCancelarRecebimento = (id, idConta) => {
    const copyContaCorrente = { ...contaCorrente };
    const filteredConta = copyContaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];
    const oldParcela = filteredConta.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    if (oldParcela.documentoEletronico) {
      //Codigo para obter o motivo
      setdadosModalMotivoDve({ open: true, id, idConta });
    } else {
      handleCancelarRecebimento(id, idConta);
    }
  };

  const handleCancelarRecebimento = async (id, idConta, motivoDve) => {
    startLoading();
    try {
      const copyContaCorrente = { ...contaCorrente };
      const filteredConta = copyContaCorrente.contas.filter(
        (conta) => conta.id === idConta
      )[0];
      const oldParcela = filteredConta.parcelas.filter(
        (parcela) => parcela.id === id
      )[0];

      let respostaDVE;

      if (oldParcela.documentoEletronico) {
        if (!contaCorrente.cliente.nif) {
          toast.error("O cliente não possui um nif");
        } else if (!contaCorrente.cliente.contacto) {
          toast.error("O cliente não possui um número de telefone");
        } else if (!contaCorrente.cliente.endereco) {
          toast.error("O cliente não possui um endereço");
        } else {
          const formData = new FormData();
          formData.append("clinicaId", clinica.clinica._id);
          formData.append("nomeCliente", contaCorrente.cliente.nome);
          formData.append("nifCliente", contaCorrente.cliente.nif);
          formData.append("valor", oldParcela.quantiaPaga);
          formData.append("descricaoPagamento", "Tratamento dentário");
          formData.append("contactoCliente", contaCorrente.cliente.contacto);
          formData.append("moradaCliente", contaCorrente.cliente.endereco);
          formData.append(
            "codigoPaisCliente",
            contaCorrente.cliente.pais.codigo
          );
          formData.append("metodoPagamento", oldParcela.metodoPagamento);
          formData.append("tipoDocEletronico", "DVE");
          formData.append("IssueReasonCode", JSON.stringify(motivoDve));
          formData.append("usuarioEmissor", auth.userId);
          formData.append(
            "IUDCancelar",
            oldParcela.documentoEletronico.entryName.replace(/\.xml$/, "")
          );
          formData.append("contaCorrente", contaCorrente._id);
          formData.append("conta", filteredConta._id);
          formData.append("parcela", oldParcela._id);
          formData.append("online", true);

          try {
            respostaDVE = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
            toast.error(
              "Ocorreu um erro no cancelamento do recebimento - Emissão de doc Eletrónico. Por favor, tente novamente"
            );
          }
        }
      }

      let newParcela;

      //Se o envio do DVE for bem sucedido ou se não existia FRE, a parcela fica atualizada como não paga
      if (
        (respostaDVE?.respostaEfatura?.responses[0]?.succeeded &&
          oldParcela.documentoEletronico) ||
        !oldParcela.documentoEletronico
      ) {
        newParcela = {
          id: oldParcela.id,
          _id: oldParcela.id,
          dataLimitePagamento: oldParcela.dataLimitePagamento,
          parcelaPaga: false,
          quantiaPaga: 0,
          quantiaPorPagar: oldParcela.quantiaPorPagar + oldParcela.quantiaPaga,
        };
      } else {
        newParcela = { ...oldParcela };
      }

      //Atualizando os campos que têm key value pairs de cancelamentos caso havia documento eletronico na parcela antiga
      if (oldParcela.documentoEletronico) {
        if (oldParcela.cancelamentosDocumentosEletronicos) {
          newParcela.cancelamentosDocumentosEletronicos = [
            ...oldParcela.cancelamentosDocumentosEletronicos,
            {
              documento: oldParcela.documentoEletronico._id,
              DVE: respostaDVE.respostaDocumentoEletronico._id,
            },
          ];
        } else {
          newParcela.cancelamentosDocumentosEletronicos = [
            {
              documento: oldParcela.documentoEletronico._id,
              DVE: respostaDVE.respostaDocumentoEletronico._id,
            },
          ];
        }
      }

      if (oldParcela.nrParcela) newParcela.nrParcela = oldParcela.nrParcela;
      if (oldParcela.entrada) newParcela.entrada = oldParcela.entrada;

      const newParcelas = filteredConta.parcelas.map((parcela) => {
        if (parcela.id === newParcela.id) {
          return newParcela;
        } else {
          return parcela;
        }
      });

      filteredConta.parcelas = newParcelas;

      //A conta fica liquidada a false caso houver sucesso no envio do documento
      //Que resulta no posteriro cancelamento do doc eletronico
      if (
        (respostaDVE?.respostaEfatura?.responses[0]?.succeeded &&
          oldParcela.documentoEletronico) ||
        !oldParcela.documentoEletronico
      ) {
        filteredConta.contaLiquidada = false;
      }

      const nrParcelasPagas = filteredConta.parcelas.filter(
        (parcela) => parcela.parcelaPaga
      ).length;

      //Aqui devo fazer o update independentemente se houve ou não erro pois se houve erro no envio de dfe, ainda assim necessito guardar o doc
      //eletronico com erro na parcela
      try {
        const formData = new FormData();
        formData.append("contas", JSON.stringify(contaCorrente.contas));

        const contaCorrenteRetornada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setContaCorrente(contaCorrenteRetornada.contaCorrente);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro no cancelamento do recebimento - Atualização da conta corrente. Por favor tente novamente."
        );
      }
      /////////////////////////////////////////////////////

      //Atualizar o plano de tratamento para que este não possa ser apagado
      //Caso essa for a primeria parcela a ser paga. Se não, isso quer dizer que
      //O campo canDelete já está a true e nao necessita ser atualizado
      if (nrParcelasPagas === 0) {
        let planoTratamento;
        try {
          planoTratamento = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/orcamento/${filteredConta.orcamento.id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro no cancelamento do recebimento - get plano de tratamento. Por favor tente novamente."
          );
        }

        const formData = new FormData();
        formData.append("canDelete", true);
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/candelete/${planoTratamento.planosTratamento[0].id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro no cancelamento do recebimento - can delete plano de tratamento. Por favor tente novamente."
          );
        }
      }

      if (respostaDVE?.respostaEfatura?.responses[0]?.succeeded) {
        toast.success(
          `Cancelamento efetuado! ${
            respostaDVE?.respostaEfatura?.responses[0]?.succeeded
              ? " DVE enviado com sucesso!"
              : ""
          } `
        );
      } else if (
        respostaDVE?.respostaEfatura?.responses[0]?.succeeded === false
      ) {
        toast.error(
          `Erro ao efetuar cancelamento. DVE com erro: ${respostaDVE?.respostaEfatura?.responses[0]?.messages[0].description}`
        );
      } else {
        toast.success(`Cancelamento efetuado com Sucesso!`);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const verReceitaHandler = (id, idConta) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];
    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    const dadosReceita = {
      metodoPagamento: parcelaFiltrada.metodoPagamento,
      valorPago: parcelaFiltrada.quantiaPaga,
      dataRecebimento: parcelaFiltrada.dataPagamento,
      observacao: parcelaFiltrada.observacao,
      caixa: parcelaFiltrada.caixa,
    };

    if (parcelaFiltrada.cartao) dadosReceita.cartao = parcelaFiltrada.cartao;
    if (parcelaFiltrada.metodoPagamento === "Cheque") {
      dadosReceita.nrCheque = parcelaFiltrada.nrCheque;
      dadosReceita.dataCheque = parcelaFiltrada.dataCheque;
      dadosReceita.contaCliente = parcelaFiltrada.nrContaCliente;
      dadosReceita.banco = parcelaFiltrada.banco;
    }

    setModalData(dadosReceita);
    setOpenModal("verReceita");
  };

  const gerarPdfFaturaEletronicaHandler = async (id, idConta) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];
    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    dadosFatura({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: parcelaFiltrada.quantiaPaga,
      nrDocumento: parcelaFiltrada.documentoEletronico.nrDocumento,
      dataDocumento: parcelaFiltrada.documentoEletronico.authorizedDateTime,
      medico_responsavel: contaFiltrada.orcamento.medico_responsavel,
      designacao: "Tratamento Dentário",
      metodoPagamento: parcelaFiltrada.metodoPagamento,
      entryName: parcelaFiltrada.documentoEletronico.entryName,
      serie: parcelaFiltrada.documentoEletronico.serie,
      tipoDocumento: parcelaFiltrada.documentoEletronico.tipoDocumento,
      usuarioEmissor: parcelaFiltrada.documentoEletronico.usuarioEmissor?.name,
    });
  };

  const beforeGerarReciboHandler = async (id, idConta) => {
    startLoading();
    try {
      const contaFiltrada = contaCorrente.contas.filter(
        (conta) => conta._id === idConta
      )[0];

      const parcelaFiltrada = contaFiltrada.parcelas.filter(
        (parcela) => parcela._id === id
      )[0];

      let nrRecibo = 1;
      if (parcelaFiltrada.nrRecibo) {
        nrRecibo = parcelaFiltrada.nrRecibo;
        gerarPdfReciboHandler(parcelaFiltrada, nrRecibo);
      } else {
        try {
          const nrDocumentos = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Erro na pesquisa da numeração de documentos eletrónicos. Por favor tente novamente."
          );
        }

        //Atualizar o numero de recibo
        try {
          const formData = new FormData();
          formData.append("valor", nrRecibo);
          formData.append("tipoDocumento", "recibo");
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Erro na atualização da numeração dos documentos eletrónicos. Por favor tente novamente."
          );
        }

        try {
          const novasContas = contaCorrente.contas;

          novasContas.forEach((c) => {
            c.parcelas.forEach((p) => {
              if (p._id === parcelaFiltrada._id) p.nrRecibo = nrRecibo;
            });
          });

          const formData = new FormData();
          formData.append("contas", JSON.stringify(novasContas));
          const contaCorrenteRetornada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          setContaCorrente(contaCorrenteRetornada.contaCorrente);
        } catch (err) {
          console.error(
            "Erro na atualização da conta corrente. Por favor tente novamente.",
            err
          );
        }

        gerarPdfReciboHandler(parcelaFiltrada, nrRecibo);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const gerarPdfReciboHandler = async (parcelaFiltrada, nrRecibo) => {
    dadosRecibo({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: parcelaFiltrada.quantiaPaga,
      nrRecibo: nrRecibo,
    });
  };

  const emitirDocumentoFiscal = async (tipoDocumento, parc) => {
    startLoading();
    try {
      setOpenModal("");
      if (!contaCorrente.cliente.nif && tipoDocumento !== "TVE") {
        toast.error("O cliente não possui um nif");
      } else if (!contaCorrente.cliente.contacto) {
        toast.error("O cliente não possui um número de telefone");
      } else if (!contaCorrente.cliente.endereco) {
        toast.error("O cliente não possui um endereço");
      } else if (!contaCorrente.cliente.pais) {
        toast.error("O cliente não possui um pais");
      } else if (
        tipoDocumento === "TVE"
          ? false
          : await checkNifError(
              contaCorrente.cliente.nif,
              contaCorrente.cliente.nome,
              contaCorrente.cliente.pais.nome
            )
      ) {
        toast.error("nif incomaptivel com o nome do cliente");
      } else {
        let parcelaFiltrada = parc;
        let contaFiltrada;
        //Pode ser enviado o id da parcela ou a propria parcela.
        //Se for enviado o id da parcela, ela necessita ser filtrada
        if (parc._id) {
          contaCorrente.contas.forEach((conta) => {
            conta.parcelas.forEach((p) => {
              if (p._id === parc._id) contaFiltrada = conta;
            });
          });
        } else {
          contaCorrente.contas.forEach((conta) => {
            conta.parcelas.forEach((p) => {
              if (p._id === parc) {
                contaFiltrada = conta;
                parcelaFiltrada = p;
              }
            });
          });
        }

        const formData = new FormData();
        formData.append("clinicaId", clinica.clinica._id);
        if (tipoDocumento !== "TVE") {
          formData.append("nomeCliente", contaCorrente.cliente.nome);
          formData.append("nifCliente", contaCorrente.cliente.nif);
          formData.append("contactoCliente", contaCorrente.cliente.contacto);
          formData.append(
            "codigoPaisCliente",
            contaCorrente.cliente.pais.codigo
          );
          formData.append("moradaCliente", contaCorrente.cliente.endereco);
        }
        formData.append("valor", parcelaFiltrada.quantiaPaga);
        formData.append("descricaoPagamento", "Tratamento dentário");
        formData.append("metodoPagamento", parcelaFiltrada.metodoPagamento);
        formData.append("tipoDocEletronico", tipoDocumento);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("contaCorrente", contaCorrente._id);
        formData.append("conta", contaFiltrada._id);
        formData.append("parcela", parcelaFiltrada._id);
        formData.append("online", true);
        if (tipoDocumento === "DVE") {
          formData.append(
            "IUDCancelar",
            parcelaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
          );
        }

        let resposta;
        try {
          resposta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Erro na emissao do documento eletrónico. Por favor tente novamente."
          );
        }

        if (tipoDocumento === "DVE") {
          const formData2 = new FormData();
          const novasContas = [...contaCorrente.contas];
          contaCorrente.contas.forEach((c) => {
            c.parcelas.forEach((p) => {
              if (p._id === parcelaFiltrada._id) {
                if (p.cancelamentosDocumentosEletronicos) {
                  p.cancelamentosDocumentosEletronicos.push({
                    DVE: resposta.respostaDocumentoEletronico._id,
                    documento: parcelaFiltrada.documentoEletronico._id,
                  });
                } else {
                  p.cancelamentosDocumentosEletronicos = [
                    {
                      DVE: resposta.respostaDocumentoEletronico._id,
                      documento: parcelaFiltrada.documentoEletronico._id,
                    },
                  ];
                }
                p.documentoEletronico = null;
              }
            });
          });
          formData2.append("contas", JSON.stringify(novasContas));
          let novaContaCorrente;
          try {
            novaContaCorrente = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente._id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
            setContaCorrente(novaContaCorrente.contaCorrente);
          } catch (err) {
            console.error("err", err);
            toast.error(
              "Erro ao atualizar a conta corrente. Por favor tente novamente."
            );
          }
        } else {
          // Atualizar o documento eletronico na parcela na base de dados
          const novasContas = contaCorrente.contas;
          novasContas.forEach((c) => {
            c.parcelas.forEach((p) => {
              if (p.id === parcelaFiltrada.id)
                p.documentoEletronico =
                  resposta.respostaDocumentoEletronico._id;
            });
          });

          const formData2 = new FormData();
          formData2.append("contas", JSON.stringify(novasContas));
          let novaContaCorrente;
          try {
            novaContaCorrente = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente.id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
            toast.error(
              "Erro ao atualizar a conta corrente. Por favor tente novamente."
            );
          }
          setContaCorrente(novaContaCorrente.contaCorrente);
        }
        if (resposta.respostaEfatura?.responses[0]?.succeeded) {
          toast.success(`${tipoDocumento} emitido com sucesso`);
        } else {
          toast.error(
            "Erro ao emitir" +
              tipoDocumento +
              ". " +
              resposta.respostaEfatura.responses[0].messages[0].description
          );
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleBeforeEmitirDocEletronico = (id, idConta, tipoDocumento) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];

    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    emitirDocumentoFiscal(tipoDocumento, parcelaFiltrada);
  };

  const handleOpenModalEditarDesconto = (conta) => {
    setModalData(conta);
    setOpenModal("editarDesconto");
  };

  const actionColumn = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <span className={params.row.parcelaPaga ? "spanCellPago" : ""}>
              {params.row.dataPagamento
                ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 6,
      renderCell: (params) => {
        return (
          <div className="cellDebitoNome">
            <span className={params.row.parcelaPaga ? "spanCellPago" : ""}>
              {params.row.nome}
            </span>
            {params.row.nrParcela && (
              <Tooltip title={`Parcelado em ${params.row.nrParcela[2]} vezes`}>
                <IconButton>
                  <span className="nrParcela">{params.row.nrParcela}</span>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Ver lista de procedimentos">
              <IconButton
                onClick={handleOpenModalProcedimentos.bind(
                  null,
                  params.row.orcamento
                )}
              >
                <ErrorIcon className="muiIconVerOrcamento" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="cellDebitoEstado">
            <span
              className={`debitos__container__estado ${
                params.row.estado === "Em atraso"
                  ? "debitos__container__estado--atraso"
                  : params.row.estado === "Pago"
                  ? "debitos__container__estado--pago"
                  : ""
              }`}
            >
              {params.row.metodoPagamento
                ? params.row.metodoPagamento
                : params.row.estado}
            </span>
          </div>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <span
              className={`debitos__container__valor ${
                params.row.estado === "Em atraso"
                  ? "debitos__container__valor--atraso"
                  : params.row.estado === "Pago"
                  ? "debitos__container__valor--pago"
                  : ""
              }`}
            >
              {params.row.valor}
            </span>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellDebitoStatus">
            {params.row.estado === "Pago" && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip title="Parcela Paga">
                  <CheckCircleIcon />
                </Tooltip>
              </span>
            )}

            {params.row.documentoEletronico?.succeeded === true && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip
                  title={`${params.row.documentoEletronico?.tipoDocumento} enviado`}
                >
                  <ArticleOutlinedIcon />
                </Tooltip>
              </span>
            )}

            {params.row.documentoEletronico?.succeeded === false && (
              <span className="debitos__container__receber debitos__container__receber--atraso">
                <Tooltip
                  title={`${params.row.documentoEletronico?.tipoDocumento} com erro -> ${params.row.documentoEletronico?.messages[0]?.description}`}
                >
                  <ArticleOutlinedIcon />
                </Tooltip>
              </span>
            )}

            {params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
              params.row.cancelamentosDocumentosEletronicos[
                params.row.cancelamentosDocumentosEletronicos?.length - 1
              ]?.DVE?.succeeded === true && (
                <span className="debitos__container__receber debitos__container__receber--pago">
                  <Tooltip
                    title={
                      <React.Fragment>
                        {params.row.cancelamentosDocumentosEletronicos?.length +
                          "cancelamentos efetuados."}
                        <br />
                        {params.row.cancelamentosDocumentosEletronicos.map(
                          (c, index) => (
                            <React.Fragment key={index}>
                              {index > 0 && <br />}
                              {c.documento.entryName.split(".")[0]}
                            </React.Fragment>
                          )
                        )}
                      </React.Fragment>
                    }
                  >
                    <ArticleOutlinedIcon />
                  </Tooltip>
                </span>
              )}

            {params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
              params.row.cancelamentosDocumentosEletronicos[
                params.row.cancelamentosDocumentosEletronicos?.length - 1
              ]?.DVE?.succeeded === false && (
                <span className="debitos__container__receber debitos__container__receber--atraso">
                  <Tooltip
                    title={
                      <React.Fragment>
                        DVE com erro:
                        <br />
                        {params.row.cancelamentosDocumentosEletronicos[
                          params.row.cancelamentosDocumentosEletronicos.length -
                            1
                        ].DVE.messages.map((m, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && (
                              <>
                                <br />
                                <br />
                              </>
                            )}
                            {m.description}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    }
                  >
                    <ArticleOutlinedIcon />
                  </Tooltip>
                </span>
              )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => {
        let content;
        if (params.row.parcelaPaga) {
          content = (
            <>
              <div
                className="popOverMenu--option"
                onClick={verReceitaHandler.bind(
                  null,
                  params.row.id,
                  params.row.contaId
                )}
              >
                <span>Ver</span>
                <RemoveRedEyeIcon className="popOverMenu--option__icon" />
              </div>

              {/*PDF FRE - apenas se o documento fiscal enviado for bem sucedido  */}
              {params.row.documentoEletronico?.succeeded && (
                <div
                  className="popOverMenu--option"
                  onClick={gerarPdfFaturaEletronicaHandler.bind(
                    null,
                    params.row.id,
                    params.row.contaId
                  )}
                >
                  <span>
                    Gerar PDF {params.row.documentoEletronico.tipoDocumento}
                  </span>
                  <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {/* EMITIR FRE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
              {!params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.DVE?.succeeded === true)) && (
                  <Popconfirm
                    title="Fatura Recibo Eletrónico"
                    description={`Pretende emitir a FRE?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={handleBeforeEmitirDocEletronico.bind(
                      null,
                      params.row.id,
                      params.row.contaId,
                      "FRE"
                    )}
                  >
                    <div className="popOverMenu--option">
                      Emitir FRE
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* EMITIR TVE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
              {!params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.DVE?.succeeded === true)) &&
                (!clienteData.nif ||
                  clienteData.nif === "000000000" ||
                  clienteData.nif === "999999999") && (
                  <Popconfirm
                    title="Talão de Venda Eletrónico"
                    description={`Pretende emitir a TVE?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={handleBeforeEmitirDocEletronico.bind(
                      null,
                      params.row.id,
                      params.row.contaId,
                      "TVE"
                    )}
                  >
                    <div className="popOverMenu--option">
                      Emitir TVE
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* RECIBO INTERNO - Parcela paga (no If lá acima), com dinheiro, orcamento particular, sem documentoEletronico com ou sem erro e sem cancelamento com erro */}
              {params.row.orcamento.beneficio === "Particular" &&
                params.row.metodoPagamento === "Dinheiro" &&
                !params.row.documentoEletronico &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.DVE?.succeeded === false)) && (
                  <Popconfirm
                    title="Recibo Interno"
                    description={`Pretende emitir um recibo Interno?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={beforeGerarReciboHandler.bind(
                      null,
                      params.row.id,
                      params.row.contaId
                    )}
                  >
                    <div className="popOverMenu--option">
                      {params.row.nrRecibo ? "Ver " : "Emitir "} Recibo Interno
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* EMITIR FRE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
              {/* {params.row.documentoEletronico?.succeeded &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.DVE?.succeeded === true)) && (
                  <Popconfirm
                    title="Nota de Devolução - DVE"
                    description={`Pretende cancelar a DFE com essa DVE?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocumentoFiscal.bind(
                      null,
                      "DVE",
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir DVE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )} */}

              {/* Exatamento como o anterior. Caso exista documentos eletronicos ou cancelamentos, são verificados como o anterior */}
              {((!params.row.documentoEletronico &&
                (!params.row.cancelamentosDocumentosEletronicos ||
                  params.row.cancelamentosDocumentosEletronicos?.length ===
                    0)) ||
                ((params.row.documentoEletronico?.succeeded ||
                  !params.row.documentoEletronico) &&
                  (params.row.cancelamentosDocumentosEletronicos?.length ===
                    0 ||
                    (params.row.cancelamentosDocumentosEletronicos?.length >
                      0 &&
                      params.row.cancelamentosDocumentosEletronicos[
                        params.row.cancelamentosDocumentosEletronicos?.length -
                          1
                      ]?.DVE?.succeeded === true)))) && (
                <Popconfirm
                  title="Cancelar Recebimento"
                  description={`Pretende cancelar o recebimento de ${
                    params.row.nome
                  } ${
                    params.row.documentoEletronico?.tipoDocumento === "FRE"
                      ? "e enviar uma DVE para o anulação da FRE?"
                      : "?"
                  }`}
                  icon={<ErrorIcon style={{ color: "red" }} />}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={beforeCancelarRecebimento.bind(
                    null,
                    params.row.id,
                    params.row.contaId
                  )}
                >
                  <div className="popOverMenu--option">
                    <span>Cancelar recebimento</span>
                    <HighlightOffIcon className="popOverMenu--option__icon" />
                  </div>
                </Popconfirm>
              )}
            </>
          );
        }

        return (
          <>
            {params.row.estado !== "Pago" &&
              auth.perm.includes("receb-deb") && (
                <span
                  className={`debitos__container__receber ${
                    params.row.estado === "Em atraso" &&
                    "debitos__container__receber--atraso"
                  }`}
                  onClick={handleOpenModalReceber.bind(
                    null,
                    params.row.id,
                    params.row.valor
                  )}
                >
                  Receber
                </span>
              )}

            {content && (
              <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                  <div className="dots__menu">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </Popover>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="debitos__container caixa">
        <div className="debitos__container__cabecalho">
          <div
            className="checkbox-wrapper-51"
            id="debitos__container__cabecalho__mostrar-recebidos"
          >
            <input
              type="checkbox"
              id="mostrarRecebidos"
              onChange={(e) => setMostrarRecebidos(e.target.checked)}
              checked={mostrarRecebidos}
            />
            <label htmlFor="mostrarRecebidos" className="toggle">
              <span>
                <svg width="10px" height="10px" viewBox="0 0 10 10">
                  <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                </svg>
              </span>
            </label>
            <span className="checkbox-text">Mostrar Recebidos</span>
          </div>

          <div className="debitos__container__cabecalho__totais">
            {mostrarRecebidos && (
              <div className="debitos__container__cabecalho__totais--recebido">
                <span>Total Recebido</span>
                <span>{totalRecebido}$00</span>
              </div>
            )}
            <div className="debitos__container__cabecalho__totais--por-receber">
              <span>Total a receber</span>
              <span>{totalReceber}$00</span>
            </div>
          </div>

          {/* Apenas mostrar o botão se existe uma conta não liquidada na conta corrente */}
          {contaCorrente &&
            contaCorrente.contas.some((c) => !c.contaLiquidada) &&
            auth.perm.includes("u-desc") && (
              <div className="btn-aplicarDesconto__container">
                <Button
                  variant="contained"
                  color="success"
                  id="basic-button"
                  aria-controls={openDropdown ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropdown ? "true" : undefined}
                  onClick={handleClickDropdown}
                  style={{ padding: "5px 10px", fontSize: "13px" }}
                >
                  Editar Desconto
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropdown}
                  onClose={handleCloseDropdown}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {contaCorrente.contas
                    .filter((c) => !c.contaLiquidada && c.orcamento.ativo)
                    .map((c, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleOpenModalEditarDesconto(c)}
                        style={{ padding: "10px", fontSize: "14px" }}
                      >
                        {`Orcamento - ${c.orcamento.codigo}`}
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            )}
        </div>
        {rows && (
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={actionColumn}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        )}
        {openModal === "receber" && (
          <ModalReceber
            open={openModal === "receber"}
            handleCloseModalReceber={handleCloseModal}
            nomeCliente={nomeCliente}
            valorAPagar={parseFloat(valor.slice(0, -3).replace(".", ""))}
            contaCorr={contaCorrente}
            idParcela={idParcela}
            sendRequest={sendRequest}
            updateContaCorrente={updateContaCorrente}
          />
        )}
        {openModal === "procedimentos" && (
          <ModalProcedimentos
            open={openModal}
            handleCloseModalProcedimentos={handleCloseModal}
            modalProcedimentosData={modalData}
          />
        )}
        {openModal === "verReceita" && (
          <ModalVerReceita
            open={openModal === "verReceita"}
            handleCloseModalVerReceita={handleCloseModal}
            dadosDaReceita={modalData}
            nomeCliente={nomeCliente}
          />
        )}
        {openModal === "editarDesconto" && (
          <ModalEditarDesconto
            open={openModal === "editarDesconto"}
            handleCloseModalEditarDesconto={handleCloseModal}
            modalData={modalData}
            updateContaCorrente={updateContaCorrente}
            contaCorrente={contaCorrente}
            auth={auth}
          />
        )}
        {dadosModalMotivoDve.open && (
          <ModalMotivoDve
            open={dadosModalMotivoDve.open}
            handleCloseModal={handleCloseModal}
            modalData={dadosModalMotivoDve}
            handleCancelarRecebimento={handleCancelarRecebimento}
          />
        )}
      </div>
    </>
  );
}

export default Debitos;
