import { useState, useEffect, useContext } from "react";
import Sidebar from "../../shared/components/sidebar/Sidebar";
import Navbar from "../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import "./Stock.scss";

import { AuthContext } from "../../shared/context/auth-context";
import { ClinicaContext } from "../../shared/context/clinica-context";

//MUI
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ModalAdicionarNoStock from "./components/ModalAdicionarNoStock";
import ModalStockRetirada from "./components/ModalStockRetirada";
import ModalStockHistoricoSaidas from "./components/ModalStockHistoricoSaidas";
import ModalStockHistoricoEntradas from "./components/ModalStockHistoricoEntradas";
import ModalEditarStock from "./components/ModalEditarStock";

import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { DataGrid } from "@mui/x-data-grid";
import { useLoading } from "../../shared/context/LoadingContext";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";
import toast from "react-hot-toast";

const columns = [
  { field: "produto", headerName: "Produto", flex: 4 },
  { field: "stock", headerName: "Stock", flex: 1 },
  { field: "qtdIdeal", headerName: "QtdIdeal", flex: 1 },
];

function Stock() {
  const { startLoading, stopLoading } = useLoading();

  const [rows, setRows] = useState([]);
  const [stock, setStock] = useState();
  const [categoriasStock, setCategoriasStock] = useState();
  const [permaStock, setPermaStock] = useState();
  const [openModal, setOpenModal] = useState("");
  const { error, sendRequest, clearError } = useHttpClient();
  const [stockRetirada, setStockRetirada] = useState();
  const [dadosProdutoEditar, setDadosProdutoEditar] = useState();

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [stockResponse, categoriasStockResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        if (
          stockResponse &&
          stockResponse.stock &&
          stockResponse.stock.length > 0
        ) {
          // Process stock data
          const tempRows = processStockData(stockResponse.stock[0].stock);
          setRows(tempRows);
          setPermaStock(stockResponse.stock[0]);
          setStock(stockResponse.stock[0]);
        } else {
          setRows([]);
          setPermaStock(null);
          setStock(null);
        }

        // Set categorias stock
        if (
          categoriasStockResponse &&
          categoriasStockResponse.categoriasStock
        ) {
          setCategoriasStock(categoriasStockResponse.categoriasStock);
        } else {
          setCategoriasStock([]);
        }
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        toast.error(
          "Ocorreu um erro ao carregar os dados. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, clinica.clinica._id, auth.token]);

  // Helper function
  const processStockData = (stockData) => {
    return stockData.map((st) => ({
      id: st.id,
      produto: st.produto.produto,
      produtoRetirado: st.produto.comRetirada,
      stock: st.quantidade,
      qtdIdeal: st.quantidadeIdeal,
      produtoID: st.produto.id,
      categoriaProduto: st.produto.categoriaStock,
    }));
  };

  useEffect(() => {
    if (stock) {
      setRows(processStockData(stock.stock));
    }
  }, [stock]);

  const handleCloseModal = () => {
    setOpenModal("");
  };

  const atualizarStock = (novoStock, mensagem) => {
    setStock(novoStock);
    setPermaStock(novoStock);

    toast.success(mensagem);
  };

  const handleFazerRetirada = (stId) => {
    setStockRetirada(stock.stock.filter((st) => st.id === stId)[0]);
    setOpenModal("stockRetirada");
  };

  const procuraStock = (e) => {
    const result = permaStock.stock.filter((perma) => {
      return perma.produto.produto
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setStock((current) => {
      return {
        ...current,
        stock: result,
      };
    });
  };

  const changeCategoriaStockHandler = (e) => {
    if (e.target.value === "todas") {
      setStock(permaStock);
    } else {
      const result = permaStock.stock.filter((perma) => {
        return perma.produto.categoriaStock === e.target.value;
      });
      setStock((current) => {
        return {
          ...current,
          stock: result,
        };
      });
    }
  };

  const getRowClassName = (params) => {
    if (params.row.qtdIdeal >= params.row.stock) {
      return "stock-row__danger";
    } else {
      return "";
    }
  };

  const handleEditarProdutoStock = (dados) => {
    setDadosProdutoEditar(dados);
    setOpenModal("editar");
  };

  const apagarProdutoDoStock = async (idProduto) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("produtoId", idProduto);

      const resultado = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/removeprodutohistorico/${stock.id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      atualizarStock(resultado.stock, "Produto removido do stock!");
    } catch (err) {
      toast.error("Ocorreu um erro na remoção do produto do stock");
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => {
        const content = (
          <div className="dots__menu__popup">
            {auth.perm.includes("u-st") && (
              <div
                className="popOverMenu--option"
                onClick={handleEditarProdutoStock.bind(null, params.row)}
              >
                <span>Editar</span>
                <EditIcon className="popOverMenu--option__icon" />
              </div>
            )}

            {!params.row.produtoRetirado && auth.perm.includes("d-st") && (
              <Popconfirm
                title="Excluir Produto"
                description={`Excluir ${params.row.produto}  do stock?`}
                icon={<ErrorIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={apagarProdutoDoStock.bind(
                  null,
                  params.row.produtoID
                )}
                // onCancel={}
              >
                <div className="popOverMenu--option">
                  <span>Apagar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );

        return (
          <>
            {auth.perm.includes("c-stR") && params.row.stock > 0 && (
              <span
                onClick={handleFazerRetirada.bind(null, params.row.id)}
                className="btn__retiradas"
              >
                Fazer Retirada
              </span>
            )}

            {auth.perm.includes("c-stR") && params.row.stock === 0 && (
              <span className="btn__esgotado">Esgotado</span>
            )}
            {(auth.perm.includes("u-st") || auth.perm.includes("d-st")) && (
              <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                  <div className="dots__menu">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </Popover>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar title="Stock" icon={Inventory2Icon} />
          <ErrorModal error={error} onClear={clearError} />
          <div className="stock__container caixa">
            <div className="stock__header">
              <div className="stock__header__container1">
                {auth.perm.includes("c-st") && (
                  <Button
                    variant="contained"
                    color="success"
                    className="stock__entrada-btn"
                    onClick={(e) => setOpenModal("adicionar")}
                  >
                    Fazer entrada no stock
                  </Button>
                )}
                {auth.perm.includes("r-st") && (
                  <>
                    <span
                      className="stock__header__historico-btn"
                      onClick={(e) => setOpenModal("historicoRetiradas")}
                    >
                      Histórico de Retiradas
                    </span>

                    <span
                      className="stock__header__historico-btn"
                      onClick={(e) => setOpenModal("historicoEntradas")}
                    >
                      Histórico de Entradas
                    </span>
                  </>
                )}
              </div>

              <div className="stock__header__container2">
                <div className="stock__header__container2__container1">
                  {categoriasStock && (
                    <>
                      <span className="financeiro__filtros__texto">
                        Exibir por Categoria
                      </span>
                      <select
                        onChange={changeCategoriaStockHandler}
                        className="select__filter-categoria"
                      >
                        <option value="todas">Todas Categorias</option>
                        {categoriasStock.map((cat, index) => (
                          <option key={index} value={cat.id}>
                            {cat.categoria}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
                <div className="stock__header__container2__container2 textBoxSearch__container">
                  <input
                    type="text"
                    className="textBoxSearch__input"
                    placeholder="O que está procurando?"
                    onChange={procuraStock}
                  />
                  <SearchIcon className="financeiro__filtros__search-icon" />
                </div>
              </div>
            </div>
            {rows && (
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  autoWidth
                  className="datagrid"
                  rows={rows}
                  columns={columns.concat(actionColumn)}
                  getRowClassName={getRowClassName}
                  autoPageSize
                />
              </div>
            )}
            {openModal === "adicionar" && (
              <ModalAdicionarNoStock
                sendRequest={sendRequest}
                clinicaId={clinica.clinica._id}
                auth={auth}
                handleCloseModalAdicionarStock={handleCloseModal}
                open={openModal === "adicionar"}
                atualizarStock={atualizarStock}
                stock={permaStock}
                categoriasStock={categoriasStock}
              />
            )}

            {openModal === "stockRetirada" && (
              <ModalStockRetirada
                sendRequest={sendRequest}
                handleCloseModalStockRetirada={handleCloseModal}
                open={openModal === "stockRetirada"}
                atualizarStock={atualizarStock}
                stock={permaStock}
                stockRetirada={stockRetirada}
              />
            )}

            {openModal === "historicoRetiradas" && (
              <ModalStockHistoricoSaidas
                sendRequest={sendRequest}
                handleCloseModalStockHistoricoSaidas={handleCloseModal}
                open={openModal === "historicoRetiradas"}
                clinicaId={clinica.clinica._id}
              />
            )}

            {openModal === "historicoEntradas" && (
              <ModalStockHistoricoEntradas
                sendRequest={sendRequest}
                handleCloseModalStockHistoricoEntradas={handleCloseModal}
                open={openModal === "historicoEntradas"}
                clinicaId={clinica.clinica._id}
              />
            )}

            {openModal === "editar" && (
              <ModalEditarStock
                sendRequest={sendRequest}
                handleCloseModalEditar={handleCloseModal}
                dadosProduto={dadosProdutoEditar}
                stock={permaStock}
                atualizarStock={atualizarStock}
                open={openModal === "editar"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Stock;
