import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfListaAtendimentosStyle.scss";
import moment from "moment/moment";

function PdfListaAtendimentos({
  listaAtendimentoData,
  medico,
  showPreviousPage,
  clinica,
  dataLista,
}) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Lista-Atendimentos-${medico}_${moment(new Date()).format(
      "DD-MM-YYYY"
    )}`,
    onAfterPrint: () => {
      showPreviousPage();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  return (
    <>
      <div ref={componentRef} className="pdf-wrapper">
        <div className="pdf-listaAtendimentos-heading">
          <span className="semiDarkText">{clinica}</span>
          <div>
            <span>Lista de Atendimentos para</span>
            <span className="semiDarkText">
              {dataLista
                ? " " + dataLista
                : " " + moment(new Date()).format("DD-MM-YYYY")}
            </span>
          </div>
          <div>
            <span>Médico: </span>
            <span className="semiDarkText">{" " + medico}</span>
          </div>
          <div>
            <span>Nr de Atendimentos: </span>
            <span className="semiDarkText">
              {" " + listaAtendimentoData.length}
            </span>
          </div>
        </div>

        <div className="pdf-listaAtendimentos-table-container">
          <table className="listaAtendimentos-table">
            <tbody>
              <tr>
                <th>Horário</th>
                <th>Cliente</th>
                <th>Contacto</th>
                <th>Procedimento</th>
              </tr>
              {listaAtendimentoData.map((l, index) => (
                <tr key={index}>
                  <td>
                    {moment(l.data_inicio_execucao).format("HH:mm")} -{" "}
                    {moment(l.data_fim_execucao).format("HH:mm")}
                  </td>
                  <td>{l.cliente}</td>
                  <td>{l.contacto ? l.contacto : ""}</td>
                  <td>{l.procedimento}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default PdfListaAtendimentos;
