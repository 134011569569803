import { useEffect, useState, useContext, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import ErrorModal from "../../../../shared/components/UIElements/ErrorModal";
import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { useLoading } from "../../../../shared/context/LoadingContext";
//Antd
import { Popover } from "antd";

//MUI
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import "./Procedimento.scss";
import ModalAdicionarEvolucao from "./ModalAdicionarEvolucao";
import toast from "react-hot-toast";

function Procedimentos({ visualizarPdf, listaPresencaProp }) {
  const { startLoading, stopLoading } = useLoading();

  const [marcacoes, setMarcacoes] = useState();
  const [allMarcacoes, setAllMarcacoes] = useState();
  const [allMarcacoesSpecificDate, setAllMarcacoesSpecificDate] = useState([]);
  const [dataSelectAtendimentosDe, setDataSelectAtendimentosDe] = useState(
    moment().format("DD-MM-YYYY")
  );
  const [nrMarcacoes, setNrMarcacoes] = useState(0);
  const [listaPresenca, setListaPresenca] = useState([]);
  const [dataDatePicker, setDataDatePicker] = useState(moment());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { error, sendRequest, clearError } = useHttpClient();
  const [verAtendidos, setVerAtendidos] = useState(false);
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const [dadosModalEvolucao, setDadosModalEvolucao] = useState({});
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setListaPresenca(listaPresencaProp);
  }, [listaPresencaProp]);

  useEffect(() => {
    const fetchMarcacoes = async () => {
      startLoading();
      try {
        const formData = new FormData();
        formData.append("medico", auth.userId);

        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/marcacoesmedico/${clinica.clinica._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseData2 = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/marcacoesmedico/${clinica.clinica._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const tempAllMarcacoes = [];

        responseData.marcacoes.forEach((mr) => {
          tempAllMarcacoes.push({
            id: mr._id,
            nome: mr.cliente.nome,
            contacto: mr.cliente.contacto,
            data_inicio_execucao: mr.data_inicio_execucao,
            data_fim_execucao: mr.data_fim_execucao,
            idPlano: mr.avaliacao ? null : mr.planoTratamento,
            idCliente: mr.cliente._id,
            categoriaTratamento: mr.categoriaTratamento,
            avaliacao: mr.avaliacao ? true : false,
            atendido: mr.atendido,
            faltou: mr.faltou,
            agendaId: mr.agendaId,
          });
        });

        responseData2.marcacoes.forEach((mr) => {
          tempAllMarcacoes.push({
            id: mr._id,
            nome: mr.cliente.nome,
            contacto: mr.cliente.contacto,
            data_inicio_execucao: mr.data_inicio_execucao,
            data_fim_execucao: mr.data_fim_execucao,
            idCliente: mr.cliente._id,
            categoriaTratamento: mr.categoriaTratamento,
            avaliacao: mr.avaliacao ? true : false,
            atendido: mr.atendido,
            faltou: mr.faltou,
            emTransicao: true,
            agendaId: mr.agendaId,
          });
        });

        const tempMarcacoes = tempAllMarcacoes.filter(
          (mr) =>
            moment(mr.data_inicio_execucao).format("DD-MM-YYYY") ===
            moment().format("DD-MM-YYYY")
        );
        const sortedAllMarcacoes = tempAllMarcacoes.sort((a, b) => {
          return (
            new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
          );
        });

        const sorteMarcacoes = tempMarcacoes.sort((a, b) => {
          return (
            new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
          );
        });

        setAllMarcacoes(sortedAllMarcacoes);
        setNrMarcacoes(sorteMarcacoes.length);
        setMarcacoes(sorteMarcacoes);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro ao carregar os dados. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchMarcacoes();
  }, [sendRequest, clinica, auth]);

  const navigate = useNavigate();

  const verPdf = () => {
    let tempProcedimentos;
    tempProcedimentos = marcacoes.map((mr) => {
      if (mr.avaliacao) {
        return {
          cliente: mr.nome,
          contacto: mr.contacto,
          procedimento: "Avaliação",
          data_inicio_execucao: mr.data_inicio_execucao,
          data_fim_execucao: mr.data_fim_execucao,
        };
      } else {
        return {
          cliente: mr.nome,
          contacto: mr.contacto,
          procedimento: mr.categoriaTratamento.categoria,
          data_inicio_execucao: mr.data_inicio_execucao,
          data_fim_execucao: mr.data_fim_execucao,
        };
      }
    });

    visualizarPdf(tempProcedimentos);
  };

  const handleAvaliar = async (idCliente, marcacaoId, agendaId) => {
    //Aqui marcamos a avaliacao como atendido e depois reencaminhos o medico para
    //criacao da avaliacao na pagina do paciente
    startLoading();
    try {
      const formData = new FormData();

      formData.append("agendaId", agendaId);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/marcaratendido/${marcacaoId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setMarcacoes((prevMarcacoes) =>
        prevMarcacoes.filter((m) => m.id !== marcacaoId)
      );
      navigate(`/avaliacao/new/${idCliente}`);
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro atualizar o agendamento para atendido, na agenda. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const handleAdicionarEvolucao = (row) => {
    setOpenModal(true);
    setDadosModalEvolucao({
      id: row.id,
      nome: row.nome,
      idCliente: row.idCliente,
      categoriaTratamento: row.categoriaTratamento,
      data_inicio_execucao: row.data_inicio_execucao,
      data_fim_execucao: row.data_fim_execucao,
      agendaId: row.agendaId,
    });
  };

  const handleCloseModalEvolucao = () => {
    setDadosModalEvolucao(null);
    setOpenModal(false);
  };

  const handleModalSubmitSuccess = (id) => {
    handleCloseModalEvolucao();
    setMarcacoes((prevMarcacoes) => prevMarcacoes.filter((m) => m.id !== id));
  };

  const marcarFalta = async (marcacaoId) => {
    startLoading();
    try {
      const formData = new FormData();

      let path = "agenda";
      const marcacaoFiltrada = marcacoes.filter((m) => m.id === marcacaoId)[0];
      if (marcacaoFiltrada && marcacaoFiltrada.emTransicao) {
        path = "agendamentosemtransicao";
      }

      formData.append("agendaId", marcacaoFiltrada.agendaId);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/${path}/marcarfalta/${marcacaoId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      if (
        moment(marcacaoFiltrada.data_inicio_execucao).isSame(moment(), "day")
      ) {
        setMarcacoes((prevMarcacoes) =>
          prevMarcacoes.filter((m) => m.id !== marcacaoId)
        );
      } else {
        setMarcacoes((prevMarcacoes) =>
          prevMarcacoes.map((m) => {
            if (m.id === marcacaoId) {
              return { ...m, faltou: true, atendido: false };
            } else {
              return m;
            }
          })
        );

        setAllMarcacoes((prevMarcacoes) =>
          prevMarcacoes.map((m) => {
            if (m.id === marcacaoId) {
              return { ...m, faltou: true, atendido: false };
            } else {
              return m;
            }
          })
        );
      }
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro ao marcar o agendamento com falta, na agenda. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const handleMarcarAtendido = async (marcacaoId) => {
    startLoading();
    try {
      const formData = new FormData();

      let path = "agenda";
      const marcacaoFiltrada = marcacoes.filter((m) => m.id === marcacaoId)[0];

      if (marcacaoFiltrada && marcacaoFiltrada.emTransicao) {
        path = "agendamentosemtransicao";
      }
      formData.append("agendaId", marcacaoFiltrada.agendaId);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/${path}/marcaratendido/${marcacaoId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      if (
        moment(marcacaoFiltrada.data_inicio_execucao).isSame(moment(), "day")
      ) {
        setMarcacoes((prevMarcacoes) =>
          prevMarcacoes.filter((m) => m.id !== marcacaoId)
        );
      } else {
        setMarcacoes((prevMarcacoes) =>
          prevMarcacoes.map((m) => {
            if (m.id === marcacaoId) {
              return { ...m, atendido: true, faltou: false };
            } else {
              return m;
            }
          })
        );

        setAllMarcacoes((prevMarcacoes) =>
          prevMarcacoes.map((m) => {
            if (m.id === marcacaoId) {
              return { ...m, atendido: true, faltou: false };
            } else {
              return m;
            }
          })
        );
      }
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro marcando o agendamento como atendido, na agenda. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const filterMarcacoes = useCallback(
    (data, marcacoesSpecificDate = [...allMarcacoesSpecificDate]) => {
      let tempMarcacoes;
      let temp;
      if (data === "escolher-data") {
        temp = [...marcacoesSpecificDate];
      } else if (data === moment().format("DD-MM-YYYY")) {
        temp = allMarcacoes.filter(
          (mr) => moment(mr.data_inicio_execucao).format("DD-MM-YYYY") === data
        );

        temp.sort((a, b) => {
          return (
            new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
          );
        });
      } else {
        temp = allMarcacoes.filter(
          (mr) => moment(mr.data_inicio_execucao).format("DD-MM-YYYY") === data
        );
      }
      tempMarcacoes = temp.sort((a, b) => {
        return (
          new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
        );
      });

      setMarcacoes(tempMarcacoes);
      setNrMarcacoes(tempMarcacoes.length);
    },
    [allMarcacoes, allMarcacoesSpecificDate]
  );

  const changeSelectAtendimentosDe = useCallback(
    (e) => {
      setDataSelectAtendimentosDe(e.target.value);
      if (e.target.value === "escolher-data") {
        setShowDatePicker(true);
      } else {
        setShowDatePicker(false);
        filterMarcacoes(e.target.value);
      }
    },

    [filterMarcacoes]
  );

  const changeDataDatePicker = async (value) => {
    setDataDatePicker(value);
    startLoading();
    try {
      const formData = new FormData();
      formData.append("medico", auth.userId);
      formData.append("date", value);
      const tempMarcacoes = [];

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/marcacoesmedico/specificdate/${clinica.clinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const responseData2 = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/marcacoesmedico/specificDate/${clinica.clinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      responseData.marcacoes.forEach((mr) => {
        tempMarcacoes.push({
          id: mr._id,
          nome: mr.cliente.nome,
          contacto: mr.cliente.contacto,
          data_inicio_execucao: mr.data_inicio_execucao,
          data_fim_execucao: mr.data_fim_execucao,
          idCliente: mr.cliente._id,
          categoriaTratamento: mr.categoriaTratamento,
          avaliacao: mr.avaliacao ? true : false,
          atendido: mr.atendido,
          faltou: mr.faltou,
          emTransicao: true,
          agendaId: mr.agendaId,
        });
      });

      responseData2.marcacoes.forEach((mr) => {
        tempMarcacoes.push({
          id: mr._id,
          nome: mr.cliente.nome,
          contacto: mr.cliente.contacto,
          data_inicio_execucao: mr.data_inicio_execucao,
          data_fim_execucao: mr.data_fim_execucao,
          idCliente: mr.cliente._id,
          categoriaTratamento: mr.categoriaTratamento,
          avaliacao: mr.avaliacao ? true : false,
          atendido: mr.atendido,
          faltou: mr.faltou,
          emTransicao: true,
          agendaId: mr.agendaId,
        });
      });

      const temp = tempMarcacoes.sort((a, b) => {
        return (
          new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
        );
      });

      setAllMarcacoesSpecificDate(temp);
      setNrMarcacoes(temp.length);

      filterMarcacoes("escolher-data", temp);
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro ao carregar os dados das marcações. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  return (
    <>
      {openModal && dadosModalEvolucao && (
        <ModalAdicionarEvolucao
          handleCloseModalEvolucao={handleCloseModalEvolucao}
          open={openModal}
          dados={dadosModalEvolucao}
          auth={auth}
          sendRequest={sendRequest}
          handleModalSubmitSuccess={handleModalSubmitSuccess}
        />
      )}
      <ErrorModal error={error} onClear={clearError} />

      <div className="marcacoes-secretaria-header-row">
        <div className="box-escolha-de-dia">
          <span className="box-title box-title--atendimentosSecretaria">
            Atendimentos de:{" "}
          </span>
          <select
            onChange={changeSelectAtendimentosDe}
            value={dataSelectAtendimentosDe}
          >
            <option value={moment().subtract(1, "days").format("DD-MM-YYYY")}>
              Ontem
            </option>
            <option value={moment().format("DD-MM-YYYY")}>Hoje</option>
            <option value={moment().add(1, "days").format("DD-MM-YYYY")}>
              Amanhã
            </option>
            <option value="escolher-data">Escolher Data</option>
          </select>

          {showDatePicker && (
            <div>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => changeDataDatePicker(value)}
                  value={dataDatePicker}
                />
              </LocalizationProvider>
            </div>
          )}
          <span className="box-title box-title--atendimentosSecretaria">{`${nrMarcacoes} marcações`}</span>

          <div
            className="checkbox-wrapper-51"
            id="ver-atendidos__checkbox-container"
          >
            <span className="box-title box-title--atendimentosSecretaria">
              Mostrar Atendidos
            </span>
            <input
              type="checkbox"
              id="mostrarRecebidos"
              onChange={(e) => setVerAtendidos(e.target.checked)}
              checked={verAtendidos}
            />
            <label htmlFor="mostrarRecebidos" className="toggle">
              <span>
                <svg width="10px" height="10px" viewBox="0 0 10 10">
                  <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                </svg>
              </span>
            </label>
          </div>
        </div>

        <div>
          <span className="blue-button" onClick={verPdf}>
            Imprimir
          </span>
        </div>
      </div>

      {marcacoes && marcacoes.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell align="left">Procedimento</TableCell>
                <TableCell align="left">Horário Inicial</TableCell>
                <TableCell align="left">Horário Final</TableCell>
                {moment(marcacoes[0].data_inicio_execucao).isSame(
                  moment(),
                  "days"
                ) && <TableCell align="left">Presente</TableCell>}
                <TableCell align="left">Status</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {marcacoes.map((row, index) => {
                if (!verAtendidos && (row.atendido || row.faltou)) {
                  return null; // Skip rendering rows that don't match the condition
                }
                return (
                  <TableRow
                    key={crypto.randomUUID()}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        to={`/clientes/${row.idCliente}`}
                        className="linkProcedimento"
                      >
                        {row.nome}
                      </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.avaliacao
                        ? "Avaliação"
                        : row.categoriaTratamento.categoria}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.data_inicio_execucao).format("HH:mm")}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.data_fim_execucao).format("HH:mm")}
                    </TableCell>
                    {moment(marcacoes[0].data_inicio_execucao).isSame(
                      moment(),
                      "days"
                    ) && (
                      <TableCell align="left">
                        {listaPresenca.includes(row.idCliente) ? (
                          <div className="cliente-presente led"></div>
                        ) : (
                          <div className="cliente-nao-presente led"></div>
                        )}
                      </TableCell>
                    )}
                    {/* Status */}
                    <TableCell align="left">
                      {row.atendido ? (
                        <span className="spanMensagemAtendido">Atendido</span>
                      ) : row.faltou ? (
                        <span className="spanMensagemFaltou">Faltou</span>
                      ) : (
                        <></>
                      )}
                    </TableCell>

                    {/* Acoes */}
                    <TableCell
                      align="right"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {moment(row.data_inicio_execucao).isBefore(
                        moment().add(1, "days"),
                        "day"
                      ) && (
                        <div className="dots__menu__popup">
                          {/* Marcar atendido */}
                          <Popover
                            content={
                              <>
                                {!row.atendido && (
                                  <div
                                    className="popOverMenu--option"
                                    onClick={handleMarcarAtendido.bind(
                                      null,
                                      row.id
                                    )}
                                  >
                                    <span>Marcar Atendido</span>
                                  </div>
                                )}
                                {!row.faltou &&
                                  moment(row.data_inicio_execucao).isBefore(
                                    moment(),
                                    "day"
                                  ) && (
                                    <div
                                      className="popOverMenu--option"
                                      onClick={marcarFalta.bind(null, row.id)}
                                    >
                                      <span>Faltou</span>
                                    </div>
                                  )}
                              </>
                            }
                            trigger="click"
                            placement="bottom"
                          >
                            <div className="dots__menu">
                              <div className="dot"></div>
                              <div className="dot"></div>
                              <div className="dot"></div>
                            </div>
                          </Popover>
                        </div>
                      )}

                      {moment(row.data_inicio_execucao).isSame(
                        moment(),
                        "day"
                      ) &&
                        !row.atendido &&
                        !row.faltou && (
                          <>
                            <button
                              className="faltouButton"
                              onClick={marcarFalta.bind(null, row.id)}
                            >
                              Faltou
                            </button>

                            {!row.avaliacao && !row.emTransicao ? (
                              <Link
                                to={`/planotratamento/executar/${row.idPlano}-${row.id}-${row.agendaId}`}
                                className="linkProcedimento"
                              >
                                <button className="executarButton">
                                  Executar
                                </button>
                              </Link>
                            ) : row.avaliacao ? (
                              <button
                                className="executarButton"
                                onClick={handleAvaliar.bind(
                                  null,
                                  row.idCliente,
                                  row._id,
                                  row.agendaId
                                )}
                              >
                                Avaliar
                              </button>
                            ) : (
                              <button
                                className="executarButton"
                                onClick={handleAdicionarEvolucao.bind(
                                  null,
                                  row
                                )}
                              >
                                Evolução
                              </button>
                            )}
                          </>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {marcacoes && marcacoes.length === 0 && (
        <span className="spanNenhumProcedimento">
          Nenhum procedimento agendado para hoje
        </span>
      )}
    </>
  );
}

export default Procedimentos;
