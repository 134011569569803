import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

function Chart({ data }) {
  return (
    <div className="wholeChartContainer">
      <div className="chart__title">
        <span className="chart__title__subtitle">Produtividade Financeira</span>
      </div>
      <div className="chart">
        <BarChart
          className="barchart"
          width={730}
          height={250}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" tick={{ fill: "white" }} />
          <YAxis tick={{ fill: "white" }} axisLine={false} />
          <Tooltip />
          <Bar dataKey="receitas" fill="#377bcc" barSize={7} />
          <Bar dataKey="despesas" fill="#ef6b82" barSize={7} />
        </BarChart>
      </div>
    </div>
  );
}
export default Chart;
