import { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { userSchema } from "../../../shared/util/schemas";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import ColorPicker from "../../../shared/components/colorPicker/ColorPicker";

import { CloseOutlined } from "@mui/icons-material";

//Mui icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import toast from "react-hot-toast";

//Material UI Select
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
//////////////////////////////////////////////////////////

import "../auth/Signup.scss";
import "../../../style/formError.scss";

function New() {
  const [file, setFile] = useState("");
  const { error, sendRequest, clearError } = useHttpClient();

  const auth = useContext(AuthContext);
  const clinicaCtx = useContext(ClinicaContext);

  const [medicos, setMedicos] = useState([]);
  const [allMedicos, setAllMedicos] = useState([]);
  const [medicosEscolhidos, setMedicosEscolhidos] = useState([]);
  const [medicosEscolhidosPermanent, setMedicosEscolhidosPermanente] = useState(
    []
  );
  const [secretarias, setSecretarias] = useState([]);
  const [allSecretarias, setAllSecretarias] = useState([]);
  const [secretariasEscolhidas, setSecretariasEscolhidas] = useState([]);
  const [secretariasEscolhidasPermanent, setSecretariasEscolhidasPermanente] =
    useState([]);
  const [roles, setRoles] = useState();
  const [role, setRole] = useState();
  const [roleName, setRoleName] = useState();
  const [color, setColor] = useState("#d6b6d2");
  const userId = useParams().userId;
  const [isMedicoAdministrador, setIsMedicoAdministrador] = useState(false);

  const [initialImage, setInitialImage] = useState("");
  const [formikInitialValues, setFormikInitialValues] = useState(null);

  const navigate = useNavigate();
  //Fetch Clinicas

  useEffect(() => {
    const fetchMedicos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/clinica/${clinicaCtx.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setAllMedicos(
          responseData.users.filter(
            (user) =>
              (user.role.role.role === "Dentista" ||
                user.role.role.role === "Médico/Administrador") &&
              user.ativo === true
          )
        );

        setMedicos(
          responseData.users
            .filter(
              (user) =>
                (user.role.role.role === "Dentista" ||
                  user.role.role.role === "Médico/Administrador") &&
                user.ativo === true
            )
            .map((m) => m.name)
        );

        setAllSecretarias(
          responseData.users.filter(
            (user) =>
              user.role.role.role === "Secretária(o)" && user.ativo === true
          )
        );

        setSecretarias(
          responseData.users
            .filter(
              (user) =>
                user.role.role.role === "Secretária(o)" && user.ativo === true
            )
            .map((m) => m.name)
        );
      } catch (err) {}
    };
    fetchMedicos();

    const fetchRolesAndUser = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/${userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseData2 = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicaroles/${clinicaCtx.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        if (responseData.user.medicos) {
          setMedicosEscolhidos(responseData.user.medicos.map((m) => m.name));
          setMedicosEscolhidosPermanente(
            responseData.user.medicos.map((m) => m.name)
          );
        }

        if (responseData.user.secretarias) {
          setSecretariasEscolhidas(
            responseData.user.secretarias.map((s) => s.name)
          );

          setSecretariasEscolhidasPermanente(
            responseData.user.secretarias.map((s) => s.name)
          );
        }

        setRole(responseData.user.role);

        setFormikInitialValues({
          nome: responseData.user.name,
          email: responseData.user.email,
          contacto: responseData.user.contacto,
          profissao: responseData.user.profissao,
          role: responseData.user.role,
          image: responseData.user.image,
          genero: responseData.user.genero,
          especialidade: responseData.user.especialidades,
        });

        setColor(responseData.user.cor);
        setRole(responseData.user.role);

        const isMedAdmin = responseData2.roles.filter(
          (r) => r.id === responseData.user.role
        )[0];

        if (isMedAdmin.role.role === "Médico/Administrador")
          setIsMedicoAdministrador(true);
        else setIsMedicoAdministrador(false);

        if (responseData.user.image) {
          setInitialImage(
            `${process.env.REACT_APP_BACKEND_LINK}/${responseData.user.image}`
          );
        } else {
          setInitialImage(
            "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
          );
        }

        const tempRoles = [];
        responseData2.roles.forEach((r) => {
          if (r.role.role !== "Médico/Administrador") {
            tempRoles.push({
              id: r.id,
              role: r.role.role,
            });
          }
        });

        setRoles(tempRoles);
        const tempRoleName = tempRoles.filter(
          (t) => t.id === responseData.user.role
        )[0];

        if (isMedAdmin.role.role === "Médico/Administrador")
          setRoleName("Médico/Administrador");
        else setRoleName(tempRoleName.role);
      } catch (err) {}
    };

    fetchRolesAndUser();
  }, [sendRequest, clinicaCtx, userId, auth.token]);

  const handleCancel = () => {
    resetForm();
    navigate("/users");
  };

  const medicosChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    setMedicosEscolhidos(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const secretariasChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    setSecretariasEscolhidas(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onSubmit = async (values, actions) => {
    let errorMessage = "";
    let filteredMedicos = [];
    let filteredSecretarias = [];

    if (roleName === "Secretária(o)") {
      medicosEscolhidos.forEach((medEsc) => {
        allMedicos.forEach((m) => {
          if (medEsc === m.name) filteredMedicos.push(m.id);
        });
      });
    }

    if (roleName === "Dentista" || roleName === "Médico/Administrador") {
      secretariasEscolhidas.forEach((secEsc) => {
        allSecretarias.forEach((s) => {
          if (secEsc === s.name) filteredSecretarias.push(s.id);
        });
      });
    }

    if (filteredMedicos) {
      const medicosRemovidos = medicosEscolhidosPermanent.filter(
        (m) => medicosEscolhidos.includes(m) === false
      );

      if (medicosRemovidos) {
        const removedMedicos = [];

        medicosRemovidos.forEach((medRem) => {
          const temp = allMedicos.filter((m) => m.name === medRem)[0];
          if (temp) {
            removedMedicos.push(temp);
          }
        });

        let medicosRemovidosComApenasUmaSecretaria = [];

        removedMedicos.forEach((m) => {
          if (m.secretarias.length <= 1)
            medicosRemovidosComApenasUmaSecretaria.push(m);
        });

        if (medicosRemovidosComApenasUmaSecretaria.length > 0) {
          errorMessage = `O(s) Dentista(s) ${medicosRemovidosComApenasUmaSecretaria.map(
            (m) => m.name + ", "
          )} não podem ser desassociados da secretária por terem apenas uma.`;
        }
      }
    }
    if (filteredSecretarias) {
      const secretariasRemovidas = secretariasEscolhidasPermanent.filter(
        (s) => secretariasEscolhidas.includes(s) === false
      );

      const removedSecretarias = [];

      secretariasRemovidas.forEach((secRem) => {
        const temp = allSecretarias.filter((s) => s.name === secRem)[0];
        if (temp) {
          removedSecretarias.push(temp);
        }
      });

      let secretariasRemovidasComApenasUmMedico = [];

      removedSecretarias.forEach((s) => {
        if (s.medicos.length <= 1)
          secretariasRemovidasComApenasUmMedico.push(s);
      });

      if (secretariasRemovidasComApenasUmMedico.length > 0) {
        errorMessage = `A(s) Secretária(s) ${secretariasRemovidasComApenasUmMedico.map(
          (m) => m.name + ", "
        )} não podem ser desassociados do medico por terem apenas um.`;
      }
    }
    if (roleName === "Secretária(o)" && medicosEscolhidos.length === 0) {
      errorMessage = "Deve ser atribuido à secretária pelo menos um médico!";
    }
    if (
      (roleName === "Dentista" || roleName === "Médico/Administrador") &&
      secretariasEscolhidas.length === 0
    ) {
      errorMessage =
        "Deve ser atribuido ao dentista pelo menos um(a) secretária(o)!";
    }

    if (errorMessage) {
      toast.success(errorMessage);
    } else {
      try {
        const formData = new FormData();
        formData.append("name", values.nome);
        formData.append("email", values.email);
        formData.append("contacto", values.contacto);
        formData.append("profissao", values.profissao);
        formData.append("genero", values.genero);
        formData.append("image", file);
        formData.append("role", values.role);
        formData.append("ativo", true);
        if (roleName === "Dentista" || roleName === "Médico/Administrador")
          formData.append("cor", color);
        formData.append("ativo", true);

        if (roleName === "Secretária(o)") {
          formData.append("medicos", JSON.stringify(filteredMedicos));
          formData.append("secretarias", null);
        } else formData.append("medicos", null);

        if (roleName === "Dentista" || roleName === "Médico/Administrador") {
          formData.append("especialidades", values.especialidade);
          formData.append("secretarias", JSON.stringify(filteredSecretarias));
        } else formData.append("especialidades", null);

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/${userId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        //Atualizando os usuarios associados ao usuario criado
        //Para Role secretaria
        if (filteredMedicos) {
          const medicosRemovidos = medicosEscolhidosPermanent.filter(
            (m) => medicosEscolhidos.includes(m) === false
          );

          const removedMedicos = [];

          medicosRemovidos.forEach((medRem) => {
            const temp = allMedicos.filter((m) => m.name === medRem)[0];
            if (temp) {
              removedMedicos.push(temp);
            }
          });
          //Se não existerem medicos removidos e o tamanho da lista de medicos nova for igual à anterior
          //Significa que não houve mudança nos medicos escolhidos e portanto não há mais nada a atualizar
          if (
            !(
              medicosRemovidos.length === 0 &&
              medicosEscolhidosPermanent.length === medicosEscolhidos.length
            )
          ) {
            //Verificar se os medicos associados foram alterados
            const medicosEscolhidosComDados = allMedicos.filter((m) =>
              filteredMedicos.includes(m.id)
            );

            medicosEscolhidosComDados.forEach((medico) => {
              if (medico.secretarias.includes(userId) === false) {
                const atualizarSecretariaEmMedico = async () => {
                  let novaListaSecretarias = medico.secretarias;
                  novaListaSecretarias.push(userId);
                  const formData = new FormData();
                  formData.append(
                    "secretarias",
                    JSON.stringify(novaListaSecretarias)
                  );
                  await sendRequest(
                    `${process.env.REACT_APP_BACKEND_LINK}/api/users/${medico.id}`,
                    "PATCH",
                    formData,
                    {
                      Authorization: "Bearer " + auth.token,
                    }
                  );
                };
                atualizarSecretariaEmMedico();
              }
            });

            removedMedicos.forEach((medico) => {
              const atualizarSecretariaEmMedico = async () => {
                let novaListaSecretarias = medico.secretarias.filter(
                  (s) => s !== userId
                );
                const formData = new FormData();
                formData.append(
                  "secretarias",
                  JSON.stringify(novaListaSecretarias)
                );
                await sendRequest(
                  `${process.env.REACT_APP_BACKEND_LINK}/api/users/${medico.id}`,
                  "PATCH",
                  formData,
                  {
                    Authorization: "Bearer " + auth.token,
                  }
                );
              };
              atualizarSecretariaEmMedico();
            });
          }
        }

        //Para role Dentista
        if (filteredSecretarias) {
          const secretariasRemovidas = secretariasEscolhidasPermanent.filter(
            (s) => secretariasEscolhidas.includes(s) === false
          );

          const removedSecretarias = [];

          secretariasRemovidas.forEach((secRem) => {
            const temp = allSecretarias.filter((s) => s.name === secRem)[0];
            if (temp) {
              removedSecretarias.push(temp);
            }
          });

          //Se não existerem secretarias removidos e o tamanho da lista de secretarias nova for igual à anterior
          //Significa que não houve mudança nos secretarias escolhidos e portanto não há mais nada a atualizar
          if (
            !(
              secretariasRemovidas.length === 0 &&
              secretariasEscolhidasPermanent.length ===
                secretariasEscolhidas.length
            )
          ) {
            //Verificar se os secretarias associados foram alterados
            const secretariasEscolhidasComDados = allSecretarias.filter((s) =>
              filteredSecretarias.includes(s.id)
            );

            secretariasEscolhidasComDados.forEach((secretaria) => {
              if (secretaria.medicos.includes(userId) === false) {
                const atualizarMedicoEmSecretaria = async () => {
                  let novaListaMedicos = secretaria.medicos;
                  novaListaMedicos.push(userId);
                  const formData = new FormData();
                  formData.append("medicos", JSON.stringify(novaListaMedicos));
                  await sendRequest(
                    `${process.env.REACT_APP_BACKEND_LINK}/api/users/${secretaria.id}`,
                    "PATCH",
                    formData,
                    {
                      Authorization: "Bearer " + auth.token,
                    }
                  );
                };
                atualizarMedicoEmSecretaria();
              }
            });

            removedSecretarias.forEach((secretaria) => {
              const atualizarMedicoEmSecretaria = async () => {
                let novaListaMedicos = secretaria.medicos.filter(
                  (m) => m !== userId
                );
                const formData = new FormData();
                formData.append("medicos", JSON.stringify(novaListaMedicos));
                await sendRequest(
                  `${process.env.REACT_APP_BACKEND_LINK}/api/users/${secretaria.id}`,
                  "PATCH",
                  formData,
                  {
                    Authorization: "Bearer " + auth.token,
                  }
                );
              };
              atualizarMedicoEmSecretaria();
            });
          }
        }

        toast.success("Usuário atualizado com sucesso!");
        handleCancel();
      } catch (err) {}
    }
  };

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: formikInitialValues || {
      nome: "",
      email: "",
      contacto: "",
      profissao: "",
      genero: "feminino",
      role: "administrativo",
    },
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit,
  });

  const colorChangeHandler = (e) => {
    setColor(e.target.value);
  };

  const changeRole = (e) => {
    const tempRoleName = roles.filter((r) => r.id === e.target.value)[0];
    setRoleName(tempRoleName.role);
    setRole(e.target.value);
  };

  return (
    <>
      <div className="new-signup">
        <Sidebar />
        <div className="newContainer">
          <Navbar
            title="Editar Utilizador"
            icon={PersonOutlineOutlinedIcon}
            paths={[
              { nome: "Utilizadores", link: `../../` },
              { nome: "Editar" },
            ]}
          />
          <ErrorModal error={error} onClear={clearError} />
          <div className="bottom caixa">
            <div className="bottom-left">
              <img src="/images/medico-ficha.png" alt="" />
            </div>
            <div className="bottom-right">
              <div className="imagePicker takes1-2">
                <div className="imagePickerContainer">
                  <CloseOutlined />
                  <img
                    src={file ? URL.createObjectURL(file) : initialImage}
                    alt=""
                  />
                  <label className="icon-file" htmlFor="file">
                    <DriveFolderUploadOutlined className="icon" />
                  </label>
                </div>

                <input
                  className="input"
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {/* Nome */}
              <div className="takes2-5 form-input">
                <input
                  id="nome"
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  value={values.nome}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nome && touched.nome ? "input-error input" : "input"
                  }
                />
                <label className="label" htmlFor="nome">
                  Nome*
                </label>

                {errors.nome && touched.nome ? (
                  <p className="error-message">{errors.nome}</p>
                ) : null}
              </div>

              {/* contacto */}
              <div className="form-input takes5-7">
                <input
                  id="contacto"
                  name="contacto"
                  type="text"
                  placeholder="5999999"
                  value={values.contacto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.contacto && touched.contacto
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="contacto">
                  Contacto*
                </label>
                {errors.contacto && touched.contacto ? (
                  <p className="error-message">{errors.contacto}</p>
                ) : null}
              </div>

              {/* Email */}
              <div className="form-input takes2-5 row2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="email">
                  Email*
                </label>
                {errors.email && touched.email ? (
                  <p className="error-message">{errors.email}</p>
                ) : null}
              </div>

              {/* Role */}
              {roles && role && !isMedicoAdministrador && (
                <div className="form-input takes5-7 row2">
                  <select
                    id="role"
                    name="role"
                    value={role}
                    onChange={changeRole}
                    className="input"
                  >
                    {roles.map((r, index) => (
                      <option value={r.id} key={`${r.role}-${index}`}>
                        {r.role}
                      </option>
                    ))}
                  </select>

                  <label className="label--up users__label__up">Role*</label>
                </div>
              )}
              {/* Genero */}
              <div className="form-input span--2">
                <select
                  id="genero"
                  name="genero"
                  value={values.genero}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input"
                >
                  <option value="feminino">Feminino</option>
                  <option value="masculino">Masculino</option>
                  <option value="outro">Outro</option>
                </select>

                <label className="label--up users__label__up">Genero*</label>
              </div>

              {/* Profissao */}
              <div className="form-input span--2">
                <input
                  id="profissao"
                  name="profissao"
                  type="text"
                  placeholder="Cirurgião"
                  value={values.profissao}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.profissao && touched.profissao
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="profissao">
                  Profissao*
                </label>
                {errors.profissao && touched.profissao ? (
                  <p className="error-message">{errors.profissao}</p>
                ) : null}
              </div>

              {/* Medicos */}
              {roleName === "Secretária(o)" && (
                <FormControl className="span--2" style={{ margin: "auto 0" }}>
                  <InputLabel
                    id="medicoAssociadosSelect-label"
                    style={{ backgroundColor: "white" }}
                  >
                    Medicos Associados
                  </InputLabel>
                  <Select
                    id="medicoAssociadosSelect"
                    multiple
                    value={medicosEscolhidos}
                    onChange={medicosChangeHandler}
                    input={<OutlinedInput label="Medicos" />}
                    renderValue={(selected) => selected.join(", ")}
                    style={{ color: "#0c53fb" }}
                  >
                    {medicos.map((med) => (
                      <MenuItem key={med} value={med}>
                        <Checkbox
                          checked={medicosEscolhidos.indexOf(med) > -1}
                        />
                        <ListItemText primary={med} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Especialidade */}
              {(roleName === "Dentista" ||
                roleName === "Médico/Administrador" ||
                isMedicoAdministrador) && (
                <div className="form-input span--2">
                  <input
                    id="especialidade"
                    name="especialidade"
                    type="text"
                    placeholder="Cirurgião"
                    value={values.especialidade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.especialidade && touched.especialidade
                        ? "input-error input"
                        : "input"
                    }
                  />
                  <label className="label" htmlFor="especialidade">
                    Especialidade*
                  </label>
                  {errors.especialidade && touched.especialidade ? (
                    <p className="error-message">{errors.especialidade}</p>
                  ) : null}
                </div>
              )}

              {/* Secretárias */}
              {(roleName === "Dentista" ||
                roleName === "Médico/Administrador") && (
                <FormControl className="takes1-3" style={{ margin: "auto 0" }}>
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    style={{ backgroundColor: "white" }}
                  >
                    Secretarias a associar
                  </InputLabel>
                  <Select
                    id="demo-multiple-checkbox"
                    multiple
                    value={secretariasEscolhidas}
                    onChange={secretariasChangeHandler}
                    input={<OutlinedInput label="Dentistas" />}
                    renderValue={(selected) => selected.join(", ")}
                    style={{ color: "#0c53fb" }}
                  >
                    {secretarias.map((sec, index) => (
                      <MenuItem key={`secretaria-${index}`} value={sec}>
                        <Checkbox
                          checked={secretariasEscolhidas.indexOf(sec) > -1}
                        />
                        <ListItemText primary={sec} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {(roleName === "Dentista" ||
                roleName === "Médico/Administrador") && (
                <div className="colorPickerDiv span--2">
                  <span>Cor do médico</span>
                  <ColorPicker
                    colorChangeHandler={colorChangeHandler}
                    color={color}
                  />
                </div>
              )}
              <div className="botoes">
                <button className="cancel-btn" onClick={handleCancel}>
                  Cancelar
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={handleSubmit}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default New;
