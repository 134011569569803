export default function lightenColor(color, amount, opacity) {
  // Remove the '#' symbol from the color if present
  const hex = color.replace("#", "");

  // Convert the hex value to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the lighter color with opacity
  const newR = Math.min(r + amount, 255);
  const newG = Math.min(g + amount, 255);
  const newB = Math.min(b + amount, 255);
  const newOpacity = opacity !== undefined ? opacity : 1;

  // Convert the RGB values back to hex
  const newColor = `#${newR.toString(16)}${newG.toString(16)}${newB.toString(
    16
  )}`;

  // Return the new color with opacity
  return `${newColor}${Math.round(newOpacity * 255).toString(16)}`;
}
