import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";

//Mui icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import toast from "react-hot-toast";
import "../../../style/formError.scss";
import "../../clientes/new/NewCliente.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { useLoading } from "../../../shared/context/LoadingContext";

function VerFuncionario() {
  const { startLoading, stopLoading } = useLoading();

  const [initialImage, setInitialImage] = useState("");
  const [dadosFuncionario, setDadosFuncionario] = useState();

  const { error, sendRequest, clearError } = useHttpClient();
  const funcionarioId = useParams().funcionarioId;
  const navigate = useNavigate();
  const [dataNascimento, setDataNascimento] = useState(
    moment().subtract(18, "years")
  );

  const auth = useContext(AuthContext);
  const handleCancel = () => {
    navigate("/funcionarios");
  };

  useEffect(() => {
    const fetchFuncionarios = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/${funcionarioId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setDataNascimento(responseData.funcionario.data_nasc);
        setDadosFuncionario({
          nome: responseData.funcionario.nome,
          genero: responseData.funcionario.genero,
          email: responseData.funcionario.email,
          contacto2: responseData.funcionario.contacto2,
          contacto: responseData.funcionario.contacto,
          observacao: responseData.funcionario.observacao,
          endereco: responseData.funcionario.endereco,
          cargo: responseData.funcionario.cargo,
          nif: responseData.funcionario.nif,
          identificacao: responseData.funcionario.identificacao,
          nr_beneficiario: responseData.funcionario.nr_beneficiario,
          foto: responseData.funcionario.foto,
        });

        if (responseData.funcionario.foto) {
          setInitialImage(
            `${process.env.REACT_APP_BACKEND_LINK}/${responseData.funcionario.foto}`
          );
        } else {
          setInitialImage(
            "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
          );
        }
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro carregando os dados do funcionário. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchFuncionarios();
  }, [sendRequest, funcionarioId, auth.token]);

  return (
    <>
      <div className="new-cliente">
        <Sidebar />
        <div className="newContainer">
          <Navbar
            title="Editar Funcionario"
            icon={PersonOutlineOutlinedIcon}
            paths={[
              { nome: "Funcionarios", link: `../..` },
              { nome: "Editar" },
            ]}
          />
          <ErrorModal error={error} onClear={clearError} />
          {dadosFuncionario && (
            <div className="bottom caixa">
              <div className="bottom-left">
                <img src="/images/medico-ficha.png" alt="" />
              </div>
              <div className="bottom-right">
                <div className="imagePicker takes1-2">
                  <div className="imagePickerContainer">
                    <img src={initialImage} alt="" />
                    <label className="icon-file" htmlFor="file">
                      <DriveFolderUploadOutlined className="icon" />
                    </label>
                  </div>
                </div>

                <div className="takes2-5 form-input">
                  <input
                    type="text"
                    defaultValue={dadosFuncionario.nome}
                    className="input"
                    readOnly
                  />
                  <label className="label" htmlFor="nome">
                    Nome*
                  </label>
                </div>

                <div className="takes5-7">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      className="dataNascimento__cliente"
                      label="Data Nascimento*"
                      inputFormat="DD-MM-YYYY"
                      value={dataNascimento}
                      onChange={(value) => setDataNascimento(value)}
                      renderInput={(params) => <TextField {...params} />}
                      readOnly
                    />
                  </LocalizationProvider>
                </div>

                <div className="form-input takes2-5 row2">
                  <input
                    type="email"
                    defaultValue={dadosFuncionario.email}
                    className="input"
                    readOnly
                  />
                  <label className="label">Email</label>
                </div>

                <div className="form-input takes5-7 row2">
                  <input
                    type="email"
                    defaultValue={dadosFuncionario.genero}
                    className={"input"}
                    readOnly
                  />
                  <label className="label">Genero</label>
                </div>

                <div className="form-input takes1-3">
                  <input
                    type="text"
                    defaultValue={dadosFuncionario.endereco}
                    className="input"
                    readOnly
                  />
                  <label className="label" htmlFor="endereco">
                    Endereço
                  </label>
                </div>

                <div className="form-input takes3-5">
                  <input
                    type="text"
                    defaultValue={dadosFuncionario.cargo}
                    className="input"
                    readOnly
                  />
                  <label className="label" htmlFor="cargo">
                    Profissão
                  </label>
                </div>

                <div className="form-input takes5-7">
                  <input
                    type="text"
                    defaultValue={dadosFuncionario.contacto}
                    className="input"
                    readOnly
                  />
                  <label className="label" htmlFor="contacto">
                    Contacto*
                  </label>
                </div>

                <div className="form-input takes1-3">
                  <input
                    type="text"
                    defaultValue={dadosFuncionario.contacto2}
                    className="input"
                    readOnly
                  />
                  <label className="label" htmlFor="contacto2">
                    Contacto 2
                  </label>
                </div>

                <div className="form-input takes3-5">
                  <input
                    type="text"
                    defaultValue={dadosFuncionario.identificacao}
                    className="input"
                    readOnly
                  />
                  <label className="label" htmlFor="identificacao">
                    BI / CNI*
                  </label>
                </div>

                <div className="form-input takes5-7">
                  <input
                    type="text"
                    defaultValue={dadosFuncionario.nif}
                    className="input"
                    readOnly
                  />
                  <label className="label" htmlFor="nif">
                    Nif*
                  </label>
                </div>

                <div className="form-input takes1-3">
                  <input
                    type="text"
                    defaultValue={dadosFuncionario.nr_beneficiario}
                    className="input"
                    readOnly
                  />
                  <label className="label" htmlFor="nr_beneficiario">
                    Nr Beneficiário
                  </label>
                </div>

                <div className="form-input takes1-7">
                  <textarea
                    rows="4"
                    cols="50"
                    id="observacao"
                    name="observacao"
                    type="textarea"
                    placeholder="Observações"
                    defaultValue={dadosFuncionario.observacao}
                    className={"input"}
                  />
                </div>

                <div className="botoes">
                  <button className="cancel-btn botao" onClick={handleCancel}>
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default VerFuncionario;
