import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import moment from "moment";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLoading } from "../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import "./RelatoriosFInanceiro.scss";

function RelatoriosFinanceiro({ clinicaId, auth, dadosMapa }) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [contasCorrentesAbsolut, setContasCorrentesAbsolut] = useState([]);
  const [entradasAbsolut, setEntradasAbsolut] = useState([]);
  const [dadosClinica, setDadosClinica] = useState();
  const [dataInicial, setDataInicial] = useState(moment().startOf("day"));
  const [dataFinal, setDataFinal] = useState(moment().endOf("day"));
  const [dataSelect, setDataSelect] = useState("Este mês");

  useEffect(() => {
    startLoading();
    const fetchContasCorrentesEntrada = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseData3 = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        if (auth.perm.includes("r-rec")) {
          setContasCorrentesAbsolut(responseData.contasCorrentes);
        } else {
          setContasCorrentesAbsolut([]);
        }

        setEntradasAbsolut(responseData3.entradas);
      } catch (err) {
        stopLoading();
        console.error("err", err);
      }
    };
    fetchContasCorrentesEntrada();

    const fetchDadosClinica = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDadosClinica(responseData.clinica);
      } catch (err) {
        stopLoading();
        console.error("err", err);
      }
    };
    stopLoading();
    fetchDadosClinica();
  }, [auth.perm, auth.token, clinicaId, sendRequest]);

  const exportarMapaHandler = () => {
    const dadosFiltrados = [];
    //Parcelas
    contasCorrentesAbsolut.forEach((c) => {
      c.contas.forEach((conta) => {
        conta.parcelas.forEach((p) => {
          if (p.documentoEletronico) {
            if (
              moment(p.documentoEletronico?.created_at).isBetween(
                dataInicial,
                dataFinal,
                undefined,
                "[]"
              )
            ) {
              dadosFiltrados.push({
                nifEntidade: c.cliente.nif,
                entidade: c.cliente.nome,
                serie: p.documentoEletronico.serie,
                tipoDocumento: p.documentoEletronico.tipoDocumento,
                nrDocumento: p.documentoEletronico.nrDocumento,
                data: moment(p.documentoEletronico.authorizedDateTime).format(
                  "DD/MM/YYYY"
                ),
                valor: p.quantiaPaga,
              });
            }
          }
        });
      });
    });

    //Entradas
    entradasAbsolut.forEach((e) => {
      if (e.documentoEletronico) {
        if (
          moment(e.documentoEletronico.created_at).isBetween(
            dataInicial,
            dataFinal,
            undefined,
            "[]"
          )
        ) {
          dadosFiltrados.push({
            nifEntidade: e.clienteFornecedor.nif,
            entidade: e.clienteFornecedor.nome,
            serie: e.documentoEletronico.serie,
            tipoDocumento: e.documentoEletronico.tipoDocumento,
            nrDocumento: e.documentoEletronico.nrDocumento,
            data: moment(e.documentoEletronico.authorizedDateTime).format(
              "DD/MM/YYYY"
            ),
            valor: e.valor,
          });
        }
      }
    });

    if (dadosFiltrados.length === 0) {
      toast.error("Sem dados para gerar o mapa!");
    } else {
      //Enviar dados de entrada
      dadosMapa(
        dadosFiltrados,
        dadosClinica,
        moment(dataInicial).format("DD-MM-YYYY"),
        moment(dataFinal).format("DD-MM-YYYY")
      );
    }
  };

  let startDate, endDate;

  const handleChangeDataSelect = (e) => {
    const value = e.target.value;

    if (e.target.value === "Hoje") {
      startDate = moment().startOf("day");
      endDate = moment().endOf("day");
    } else if (e.target.value === "Esta semana") {
      startDate = moment().startOf("week");
      endDate = moment().endOf("week");
    } else if (e.target.value === "Este mês") {
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
    } else if (e.target.value === "Mês passado") {
      startDate = moment().subtract(1, "month").startOf("month");
      endDate = moment().subtract(1, "month").endOf("month");
    } else {
      startDate = dataInicial;
      endDate = dataFinal;
    }

    setDataInicial(startDate);
    setDataFinal(endDate);
    setDataSelect(value);
  };

  return (
    <div>
      <div className="relatorio__filtros__container">
        <FormControl className="relatorio__filtros__container__selectContainer">
          <InputLabel id="demo-simple-select-label">Periodo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dataSelect}
            label="Periodo"
            onChange={handleChangeDataSelect}
          >
            <MenuItem value="Hoje">Hoje</MenuItem>
            <MenuItem value="Esta semana">Esta Semana</MenuItem>
            <MenuItem value="Este mês">Este mês</MenuItem>
            <MenuItem value="Mês passado">Mês passado</MenuItem>
            <MenuItem value="Escolher periodo">Escolher periodo</MenuItem>
          </Select>
        </FormControl>

        {dataSelect === "Escolher periodo" && (
          <div className="relatorio__datasContainer">
            <div className="relatorio__datasContainer__datePickerContainer">
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data Inicial*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataInicial(value)}
                  value={dataInicial}
                />
              </LocalizationProvider>
            </div>
            <div className="relatorio__datasContainer__datePickerContainer">
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data Final*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataFinal(value)}
                  value={dataFinal}
                />
              </LocalizationProvider>
            </div>
          </div>
        )}
      </div>
      <div>
        <button
          onClick={exportarMapaHandler}
          className="relatorio__filtros__gerarDocButton"
        >
          Mapa de Venda
        </button>
      </div>
    </div>
  );
}

export default RelatoriosFinanceiro;
