import PdfMapaFinanceiro from "./PdfMapaFinanceiro";

function PdfMapaFinanceiroWrapper({ showSinglePage, mapaData }) {
  const showSingleP = () => {
    showSinglePage();
  };
  return (
    <div>
      <PdfMapaFinanceiro mapaData={mapaData} showSingleP={showSingleP} />
    </div>
  );
}

export default PdfMapaFinanceiroWrapper;
