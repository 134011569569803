//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import "./ModalEvolucao.scss";

import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function ModalEvolucao({ openModalEvolucao, handleCloseModal, data, auth }) {
  const handleApagarEvolucaoPlano = async () => {};

  const handleOpenModalEditarPlano = async () => {};

  return (
    <>
      <Modal
        open={openModalEvolucao}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box" id="modal__evolucao">
          <h1 className="fluxo-atendimento__modal__titulo">Evoluções</h1>
          <div className="modalEvolucao__container">
            {data.sessoes.map((sessao, index) => (
              <div className="modalEvolucao__container__item" key={index}>
                <div className="modalEvolucao__container__item--left">
                  <span className="modalEvolucao__container__item--left__evolucao">
                    {sessao.evolucao}
                  </span>
                  <span className="modalEvolucao__container__item--left__otherinfo">
                    {moment(sessao.data_inicio_execucao).format("DD-MM-YYYY")},{" "}
                    {moment(sessao.data_inicio_execucao).format("HH:mm")} às{" "}
                    {moment(sessao.data_fim_execucao).format("HH:mm")}
                    {" - "}
                    {data.medico.genero === "masculino" ? "Dr. " : "Dra. "}
                    {data.medico.name}
                  </span>
                </div>
                <div className="modalEvolucao__container__btns">
                  {data.medico?._id === auth.userId && (
                    <>
                      <Tooltip title="Editar">
                        <IconButton
                          onClick={handleOpenModalEditarPlano.bind(
                            null,
                            sessao._id
                          )}
                          className="modalEvolucao__container__btns__icon-btn"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Apagar">
                        <IconButton
                          onClick={handleApagarEvolucaoPlano.bind(
                            null,
                            sessao._id
                          )}
                          className="modalEvolucao__container__btns__icon-btn"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            ))}
            <div
              className="fluxo-atendimento__modal__bottom_btns"
              id="modalEvolucao__btns"
            >
              <span
                className="cancel-btn"
                onClick={handleCloseModal}
                style={{ display: "block" }}
              >
                Fechar
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEvolucao;
