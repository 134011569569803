import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import dataGridData from "./components/datagridData";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import moment from "moment";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import toast from "react-hot-toast";

import { useLoading } from "../../../shared/context/LoadingContext";
import FiltrosDfesPorEmitir from "./components/filtros/FiltrosDfesPorEmitir";

function DfesPorEmitir({ clinicaId, auth }) {
  const { startLoading, stopLoading } = useLoading();

  const { sendRequest } = useHttpClient();
  const [rows, setRows] = useState([]);
  const [contasCorrentes, setContasCorrentes] = useState();
  const [contasCorrentesAbsolut, setContasCorrentesAbsolut] = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [entradasAbsolut, setEntradasAbsolut] = useState([]);

  const [filtrosAplicados, setFiltrosAplicados] = useState([]);
  const [filtroCaixa, setFiltroCaixa] = useState();
  const [filtroCategoria, setFiltroCategoria] = useState();
  const [filtroMetodoPagamento, setFiltroMetodoPagamento] = useState();

  const fetchDfesPorEmitir = async (startDate, endDate) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);

      const [responseDataCc, responseDataEntradas] = await Promise.all([
        sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/daterange/poremitir/${clinicaId}`,
          "PATCH",
          formData,
          { Authorization: "Bearer " + auth.token }
        ),
        sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clinica/daterange/poremitir/${clinicaId}`,
          "PATCH",
          formData,
          { Authorization: "Bearer " + auth.token }
        ),
      ]);

      console.log("responseDataCc", responseDataCc);
      console.log("responseDataEntradas", responseDataEntradas);
      const updateState = (data, permission, setState, setAbsoluteState) => {
        if (auth.perm.includes(permission)) {
          setState(data);
          setAbsoluteState(data);
        } else {
          setState([]);
          setAbsoluteState([]);
        }
      };

      updateState(
        responseDataCc.contasCorrentes,
        "r-rec",
        setContasCorrentes,
        setContasCorrentesAbsolut
      );

      updateState(
        responseDataEntradas.entradas,
        "r-ent",
        setEntradas,
        setEntradasAbsolut
      );

      handleFiltrar(
        responseDataCc.contasCorrentes,
        responseDataEntradas.entradas,
        [],
        null,
        null,
        null,
        {
          dataInicial: moment(startDate).format("DD/MM/YYYY"),
          dataFinal: moment(endDate).format("DD/MM/YYYY"),
        }
      );
    } catch (err) {
      console.error("Erro ao buscar dados:", err);
      toast.error(
        "Ocorreu um erro ao carregar os dados. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchDfesPorEmitir(new Date().toISOString(), new Date().toISOString());
  }, []);

  useEffect(() => {
    let dataParaDataGrid = [];

    if (contasCorrentes) {
      contasCorrentes.forEach((contaCorrente) => {
        contaCorrente.contas.forEach((conta) => {
          if (conta.aprovado) {
            conta.parcelas.forEach((parcela) => {
              dataParaDataGrid.push({
                tipo: "receita",
                cliente: contaCorrente.cliente.nome,
                idCliente: contaCorrente.cliente._id,
                dadosCliente: contaCorrente.cliente,
                idContaCorrente: contaCorrente._id,
                orcamento: conta.orcamento,
                id: parcela._id,
                contaId: conta._id,
                dataLimitePagamento: parcela.dataLimitePagamento,
                dataPagamento: parcela.dataPagamento,
                quantiaPorPagar: parcela.quantiaPorPagar,
                quantiaPaga: parcela.quantiaPaga,
                metodoPagamento: parcela.metodoPagamento,
                entrada: parcela.entrada,
                nrParcela: parcela.nrParcela,
                parcelaPaga: parcela.parcelaPaga,
                estado: parcela.parcelaPaga
                  ? "Pago"
                  : moment()
                      .startOf("day")
                      .isAfter(
                        moment(parcela.dataLimitePagamento).startOf("day")
                      )
                  ? "Em atraso"
                  : "",
                documentoEletronico: parcela.documentoEletronico,
              });
            });
          }
        });
      });
    }

    if (entradas && entradas.length > 0) {
      entradas.forEach((entrada) => {
        if (entrada.tipo === "reembolso" && !entrada.entradaRecebida) {
          return;
        }
        dataParaDataGrid.push({
          tipo: "entrada",
          id: entrada.id,
          cliente: entrada.cliente,
          dataPagamento: entrada.dataPagamento,
          valor: entrada.valor,
          metodoPagamento: entrada.metodoPagamento,
          descricao: entrada.descricao,
          comprovativo: entrada.comprovativo,
          estado: "Pago",
          documentoEletronico: entrada.documentoEletronico,
        });
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) => new Date(a.dataPagamento) - new Date(b.dataPagamento)
    );

    setRows(dataGridData(dataParaDataGrid));
  }, [contasCorrentes, entradas]);

  const handleFiltrar = (
    newContasCorrentes,
    newEntradas,
    filtros,
    filtroCai,
    filtroCat,
    filtroMet
  ) => {
    setFiltroCaixa(filtroCai);
    setFiltroCategoria(filtroCat);
    setFiltroMetodoPagamento(filtroMet);
    setFiltrosAplicados(filtros);
    if (newContasCorrentes) {
      setContasCorrentes([...newContasCorrentes]);
    }
    if (newEntradas) {
      setEntradas([...newEntradas]);
    }
  };

  const actionColumn = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.tipo === "receita" && (
              <span className="spanCellPago">
                {moment(params.row.dataPagamento).format("DD-MM-YYYY")}
              </span>
            )}

            {params.row.tipo === "entrada" && (
              <span>
                {params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoNome">
                <span className="spanCellPago">
                  {params.row.nome}{" "}
                  <Link
                    to={`/clientes/${params.row.idCliente}-debitos`}
                    className="link-cliente-receita"
                  >
                    {" "}
                    {params.row.cliente}
                  </Link>
                </span>
                {params.row.nrParcela && (
                  <Tooltip
                    title={`Parcelado em ${params.row.nrParcela[2]} vezes`}
                  >
                    <IconButton>
                      <span className="nrParcela">{params.row.nrParcela}</span>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div className="spanCellPago">
                {params.row.descricao}
                {params.row.cliente && (
                  <>
                    {" - "}
                    <Link
                      to={`/clientes/${params.row.cliente._id}-debitos`}
                      className="link-cliente-receita"
                    >
                      {params.row.cliente.nome}
                    </Link>
                  </>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoEstado">
                <span
                  className={`debitos__container__estado ${
                    params.row.estado === "Em atraso"
                      ? "debitos__container__estado--atraso"
                      : params.row.estado === "Pago"
                      ? "debitos__container__estado--pago"
                      : ""
                  }`}
                >
                  {params.row.metodoPagamento
                    ? params.row.metodoPagamento
                    : params.row.estado}
                </span>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div className="cellDebitoEstado">
                <span
                  className={
                    "debitos__container__estado debitos__container__estado--pago"
                  }
                >
                  {params.row.metodoPagamento}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellAction">
                <span
                  className={`debitos__container__valor ${
                    params.row.estado === "Em atraso"
                      ? "debitos__container__valor--atraso"
                      : params.row.estado === "Pago"
                      ? "debitos__container__valor--pago"
                      : ""
                  }`}
                >
                  {params.row.valor}
                </span>
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="financeiro__container caixa">
      <div className="financeiro__adicionarEFiltros">
        <FiltrosDfesPorEmitir
          contasCorrentes={contasCorrentesAbsolut}
          entradas={entradasAbsolut}
          handleFiltrar={handleFiltrar}
          filtrosAplicados={filtrosAplicados}
          filtroCaixa={filtroCaixa}
          filtroCategoria={filtroCategoria}
          filtroMetodoPagamento={filtroMetodoPagamento}
          clinicaId={clinicaId}
          auth={auth}
          fetchContasCorrentesDespesaEntrada={fetchDfesPorEmitir}
        />
      </div>

      {rows && (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            pageSize={10}
            rowsPerPageOptions={[10]}
            columns={actionColumn}
          />
        </div>
      )}
    </div>
  );
}

export default DfesPorEmitir;
