import { useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import "./ModalNotificar.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

function ModalNotificar({
  show,
  medicos,
  handleModalNotificarClose,
  handleModalNotificarSubmit,
}) {
  const medicoRef = useRef(medicos[0].id);
  const tipoNotificacaoRef = useRef("avaliação");

  return (
    <div>
      <Modal
        className="ModalNotificar"
        open={show}
        onClose={handleModalNotificarClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <span className="modalNotificarTitulo">Tipo de notificação</span>

          <select className="modalNotificarSelect" ref={tipoNotificacaoRef}>
            <option value="avaliação">Avaliação</option>
            <option value="consulta">Consulta</option>
            <option value="procedimento">Procedimento</option>
          </select>

          <span className="modalNotificarTitulo">Médico a notificar</span>

          <select className="modalNotificarSelect" ref={medicoRef}>
            {medicos.map((m, index) => (
              <option key={index} value={m.id}>
                {m.nome}
              </option>
            ))}
          </select>
          <div className="modalNotificarBtns">
            <span
              className="modalNotificarCancelBtn"
              onClick={handleModalNotificarClose}
            >
              Cancelar
            </span>
            <span
              className="modalNotificarSubmitBtn"
              onClick={() =>
                handleModalNotificarSubmit(
                  tipoNotificacaoRef.current.value,
                  medicoRef.current.value
                )
              }
            >
              Enviar
            </span>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalNotificar;
