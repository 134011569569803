import React from "react";
import SingleUpcomingAppointment from "./SingleUpcomingAppointment";

function ListUpcomingAppointments({ agendamentos }) {
  return (
    <div className="single-cliente__agendamentos">
      {agendamentos.map((agendamento, index) => (
        <SingleUpcomingAppointment agendamento={agendamento} key={index} />
      ))}
    </div>
  );
}

export default ListUpcomingAppointments;
