import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./AvaliacoesPorOrcamentar.scss";
import { localeText } from "../../../../../shared/util/datatableTranslations";
import { DataGrid } from "@mui/x-data-grid";

const PAGESIZE = 15;

function AvaliacoesPorOrcamentar({ open, data, handleCloseModal }) {
  const [shownData, setShownData] = useState([]);

  useEffect(() => {
    setShownData(data);
  }, [data]);

  const columns = [
    {
      field: "nome",
      headerName: "Cliente",
      flex: 5,
    },
    {
      field: "data",
      headerName: "Data",
      flex: 2,
    },
    {
      field: "medico",
      headerName: "Médico(a)",
      flex: 2,
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 2,
      renderCell: (params) => {
        return (
          <span className="listaDivida__tableContent">
            <Link
              to={`/clientes/${params.row.idCliente}-avaliacao`}
              className="listaDivida__clientAnchor"
            >
              <button className="secretaria__dashboard__modalAcoesBtn">
                Ver Avaliação
              </button>
            </Link>
          </span>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    if (data) {
      const result = data.filter((dt) =>
        dt.nome.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setShownData(result);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box"
        id="orcamentos-por-aprovar__modal"
      >
        <div className="orcamentos-por-aprovar__modal__cabecalho">
          <h1 className="fluxo-atendimento__modal__titulo">
            {" "}
            Avaliações por orçamentar
          </h1>
          <div className="search__datatable__container">
            <span>Procurar</span>
            <input
              type="search"
              className="search__datatable__input"
              placeholder="Procurar"
              onChange={searchHandler}
            />
          </div>
        </div>

        <div className="orcamentos-por-aprovar__modal__container">
          <DataGrid
            autoWidth
            className="datagrid"
            rows={shownData}
            columns={columns.concat(actionColumn)}
            pageSize={PAGESIZE}
            rowsPerPage={PAGESIZE}
            localeText={localeText}
          />
        </div>

        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          <span
            className="cancel-btn"
            onClick={handleCloseModal.bind(null, "avaliacoes")}
          >
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default memo(AvaliacoesPorOrcamentar);
