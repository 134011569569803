import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomeSecretaria.scss";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";

import SecretariaBottomContainers from "./components/SecretariaBottomContainers";
import MarcacoesSecretaria from "./components/marcacoesSecretaria/MarcacoesSecretaria";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import Tasks from "../components/tasks/Tasks";
import PdfListaAtendimentos from "../../../shared/components/PdfGenerator/PdfListaAtendimentos/PdfListaAtendimentos";

import "../components/top5tratamentos/Top5tratamentos.scss";
import "../../../shared/css/Checkbox.scss";

function HomeSecretaria() {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const [dadosPdf, setDadosPdf] = useState({
    show: false,
    listaAtendimentoData: [],
    medico: "",
  });

  const navigate = useNavigate();

  const showSinglePage = () => {
    setDadosPdf({ show: false });
  };

  const verPdf = (medico, allMarcacoes, dataLista) => {
    const filteredMarcacoes = [];
    allMarcacoes.forEach((mr) => {
      if (mr.medico._id === medico.id) {
        if (mr.avaliacao) {
          filteredMarcacoes.push({
            cliente: mr.nome,
            contacto: mr.contacto,
            procedimento: "Avaliação",
            data_inicio_execucao: mr.data_inicio_execucao,
            data_fim_execucao: mr.data_fim_execucao,
          });
        } else {
          filteredMarcacoes.push({
            cliente: mr.nome,
            contacto: mr.contacto,
            procedimento: mr.categoriaTratamento.categoria,
            data_inicio_execucao: mr.data_inicio_execucao,
            data_fim_execucao: mr.data_fim_execucao,
          });
        }
      }
    });

    setDadosPdf({
      show: true,
      listaAtendimentoData: filteredMarcacoes,
      medico: medico.nome,
      dataLista,
    });
  };
  ///////////////////////////////////////////////////

  return (
    <>
      {dadosPdf.show && (
        <PdfListaAtendimentos
          showPreviousPage={showSinglePage}
          medico={dadosPdf.medico}
          clinica={clinica.clinica.nome}
          listaAtendimentoData={dadosPdf.listaAtendimentoData}
          dataLista={dadosPdf.dataLista}
        />
      )}

      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar title="Dashboard" icon={DashboardIcon} />
          <div className="dashboard__secretaria__heading">
            <div className="dashboard__ola">
              <span className="dashboard__ola__big">{`Olá, ${auth.nome}!`}</span>
              <span className="dashboard__ola__small">
                Tenha um excelente dia de trabalho &#128522;!
              </span>
            </div>

            <div className="dashboard__secretaria__addCliente__container">
              <span
                className="blue-button blue-button__dashboard__secretaria"
                onClick={() => navigate("/clientes/new")}
              >
                Adicionar Cliente
              </span>
            </div>
          </div>

          <SecretariaBottomContainers />

          <div className="dashboard-administrativo__marcacoes caixa">
            <MarcacoesSecretaria verPdf={verPdf} />
          </div>
          <div className="dashboard__bottom caixa tasks__container">
            <Tasks sendRequest={sendRequest} clinica={clinica} auth={auth} />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeSecretaria;
