import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import "../List.scss";

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined"; //Executar
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"; //ver

//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";

function ListPlanoTratamento({ data, handleOpenModal, handleDesativar }) {
  const [planosTratamento, setPlanosTratamento] = useState(data);
  const auth = useContext(AuthContext);
  useEffect(() => {
    setPlanosTratamento(data);
  }, [data]);

  let content = [];
  if (data) {
    data.forEach((row) => {
      if (
        !row.plano_completo &&
        !row.data_inicio &&
        row.canDelete &&
        auth.perm.includes("d-plt")
      ) {
        content.push(
          <div className="dots__menu__popup">
            {/* Desativar Plano */}
            <Popconfirm
              title="Desativação de plano de tratamento"
              description="Tem a certeza que pretende desativar o plano de tratamento?"
              icon={<DeleteIcon style={{ color: "red" }} />}
              okText="Sim"
              cancelText="Não"
              onConfirm={handleDesativar.bind(null, row.id)}
            >
              <div className="popOverMenu--option">
                <span>Desativar</span>
                <DeleteIcon className="popOverMenu--option__icon" />
              </div>
            </Popconfirm>
          </div>
        );
      }
    });
  }

  return (
    <>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Histórico
              </TableCell> */}

              <TableCell className="tableCell tableCell--heading">
                Medico Responsavel
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data Inicio
              </TableCell>
              <TableCell className="tableCell tableCell--heading">
                Data Fim
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Estado
              </TableCell>

              <TableCell className="tableCell tableCell--heading tableCell--heading--end">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          {planosTratamento && (
            <TableBody>
              {planosTratamento.map((row, index) => (
                <TableRow key={row.id}>
                  {/* <TableCell
                    className="tabelCell"
                    style={{ textAlign: "center" }}
                  >
                    {auth.perm.includes("r-plt") && (
                      <Tooltip title="Ver Evoluções">
                        <RemoveRedEyeIcon
                          className="icon__list"
                          onClick={handleOpenModal.bind(
                            null,
                            row.id,
                            "historico"
                          )}
                        />
                      </Tooltip>
                    )}
                  </TableCell> */}

                  <TableCell className="tableCell">
                    {row.medico_responsavel.name}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.data_inicio ? row.data_inicio : ""}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.data_fim ? row.data_fim : ""}
                  </TableCell>

                  <TableCell className="tableCell">
                    <span
                      className={
                        row.plano_completo
                          ? "status concluido"
                          : "status transformado"
                      }
                    >
                      {row.plano_completo ? "Concluido" : "Em Andamento"}
                    </span>
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="tableCell__action">
                      {auth.perm.includes("exec-plt") &&
                        !row.plano_completo && (
                          <Tooltip title="Executar">
                            <PlaylistAddCheckOutlinedIcon
                              className="icon__list"
                              onClick={handleOpenModal.bind(null, row.id)}
                            />
                          </Tooltip>
                        )}

                      {content[index] && (
                        <Popover
                          content={content[index]}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default ListPlanoTratamento;
