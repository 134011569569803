const validarAvaliacao = (serviceList, tratamentos) => {
  let errorMessage = null;

  const itemsOnServiceList = serviceList.filter((s) => s.show === true);

  if (itemsOnServiceList.length === 0) {
    errorMessage = "Crie pelo menos um procedimento!";
  }

  // Create a map to store treatments and their associated teeth
  const treatmentMap = new Map();

  serviceList.forEach((service) => {
    if (service.show) {
      if (service.aceitaDentes) {
        if (service.dentesTratados.length === 0) {
          errorMessage = "Um dos procedimentos está sem dentes definidos!";
        }
      }

      // Check for duplicate treatments with overlapping teeth
      if (treatmentMap.has(service.tratamento)) {
        const existingTeeth = treatmentMap.get(service.tratamento);
        const overlappingTeeth = service.dentesTratados.filter((tooth) =>
          existingTeeth.includes(tooth)
        );

        if (overlappingTeeth.length > 0) {
          const tratamento = tratamentos.find(
            (t) => t._id === service.tratamento
          );
          const tratamentoName = tratamento
            ? tratamento.designacao
            : "Tratamento desconhecido";
          errorMessage = `Há procedimentos duplicados com dentes em comum: ${tratamentoName}`;
        } else {
          // If no overlap, add these teeth to the existing set
          treatmentMap.set(service.tratamento, [
            ...new Set([...existingTeeth, ...service.dentesTratados]),
          ]);
        }
      } else {
        // If this is a new treatment, add it to the map
        treatmentMap.set(service.tratamento, service.dentesTratados);
      }
    }
  });

  return errorMessage;
};

export default validarAvaliacao;
