import { useState, useEffect } from "react";

//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import "./ModalDespesa.scss";

function ModalVerDespesa({
  openModalVerDespesa,
  handleCloseModalVerDespesa,
  dadosModalVerDespesa,
}) {
  const [openModal, setOpenModal] = useState(openModalVerDespesa);
  useEffect(() => {
    setOpenModal(openModalVerDespesa);
  }, [openModalVerDespesa]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalVerDespesa}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-despesa__modal"
          id="modal__adicionar-despesa"
        >
          <h1 className="fluxo-atendimento__modal__titulo">Ver despesa</h1>
          <div className="adicionar-despesa__modal__container">
            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={dadosModalVerDespesa.descricao}
                className="customInput inputNumber"
                id="descricao"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="descricao">
                Descricao
              </label>
            </div>

            <div className="customInputContainer adicionar-despesa__modal__container--span1">
              <input
                type="text"
                defaultValue={dadosModalVerDespesa.valor}
                className="customInput inputNumber"
                id="valor"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="valor">
                Valor
              </label>
            </div>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data de vencimento*"
                inputFormat="DD-MM-YYYY"
                value={moment(dadosModalVerDespesa.dataLimitePagamento)}
                renderInput={(params) => <TextField {...params} />}
                disabled
                onChange={() => {}}
              />
            </LocalizationProvider>

            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={
                  dadosModalVerDespesa.categoria.categoria
                    ? dadosModalVerDespesa.categoria.categoria
                    : dadosModalVerDespesa.categoria.value
                }
                className="customInput inputNumber"
                id="categoria"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="categoria">
                Categoria
              </label>
            </div>

            <div className="customInputContainer adicionar-despesa__modal__container--span1">
              <input
                type="text"
                defaultValue={
                  dadosModalVerDespesa.caixa.caixa
                    ? dadosModalVerDespesa.caixa.caixa
                    : dadosModalVerDespesa.caixa.value
                }
                className="customInput inputNumber"
                id="caixa"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="caixa">
                Caixa
              </label>
            </div>
            <textarea
              cols="30"
              rows="7"
              defaultValue={
                dadosModalVerDespesa.observacoes
                  ? dadosModalVerDespesa.observacoes
                  : ""
              }
              className="adicionar-despesa__modal__container__textarea"
              disabled
              style={{ color: "#88959e" }}
            ></textarea>
          </div>

          {dadosModalVerDespesa.despesaPaga && (
            <div
              className="adicionar-despesa__modal__pagamento"
              style={{ marginTop: 25, marginBottom: 25 }}
            >
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data de pagamento*"
                  inputFormat="DD-MM-YYYY"
                  value={dadosModalVerDespesa.dataPagamento}
                  renderInput={(params) => <TextField {...params} />}
                  disabled
                  onChange={() => {}}
                />
              </LocalizationProvider>

              <div className="customInputContainer adicionar-despesa__modal__container--span1">
                <input
                  type="text"
                  defaultValue={dadosModalVerDespesa.metodoPagamento}
                  className="customInput inputNumber"
                  id="metodoPagamento"
                  style={{ pointerEvents: "none", color: "#88959e" }}
                />
                <label className="customInputLabel" htmlFor="metodoPagamento">
                  Metodo do Pagamento
                </label>
              </div>
            </div>
          )}

          {dadosModalVerDespesa.comprovativo && (
            <div className="verComprovativoContainer">
              <span>Comprovativo:</span>
              <a
                href={`${process.env.REACT_APP_BACKEND_LINK}/${dadosModalVerDespesa.comprovativo.ficheiro}`}
                download
                target="_blank"
                rel="noreferrer"
              >
                {dadosModalVerDespesa.comprovativo.descricao}
              </a>
            </div>
          )}

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalVerDespesa}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalVerDespesa;
