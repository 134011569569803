import React from "react";
import PdfFatura from "./PdfFatura";

function PdfFaturaWrapper({ showPreviousPage, faturaData }) {
  const showPreviousP = () => {
    showPreviousPage();
  };

  return (
    <div>
      <PdfFatura faturaData={faturaData} showPreviousPage={showPreviousP} />
    </div>
  );
}

export default PdfFaturaWrapper;
