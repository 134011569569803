import { useState, useEffect, memo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./NewTaksModal.scss";
import toast from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useLoading } from "../../../shared/context/LoadingContext";

function ModalNewTask({
  open,
  users,
  handleCloseModal,
  atualizarTarefas,
  auth,
  sendRequest,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [delegatario, setDelegatario] = useState();
  const [titulo, setTitulo] = useState("");
  const [data, setData] = useState(new Date());
  const [descricao, setDescricao] = useState("");
  const [mensagemErro, setMensagemErro] = useState("");

  useEffect(() => {
    setDelegatario(auth.userId);
  }, [auth.userId]);

  const preSubmitHandler = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submit();
    }
  };

  const submit = async () => {
    startLoading();
    try {
      let erro = false;
      if (!delegatario || !titulo || !descricao || !data) {
        erro = true;
        setMensagemErro("Preencha todos os campos antes de prosseguir");
      }
      if (!erro) {
        const formData = new FormData();
        formData.append("delegador", auth.userId);
        formData.append("delegatario", delegatario);
        formData.append("titulo", titulo);
        formData.append("descricao", descricao);
        formData.append("data", data);

        const taskReturned = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/tasks`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setTitulo("");
        setDescricao("");
        setIsSubmitting(false);
        atualizarTarefas(taskReturned.task, "adicionar");
        setMensagemErro("");
        toast.success("Tarefa adicionada com sucesso");
      } else {
        setIsSubmitting(false);
      }
    } catch (err) {
      handleCloseModal();
      toast.error("Erro ao adicionar a tarefa");
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="fluxo-atendimento__modal__box" id="dividas__modal">
        <h1 className="fluxo-atendimento__modal__titulo">Nova Tarefa</h1>
        <div className="newTaskContainer">
          <div
            className={`customInputContainer ${
              auth.perm.includes("c-tar") ? "takes4" : "takes5"
            }`}
          >
            <input
              type="text"
              onChange={(e) => setTitulo(e.target.value)}
              className="customInput"
              value={titulo}
            />
            <label className="customInputLabel">Título*</label>
          </div>

          {auth.perm.includes("c-tar") && (
            <div className="customInputContainer takes2">
              <select
                onChange={(e) => setDelegatario(e.target.value)}
                className="customInputSelect"
                value={delegatario}
              >
                {users &&
                  delegatario &&
                  users.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <label className="customInputLabelSelect">Delegatário*</label>
            </div>
          )}

          <LocalizationProvider dateAdapter={AdapterMoment} className="takes2">
            <DesktopDatePicker
              className="dataNascimento__cliente datepicker-tasks"
              label="Data Execução*"
              inputFormat="DD-MM-YYYY"
              value={data}
              onChange={(value) => setData(value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <div className="customInputContainer takesAll">
            <textarea
              rows={5}
              onChange={(e) => setDescricao(e.target.value)}
              className="customInputTextarea"
              value={descricao}
            />
            <label className="customInputLabelSelect">Descrição*</label>
          </div>
        </div>

        {mensagemErro && (
          <div className="fluxo-atendimento__modal__erros">{mensagemErro}</div>
        )}

        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          <span
            disabled={isSubmitting}
            className="blue-button"
            onClick={preSubmitHandler}
          >
            {isSubmitting ? "Guardando..." : "Guardar"}
          </span>

          <span className="cancel-btn" onClick={handleCloseModal}>
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default memo(ModalNewTask);
