export const localeText = {
  noRowsLabel: "Sem Dados",
  noResultsOverlayLabel: "Nenhum resultado encontrado.",
  errorOverlayDefaultLabel: "Ocorreu um erro.",
  //Columns selector toolbar button text
  toolbarColumns: "Colunas",
  toolbarColumnsLabel: "Seleccionar colunas",
  // Filters toolbar button text
  toolbarFilters: "Filtros",
  toolbarFiltersLabel: "Mostrar filtros",
  toolbarFiltersTooltipHide: "Esconder filtros",
  toolbarFiltersTooltipShow: "Mostrar filtros",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "Procurar…",
  toolbarQuickFilterLabel: "Procurar",
  toolbarQuickFilterDeleteIconLabel: "Limpar",

  // Export selector toolbar button text
  toolbarExport: "Exportar",
  toolbarExportLabel: "Exportar",
  toolbarExportCSV: "Fazer download como CSV",
  toolbarExportPrint: "Print",
  toolbarExportExcel: "Download como Excel",

  // Columns panel text
  columnsPanelTextFieldLabel: "Encontrar coluna",
  columnsPanelTextFieldPlaceholder: "Titulo da Coluna",
  columnsPanelDragIconLabel: "Reordenar coluna",
  columnsPanelShowAllButton: "Mostrar tudo",
  columnsPanelHideAllButton: "Esconder tudo",

  // Filter panel text
  filterPanelAddFilter: "Adicionar filtro",
  filterPanelDeleteIconLabel: "Apagar",
  filterPanelLinkOperator: "Operador lógico",
  filterPanelOperators: "Operador", // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: "E",
  filterPanelOperatorOr: "Ou",
  filterPanelColumns: "Colunas",
  filterPanelInputLabel: "Valor",
  filterPanelInputPlaceholder: "Filtrar valor",

  // Filter operators text
  filterOperatorContains: "contém",
  filterOperatorEquals: "igual",
  filterOperatorStartsWith: "começa com",
  filterOperatorEndsWith: "termina com",
  filterOperatorIs: "é",
  filterOperatorNot: "não é",
  filterOperatorAfter: "antecede",
  filterOperatorOnOrAfter: "está em ou depois de",
  filterOperatorBefore: "está antes de",
  filterOperatorOnOrBefore: "está em ou antes de",
  filterOperatorIsEmpty: "está vazio",
  filterOperatorIsNotEmpty: "não está vazio",
  filterOperatorIsAnyOf: "é qualquer de",

  // Filter values text
  filterValueAny: "qualquer",
  filterValueTrue: "verdadeiro",
  filterValueFalse: "falso",

  // Column menu text
  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Mostrar colunas",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Esconder",
  columnMenuUnsort: "Desordenar",
  columnMenuSortAsc: "Ordenar ascendente",
  columnMenuSortDesc: "Ordenar descendente",

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: "mostrar filtros",
  columnHeaderSortIconLabel: "Ordenar",

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} linas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  // Total row amount footer text
  footerTotalRows: "Total Rows:",

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "Seleção de Checkbox",
  checkboxSelectionSelectAllRows: "Selecionar todas as linhas",
  checkboxSelectionUnselectAllRows: "Deselecionar todas as linhas",
  checkboxSelectionSelectRow: "Selecionar linha",
  checkboxSelectionUnselectRow: "Deselecionar linha",

  // Boolean cell text
  booleanCellTrueLabel: "sim",
  booleanCellFalseLabel: "não",

  // Actions cell more text
  actionsCellMore: "mais",

  // Column pinning text
  pinToLeft: "Fixar à esquerda",
  pinToRight: "Fixar à direita",
  unpin: "Desfixar",

  // Tree Data
  treeDataGroupingHeaderName: "Grupo",
  treeDataExpand: "ver crianças",
  treeDataCollapse: "esconder crianças",

  // Grouping columns
  groupingColumnHeaderName: "Grupo",
  groupColumn: (name) => `Grupo por ${name}`,
  unGroupColumn: (name) => `Não agrupar por ${name}`,

  // Master/detail
  detailPanelToggle: "Alternar painel de detalhes",
  expandDetailPanel: "Expandir",
  collapseDetailPanel: "Colapsar",

  // Used core components translation keys
  MuiTablePagination: {},

  // Row reordering text
  rowReorderingHeaderName: "Reordenação de linhas",

  // Aggregation
  aggregationMenuItemHeader: "Agregação",
  aggregationFunctionLabelSum: "soma",
  aggregationFunctionLabelAvg: "média",
  aggregationFunctionLabelMin: "minimo",
  aggregationFunctionLabelMax: "máximo",
  aggregationFunctionLabelSize: "tamanho",
};
