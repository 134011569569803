import { createContext } from "react";

export const ClinicaContext = createContext({
  id: "",
  nome: "",
  cidade: "",
  endereco: "",
  ilha: "",
  contacto: "",
  email: "",
  nif: "",
  nrCertidao: "",
  logotipo: "",
  ativo: "",
  dashboard: "",
  emTransicao: false,
});
