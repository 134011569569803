import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ModalErrosDfe.scss";

function ModalErrosDfe({ dadosModal, handleCloseModal, open }) {
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="fluxo-atendimento__modal__box" id="modalErrosDfe">
        <h1 className="fluxo-atendimento__modal__titulo">Erros do DFE</h1>
        <div className="modalErrosDfe__mainContainer">
          <ul>
            {dadosModal.erros.map((e) => (
              <li>{e.description}</li>
            ))}
          </ul>
        </div>
        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleCloseModal}
            style={{ display: "block" }}
          >
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalErrosDfe;
