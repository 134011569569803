import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { funcionarioSchema } from "../../../shared/util/schemas";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import { AuthContext } from "../../../shared/context/auth-context";

//Mui icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";
import "../../../style/formError.scss";
import "../../clientes/new/NewCliente.scss";

function EditFuncionario() {
  const { startLoading, stopLoading } = useLoading();

  const [file, setFile] = useState("");
  const [initialImage, setInitialImage] = useState("");
  const [formikInitialValues, setFormikInitialValues] = useState(null);

  const { error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const funcionarioId = useParams().funcionarioId;
  const navigate = useNavigate();
  const [dataNascimento, setDataNascimento] = useState(
    moment().subtract(18, "years")
  );

  const handleCancel = () => {
    resetForm();
    navigate("/funcionarios");
  };

  useEffect(() => {
    const fetchFuncionarios = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/${funcionarioId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDataNascimento(responseData.funcionario.data_nasc);
        setFormikInitialValues({
          nome: responseData.funcionario.nome,
          genero: responseData.funcionario.genero,
          email: responseData.funcionario.email,
          contacto2: responseData.funcionario.contacto2,
          contacto: responseData.funcionario.contacto,
          observacao: responseData.funcionario.observacao,
          endereco: responseData.funcionario.endereco,
          cargo: responseData.funcionario.cargo,
          nif: responseData.funcionario.nif,
          identificacao: responseData.funcionario.identificacao,
          nr_beneficiario: responseData.funcionario.nr_beneficiario,
          foto: responseData.funcionario.foto,
        });
        if (responseData.funcionario.foto) {
          setInitialImage(
            `${process.env.REACT_APP_BACKEND_LINK}/${responseData.funcionario.foto}`
          );
        } else {
          setInitialImage(
            "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
          );
        }
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro buscando os funcionários. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchFuncionarios();
  }, [sendRequest, funcionarioId, auth.token]);

  const onSubmit = async (values, actions) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("nome", values.nome);
      formData.append("genero", values.genero);
      formData.append("data_nasc", dataNascimento);
      formData.append("email", values.email);
      formData.append("contacto2", values.contacto2);
      formData.append("contacto", values.contacto);
      formData.append("observacao", values.observacao);
      formData.append("foto", file);
      formData.append("endereco", values.endereco);
      formData.append("cargo", values.cargo);
      formData.append("nif", values.nif);
      formData.append("identificacao", values.identificacao);
      formData.append("nr_beneficiario", values.nr_beneficiario);
      formData.append("ativo", true);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/${funcionarioId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      actions.resetForm();
      toast.success("Funcionario atualizado com sucesso!");
      navigate("/funcionarios");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro atualizando os dados do funcionario. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: formikInitialValues || {
      nome: "",
      genero: "feminino",
      email: "",
      contacto2: "",
      contacto: "",
      observacao: "",
      endereco: "",
      cargo: "",
      nif: "",
      identificacao: "",
      nr_beneficiario: "",
    },
    enableReinitialize: true,
    validationSchema: funcionarioSchema,
    onSubmit,
  });

  return (
    <>
      <div className="new-cliente">
        <Sidebar />
        <div className="newContainer">
          <Navbar
            title="Editar Funcionario"
            icon={PersonOutlineOutlinedIcon}
            paths={[
              { nome: "Funcionarios", link: `../..` },
              { nome: "Editar" },
            ]}
          />
          <ErrorModal error={error} onClear={clearError} />
          <div className="bottom caixa">
            <div className="bottom-left">
              <img src="/images/medico-ficha.png" alt="" />
            </div>
            <div className="bottom-right">
              <div className="imagePicker takes1-2">
                <div className="imagePickerContainer">
                  <img
                    src={file ? URL.createObjectURL(file) : initialImage}
                    alt=""
                  />
                  <label className="icon-file" htmlFor="file">
                    <DriveFolderUploadOutlined className="icon" />
                  </label>
                </div>

                <input
                  className="input"
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              <div className="takes2-5 form-input">
                <input
                  id="nome"
                  name="nome"
                  type="text"
                  placeholder="Joao Sousa"
                  value={values.nome}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nome && touched.nome ? "input-error input" : "input"
                  }
                />
                <label className="label" htmlFor="nome">
                  Nome*
                </label>
                {errors.nome && touched.nome ? (
                  <p className="error-message">{errors.nome}</p>
                ) : null}
              </div>

              <div className="takes5-7">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    className="dataNascimento__cliente"
                    label="Data Nascimento*"
                    inputFormat="DD-MM-YYYY"
                    value={dataNascimento}
                    onChange={(value) => setDataNascimento(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div className="form-input takes2-5 row2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="joao@gmail.com"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label">Email</label>
                {errors.email && touched.email ? (
                  <p className="error-message" htmlFor="email">
                    {errors.email}
                  </p>
                ) : null}
              </div>

              <div className="form-input takes5-7 row2">
                <select
                  id="genero"
                  name="genero"
                  value={values.genero}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input"
                >
                  <option value="feminino">Feminino</option>
                  <option value="masculino">Masculino</option>
                  <option value="outro">Outro</option>
                </select>
              </div>

              <div className="form-input takes1-3">
                <input
                  id="endereco"
                  name="endereco"
                  type="text"
                  placeholder="Mindelo"
                  value={values.endereco}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.endereco && touched.endereco
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="endereco">
                  Endereço
                </label>
                {errors.endereco && touched.endereco ? (
                  <p className="error-message">{errors.endereco}</p>
                ) : null}
              </div>

              <div className="form-input takes3-5">
                <input
                  id="cargo"
                  name="cargo"
                  type="text"
                  placeholder="Ferreiro"
                  value={values.cargo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.cargo && touched.cargo
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="cargo">
                  Profissão
                </label>
                {errors.cargo && touched.cargo ? (
                  <p className="error-message">{errors.cargo}</p>
                ) : null}
              </div>

              <div className="form-input takes5-7">
                <input
                  id="contacto"
                  name="contacto"
                  type="text"
                  placeholder="5999999"
                  value={values.contacto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.contacto && touched.contacto
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="contacto">
                  Contacto*
                </label>
                {errors.contacto && touched.contacto ? (
                  <p className="error-message">{errors.contacto}</p>
                ) : null}
              </div>

              <div className="form-input takes1-3">
                <input
                  id="contacto2"
                  name="contacto2"
                  type="text"
                  placeholder="2313131"
                  value={values.contacto2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.contacto2 && touched.contacto2
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="contacto2">
                  Contacto 2
                </label>
                {errors.contacto2 && touched.contacto2 ? (
                  <p className="error-message">{errors.contacto2}</p>
                ) : null}
              </div>

              <div className="form-input takes3-5">
                <input
                  id="identificacao"
                  name="identificacao"
                  type="text"
                  placeholder="1333333"
                  value={values.identificacao}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.identificacao && touched.identificacao
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="identificacao">
                  BI / CNI*
                </label>
                {errors.identificacao && touched.identificacao ? (
                  <p className="error-message">{errors.identificacao}</p>
                ) : null}
              </div>

              <div className="form-input takes5-7">
                <input
                  id="nif"
                  name="nif"
                  type="text"
                  placeholder="1333333"
                  value={values.nif}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nif && touched.nif ? "input-error input" : "input"
                  }
                />
                <label className="label" htmlFor="nif">
                  Nif*
                </label>
                {errors.nif && touched.nif ? (
                  <p className="error-message">{errors.nif}</p>
                ) : null}
              </div>

              <div className="form-input takes1-3">
                <input
                  id="nr_beneficiario"
                  name="nr_beneficiario"
                  type="text"
                  placeholder="1333333"
                  value={values.nr_beneficiario}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nr_beneficiario && touched.nr_beneficiario
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="nr_beneficiario">
                  Nr Beneficiário
                </label>
                {errors.nr_beneficiario && touched.nr_beneficiario ? (
                  <p className="error-message">{errors.nr_beneficiario}</p>
                ) : null}
              </div>

              <div className="form-input takes1-7">
                <textarea
                  rows="4"
                  cols="50"
                  id="observacao"
                  name="observacao"
                  type="textarea"
                  placeholder="Observações"
                  value={values.observacao}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.observacao && touched.observacao
                      ? "input-error input"
                      : "input"
                  }
                />
                {errors.observacao && touched.observacao ? (
                  <p className="error-message">{errors.observacao}</p>
                ) : null}
              </div>

              <div className="botoes">
                <button className="cancel-btn" onClick={handleCancel}>
                  Cancelar
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={handleSubmit}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditFuncionario;
