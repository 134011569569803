//MUI
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const motivosDve = [
  { cod: 2, description: "Retificação - Art.º 65, n.º 2 CIVA" },
  { cod: 3, description: "Retificação - Art.º 65, n.º 3 CIVA" },
  { cod: 6, description: "Retificação - Art.º 65, n.º 6 CIVA" },
  { cod: 7, description: "Retificação - Art.º 65, n.º 7 CIVA" },
  { cod: 8, description: "Retificação - Art.º 65, n.º 8 CIVA" },
  { cod: 9, description: "Retificação - Art.º 65, n.º 9 CIVA" },
  { cod: 0, description: "Outro" },
];

function ModalMotivoDveReembolso({
  open,
  handleCloseModal,
  modalData,
  handleCancelarRecebimentoEntrada,
}) {
  const [motivoDve, setMotivoDve] = useState(motivosDve[0]);
  const [erro, setErro] = useState("");
  const [outro, setOutro] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const motivosDveChangeHandler = (e) => {
    const selectedMotivo = motivosDve.find(
      (m) => m.cod === parseInt(e.target.value)
    );
    setMotivoDve(selectedMotivo);
  };

  const beforeSubmitHandler = () => {
    if (motivoDve.cod === 0 && motivoDve.description.trim().length < 10)
      setErro("Motivo de emissão da DVE teve ter 10 ou mais caracteres!");
    else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const submitHandler = () => {
    setIsSubmitting(true);
    if (motivoDve.cod === 0) {
      handleCancelarRecebimentoEntrada(modalData, true, {
        cod: motivoDve.cod,
        description: outro.trim(),
      });
    } else {
      handleCancelarRecebimentoEntrada(modalData, true, motivoDve);
    }

    /////////////////////////////////
    setIsSubmitting(false);
    handleCloseModal();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box motivoDve__modal"
          id="modal__motivoDve"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Motivo emissão de DVE
          </h1>

          <div className="motivoDve__modal__container">
            <select
              onChange={motivosDveChangeHandler}
              className="motivoDve__modal__select"
            >
              {motivosDve.map((m) => (
                <option key={m.cod} value={m.cod}>
                  {m.description}
                </option>
              ))}
            </select>

            {motivoDve.cod === 0 && (
              <textarea
                className="motivoDve__modal__textarea"
                cols={4}
                rows={4}
                onChange={(e) => setOutro(e.target.value)}
                value={outro}
                placeholder="Descrição do motivo de emissão da DVE"
              />
            )}
          </div>

          <div className="motivoDve__modal__erro">{erro && erro}</div>

          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={beforeSubmitHandler}
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </span>

            <span
              className="cancel-btn"
              onClick={handleCloseModal}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalMotivoDveReembolso;
