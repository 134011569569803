import { useState, memo } from "react";
import ValorDataCaixa from "./ValorDataCaixa";

import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";
function CreditoTab({
  valor,
  handleCloseModalReceber,
  receberHandler,
  totalPorReceber,
  caixas,
  parcela,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cartao, setCartao] = useState("Vinti4");
  const [dadosValorDataCaixa, setDadosValorDataCaixa] = useState({
    valor: valor,
    data: new Date(),
    caixa: caixas[0].id,
    observacao: "",
  });

  const changeValorDataCaixa = (data) => {
    setDadosValorDataCaixa(data);
  };

  const preReceberHandler = (e, emitir) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      receberHandler(
        {
          quantiaPaga: Number(dadosValorDataCaixa.valor),
          metodoPagamento: "Cartão de crédito",
          cartao: cartao,
          dataPagamento: dadosValorDataCaixa.data,
          caixa: dadosValorDataCaixa.caixa,
          parcelaPaga: dadosValorDataCaixa.valor === valor,
          quantiaPorPagar: valor - dadosValorDataCaixa.valor,
          observacao: dadosValorDataCaixa.observacao,
        },
        emitir
      );
    }
  };

  const cartaoChangeHandler = (value) => {
    setCartao(value);
  };

  return (
    <div className="credito__tab__container">
      <InputSelect
        handleChange={cartaoChangeHandler}
        label="Cartão"
        initialValue={cartao}
        items={["Vinti4", "Visa", "MasterCard"]}
      />
      <ValorDataCaixa
        valor={valor}
        changeValorDataCaixa={changeValorDataCaixa}
        caixas={caixas}
        totalPorReceber={totalPorReceber}
        tab="credito"
      />

      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalReceber}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        <span
          className="blue-button"
          disabled={isSubmitting}
          onClick={preReceberHandler}
        >
          {isSubmitting ? "Recebendo..." : "Receber"}
        </span>

        {!parcela.documentoEletronico &&
          (parcela.cancelamentosDocumentosEletronicos?.length === 0 ||
            (parcela.cancelamentosDocumentosEletronicos?.length > 0 &&
              parcela.cancelamentosDocumentosEletronicos[
                parcela.cancelamentosDocumentosEletronicos?.length - 1
              ]?.NCE?.succeeded === true)) && (
            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={preReceberHandler.bind(null, null, "emitirFRE")}
            >
              {isSubmitting ? "Recebendo..." : "Receber e emitir FRE"}
            </span>
          )}
      </div>
    </div>
  );
}

export default memo(CreditoTab);
