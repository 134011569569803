import { useState } from "react";
import "../../../../shared/css/ElementoPlanoOrcamentoAvaliacao.scss";

import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
function ElementoOrcamento(props) {
  const [beneficio, setBeneficio] = useState();

  useEffect(() => {
    setBeneficio(props.beneficio);
  }, [
    props.beneficio,
    props.tratamento,
    props.dentesTratados,
    props.tratamentoNaoCoberto,
  ]);

  return (
    <>
      <div>
        <span
          className={`elementoOrcamento__nomeTratamento ${
            !props.show ? "elementoOrcamento__span__notToShow" : ""
          }`}
        >
          {props.tratamento.designacao}
        </span>
        <div className="elementoOrcamento__facesEDentes">
          <span
            className={`${
              !props.show ? "elementoOrcamento__span__notToShow" : ""
            }`}
          >
            {props.dente}{" "}
          </span>
          {props.faces.length > 0 ? ` - ${props.faces.join(", ")}` : ""}
        </div>
      </div>

      {beneficio === "INPS" && (
        <>
          <span
            className={`${
              !props.show ? "elementoOrcamento__span__notToShow" : ""
            }`}
          >
            {props.tratamentoNaoCoberto
              ? ""
              : Intl.NumberFormat("pt-BR").format(
                  props.tratamento.preco_segurado
                )}
          </span>

          <span
            className={`${
              !props.show ? "elementoOrcamento__span__notToShow" : ""
            }`}
          >
            {props.tratamentoNaoCoberto
              ? ""
              : Intl.NumberFormat("pt-BR").format(
                  props.tratamento.comparticipacao_inps
                )}
          </span>

          <span
            className={`${
              !props.show ? "elementoOrcamento__span__notToShow" : ""
            }`}
          >
            {props.tratamentoNaoCoberto
              ? Intl.NumberFormat("pt-BR").format(
                  props.tratamento.preco_particular
                )
              : Intl.NumberFormat("pt-BR").format(
                  props.tratamento.preco_beneficiario
                )}
          </span>
        </>
      )}

      {beneficio === "Particular" && (
        <span>
          {Intl.NumberFormat("pt-BR").format(props.tratamento.preco_particular)}
        </span>
      )}

      <div className="elemento-avaliacao-btns-container">
        {props.show ? (
          <Tooltip title="Remover">
            <button
              className="btn-acoes-avaliacao btn-delete"
              onClick={() => props.removeService(props.id)}
            >
              <DeleteIcon className="mdi mdi-delete" />
              <DeleteForeverIcon className="mdi mdi-delete-empty" />
              {/* <span>Excluir</span> */}
            </button>
          </Tooltip>
        ) : (
          <Tooltip title="Adicionar">
            <button
              className="btn-acoes-avaliacao btn-adicionar"
              onClick={() => props.addService(props.id)}
            >
              <AddIcon className="mdi mdi-adicionar" />
              <AddCircleOutlineIcon className="mdi mdi-adicionar-empty" />
              {/* <span>Adicionar</span> */}
            </button>
          </Tooltip>
        )}
      </div>
    </>
  );
}

export default ElementoOrcamento;
