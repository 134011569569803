const validarOrcamento = (serviceList) => {
  let errorMessage = null;

  const itemsOnServiceList = serviceList.filter((s) => s.show === true);

  if (itemsOnServiceList.length === 0)
    errorMessage = "Mantenha pelo menos um procedimento!";

  return errorMessage;
};

export default validarOrcamento;
