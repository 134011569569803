import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import "./ModalStockHistoricoSaidas.scss";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { generateExcelFile } from "../../../shared/components/excelUtils/exportExcel";
//MUI DATATABLE
import { DataGrid } from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";
import { AuthContext } from "../../../shared/context/auth-context";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

const columns = [
  { field: "produto", headerName: "Produto", flex: 4 },
  { field: "qtdRetirada", headerName: "Qtd Retirada", flex: 1 },
  { field: "respMovimento", headerName: "Retirada por", flex: 1 },
  { field: "authorizer", headerName: "Autorizado por", flex: 1 },
  { field: "data", headerName: "Data", flex: 1 },
];

function ModalStockHistoricoSaidas({
  handleCloseModalStockHistoricoSaidas,
  sendRequest,
  open,
  clinicaId,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [rows, setRows] = useState([]);
  const [rowsPermanent, setRowsPermanent] = useState([]);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(1, "months")
  );
  const [dataFinal, setDataFinal] = useState(moment());

  const auth = useContext(AuthContext);
  useEffect(() => {
    const fetchStock = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/clinica/saidas/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const tempRows = [];
        const tempFilteredRows = [];

        responseData.stock &&
          responseData.stock.historicoMovimentos.forEach((mv) => {
            if (mv.saida) {
              tempRows.push({
                id: mv.id,
                produto: mv.produto.produto,
                qtdRetirada: mv.quantidade,
                respMovimento:
                  (mv.responsavelMovimentoUser &&
                    mv.responsavelMovimentoUser.name) ||
                  (mv.responsavelMovimentoFuncionario &&
                    mv.responsavelMovimentoFuncionario.nome),
                authorizer: mv.beneficiario && mv.beneficiario.name,
                data: moment(mv.data).format("DD-MM-YYYY HH:mm"),
                dataSemFiltrar: mv.data,
              });

              if (
                moment(mv.data).isBetween(
                  moment().subtract(1, "months").startOf("day"),
                  moment().endOf("day"),
                  null,
                  "[]"
                )
              ) {
                tempFilteredRows.push({
                  id: mv.id,
                  produto: mv.produto.produto,
                  qtdRetirada: mv.quantidade,
                  respMovimento:
                    (mv.responsavelMovimentoUser &&
                      mv.responsavelMovimentoUser.name) ||
                    (mv.responsavelMovimentoFuncionario &&
                      mv.responsavelMovimentoFuncionario.nome),
                  authorizer: mv.beneficiario && mv.beneficiario.name,
                  data: moment(mv.data).format("DD-MM-YYYY HH:mm"),
                  dataSemFiltrar: mv.data,
                });
              }
            }
          });

        tempRows.sort(
          (a, b) =>
            moment(b.data, "DD-MM-YYYY HH:mm").toDate() -
            moment(a.data, "DD-MM-YYYY HH:mm").toDate()
        );

        tempFilteredRows.sort(
          (a, b) =>
            moment(b.data, "DD-MM-YYYY HH:mm").toDate() -
            moment(a.data, "DD-MM-YYYY HH:mm").toDate()
        );

        setRows(tempFilteredRows);
        setRowsPermanent(tempRows);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro buscando os dados das saidas de stock. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchStock();
  }, [sendRequest, clinicaId, auth.token]);

  const dataIncialChangeHandler = (value) => {
    setDataInicial(value);
    filterDataHandler(value, dataFinal);
  };

  const dataFinalChangeHandler = (value) => {
    setDataFinal(value);
    filterDataHandler(dataInicial, value);
  };

  const filterDataHandler = (dtIncial, dtFinal) => {
    const historicoFiltrado = rowsPermanent.filter((item) => {
      return moment(item.dataSemFiltrar).isBetween(
        dtIncial.startOf("day"),
        dtFinal.endOf("day"),
        null,
        "[]"
      );
    });
    setRows(historicoFiltrado);
  };

  const verTodasHandler = () => {
    setRows(rowsPermanent);
  };

  const handleExportHistoricoRetiradas = () => {
    const sheetData = rows.map((r) => {
      return {
        data: r.data,
        produto: r.produto,
        autorizado_por: r.authorizer,
        retirado_por: r.respMovimento,
      };
    });

    generateExcelFile(
      `Historico retiradas ${moment().format("DD-MM-YYYY")}.xlsx`,
      "Stock",
      sheetData
    );
  };
  return (
    <Modal
      open={open}
      onClose={handleCloseModalStockHistoricoSaidas}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box"
        id="historicoSaidasModal__modal"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Historico de retiradas
        </h1>
        <div className="historicoSaidasModal__container">
          <div className="historico__dataFilter__container">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Incial"
                inputFormat="DD-MM-YYYY"
                value={dataInicial}
                onChange={dataIncialChangeHandler}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Final"
                inputFormat="DD-MM-YYYY"
                value={dataFinal}
                onChange={dataFinalChangeHandler}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <span className="blue-button" onClick={verTodasHandler}>
              Ver Todas
            </span>

            <div
              className="financeiro__filtros__filtrar"
              onClick={handleExportHistoricoRetiradas}
            >
              <ArticleIcon />
              <span>Exportar Excel</span>
            </div>
          </div>

          {rows && (
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </div>
          )}
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalStockHistoricoSaidas}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalStockHistoricoSaidas;
