import React, { useEffect, useState, memo, useCallback } from "react";
import "./Debitos.scss";

import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import ListaAtraso from "../listaAtraso/ListaAtraso";
function Debitos({ sendRequest, clinica, auth }) {
  const [contasCorrentes, setContasCorrentes] = useState([]);
  const [totalDivida, setTotalDivida] = useState(0);
  const [listaDivida, setListaDivida] = useState([]);
  const [openModal, setOpenModal] = useState("");
  useEffect(() => {
    const fetchContasCorrentes = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/ematraso/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        let tempListaDividas = [];
        let tempTotalDividas = 0;
        responseData.contasCorrentes.forEach((cc) => {
          cc.contas.forEach((conta) => {
            if (!conta.contaLiquidada && conta.aprovado) {
              conta.parcelas.forEach((par) => {
                if (
                  !par.parcelaPaga &&
                  moment(par.dataLimitePagamento).isBefore(moment(), "day")
                ) {
                  tempListaDividas.push({
                    id: crypto.randomUUID(),
                    cliente: cc.cliente.nome,
                    contacto: cc.cliente.contacto,
                    divida: par.quantiaPorPagar.toLocaleString("pt-Br") + "$00",
                    dataLimitePagamento: moment(par.dataLimitePagamento).format(
                      "DD-MM-YYYY"
                    ),
                    idCliente: cc.cliente.id,
                  });

                  tempTotalDividas += par.quantiaPorPagar;
                }
              });
            }
          });
        });

        setTotalDivida(tempTotalDividas);
        setListaDivida(tempListaDividas);
        setContasCorrentes(responseData.contasCorrentes);
      } catch (err) {}
    };
    fetchContasCorrentes();
  }, [auth.token, clinica.clinica._id, sendRequest]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <div className="administrativoBottomContainers__container administrativoBottomContainers__container--debitos dasboard__middle__Earnings caixa">
        <span className="box-title">Débitos</span>
        {contasCorrentes && (
          <div className="lucro-box">
            <div className="lucro-data">
              <span className="lucro lucro--red">
                <AttachMoneyIcon className="administrativoBottomContainers__debitosEmAtraso__twoDivs__element__iconText--icon" />
                {"  "}
                {totalDivida.toLocaleString("pt-Br")}
              </span>
              <span className="lucro-description">Em atraso</span>
            </div>
            <hr />
            <div className="lucro-data">
              <span className="lucro">
                <PersonIcon className="administrativoBottomContainers__debitosEmAtraso__twoDivs__element__iconText--icon" />{" "}
                {contasCorrentes.length}
              </span>
              <span className="lucro-description">Contas em atraso</span>
            </div>
          </div>
        )}

        <div
          className="button-more-div"
          onClick={(e) => setOpenModal("listaAtraso")}
        >
          <span className="button-more">Mais</span>
          <span className="more-arrow"> {">"} </span>
        </div>
      </div>

      <ListaAtraso
        open={openModal === "listaAtraso"}
        data={listaDivida}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

export default memo(Debitos);
