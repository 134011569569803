import { useState, useEffect, useContext } from "react";
import ListPrescricoes from "../../Lists/ListPrescricoes/ListPrescricoes";
import moment from "moment/moment";

import { AuthContext } from "../../../../../../shared/context/auth-context";

import toast from "react-hot-toast";
import { useLoading } from "../../../../../../shared/context/LoadingContext";
function PrescricoesComponent({
  clienteId,
  sendRequest,
  token,
  dadosPrescricao,
}) {
  const { startLoading, stopLoading } = useLoading();
  const auth = useContext(AuthContext);
  const [prescricoes, setPrescricoes] = useState([]);

  useEffect(() => {
    const fetchPrescricoesByCliente = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/prescricoes/cliente/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const temp = responseData.prescricoes.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        const prescricoes_com_data = temp.map((presc) => {
          const data_prescricao = moment(presc.created_at).format(
            "DD-MM-YYYY / HH:mm:ss"
          );
          return (presc = {
            ...presc,
            data_prescricao: data_prescricao,
          });
        });

        setPrescricoes(prescricoes_com_data);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro na busca das prescrições do cliente. Por favor tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchPrescricoesByCliente();
  }, [sendRequest, clienteId, auth.token]);

  const handleApagar = async (id) => {
    try {
      startLoading();
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/prescricoes/${id}`,
        "DELETE",
        {},
        {
          Authorization: "Bearer " + token,
        }
      );

      setPrescricoes((currentPrescricoes) => {
        return currentPrescricoes.filter((el) => {
          return el.id !== id;
        });
      });

      toast.success("Eliminação da prescrição efetuada com sucesso!");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro na eliminação da prescrição. Por favor tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const prescricaoData = (cliente, clinica, medico, data) => {
    const dados = {
      cliente,
      clinica,
      medico,
      data,
    };
    dadosPrescricao(dados);
  };

  return (
    <>
      {prescricoes.length !== 0 && (
        <>
          <ListPrescricoes
            prescricaoData={prescricaoData}
            data={prescricoes}
            handleApagar={handleApagar}
          />
        </>
      )}
      {prescricoes.length === 0 && (
        <div className="no_data_div">Nenhuma Prescrição encontrada.</div>
      )}
    </>
  );
}

export default PrescricoesComponent;
