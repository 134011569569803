const dataGridData = (data) => {
  const rows = data.map((dt) => ({
    id: dt.id,
    tipo: dt.tipo,
    data: dt.data,
    hora: dt.hora,
    nrDocumento: dt.nrDocumento,
    serie: dt.serie,
    messages: dt.messages,
    estado: dt.estado,
    IUD: dt.IUD,
    IUDCancelar: dt.IUDCancelar,
    IUDReceber: dt.IUDReceber,
    usuarioEmissor: dt.usuarioEmissor,
    parcela: dt.parcela,
    conta: dt.conta,
    contaCorrente: dt.contaCorrente,
    cliente: dt.cliente,
    clienteFornecedor: dt.clienteFornecedor,
    dataLimitePgamento: dt.dataLimitePgamento,
    valor: dt.valor,
  }));

  return rows;
};

export default dataGridData;
