import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfRceStyle.scss";
import moment from "moment/moment";
import QrCode from "../../qrCode/QrCode";
function PdfRce({ reciboData, showPreviousPage }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Fatura-${reciboData.cliente.nome}_${moment(
      new Date()
    ).format("DD-MM-YYYY")}`,
    onAfterPrint: () => {
      showPreviousPage();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  return (
    <>
      <div ref={componentRef} className="pdf-rce-wrapper">
        <div className="pdf-orcamento-cabecalho" id="pdf-rce-cabecalho">
          <div className="pdf-orcamento-cabecalho-left">
            <img
              src={`${process.env.REACT_APP_BACKEND_LINK}/${reciboData.clinica.logotipo}`}
              alt=""
            />
          </div>

          <div className="pdf-orcamento-cabecalho-right pdf-rce-cabecalho-right">
            <span>{reciboData.clinica.nome}</span>
            {reciboData.medico_responsavel && (
              <span>
                {reciboData.medico_responsavel.genero === "masculino"
                  ? "Dr. "
                  : "Dra. "}
                {reciboData.medico_responsavel.name}/
                {reciboData.medico_responsavel.profissao}
              </span>
            )}
            <span>Telef: {reciboData.clinica.contacto}</span>
            <span>{reciboData.clinica.endereco}</span>
            <span>NIF: {reciboData.clinica.nif}</span>
          </div>
        </div>

        <div className="pdf-rce-cabecalho2">
          <div className="pdf-rce__infoCliente">
            <div>
              <span>Cliente: </span>
              <span className="blackText">{reciboData.cliente.nome}</span>
            </div>

            <div>
              <span>Morada: </span>
              <span className="blackText">{reciboData.cliente.endereco}</span>
            </div>

            <div>
              <span>Contacto: </span>
              <span className="blackText">{reciboData.cliente.contacto}</span>
            </div>
          </div>
        </div>

        <div className="pdf-rce-cabecalho3">
          <div className="pdf-rce__reciboData">
            <div>
              <span>Documento: </span>
              <span className="blackText">
                {reciboData.tipoDocumento +
                  "/" +
                  moment(reciboData.dataDocumento).year() +
                  "/" +
                  reciboData.serie +
                  "/" +
                  reciboData.nrDocumento}
              </span>
            </div>
            <div>
              <span>Data: </span>
              <span className="blackText">
                {moment(reciboData.dataDocumento).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>

        <div className="divFaturaTabela">
          <table>
            <tbody>
              <tr className="headingLineFatura">
                <th>Referencia</th>
                <th>Nº Doc Interno</th>
                <th className="right-td">Montante Pago</th>
              </tr>
              <tr className="normalLine primeiraLinha">
                <td>{reciboData.referencia.replace(/\.xml$/, "")}</td>
                <td>{reciboData.nrDocumento}</td>
                <td className="right-td">
                  {reciboData.valor.toLocaleString("pt-br")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="pdf-rce-rodape">
          <hr />
          <div className="pdf-rce-rodape__container">
            <div className="pdf-rce-rodape__container2">
              <span className="pdf-rce-rodape__container2__titulo">
                Informações de Pagamento:
              </span>
              <span>Data Pagamento</span>
              <span className="pdf-rce-rodape__container2__item--dark">
                {moment(reciboData.dataPagamento).format("DD/MM/YYYY")}
              </span>
              <span>Meio Pagamento</span>
              <span className="pdf-rce-rodape__container2__item--dark">
                {reciboData.metodoPagamento}
              </span>
              <span>Total</span>
              <span className="pdf-rce-rodape__container2__item--dark">
                {reciboData.valor.toLocaleString("pt-br")}$00
              </span>
            </div>

            <div className="pdf-rce-rodape__container1">
              <QrCode
                link={reciboData.entryName.replace(/\.xml$/, "")}
                estilo={{ alignItems: "flex-end" }}
              />
            </div>
          </div>
          <div
            className={`pdf-rce-rodape__elaboradoPor ${
              reciboData.usuarioEmissor
                ? ""
                : "pdf-rce-rodape__elaboradoPor--semEmissor"
            }`}
          >
            <span>Elaborado Por</span>
            {reciboData.usuarioEmissor ? (
              <span className="pdf-rce-rodape__elaboradoPor__emissor">
                {reciboData.usuarioEmissor +
                  " - " +
                  moment(reciboData.dataDocumento).format("DD/MM/YYYY")}
              </span>
            ) : (
              <hr />
            )}
          </div>
          <div className="pdf-rce-rodape__poweredBy">
            Powered by: HealthTech CV
          </div>
        </div>
      </div>
    </>
  );
}

export default PdfRce;
