import React from "react";
import Modal from "./Modal";

const ErrorModal = (props) => {
  return (
    <Modal
      onCancel={props.onClear}
      header="Ocorreu um erro!"
      show={!!props.error}
      icon={"error"}
      footer={
        <span onClick={props.onClear} className="modal__okayBtn">
          Okay
        </span>
      }
    >
      <p>{props.error}</p>
    </Modal>
  );
};

export default ErrorModal;
