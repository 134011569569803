import NotificationsNoneOutlinedIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Badge from "@mui/material/Badge";

function NotificationsPopover({ nrNotificacoes, onClick, anchorEl }) {
  return (
    <>
      <Tooltip
        title={
          nrNotificacoes === 1
            ? `Tem uma notificação`
            : nrNotificacoes === 0
            ? "Não tem notificações"
            : `Tem ${nrNotificacoes} notificações`
        }
      >
        <IconButton onClick={nrNotificacoes ? onClick : null}>
          <Badge badgeContent={nrNotificacoes} color="primary">
            <NotificationsNoneOutlinedIcon
              className={nrNotificacoes > 0 ? "sinoComNotificacao" : ""}
            />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
}

export default NotificationsPopover;
