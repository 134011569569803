import { useContext } from "react";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import "../List.scss";

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download"; //Delete icon
//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";

function ListFicheiros({ ficheiros, eliminarFicheiro }) {
  const auth = useContext(AuthContext);
  let content = [];

  if (ficheiros) {
    ficheiros.forEach((row) => {
      content.push(
        <div className="dots__menu__popup">
          {/* Editar */}
          {/* {auth.perm.includes("u-aval") && (
              <div
                className="popOverMenu--option"
                onClick={() => navigate(`/avaliacao/edit/${row.id}`)}
              >
                <span>Editar</span>
                <ModeEditOutlineOutlinedIcon className="popOverMenu--option__icon" />
              </div>
            )} */}
          {/* Desativar Avaliacao */}

          {/* Baixar */}
          {auth.perm.includes("r-anex") && (
            <a
              href={`${process.env.REACT_APP_BACKEND_LINK}/${row.ficheiro}`}
              download
              target="_blank"
              rel="noreferrer"
            >
              <div className="popOverMenu--option">
                <span>Baixar</span>
                <DownloadIcon className="popOverMenu--option__icon" />
              </div>
            </a>
          )}

          {auth.perm.includes("d-anex") && (
            <Popconfirm
              title="Eliminar o Ficheiro"
              description="Tem a certeza que pretende eliminar o ficheiro?"
              icon={<DeleteIcon style={{ color: "red" }} />}
              okText="Sim"
              cancelText="Não"
              onConfirm={eliminarFicheiro.bind(null, row.id, row.ficheiro)}
            >
              <div className="popOverMenu--option">
                <span>Eliminar</span>
                <DeleteIcon className="popOverMenu--option__icon" />
              </div>
            </Popconfirm>
          )}
        </div>
      );
    });
  }

  return (
    <>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Descrição
              </TableCell>

              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Observações
              </TableCell>

              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Criado_por
              </TableCell>

              <TableCell className="tableCell tableCell--heading tableCell--heading--end">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          {ficheiros && (
            <TableBody>
              {ficheiros.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">{row.descricao}</TableCell>

                  <TableCell className="tableCell">{row.observacao}</TableCell>

                  <TableCell className="tableCell">
                    {row.creator.nome}
                  </TableCell>

                  <TableCell className="tableCell">
                    <div className="tableCell__action">
                      {content[index] && (
                        <Popover
                          content={content[index]}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default ListFicheiros;
