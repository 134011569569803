import React from "react";
import { Link } from "react-router-dom";
import "./PageNotFound.scss";

function PageNotFound() {
  return (
    <div className="pageNotFoundContainer">
      <h2>
        A Página que procura não foi encontrada ou não tem permissão para entrar
        nela
      </h2>
      <Link to="/" style={{ textDecoration: "none" }}>
        Voltar à pagina Inicial
      </Link>
    </div>
  );
}

export default PageNotFound;
