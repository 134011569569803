//MUI
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalProcedimentos.scss";
function ModalProcedimentos({
  open,
  handleCloseModalProcedimentos,
  modalProcedimentosData,
}) {
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModalProcedimentos}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box verProcedimentos__modal"
          id="modal__verProcedimentos"
        >
          <h1 className="fluxo-atendimento__modal__titulo">Procedimentos</h1>

          <span className="fluxo-atendimento__modal__designacao">
            Tratamento
          </span>
          <span className="fluxo-atendimento__modal__designacao">
            Dentes/Região
          </span>

          <span className="fluxo-atendimento__modal__designacao">Plano</span>

          <span className="fluxo-atendimento__modal__designacao">Valor</span>

          {modalProcedimentosData.procedimentos &&
            modalProcedimentosData.procedimentos.map((proc, index) => (
              <React.Fragment key={index}>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.tratamento.designacao}
                  {proc.faces && proc.faces > 0
                    ? `- ${proc.faces.length} ${
                        proc.faces.length > 1 ? "faces" : "face"
                      } ${`- ${proc.faces.join(",")}`}`
                    : ""}
                </span>

                <span className="fluxo-atendimento__modal__cell">
                  {proc.dente}
                </span>

                <span className="fluxo-atendimento__modal__cell">
                  {modalProcedimentosData.beneficio}
                </span>
                <span className="fluxo-atendimento__modal__cell">
                  {modalProcedimentosData.beneficio === "Particular"
                    ? proc.tratamento.preco_particular
                    : proc.tratamento.preco_beneficiario}
                </span>
              </React.Fragment>
            ))}

          <div className="orcamento__modal__valores">
            <div className="orcamento__modal__valores--container orcamento__modal__valores--container-1">
              <span className="orcamento__modal__valores__descricao">
                Subtotal
              </span>
              <span className="orcamento__modal__valores__descricao">
                Desconto
              </span>

              <span className="orcamento__modal__valores__descricao">
                Total
              </span>
            </div>
            <div className="orcamento__modal__valores--container orcamento__modal__valores--container-2">
              <span className="orcamento__modal__valores__valor">
                {modalProcedimentosData.subtotal}$00
              </span>

              <span className="orcamento__modal__valores__valor">
                {modalProcedimentosData.desconto}$00
              </span>
              <span className="orcamento__modal__valores__valor orcamento__modal__valores__valor--total">
                {modalProcedimentosData.total}$00
              </span>
            </div>
          </div>

          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span
              className="cancel-btn"
              onClick={handleCloseModalProcedimentos}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalProcedimentos;
