import React from "react";
import PdfImpresso from "./PdfImpresso";

function PdfImpressoWrapper({ showPreviousPage, impressoData }) {
  const showPreviousP = () => {
    showPreviousPage();
  };

  return (
    <div>
      <PdfImpresso
        impressoData={impressoData}
        showPreviousPage={showPreviousP}
      />
    </div>
  );
}

export default PdfImpressoWrapper;
