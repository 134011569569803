import { useState, useEffect } from "react";
import uuid from "react-uuid";
import "./InputNumber.scss";
import "../customInputs.scss";
const randomId = uuid();

function InputNumber({
  initialValue,
  label,
  handleChange,
  min,
  max,
  className,
  notEmpty,
}) {
  const [value, setValue] = useState(0);
  const [maximum, setMaximum] = useState();
  const [minimum, setMinimum] = useState();
  const [errorClass, setErrorClass] = useState(false);

  useEffect(() => {
    setValue(initialValue);
    setMinimum(min);
    setMaximum(max);
  }, [initialValue, min, max]);

  const preChange = (e) => {
    let nextValue = e.target.value;
    if (minimum && e.target.value < minimum) nextValue = minimum;
    else if (maximum && e.target.value > maximum) nextValue = maximum;
    else nextValue = e.target.value;

    if (notEmpty && !nextValue) setErrorClass(true);
    else setErrorClass(false);
    setValue(nextValue);
    handleChange(nextValue);
  };

  return (
    <div className="customInputContainer">
      <input
        type="number"
        value={value}
        className={`customInput inputNumber ${className ? className : ""} ${
          errorClass && "customInputError"
        }`}
        placeholder={label}
        onChange={preChange}
        id={randomId}
      />
      <label className="customInputLabel" htmlFor={randomId}>
        {label}
      </label>
    </div>
  );
}

export default InputNumber;
