import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../shared/context/auth-context";
import AtestadosComponent from "./components/AtestadosComponent";
import PrescricoesComponent from "./components/PrescricoesComponent";
import ImpressosComponent from "./components/ImpressosComponent";
function Documentos({
  clienteId,
  sendRequest,
  dadosPrescricao,
  dadosAtestado,
  dadosImpresso,
}) {
  const [showTabs, setShowTabs] = useState("prescricoes");
  const [openAtestadoModal, setOpenAtestadoModal] = useState(false);
  const auth = useContext(AuthContext);

  const handleCloseModalAtestado = () => {
    setOpenAtestadoModal(false);
  };
  const navigate = useNavigate();
  return (
    <div className="container-outros caixa">
      <div className="container-outros-tabs">
        {auth.perm.includes("c-pres") && (
          <span
            className={`tab-button ${
              showTabs === "prescricoes" && "tab-button--active"
            }`}
            onClick={() => setShowTabs("prescricoes")}
          >
            Prescrições
          </span>
        )}
        {auth.perm.includes("c-ates") && (
          <span
            className={`tab-button ${
              showTabs === "atestado" && "tab-button--active"
            }`}
            onClick={() => setShowTabs("atestado")}
          >
            Atestados
          </span>
        )}

        <span
          className={`tab-button ${
            showTabs === "impressos" && "tab-button--active"
          }`}
          onClick={() => setShowTabs("impressos")}
        >
          Impressos
        </span>
      </div>

      <div className="container-outros-tabs--Container">
        <div className="tabs-container-top">
          {showTabs === "prescricoes" && auth.perm.includes("c-pres") && (
            <span
              className="tabs-container-top-button"
              onClick={() => navigate(`../../../prescricao/${clienteId}`)}
            >
              Gerar Prescrição
            </span>
          )}

          {showTabs === "atestado" && auth.perm.includes("c-ates") && (
            <span
              className="tabs-container-top-button"
              onClick={(e) => setOpenAtestadoModal(true)}
            >
              Gerar Atestado
            </span>
          )}

          {showTabs === "impressos" && (
            <span
              className="tabs-container-top-button"
              onClick={() => navigate(`../../../impresso/${clienteId}`)}
            >
              Gerar Impresso
            </span>
          )}
        </div>

        <div className="tabs-container-content">
          {showTabs === "prescricoes" && auth.perm.includes("c-pres") && (
            <PrescricoesComponent
              dadosPrescricao={dadosPrescricao}
              clienteId={clienteId}
              sendRequest={sendRequest}
              token={auth.token}
            />
          )}
          {showTabs === "atestado" && auth.perm.includes("c-ates") && (
            <AtestadosComponent
              clienteId={clienteId}
              sendRequest={sendRequest}
              open={openAtestadoModal}
              handleCloseModalAtestado={handleCloseModalAtestado}
              dadosAtestado={dadosAtestado}
            />
          )}

          {showTabs === "impressos" && (
            <ImpressosComponent
              clienteId={clienteId}
              sendRequest={sendRequest}
              dadosImpresso={dadosImpresso}
              token={auth.token}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Documentos;
