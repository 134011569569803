import { useState, memo } from "react";
import ValorDataCaixa from "./ValorDataCaixa";

function DinheiroTransferenciaTab({
  valor,
  handleCloseModalReceber,
  receberHandler,
  metodo,
  totalPorReceber,
  caixas,
  beneficioOrcamento,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dadosValorDataCaixa, setDadosValorDataCaixa] = useState({
    valor: valor,
    data: new Date(),
    caixa: caixas[0].id,
    observacao: "",
  });

  const changeValorDataCaixa = (data) => {
    setDadosValorDataCaixa(data);
  };

  const preReceberHandler = (e, emitir) => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      receberHandler(
        {
          quantiaPaga: Number(dadosValorDataCaixa.valor),
          metodoPagamento: metodo,
          dataPagamento: dadosValorDataCaixa.data,
          caixa: dadosValorDataCaixa.caixa,
          parcelaPaga: dadosValorDataCaixa.valor === valor,
          quantiaPorPagar: valor - dadosValorDataCaixa.valor,
          observacao: dadosValorDataCaixa.observacao,
        },
        emitir
      );
    }
  };

  return (
    <div className="tabContainer tabContainer--dinheiro">
      <ValorDataCaixa
        valor={valor}
        changeValorDataCaixa={changeValorDataCaixa}
        caixas={caixas}
        totalPorReceber={totalPorReceber}
        tab="dinheiro"
      />

      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalReceber}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        <span
          className="blue-button"
          disabled={isSubmitting}
          onClick={preReceberHandler}
        >
          {isSubmitting ? "Recebendo..." : "Receber"}
        </span>

        {/* Recebimentos em dinheiro de orcamentos particulares não têm necessidade de emitr Fre. Apenas Recibo */}
        {beneficioOrcamento === "INPS" && (
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={preReceberHandler.bind(null, null, "emitirFRE")}
          >
            {isSubmitting ? "Recebendo..." : "Receber e emitir FRE"}
          </span>
        )}

        {beneficioOrcamento === "Particular" && (
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={preReceberHandler.bind(null, null, "emitirRecibo")}
          >
            {isSubmitting ? "Recebendo..." : "Receber e emitir Recibo"}
          </span>
        )}
      </div>
    </div>
  );
}

export default memo(DinheiroTransferenciaTab);
