import React from "react";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

function ElementoFicheiro({ ficheiro, i, changeDescricao, changeObservacao }) {
  const [descricao, setDescricao] = useState("");
  const [observacao, setObservacao] = useState("");
  useEffect(() => {
    setDescricao(ficheiro.descricao);
    setObservacao(ficheiro.observacao);
  }, [ficheiro]);

  const descricaoChangeHandler = (e) => {
    setDescricao(e.target.value);
    changeDescricao(e.target.value, i);
  };

  const observacaoChangeHandler = (e) => {
    setObservacao(e.target.value);
    changeObservacao(e.target.value, i);
  };

  return (
    <div className="modalAdicionarFicheiros__ficheiro-container">
      <div className="modalAdicionarFicheiros__ficheiro-container--1">
        <div className="modalAdicionarFicheiros__ficheiro-container--1__container-imagem">
          <span className="modalAdicionarFicheiros__ficheiro-container--1__container-imagem--fileType">
            {ficheiro.file.type.split("/")[1]}
          </span>
          <svg
            className="modalAdicionarFicheiros__ficheiro-container--1__container-imagem--svg"
            width="70px"
            height="70px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 14H14M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
              stroke="#fff"
              stroke-width="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="modalAdicionarFicheiros__ficheiro-container--1__container-info">
          <div className="form-input">
            <input
              id={`descricao-${i}`}
              name={`descricao-${i}`}
              type="text"
              placeholder="Designação"
              value={descricao}
              onChange={descricaoChangeHandler}
              className="input"
            />
            <label className="label" htmlFor={`descricao-${i}`}>
              Descrição*
            </label>
          </div>

          <div className="form-input">
            <textarea
              name={`observacao-${i}`}
              type="text"
              placeholder="Observação"
              value={observacao}
              onChange={observacaoChangeHandler}
              className="input"
              rows={5}
            />
          </div>
        </div>
      </div>
      <div className="modalAdicionarFicheiros__ficheiro-container--2">
        <div className="modalAdicionarFicheiros__ficheiro-container--2--1">
          <span>{Number((ficheiro.file.size / 1000000).toFixed(2))}MB</span>
          <DeleteIcon className="modalAdicionarFicheiros__ficheiro-container--2--1__deleteIcon" />
        </div>
        <div className="modalAdicionarFicheiros__ficheiro-container--2--2"></div>
      </div>
    </div>
  );
}

export default ElementoFicheiro;
