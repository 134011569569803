import React from "react";
import { useState, useEffect, useContext } from "react";
import ModalAdicionarFicheiros from "./components/ModalAdicionarFicheiros";
import ListFicheiros from "../Lists/ListFicheiros/ListFicheiros";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../../shared/context/auth-context";
import toast from "react-hot-toast";
import "./Ficheiros.scss";
import { useLoading } from "../../../../../shared/context/LoadingContext";

function Ficheiros({ clienteId }) {
  const { startLoading, stopLoading } = useLoading();
  const [openModal, setOpenModal] = useState(false);
  const [ficheiros, setFicheiros] = useState([]);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchFicheiros = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/ficheiros/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setFicheiros(responseData.ficheiros.ficheiros);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro na busca dos ficheiros do cliente. Por favor tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchFicheiros();
  }, [sendRequest, clienteId, auth.token]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const adicionarFicheiros = (novosFicheiros) => {
    setFicheiros(novosFicheiros);
  };

  const eliminarFicheiro = async (idFicheiro, pathFicheiro) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("idFicheiro", idFicheiro);
      formData.append("pathFicheiro", pathFicheiro);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/ficheiros/delete/${clienteId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      toast.success("Ficheiro removido com sucesso");
      const tempFicheiros = [...ficheiros].filter((f) => f.id !== idFicheiro);
      setFicheiros(tempFicheiros);
    } catch (err) {
      toast.error("Houve um erro ao remover o ficheiro");
    } finally {
      stopLoading();
    }
  };

  return (
    <>
      <div>
        <div className="ficheiros__container">
          <span className="blue-button" onClick={(e) => setOpenModal(true)}>
            Adicionar Ficheiros
          </span>
        </div>

        {ficheiros && ficheiros.length > 0 && (
          <ListFicheiros
            ficheiros={ficheiros}
            eliminarFicheiro={eliminarFicheiro}
          />
        )}

        {ficheiros.length === 0 && (
          <div className="ficheiros__container__noFileMessage">
            Nenhum ficheiro associado ao cliente
          </div>
        )}

        {openModal && (
          <ModalAdicionarFicheiros
            open={openModal}
            handleCloseModal={handleCloseModal}
            clienteId={clienteId}
            adicionarFicheiros={adicionarFicheiros}
          />
        )}
      </div>
    </>
  );
}

export default Ficheiros;
