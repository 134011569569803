import { useContext, useEffect, useState } from "react";
// import "./HomeSecretaria.scss";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";

import DashboardIcon from "@mui/icons-material/Dashboard";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import DashboardAdministrativoFinanceiro from "./components/DashboardAdministrativoFinanceiro";
import DashboardAdministrativoTopCategorias from "./components/DashboardAdministrativoTopCategorias";
import DashboardAdministrativoProdutividadeFinanceira from "./components/DashboardAdministrativoProdutividadeFinanceira";
import { useLoading } from "../../../shared/context/LoadingContext";
import "./HomeAdministrativo.scss";
import StockControllAdministrativo from "./components/StockControllAdministrativo";
import toast from "react-hot-toast";
import "../../../shared/css/Checkbox.scss";
function HomeAdministrativo() {
  const { startLoading, stopLoading } = useLoading();

  const [contasCorrentes, setContasCorrentes] = useState();
  const [despesas, setDespesas] = useState();
  const [categoriasFinanceiro, setCategoriasFinanceiro] = useState();
  const [stock, setStock] = useState();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const { sendRequest } = useHttpClient();
  ///////////////////////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [
          contasCorrentesResponse,
          despesasResponse,
          categoriasResponse,
          stockResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/dashadministrativo/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/despesas/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/clinica/emfalta/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        setContasCorrentes(contasCorrentesResponse.contasCorrentes);
        setDespesas(despesasResponse.despesas);
        setCategoriasFinanceiro(categoriasResponse.categoriasFinanceiro);
        setStock(stockResponse.stock);
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        toast.error(
          "Ocorreu um erro ao carregar os dados. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, auth.token, clinica.clinica._id]);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar title="Dashboard" icon={DashboardIcon} />
        <div className="dashboard__ola">
          <span className="dashboard__ola__big">{`Olá, ${auth.nome}!`}</span>
          <span className="dashboard__ola__small">
            Tenha um excelente dia de trabalho &#128522;!
          </span>
        </div>
        {contasCorrentes && despesas && categoriasFinanceiro && (
          <div className="homeContainerAdministrativo">
            <div className="homeAdministrativo__topContainer">
              <DashboardAdministrativoFinanceiro
                cCorrentes={contasCorrentes}
                desp={despesas}
              />
              <DashboardAdministrativoTopCategorias
                desp={despesas}
                catF={categoriasFinanceiro}
              />
            </div>
            <div className="homeAdministrativo__bottomContainer">
              <DashboardAdministrativoProdutividadeFinanceira
                desp={despesas}
                cCorrentes={contasCorrentes}
              />
              <StockControllAdministrativo stock={stock} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeAdministrativo;
