import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import "./DashboardAdministrativoTopCategorias.scss";
import PieChartAdministrativo from "./PieChartAdministrativo";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#ed143d"];
function DashboardAdministrativoTopCategorias({ desp, catF }) {
  const [categoriasFinanceiro, setCategoriasFinanceiro] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (catF) {
      const tempCat = catF.map((c) => {
        return {
          name: c.categoria,
          value: 0,
        };
      });

      desp.forEach((d) => {
        if (d.despesaPaga) {
          tempCat[
            tempCat.findIndex((t) => t.name === d.categoria.categoria)
          ].value += d.valor;
        }
      });

      const filteredTempCat = tempCat
        .filter((t) => t.value > 0)
        .sort((a, b) => b.value - a.value);

      let slicedTempCat;
      if (filteredTempCat.length >= 5) {
        slicedTempCat = filteredTempCat.slice(0, 5);
      } else {
        slicedTempCat = filteredTempCat;
      }

      const tempTotal = slicedTempCat.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      );

      const tempProgressData = slicedTempCat.map((s) => {
        return {
          name: s.name,
          percent: ((s.value / tempTotal) * 100).toFixed(2),
        };
      });

      setTotal(tempTotal.toLocaleString("pt-BR") + "$00");
      setCategoriasFinanceiro(slicedTempCat);
      setProgressData(tempProgressData);
    }
  }, [desp, catF]);

  return (
    <div className="caixa dashboardAdministrativoTopCategorias__container">
      <span className="dashboardAdministrativoFinanceiro__titulo">
        Top 5 Categorias de Despesa
      </span>
      <div className="dashboardAdministrativoTopCategorias__2divs">
        {categoriasFinanceiro && (
          <PieChartAdministrativo data={categoriasFinanceiro} COLORS={COLORS} />
        )}
        <div className="dashboardAdministrativoTopCategorias__percentagesList">
          {progressData && progressData.length > 0 ? (
            <>
              {progressData.map((p, index) => (
                <div
                  className="dashboardAdministrativoTopCategorias__percentagesList__element"
                  key={index}
                >
                  <span>{p.name}</span>
                  <Progress
                    percent={p.percent}
                    size="small"
                    strokeColor={COLORS[index]}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="dashboardAdministrativoTopCategorias__percentagesList__element">
              <span className="dashboardAdministrativoTopCategorias__percentagesList__grey">
                sem dados
              </span>
            </div>
          )}
          <span className="dashboardAdministrativoTopCategorias__percentagesList__grey">
            Total: {total}
          </span>
        </div>
      </div>
    </div>
  );
}

export default DashboardAdministrativoTopCategorias;
