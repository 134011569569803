import { useContext } from "react";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import "../List.scss";

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined"; //gerar Pdf
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon

//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";

function List(props) {
  const auth = useContext(AuthContext);

  const verPrescricao = (id) => {
    const filteredPrescricao = props.data.filter((pres) => {
      return pres.id === id;
    });

    props.prescricaoData(
      filteredPrescricao[0].cliente,
      filteredPrescricao[0].clinica,
      filteredPrescricao[0].medico,
      filteredPrescricao[0].prescricoes
    );
  };

  let content = [];
  if (props.data) {
    props.data.forEach((row) => {
      content.push(
        <div className="dots__menu__popup">
          {auth.perm.includes("d-pres") && (
            <Popconfirm
              title="Apagar prescrição"
              description="Tem a certeza que pretende apagar a prescrição?"
              icon={<DeleteIcon style={{ color: "red" }} />}
              okText="Sim"
              cancelText="Não"
              onConfirm={props.handleApagar.bind(null, row.id)}
            >
              <div className="popOverMenu--option">
                <span>Apagar</span>
                <DeleteIcon className="popOverMenu--option__icon" />
              </div>
            </Popconfirm>
          )}
        </div>
      );
    });
  }

  return (
    <>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell tableCell--heading">
                Médico Responsável
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data Prescrição
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          {props.data && (
            <TableBody>
              {props.data.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">{row.medico.name}</TableCell>
                  <TableCell className="tableCell">
                    {row.data_prescricao}
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="botoes-action">
                      {auth.perm.includes("r-pres") && !row.terminado && (
                        <Tooltip title="Gerar Pdf">
                          <PictureAsPdfOutlinedIcon
                            className="action-button-with-tooltip"
                            onClick={verPrescricao.bind(null, row.id)}
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      )}

                      {content[index] && (
                        <Popover
                          content={content[index]}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default List;
