import { useState, useEffect } from "react";
import "./InputText.scss";
import "../customInputs.scss";

const randomId = crypto.randomUUID();

function InputText({
  initialValue,
  label,
  handleChange,
  className,
  notEmpty,
  id = "",
}) {
  const [value, setValue] = useState("");
  const [errorClass, setErrorClass] = useState(false);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const preChange = (e) => {
    if (notEmpty && !e.target.value) setErrorClass(true);
    else setErrorClass(false);
    setValue(e.target.value);
    handleChange(e.target.value);
  };

  return (
    <div className={`customInputContainer ${className ? className : ""}`}>
      <input
        type="text"
        value={value}
        className={`customInput inputNumber  ${
          errorClass && "customInputError"
        }`}
        placeholder={label}
        onChange={preChange}
        id={randomId + id}
      />
      <label className="customInputLabel" htmlFor={randomId + id}>
        {label}
      </label>
    </div>
  );
}

export default InputText;
