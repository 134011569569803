import React, { useState, useContext, useEffect } from "react";
import "./ModalImportXlsStock.scss";
import moment from "moment";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { AuthContext } from "../../../shared/context/auth-context";

import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import InputNumber from "../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../shared/components/inputs/InputText/InputText";
import { useLoading } from "../../../shared/context/LoadingContext";

function ModalImportXlsStock({
  stock,
  handleCloseModalImportStock,
  sendRequest,
  produtos,
  atualizarStock,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState();
  const [data, setData] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [categoriasStock, setCategoriasStock] = useState();
  const [categoriaStock, setCategoriaStock] = useState();
  const [adicionarComoDespesa, setAdicionarComoDespesa] = useState(false);
  const [custoTotal, setCustoTotal] = useState(0);
  const [caixa, setCaixa] = useState();
  const [caixas, setCaixas] = useState([]);
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [despesaPaga, setDespesaPaga] = useState(true);
  const clinica = useContext(ClinicaContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCategoriasStock = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCategoriasStock(responseData.categoriasStock);
        setCategoriaStock(responseData.categoriasStock[0].id);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro buscando os dados das categorias de stock. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };

    const fetchCategorias = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/despesa/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCategorias(
          responseData.categoriasFinanceiro.map((cat) => {
            return { id: cat.id, value: cat.categoria };
          })
        );
        setCategoria(responseData.categoriasFinanceiro[0].id);
      } catch (err) {
        stopLoading();
        console.error("err", err);
      }
    };

    const fetchCaixas = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCaixas(
          responseData.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
        setCaixa(responseData.caixas[0].id);
      } catch (err) {
        stopLoading();
        console.error("err", err);
      }
    };

    fetchCategoriasStock();
    fetchCaixas();
    fetchCategorias();
    stopLoading();
  }, [auth.token, clinica, sendRequest]);

  const handleSubmitImportStock = async () => {
    let novosProdutos = [];
    let produtosExistentes = [];
    let novosMovimentosParaStock = [];
    let tempStock = { ...stock };
    if (!data) {
      toast.error("Nenhum dado disponivel no excel.");
      setIsSubmitting(false);
    } else if (adicionarComoDespesa && !descricao) {
      toast.error("Adicione uma descrição para a despesa.");
      setIsSubmitting(false);
    } else if (adicionarComoDespesa && (!custoTotal || custoTotal === 0)) {
      toast.error("O custo da despesa não pode ser 0");
      setIsSubmitting(false);
    } else {
      startLoading();

      try {
        data.forEach((dt) => {
          let found = false;

          //Separar os novos dos existentes
          produtos.forEach((p) => {
            if (p.produto.toLowerCase() === dt.nome.toLowerCase()) {
              produtosExistentes.push({
                id: p.id,
                quantidade: dt.quantidade,
                quantidadeIdeal: dt["quantidade ideal"],
              });

              novosMovimentosParaStock.push({
                data: new Date(),
                produto: p._id,
                quantidade: dt.quantidade,
                responsávelMovimento: auth.userId,
                saida: false,
              });
              found = true;
            }
          });

          if (!found) {
            novosProdutos.push({
              clinica: clinica.clinica._id,
              produto: dt.nome,
              quantidade: dt.quantidade,
              quantidadeIdeal: dt["quantidade ideal"],
              categoriaStock: categoriaStock,
            });
          }
        });

        if (novosProdutos.length > 0) {
          //envia-se os produtos à base de dados e depois atualiza-se a array de velhos produtos com os dados retornados que serao então adicionados ao stock
          const formData = new FormData();
          formData.append("produtos", JSON.stringify(novosProdutos));
          let produtosRetornados;
          try {
            produtosRetornados = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/produtos/addmany`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
            toast.error(
              "Ocorreu um erro guardando os produtos. Por favor, tente novamente."
            );
          }

          const novosProdutosParaStock = [];

          novosProdutos.forEach((np) => {
            produtosRetornados.produtos.forEach((p) => {
              if (p.produto.toLowerCase() === np.produto.toLowerCase()) {
                novosProdutosParaStock.push({
                  produto: p._id,
                  quantidade: np.quantidade,
                  quantidadeIdeal: np.quantidadeIdeal,
                });

                novosMovimentosParaStock.push({
                  data: new Date(),
                  produto: p._id,
                  quantidade: np.quantidade,
                  responsavelMovimentoUser: auth.userId,
                  saida: false,
                });
              }
            });
          });

          tempStock.stock.push(...novosProdutosParaStock);
        }

        tempStock.historicoMovimentos.push(...novosMovimentosParaStock);
        tempStock.stock.forEach((s) => {
          produtosExistentes.forEach((pe) => {
            if (pe.id === s.produto._id) {
              s.quantidade += pe.quantidade;
              s.quantidadeIdeal = pe.quantidadeIdeal
                ? pe.quantidadeIdeal
                : s.quantidadeIdeal;
            }
          });
        });

        const formData2 = new FormData();
        formData2.append("stockDaClinica", JSON.stringify(tempStock.stock));
        formData2.append(
          "historicoMovimentos",
          JSON.stringify(tempStock.historicoMovimentos)
        );

        let stockAtualizado;
        try {
          stockAtualizado = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/${stock._id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro atualizando o stock. Por favor, tente novamente."
          );
        }

        if (adicionarComoDespesa) {
          let observacao = "";
          data.forEach((d) => (observacao += `${d.nome} -> ${d.quantidade}; `));

          const formData = new FormData();
          formData.append("clinica", clinica.clinica._id);
          formData.append("observacoes", observacao);
          formData.append("descricao", descricao);
          formData.append("valor", custoTotal);
          formData.append("dataLimitePagamento", moment());
          formData.append("categoria", categoria);
          formData.append("caixa", caixa);
          formData.append("criadoPor", auth.userId);
          formData.append("despesaPaga", despesaPaga);

          //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/despesas`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
            toast.error(
              "Ocorreu um erro criando a despesa para os produtos importados. Por favor, tente novamente."
            );
          }
        }

        atualizarStock(
          stockAtualizado.stock,
          `Ficheiro importado com sucesso! ${
            adicionarComoDespesa ? " Depesa adicionada!" : ""
          }`
        );
        handleCloseModalImportStock();
      } catch (err) {
        console.error("err", err);
      } finally {
        setIsSubmitting(false);
        stopLoading();
      }
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    // Check file size (10MB limit)
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB in bytes
    let err = false;

    // Check file extension
    const allowedExtensions = /(\.xls|\.xlsx)$/i;
    if (!allowedExtensions.test(selectedFile.name)) {
      err = true;
      setErrorMessage(
        `O ficheiro "${selectedFile.name}" tem uma extensão inválida.`
      );
      return;
    } else if (selectedFile.size > maxSizeInBytes) {
      err = true;
      setErrorMessage(
        `O ficheiro "${selectedFile.name}" tem uma extensão inválida.`
      );
      return;
    } else {
      err = false;
      setErrorMessage("");
    }

    if (!err) {
      convertFileToObject(selectedFile);
      setFile(selectedFile);
    }
  };

  const preSubmitHandler = () => {
    setErrorMessage("");
    setIsSubmitting(true);
    handleSubmitImportStock();
  };

  const convertFileToObject = (ficheiro) => {
    let jsonData;
    if (ficheiro) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryData = event.target.result;
        const workbook = XLSX.read(binaryData, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        jsonData = XLSX.utils.sheet_to_json(worksheet);
        setData(jsonData);
      };
      reader.readAsArrayBuffer(ficheiro);
    }
    return jsonData;
  };

  const custoTotalChangeHandler = (value) => {
    setCustoTotal(value);
  };

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };
  return (
    <div className="stockImport__container">
      <div className="modalAdicionarFicheiros__container">
        {categoriasStock && categoriaStock && (
          <div className="customInputContainer">
            <select
              onChange={(e) => setCategoriaStock(e.target.value)}
              className="customInputSelect"
              value={categoriaStock}
            >
              {categoriasStock &&
                categoriasStock.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.categoria}
                  </option>
                ))}
            </select>
            <label className="customInputLabelSelect">Categoria Stock</label>
          </div>
        )}

        <div className="modalAdicionarFicheiros__container-adicionar">
          <svg
            className="modalAdicionarFicheiros__container-adicionar--svg"
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" fill="white" />
            <path
              d="M5 12V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V12"
              stroke="#88959e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 3L12 15M12 15L16 11M12 15L8 11"
              stroke="#88959e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="modalAdicionarFicheiros__container-adicionar--msgEscolher">
            Clique embaixo para escolher o ficheiro.
          </span>
          <div>
            <input
              type="file"
              style={{ display: "none" }}
              id="fileInput"
              onChange={handleFileChange}
            />
            <label
              htmlFor="fileInput"
              className="modalAdicionarFicheiros__container-adicionar--btn-adicionar"
            >
              Escolher Ficheiro
            </label>
          </div>
          {!file?.name && (
            <>
              <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                O Tamanho máximo do upload é 10MB
              </span>
              <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                Formatos aceites: .xls e .xlsx
              </span>
            </>
          )}
          {file?.name && (
            <span className="modalImportarXlsStock__nomeFicheiro">
              {file.name}
            </span>
          )}
          <span
            className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro"
            style={{ marginTop: "20px", lineHeight: "25px", maxWidth: "800px" }}
          >
            O ficheiro deve ter 3 colunas: "nome", "quantidade" e "quantidade
            ideal", escritas exatamente dessa forma. Os nomes dos produtos cuja
            quantidade pretende alterar, devem ser exatamente iguais aos nomes
            já existentes no stock, caso contrário serão criados novos produtos.
          </span>

          <span className="modalImportarXlsStock__spanModeloXlsx">
            <a href="/modeloImportStock.xlsx" download="modeloImportStock.xlsx">
              Baixar Modelo xlsx
            </a>
          </span>
        </div>

        <div>
          <div className="adicionar-despesa__modal__container__checkbox-pago__container">
            <input
              type="checkbox"
              name=""
              id="adicionarComoDespesa-checkbox"
              onChange={(e) => setAdicionarComoDespesa(e.target.checked)}
              checked={adicionarComoDespesa}
            />
            <label htmlFor="adicionarComoDespesa-checkbox">
              Adicionar os custos como despesa
            </label>
          </div>

          {adicionarComoDespesa && (
            <div className="modalImportarXlsStock__adicionarComoDespesa__container">
              <InputText
                className="modalImportarXlsStock__adicionarComoDespesa__container__span3"
                label="Descrição *"
                initialValue={descricao}
                handleChange={descricaoChangeHandler}
              />

              <InputNumber
                className="modalImportarXlsStock__adicionarComoDespesa__container__span1"
                label="Custo Total *"
                initialValue={custoTotal}
                handleChange={custoTotalChangeHandler}
              />

              {categorias && categoria && (
                <div className="customInputContainer modalImportarXlsStock__adicionarComoDespesa__container__span2">
                  <select
                    onChange={(e) => setCategoria(e.target.value)}
                    className="customInputSelect"
                    value={categoria}
                  >
                    {categorias &&
                      categoria &&
                      categorias.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">Categoria</label>
                </div>
              )}

              {caixas && caixa && (
                <div className="customInputContainer modalImportarXlsStock__adicionarComoDespesa__container__span2">
                  <select
                    onChange={(e) => setCaixa(e.target.value)}
                    className="customInputSelect"
                    value={caixa}
                  >
                    {caixas &&
                      caixa &&
                      caixas.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">Caixa</label>
                </div>
              )}

              <div className="modalImportarXlsStock__adicionarComoDespesa__container__despesaPagaContainer modalImportarXlsStock__adicionarComoDespesa__container__span3">
                <input
                  type="checkbox"
                  name=""
                  id="adicionarComoDespesa-checkbox"
                  onChange={(e) => setDespesaPaga(e.target.checked)}
                  checked={despesaPaga}
                />
                <label htmlFor="adicionarComoDespesa-checkbox">
                  Despesa Paga
                </label>
              </div>
            </div>
          )}
        </div>
        {errorMessage && (
          <div className="modalAdicionarFicheiros__errorMessage">
            {errorMessage}
          </div>
        )}
      </div>

      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalImportStock}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        <span
          disabled={isSubmitting || errorMessage || !file}
          className={
            isSubmitting || errorMessage || !file ? "cancel-btn" : `blue-button`
          }
          onClick={preSubmitHandler}
        >
          {isSubmitting ? "Importando..." : "Importar"}
        </span>
      </div>
    </div>
  );
}

export default ModalImportXlsStock;
