import React, { useState, useEffect } from "react";
import ChartAdministrativo from "./ChartAdministrativo";
import "./DashboardAdministrativoProdutividadeFinanceira.scss";

function DashboardAdministrativoProdutividadeFinanceira({ desp, cCorrentes }) {
  const [data, setData] = useState();
  useEffect(() => {
    // Initialize an array to store the monthly totals
    const monthlyTotals = [];

    // Iterate over each month of the year
    for (let month = 0; month < 12; month++) {
      // Initialize the total for the current month
      let total = 0;
      let totalDespesa = 0;

      for (const data of cCorrentes) {
        // Iterate over each conta in the contas array
        for (const conta of data.contas) {
          // Iterate over each parcela in the conta
          for (const parcela of conta.parcelas) {
            // Get the month and year from the dataPagamento
            const paymentMonth = new Date(parcela.dataPagamento).getMonth();
            const paymentYear = new Date(parcela.dataPagamento).getFullYear();

            // Check if the payment is within the current month and year
            if (
              paymentMonth === month &&
              paymentYear === new Date().getFullYear()
            ) {
              // Add the quantiaPaga to the total
              total += parcela.quantiaPaga;
            }
          }
        }
      }

      for (const data of desp) {
        // Get the month and year from the dataPagamento
        const paymentMonth = new Date(data.dataPagamento).getMonth();
        const paymentYear = new Date(data.dataPagamento).getFullYear();

        // Check if the payment is within the current month and year
        if (
          paymentMonth === month &&
          paymentYear === new Date().getFullYear()
        ) {
          // Add the quantiaPaga to the total
          totalDespesa += data.valor;
        }
      }

      // Create an object for the current month
      const monthObject = {
        name: getMonthName(month), // Replace with your own function to get the month name
        receitas: total, // Format the total with two decimal places
        despesas: totalDespesa,
      };
      // Add the month object to the monthlyTotals array
      monthlyTotals.push(monthObject);
    }
    setData(monthlyTotals);
  }, [cCorrentes]);

  // Function to get the month name based on the month index
  function getMonthName(monthIndex) {
    const months = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    return months[monthIndex];
  }

  // Output the monthly totals
  return (
    <>
      {data && (
        <div className="caixa dashboardAdministrativoProdutividadeFinanceira__container">
          <ChartAdministrativo data={data} />
        </div>
      )}
    </>
  );
}

export default DashboardAdministrativoProdutividadeFinanceira;
