import { useState, useEffect, useContext } from "react";
import ListImpressos from "../../Lists/ListImpressos/ListImpressos";
import moment from "moment/moment";

import { AuthContext } from "../../../../../../shared/context/auth-context";

import toast from "react-hot-toast";
import { useLoading } from "../../../../../../shared/context/LoadingContext";
function ImpressosComponent({ clienteId, sendRequest, token, dadosImpresso }) {
  const { startLoading, stopLoading } = useLoading();
  const auth = useContext(AuthContext);
  const [impressos, setImpressos] = useState([]);

  useEffect(() => {
    const fetchImpressosByCliente = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/impressos/cliente/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const temp = responseData.impressos.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        const impressos_com_data = temp.map((imp) => {
          const data_impresso = moment(imp.created_at).format(
            "DD-MM-YYYY / HH:mm:ss"
          );
          return (imp = {
            ...imp,
            data_impresso: data_impresso,
          });
        });

        setImpressos(impressos_com_data);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro na busca dos impressos do cliente. Por favor tente novamente."
        );
      } finally {
        stopLoading();
      }
    };
    fetchImpressosByCliente();
  }, [sendRequest, clienteId, auth.token]);

  const handleApagar = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/impressos/${id}`,
        "DELETE",
        {},
        {
          Authorization: "Bearer " + token,
        }
      );
      setImpressos((currentImpressos) => {
        return currentImpressos.filter((el) => {
          return el.id !== id;
        });
      });

      toast.success("Eliminação da impresso efetuada com sucesso!");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro ao eliminar o impresso. Por favor tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const impressoData = (cliente, clinica, medico, textoImpresso) => {
    const dados = {
      cliente,
      clinica,
      medico,
      textoImpresso,
    };
    dadosImpresso(dados);
  };

  return (
    <>
      {impressos.length !== 0 && (
        <>
          <ListImpressos
            impressoData={impressoData}
            data={impressos}
            handleApagar={handleApagar}
          />
        </>
      )}
      {impressos.length === 0 && (
        <div className="no_data_div">Nenhum impresso encontrado.</div>
      )}
    </>
  );
}

export default ImpressosComponent;
