import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import ElementoOrcamento from "../components/Elemento/ElementoOrcamento";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"; //gerar Orcamento
import validarOrcamento from "../helperFunctions/validation";
import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";

import "../../../shared/css/PlanoAvaliacaoOrcamento.scss";
import "../../../style/formError.scss";
const moment = require("moment");
const OPCOES_BENEFICIO = ["Particular", "INPS"];

function EditOrcamento() {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orcamento, setOrcamento] = useState();
  const [serviceList, setServiceList] = useState();
  const [beneficio, setBeneficio] = useState();
  const [subtotal, setSubtotal] = useState();
  const [total, setTotal] = useState();
  const [desconto, setDesconto] = useState(0);
  const [tipoDesconto, setTipoDesconto] = useState("$");
  const [parcelarOrcamento, setParcelarOrcamento] = useState(false);
  const [contaCorrente, setContaCorrente] = useState();
  const [valorEntrada, setvalorEntrada] = useState(0);
  const [nrParcelasState, setNrParcelasState] = useState(1);
  const [observacoes, setObservacoes] = useState();
  const [nrTratamentosInps, setNrTratamentosInps] = useState();
  const [nrTratamentosNaoInps, setNrTratamentosNaoInps] = useState();
  const [nrTratamentosInpsFixed, setNrTratamentosInpsFixed] = useState();
  const [nrTratamentosNaoInpsFixed, setNrTratamentosNaoInpsFixed] = useState();
  const [orcamentoIsentoPagamento, setOrcamentoIsentoPagamento] =
    useState(false);

  const { sendRequest } = useHttpClient();
  const orcamentoId = useParams().orcamentoId;
  const auth = useContext(AuthContext);

  const navigate = useNavigate();
  useEffect(() => {
    startLoading();
    const fetchOrcamentoEContaCorrente = async () => {
      try {
        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/${orcamentoId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro carregando os dados do orçamento. Por favor, tente novamente."
          );
        }
        let nrCobertosInps = 0;
        let nrNaoCobertosInps = 0;
        const newServiceList = responseData.orcamento.procedimentos.map(
          (prc) => {
            if (prc.tratamento.coberturaInps) nrCobertosInps += 1;
            else nrNaoCobertosInps += 1;
            return { ...prc, show: true };
          }
        );

        setNrTratamentosInps(nrCobertosInps);
        setNrTratamentosNaoInps(nrNaoCobertosInps);
        setNrTratamentosInpsFixed(nrCobertosInps);
        setNrTratamentosNaoInpsFixed(nrNaoCobertosInps);
        setSubtotal(responseData.orcamento.subtotal);
        setTotal(responseData.orcamento.total);
        setBeneficio(responseData.orcamento.beneficio);
        setObservacoes(responseData.orcamento.observacoes);
        setDesconto(
          responseData.orcamento.tipoDesconto === "$"
            ? responseData.orcamento.desconto
            : responseData.orcamento.subtotal / responseData.orcamento.desconto
        );
        setTipoDesconto(responseData.orcamento.tipoDesconto);
        setOrcamento(responseData.orcamento);
        setServiceList(newServiceList);

        let response;
        try {
          response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/cliente/${responseData.orcamento.cliente.id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro carregando os dados da conta corrente. Por favor, tente novamente."
          );
        }

        setContaCorrente(response.contaCorrente);

        const contaFiltrada = response.contaCorrente.contas.filter(
          (conta) => conta.orcamento === responseData.orcamento._id
        )[0];

        setvalorEntrada(
          contaFiltrada.parcelas[0].entrada
            ? contaFiltrada.parcelas[0].quantiaPorPagar
            : 0
        );
        setNrParcelasState(
          contaFiltrada.parcelas[0].entrada
            ? contaFiltrada.parcelas.length - 1
            : contaFiltrada.parcelas.length
        );
        setParcelarOrcamento(contaFiltrada.parcelas.length > 1 ? true : false);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchOrcamentoEContaCorrente();
  }, [sendRequest, orcamentoId, auth.token]);

  const submitHandler = async (contaAprovada) => {
    startLoading();
    try {
      const filteredServiceList = [];

      //////////////Orcamento///////////////
      ////////////////////////////////////
      const formErrorMessage = validarOrcamento(serviceList);

      if (!formErrorMessage) {
        serviceList.forEach((service) => {
          if (service.show) {
            filteredServiceList.push({
              id: service.id,
              tratamento: service.tratamento.id, //
              dente: service.dente, //
              quantidade: 1,
              faces: service.faces, //
              pr_total:
                beneficio === "Particular"
                  ? service.tratamento.preco_particular
                  : service.tratamento.coberturaInps
                  ? service.tratamento.preco_beneficiario
                  : service.tratamento.preco_particular,
              iva: 0,
            });
          }
        });

        let avaliacao = { ...orcamento.avaliacao };

        serviceList.forEach((ser) => {
          avaliacao.procedimentos.forEach((avPr) => {
            if (ser.show && avPr.id === ser.id) {
              avPr.orcamentado = true;
            }
          });
        });

        const formData = new FormData();
        formData.append("cliente", orcamento.cliente._id);
        formData.append("medico_responsavel", orcamento.medico_responsavel._id);
        formData.append("clinica", orcamento.clinica._id);
        formData.append("avaliacao", avaliacao._id);
        //Para garantir que o beneficio vá como INPS para um orçamento Particular e visse versa
        formData.append(
          "beneficio",
          beneficio === "INPS" && nrTratamentosInps > 0 ? "INPS" : "Particular"
        );
        formData.append("procedimentos", JSON.stringify(filteredServiceList));
        formData.append("total", total);
        formData.append("subtotal", subtotal);
        formData.append(
          "desconto",
          tipoDesconto === "$" ? desconto : subtotal * (desconto / 100)
        );
        formData.append("tipoDesconto", tipoDesconto);
        formData.append("transformado", contaAprovada);
        formData.append("observacoes", observacoes ? observacoes : "");
        formData.append("criadoPor", auth.userId);
        formData.append("ativo", true);
        let respostaOrcamento;
        try {
          respostaOrcamento = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/${orcamentoId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro atualizando os dados do orçamento. Por favor, tente novamente."
          );
        }

        //Atualizando os itens orcamentos na avaliacao
        //Avaliacao fica terminada se todos os procedimentos forem orcamentados de primeira

        // avaliacao.procedimentos.forEach(pA=>{

        // });

        const procedimentosOrcamentados = avaliacao.procedimentos.filter(
          (pr) => pr.orcamentado === true
        );
        const nrDeProcedimentosNaAvaliacao = avaliacao.procedimentos.length;

        const formData2 = new FormData();

        if (procedimentosOrcamentados.length === nrDeProcedimentosNaAvaliacao) {
          formData2.append("terminado", true);
          formData2.append("transformado", false);
        } else {
          formData2.append("terminado", false);
          formData2.append("transformado", true);
        }

        if (
          procedimentosOrcamentados.length > 0 &&
          procedimentosOrcamentados.length < nrDeProcedimentosNaAvaliacao
        ) {
          formData2.append("transformado", true);
          formData2.append("terminado", false);
        }

        formData2.append(
          "procedimentos",
          JSON.stringify(avaliacao.procedimentos)
        );

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${avaliacao._id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro atualizando os dados da avaliação. Por favor, tente novamente."
          );
        }
        //Conta Corrente
        ////////////////////
        const parcelas = [];

        if (!parcelarOrcamento || orcamentoIsentoPagamento) {
          //Caso o cliente não quiser parcelar o orçamento, ou caso for 1 orçamento isento de pagamento, fica com uma parcela apenas
          parcelas.push({
            quantiaPaga: 0,
            dataLimitePagamento: moment().add(5, "day"),
            parcelaPaga: false,
            quantiaPorPagar: total,
          });
        }

        //Criando parcelas caso o cliente não houver dado valor de entrada e caso o orcamento nao for isento de pagamento
        if (
          parcelarOrcamento &&
          valorEntrada === 0 &&
          !orcamentoIsentoPagamento
        ) {
          let tempTotalParcelas = 0;
          for (let i = 1; i <= nrParcelasState; i++) {
            if (
              i === Number(nrParcelasState) &&
              total - tempTotalParcelas > Math.floor(total / nrParcelasState)
            ) {
              parcelas.push({
                quantiaPaga: 0,
                dataLimitePagamento: moment().add(i * 5, "day"),
                parcelaPaga: false,
                quantiaPorPagar: total - tempTotalParcelas,
                nrParcela: `${i}/${nrParcelasState}`,
              });
            } else {
              tempTotalParcelas += Math.floor(total / nrParcelasState);
              parcelas.push({
                quantiaPaga: 0,
                dataLimitePagamento: moment().add(i * 5, "day"),
                parcelaPaga: false,
                quantiaPorPagar: Math.floor(total / nrParcelasState),
                nrParcela: `${i}/${nrParcelasState}`,
              });
            }
          }
        }

        //Criando parcelas caso o cliente der valor de entrada e caso o orcamento nao for isento de pagamento
        if (
          parcelarOrcamento &&
          valorEntrada > 0 &&
          !orcamentoIsentoPagamento
        ) {
          parcelas.push({
            quantiaPaga: 0,
            dataLimitePagamento: moment(),
            parcelaPaga: false,
            quantiaPorPagar: valorEntrada,
            entrada: true,
          });

          let tempTotalParcelas = 0;
          for (let i = 1; i <= nrParcelasState; i++) {
            if (
              i === Number(nrParcelasState) &&
              total - valorEntrada - tempTotalParcelas >
                Math.floor((total - valorEntrada) / nrParcelasState)
            ) {
              parcelas.push({
                quantiaPaga: 0,
                dataLimitePagamento: moment().add(i * 5, "day"),
                parcelaPaga: false,
                quantiaPorPagar: total - valorEntrada - tempTotalParcelas,
                nrParcela: `${i}/${nrParcelasState}`,
              });
            } else {
              tempTotalParcelas += Math.floor(
                (total - valorEntrada) / nrParcelasState
              );
              parcelas.push({
                quantiaPaga: 0,
                dataLimitePagamento: moment().add(i * 5, "day"),
                parcelaPaga: false,
                quantiaPorPagar: Math.floor(
                  (total - valorEntrada) / nrParcelasState
                ),
                nrParcela: `${i}/${nrParcelasState}`,
              });
            }
          }
        }

        //Criando o objeto conta
        //A conta fica liquidada se o cliente não parcelar, ou seja, pagar tudo de uma vez
        const conta = {
          orcamento: respostaOrcamento.orcamento.id,
          parcelas: parcelas,
          nrParcelas: parcelarOrcamento ? nrParcelasState : 1,
          contaLiquidada: false,
          total: total,
          data_criacao: new Date(),
          aprovado: contaAprovada,
        };

        //A conta Anterior deixa de existir, sendo posteriormente substituida pela conta gerada por esse orcamento
        const contaCorrenteFinal = contaCorrente.contas.map(
          (c) => c.orcamento !== orcamento._id
        );

        const formData3 = new FormData();
        //Caso o cliente nao tem conta corrente, ela é criada na base de dados
        //Caso o cliente ja tem conta corrente, ela é atualizada
        formData3.append(
          "contas",
          JSON.stringify([...contaCorrenteFinal.contas, conta])
        );
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
            "PATCH",
            formData3,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro atualizando os dados da conta corrente. Por favor, tente novamente."
          );
        }

        //Guardando o plano de tratamento
        const procedimentosFiltrados = [];

        filteredServiceList.forEach((service) => {
          procedimentosFiltrados.push({
            tratamento: service.tratamento,
            dente: service.dente,
            faces: service.faces,
            medico: avaliacao.medico_responsavel.id,
            procedimento_completo: false,
          });
        });

        if (contaAprovada) {
          const formData = new FormData();
          formData.append("ativo", true);
          formData.append(
            "procedimentos",
            JSON.stringify(procedimentosFiltrados)
          );
          formData.append("cliente", avaliacao.cliente.id);
          formData.append("clinica", avaliacao.clinica.id);
          formData.append("orcamento", respostaOrcamento.orcamento.id);
          formData.append("canDelete", true);
          formData.append(
            "medico_responsavel",
            avaliacao.medico_responsavel.id
          );

          //Gravando plano de tratamento
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
            toast.error(
              "Ocorreu um erro criando o plano de tratamento. Por favor, tente novamente."
            );
          }
        }

        toast.success("Orçamento adicionado com sucesso!");
        navigate(`../../../clientes/${avaliacao.cliente.id}-orcamento`);
      } else {
        toast.error(formErrorMessage);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
    }
  };

  //funcoes executadas pelas childs
  const removeService = (id) => {
    const list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });

    const indice = list.indexOf(servico[0]);

    list[indice].show = false;
    setServiceList([...list]);

    //Visto que a funcao é chamada para atribuir um evento onde se necessita do valor do evento
    //Crio um objeto similar ao evento e reaproveito a funcao passando o valor que pretendo usar
    updateValores({ target: { value: beneficio } });

    //Atualizar o numero de tratamentos cobertos e nao cobertos pelo inps na lista
    if (servico[0].tratamento.coberturaInps)
      setNrTratamentosInps((current) => current - 1);
    else setNrTratamentosNaoInps((current) => current - 1);

    //Se não existir mais tratamentos cobertos pelo INPS, o orçamento se transforma num orçamento particular
    if (beneficio === "INPS") {
      let nrCobertos = 0;

      list.forEach((l) => {
        if (l.show) {
          if (l.tratamento.coberturaInps) nrCobertos += 1;
        }
      });

      if (nrCobertos <= 0) {
        updateValores({ target: { value: "Particular" } });
      }
    }
  };

  const addService = (id) => {
    const list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });

    const indice = list.indexOf(servico[0]);

    list[indice].show = true;
    setServiceList([...list]);

    //Visto que a funcao é chamada para atribuir um evento onde se necessita do valor do evento
    //Crio um objeto similar ao evento e reaproveito a funcao passando o valor que pretendo usar
    updateValores({ target: { value: beneficio } });

    //Atualizar o numero de tratamentos cobertos e nao cobertos pelo inps na lista
    if (servico[0].tratamento.coberturaInps)
      setNrTratamentosInps((current) => current + 1);
    else setNrTratamentosNaoInps((current) => current + 1);

    //Se não existir mais tratamentos cobertos pelo INPS, o orçamento se transforma num orçamento particular
    // if (beneficio === "INPS") {
    //   let nrCobertos = 0;

    //   list.forEach((l) => {
    //     if (l.show) {
    //       if (l.tratamento.coberturaInps) nrCobertos += 1;
    //     }
    //   });

    //   if (nrCobertos <= 0) {
    //     setOpcoesBeneficio(["Particular"]);
    //     //Com updateValores com particular, o beneficio e set a particular portanto nao há necessidade
    //     //de atualizar a state beneficio para particular aqui
    //     updateValores({ target: { value: "Particular" } });
    //   }
    // }
  };

  //Quando se altera o beneficio
  const updateValores = (e) => {
    setBeneficio(e.target.value);
    const isParticular = e.target.value === "Particular";

    const tempServiceList = serviceList.map((el) => {
      if (el.show === true) {
        return {
          ...el,
          valUnit: el.tratamento.preco_particular,
          valorTotal: isParticular
            ? el.tratamento.preco_particular
            : el.tratamento.coberturaInps
            ? el.tratamento.preco_beneficiario
            : el.tratamento.preco_particular,
        };
      } else {
        return el;
      }
    });

    const filteredServiceList = tempServiceList.filter((el) => {
      return el.show === true;
    });

    setServiceList(tempServiceList);

    let tempSubTotal = 0;

    if (isParticular) {
      tempSubTotal = filteredServiceList.reduce((acc, el) => {
        return acc + el.tratamento.preco_particular;
      }, 0);
    } else {
      filteredServiceList.forEach((s) => {
        if (s.tratamento.coberturaInps)
          tempSubTotal += s.tratamento.preco_beneficiario;
        else tempSubTotal += s.tratamento.preco_particular;
      });
    }

    setSubtotal(tempSubTotal);
    handleIsencaoPagamento(desconto, tipoDesconto, tempSubTotal);
    calculateTotalWithDesconto(desconto, tipoDesconto, tempSubTotal);
  };

  const handleIsencaoPagamento = (desc, tipoDesc, subTo) => {
    if (
      (tipoDesc === "%" && desc >= 100) ||
      (tipoDesc === "$" && desc >= subTo)
    ) {
      setOrcamentoIsentoPagamento(true);
    } else {
      setOrcamentoIsentoPagamento(false);
    }
  };

  const changeDescontoHandler = (e) => {
    handleIsencaoPagamento(e.target.value, tipoDesconto, subtotal);

    if (e.target.value < 0) {
      setDesconto(0);
      calculateTotalWithDesconto(0, tipoDesconto, subtotal);
    } else if (tipoDesconto === "%" && e.target.value > 100) {
      setDesconto(100);
      calculateTotalWithDesconto(100, tipoDesconto, subtotal);
    } else if (tipoDesconto === "$" && e.target.value > subtotal) {
      setDesconto(subtotal);
      calculateTotalWithDesconto(subtotal, tipoDesconto, subtotal);
    } else {
      setDesconto(e.target.value);
      calculateTotalWithDesconto(e.target.value, tipoDesconto, subtotal);
    }
  };

  const changeTipoDescontoHandler = (e) => {
    handleIsencaoPagamento(desconto, e.target.value, subtotal);

    setTipoDesconto(e.target.value);
    if (e.target.value === "%" && desconto > 100) {
      setDesconto(100);
      calculateTotalWithDesconto(100, e.target.value, subtotal);
    } else {
      calculateTotalWithDesconto(desconto, e.target.value, subtotal);
    }
  };

  const calculateTotalWithDesconto = (desconto, tipoDesconto, subtotal) => {
    if (tipoDesconto === "$") {
      setTotal(subtotal - desconto);
    }

    if (tipoDesconto === "%") {
      setTotal(subtotal - (desconto / 100) * subtotal);
    }
  };

  const changeNrParcelasHandler = (e) => {
    if (!e.target.value || e.target.value < 1) setNrParcelasState(1);
    else setNrParcelasState(e.target.value);
  };

  const preSubmitHandler = (aprovar) => {
    if (!isSubmitting) {
      if (serviceList.filter((el) => el.show === true).length <= 0) {
        toast.error("Nenhum tratamento selecionado");
      } else {
        setIsSubmitting(true);
        submitHandler(aprovar);
      }
    }
  };

  const handleParcelarOrcamentoCheckbox = (e) => {
    setParcelarOrcamento(e.target.checked);
    if (!e.target.checked) {
      setNrParcelasState(1);
      setvalorEntrada(0);
      updateValores({ target: { value: beneficio } });
    }
  };

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {orcamento && (
            <Navbar
              title="Orçamento"
              icon={ArticleOutlinedIcon}
              paths={[
                {
                  nome: orcamento.cliente.nome,
                  link: `../../../clientes/${orcamento.cliente.id}-orcamento`,
                },
                { nome: "Novo" },
              ]}
            />
          )}

          {serviceList && beneficio && orcamento && (
            <div className="bottom-orcamento caixa">
              <div className="pOrcamento-header">
                <div className="pOrcamento-header__item">
                  <label className="label">Cliente:</label>
                  <span className="input">{orcamento.cliente.nome}</span>
                </div>

                <div className="pOrcamento-header__item form-input">
                  <select
                    id="select_beneficio"
                    name="beneficio"
                    value={beneficio}
                    onChange={updateValores}
                    className="input"
                  >
                    {OPCOES_BENEFICIO.map((op, index) => (
                      <option value={op} key={index}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="pOrcamento-header__item">
                  <label className="label">Médico Avaliador:</label>
                  <span className="input">
                    {orcamento.medico_responsavel.name}
                  </span>
                </div>
              </div>

              {nrTratamentosInpsFixed &&
              nrTratamentosInpsFixed > 0 &&
              nrTratamentosNaoInpsFixed > 0 &&
              beneficio === "INPS" ? (
                <span className="tituloListaProcedimentos">
                  {`Lista de tratamentos - INPS (${nrTratamentosInps})`}
                </span>
              ) : (
                ""
              )}

              <div
                className={`orcamento__item orcamento__item--headerDiv ${
                  beneficio === "Particular"
                    ? "orcamento__item--particular"
                    : ""
                }`}
              >
                <span className="orcamento__item--header">Tratamento</span>
                <span className="orcamento__item--header">Val. Unit.</span>
                {beneficio !== "Particular" && (
                  <span className="orcamento__item--header">INPS</span>
                )}
                {beneficio !== "Particular" && (
                  <span className="orcamento__item--header">Beneficiario</span>
                )}
              </div>

              {beneficio === "Particular" &&
                serviceList.map((singleService, i) => (
                  <div
                    key={i}
                    className="orcamento__item orcamento__item--particular"
                  >
                    <ElementoOrcamento
                      //single service data
                      dente={singleService.dente}
                      faces={singleService.faces}
                      tratamento={singleService.tratamento}
                      beneficio={beneficio}
                      //outros
                      removeService={removeService}
                      addService={addService}
                      id={singleService.id}
                      show={singleService.show}
                    />
                  </div>
                ))}

              {/* Lista para os de cobertura INPS */}
              {beneficio === "INPS" &&
                serviceList.map(
                  (singleService, i) =>
                    singleService.tratamento.coberturaInps &&
                    nrTratamentosInpsFixed > 0 && (
                      <div key={i} className="orcamento__item">
                        <ElementoOrcamento
                          //single service data
                          dente={singleService.dente}
                          faces={singleService.faces}
                          tratamento={singleService.tratamento}
                          beneficio={beneficio}
                          //outros
                          removeService={removeService}
                          addService={addService}
                          id={singleService.id}
                          show={singleService.show}
                        />
                      </div>
                    )
                )}

              {beneficio === "INPS" &&
              nrTratamentosNaoInpsFixed &&
              nrTratamentosNaoInpsFixed > 0 ? (
                <>
                  <span className="tituloListaProcedimentos tituloListaProcedimentos--2">
                    Lista de tratamentos - PARTICULAR ({nrTratamentosNaoInps})
                  </span>
                  {serviceList.map(
                    (singleService, i) =>
                      !singleService.tratamento.coberturaInps && (
                        <div key={i} className="orcamento__item">
                          <ElementoOrcamento
                            //single service data
                            dente={singleService.dente}
                            faces={singleService.faces}
                            tratamento={singleService.tratamento}
                            beneficio={beneficio}
                            //outros
                            removeService={removeService}
                            addService={addService}
                            id={singleService.id}
                            show={singleService.show}
                            tratamentoNaoCoberto={true}
                          />
                        </div>
                      )
                  )}
                </>
              ) : (
                ""
              )}
              <span className="orcamento__areaPagamento__titulo">
                Pagamento
              </span>
              <div className="orcamento__areaPagamento__container">
                <div className="orcamento__areaPagamento__container--1">
                  <span className="orcamento__areaPagamento__tituloDesconto">
                    Desconto
                  </span>
                  <div className="orcamento__areaPagamento__desconto">
                    <select
                      onChange={changeTipoDescontoHandler}
                      value={tipoDesconto}
                    >
                      <option value="$">$00</option>
                      <option value="%">%</option>
                    </select>
                    <input
                      type="number"
                      min={0}
                      value={desconto}
                      onChange={changeDescontoHandler}
                    />
                  </div>

                  {/* Parcelas */}
                  <div className="orcamento__areaPagamento__parcelas__container">
                    {!orcamentoIsentoPagamento && (
                      <div className="orcamento__areaPagamento__parcelas__checkbox-div">
                        <input
                          type="checkbox"
                          id="parcelarOrcamento"
                          onChange={handleParcelarOrcamentoCheckbox}
                          checked={parcelarOrcamento}
                        />
                        <label
                          htmlFor="parcelarOrcamento"
                          className="parcelarOrcamentoLabel"
                        >
                          Parcelar Orçamento
                        </label>
                      </div>
                    )}
                    {parcelarOrcamento && (
                      <>
                        <div className="orcamento__areaPagamento__parcelas__parcelamentoContainer">
                          <div className="orcamento__areaPagamento__parcelas__parcelamentoContainer__element">
                            <span className="orcamento__areaPagamento__parcelas__parcelamentoContainer__element__title">
                              Entrada
                            </span>
                            <div className="orcamento__areaPagamento__parcelas__parcelamentoContainer__element__entrada">
                              <input
                                type="text"
                                className="orcamento__areaPagamento__parcelas__parcelamentoContainer__element__entrada__precoSimbolo"
                                defaultValue={"$00"}
                                disabled
                              />

                              <input
                                type="number"
                                min={0}
                                value={valorEntrada}
                                onChange={(e) =>
                                  setvalorEntrada(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="orcamento__areaPagamento__parcelas__parcelamentoContainer__element">
                            <span className="orcamento__areaPagamento__parcelas__parcelamentoContainer__element__title">
                              Número de parcelas
                            </span>
                            <input
                              type="number"
                              min={1}
                              onChange={changeNrParcelasHandler}
                              value={nrParcelasState}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <span className="orcamento__areaPagamento__parcelas__parcelamentoContainer__element__title">
                    Observação
                  </span>
                  {orcamento && (
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="7"
                      defaultValue={orcamento.avaliacao.observacoes}
                      onChange={(e) => setObservacoes(e.target.value)}
                    ></textarea>
                  )}
                </div>
                <div className="orcamento__areaPagamento__container--3"></div>

                <div className="orcamento__areaPagamento__container--2">
                  <span className="orcamento__areaPagamento__container--2__titulo">
                    Detalhes sobre o pagamento
                  </span>
                  <div className="orcamento__areaPagamento__container--2__line">
                    <span>Subtotal:</span>
                    <span>
                      {Intl.NumberFormat("pt-BR").format(subtotal)}$00
                    </span>
                  </div>

                  <div className="orcamento__areaPagamento__container--2__line">
                    <span>Desconto</span>
                    <span>
                      {desconto}
                      {tipoDesconto}
                    </span>
                  </div>
                  <div className="orcamento__areaPagamento__container--2__line">
                    <span className="orcamento__areaPagamento__container--2__line__title-big">
                      Total
                    </span>
                    <span>{Intl.NumberFormat("pt-BR").format(total)}$00</span>
                  </div>
                  {parcelarOrcamento && (
                    <>
                      <div className="orcamento__areaPagamento__container--2__line">
                        <span>Entrada</span>
                        <span>
                          {Intl.NumberFormat("pt-BR").format(valorEntrada)}$00
                        </span>
                      </div>

                      <div className="orcamento__areaPagamento__container--2__line">
                        <span>Parcelas</span>
                        <span>{`${nrParcelasState}x ${Intl.NumberFormat(
                          "pt-BR"
                        ).format(
                          (total - valorEntrada) / nrParcelasState
                        )}$00`}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="botoes">
                <button
                  className="cancel-btn"
                  onClick={() =>
                    navigate(
                      `../../../clientes/${orcamento.cliente._id}-orcamento`
                    )
                  }
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={"blue-button"}
                  onClick={preSubmitHandler.bind(null, false)}
                >
                  {isSubmitting ? "Guardar..." : "Guardar"}
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={preSubmitHandler.bind(null, true)}
                >
                  {isSubmitting ? "Guardar..." : "Guardar e Aprovar"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EditOrcamento;
