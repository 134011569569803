import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import Sidebar from "../../shared/components/sidebar/Sidebar";
import Navbar from "../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { AuthContext } from "../../shared/context/auth-context";
//mui icons
import HomeWorkIcon from "@mui/icons-material/HomeWork";

import "../../style/formError.scss";
import "./Configuracoes.scss";
import CategoriasFinanceiro from "./categoriasFinanceiro/CategoriasFinanceiro";
import CategoriasStock from "./categoriasStock/CategoriasStock";
import Caixas from "./caixas/Caixas";
import Permissoes from "./permissoes/Permissoes";
import CategoriasTratamento from "./categoriasTratamento/CategoriasTratamento";
import ClientesFornecedores from "./clientesFornecedores/ClientesFornecedores";
import DespesasRecorrentes from "./despesasRecorrentes/DespesasRecorrentes";

import { ClinicaContext } from "../../shared/context/clinica-context";

function Configuracoes() {
  const [showMainTabs, setShowMainTabs] = useState();

  const { error, sendRequest, clearError } = useHttpClient();

  const temporary = useParams().aba;

  const auth = useContext(AuthContext);
  const clinicaId = useContext(ClinicaContext).clinica._id;

  useEffect(() => {
    const aba = temporary;
    if (aba) {
      setShowMainTabs(aba);
    } else {
      setShowMainTabs("permissoes");
    }
  }, [sendRequest, temporary, auth.token]);

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar title="Configurações da Clinica" icon={HomeWorkIcon} />
          <ErrorModal error={error} onClear={clearError} />

          <div className="single__abas-principais single__abas-principais--clinica">
            {(auth.role === "Médico/Administrador" ||
              auth.role === "Administrador Não Médico") && (
              <span
                className={`single__abas-principais__aba ${
                  showMainTabs === "permissoes" &&
                  "single__abas-principais__aba--active"
                }`}
                onClick={() => setShowMainTabs("permissoes")}
              >
                Permissões
              </span>
            )}

            <span
              className={`single__abas-principais__aba ${
                showMainTabs === "categoriasTratamento" &&
                "single__abas-principais__aba--active"
              }`}
              onClick={() => setShowMainTabs("categoriasTratamento")}
            >
              Categorias Tratamento
            </span>

            <span
              className={`single__abas-principais__aba ${
                showMainTabs === "categoriasFinanceiro" &&
                "single__abas-principais__aba--active"
              }`}
              onClick={() => setShowMainTabs("categoriasFinanceiro")}
            >
              Categorias Financeiro
            </span>

            <span
              className={`single__abas-principais__aba ${
                showMainTabs === "categoriasStock" &&
                "single__abas-principais__aba--active"
              }`}
              onClick={() => setShowMainTabs("categoriasStock")}
            >
              Categorias Stock
            </span>

            <span
              className={`single__abas-principais__aba ${
                showMainTabs === "caixas" &&
                "single__abas-principais__aba--active"
              }`}
              onClick={() => setShowMainTabs("caixas")}
            >
              Caixas
            </span>

            <span
              className={`single__abas-principais__aba ${
                showMainTabs === "clientesFornecedores" &&
                "single__abas-principais__aba--active"
              }`}
              onClick={() => setShowMainTabs("clientesFornecedores")}
            >
              Clientes/Fornecedores
            </span>
            <span
              className={`single__abas-principais__aba ${
                showMainTabs === "despesasRecorrentes" &&
                "single__abas-principais__aba--active"
              }`}
              onClick={() => setShowMainTabs("despesasRecorrentes")}
            >
              Despesas Recorrentes
            </span>
          </div>

          {showMainTabs === "categoriasFinanceiro" && (
            <CategoriasFinanceiro
              sendRequest={sendRequest}
              clinicaId={clinicaId}
            />
          )}

          {showMainTabs === "categoriasStock" && (
            <CategoriasStock sendRequest={sendRequest} clinicaId={clinicaId} />
          )}

          {showMainTabs === "caixas" && (
            <Caixas sendRequest={sendRequest} clinicaId={clinicaId} />
          )}

          {showMainTabs === "permissoes" && (
            <Permissoes sendRequest={sendRequest} clinicaId={clinicaId} />
          )}

          {showMainTabs === "categoriasTratamento" && (
            <CategoriasTratamento
              sendRequest={sendRequest}
              clinicaId={clinicaId}
            />
          )}

          {showMainTabs === "clientesFornecedores" && (
            <ClientesFornecedores
              sendRequest={sendRequest}
              clinicaId={clinicaId}
            />
          )}

          {showMainTabs === "despesasRecorrentes" && (
            <DespesasRecorrentes
              sendRequest={sendRequest}
              clinicaId={clinicaId}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Configuracoes;
