import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ToasterProvider } from "./shared/context/toast-context";
import "./style/global.scss";

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker.register("/service-worker.js");
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <ToasterProvider>
      <App />
    </ToasterProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
