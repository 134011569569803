import { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { userSchema } from "../../../shared/util/schemas";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import ColorPicker from "../../../shared/components/colorPicker/ColorPicker";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";

import { CloseOutlined } from "@mui/icons-material";

//Mui icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import toast from "react-hot-toast";

//Material UI Select
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
//////////////////////////////////////////////////////////

import "../auth/Signup.scss";
import "../../../style/formError.scss";

function New() {
  const [file, setFile] = useState("");
  const { error, sendRequest, clearError } = useHttpClient();

  const auth = useContext(AuthContext);
  const clinicaCtx = useContext(ClinicaContext);

  const [medicos, setMedicos] = useState([]);
  const [allMedicos, setAllMedicos] = useState([]);
  const [medicosEscolhidos, setMedicosEscolhidos] = useState([]);
  const [secretarias, setSecretarias] = useState([]);
  const [allSecretarias, setAllSecretarias] = useState([]);
  const [secretariasEscolhidas, setSecretariasEscolhidas] = useState([]);
  const [role, setRole] = useState();
  const [roles, setRoles] = useState();
  const [roleName, setRoleName] = useState();
  const [color, setColor] = useState("#d6b6d2");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMedicos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/clinica/${clinicaCtx.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setAllMedicos(
          responseData.users.filter(
            (user) =>
              (user.role.role.role === "Dentista" ||
                user.role.role.role === "Médico/Administrador") &&
              user.ativo === true
          )
        );

        setMedicos(
          responseData.users
            .filter(
              (user) =>
                (user.role.role.role === "Dentista" ||
                  user.role.role.role === "Médico/Administrador") &&
                user.ativo === true
            )
            .map((m) => m.name)
        );

        setAllSecretarias(
          responseData.users.filter(
            (user) =>
              user.role.role.role === "Secretária(o)" && user.ativo === true
          )
        );

        setSecretarias(
          responseData.users
            .filter(
              (user) =>
                user.role.role.role === "Secretária(o)" && user.ativo === true
            )
            .map((m) => m.name)
        );
      } catch (err) {}
    };

    const fetchRoles = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicaroles/${clinicaCtx.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const tempRoles = [];
        responseData.roles.forEach((r) => {
          if (r.role.role !== "Médico/Administrador") {
            tempRoles.push({
              id: r.id,
              role: r.role.role,
            });
          }
        });
        setRoles(tempRoles);
        setRole(tempRoles[0].id);
        setRoleName(tempRoles[0].role);
      } catch (err) {}
    };

    fetchMedicos();
    fetchRoles();
  }, [sendRequest, clinicaCtx, auth.token]);

  const handleCancel = () => {
    resetForm();
    navigate("/users");
  };

  const medicosChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    setMedicosEscolhidos(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const secretariasChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    setSecretariasEscolhidas(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onSubmit = async (values, actions) => {
    if (roleName === "Secretária(o)" && medicosEscolhidos.length === 0) {
      toast.error("Deve ser atribuido à secretária pelo menos um dentista!");
    } else if (roleName === "Dentista" && secretariasEscolhidas.length === 0) {
      toast.error(
        "Deve ser atribuido ao dentista pelo menos um(a) secretária(o)!"
      );
    } else {
      let filteredMedicosId = [];
      let filteredMedicos = [];
      let filteredSecretariasId = [];
      let filteredSecretarias = [];

      if (roleName === "Secretária(o)") {
        medicosEscolhidos.forEach((medEsc) => {
          allMedicos.forEach((m) => {
            if (medEsc === m.name) {
              filteredMedicosId.push(m.id);
              filteredMedicos.push(m);
            }
          });
        });
      }

      if (roleName === "Dentista") {
        secretariasEscolhidas.forEach((secEsc) => {
          allSecretarias.forEach((s) => {
            if (secEsc === s.name) {
              filteredSecretariasId.push(s.id);
              filteredSecretarias.push(s);
            }
          });
        });
      }

      try {
        const formData = new FormData();
        formData.append("name", values.nome);
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("contacto", values.contacto);
        formData.append("profissao", values.profissao);
        formData.append("genero", values.genero);
        formData.append("image", file);
        formData.append("role", role);
        formData.append("clinica", clinicaCtx.clinica._id);
        if (roleName === "Dentista") formData.append("cor", color);
        formData.append("ativo", true);

        if (roleName === "Secretária(o)") {
          formData.append("medicos", JSON.stringify(filteredMedicosId));
          formData.append("secretarias", null);
        } else formData.append("medicos", null);

        if (roleName === "Dentista") {
          formData.append("especialidades", values.especialidade);
          formData.append("secretarias", JSON.stringify(filteredSecretariasId));
        } else formData.append("especialidades", null);

        const respostaUsuario = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/signup`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        //Atualizando os usuarios associados ao usuario criado
        //Para Role secretaria
        if (filteredMedicos) {
          filteredMedicos.forEach((medico) => {
            const atualizarSecretariaEmMedico = async () => {
              let novaListaSecretarias = [];
              if (medico.secretarias) novaListaSecretarias = medico.secretarias;
              novaListaSecretarias.push(respostaUsuario.userId);

              const formData = new FormData();
              formData.append(
                "secretarias",
                JSON.stringify(novaListaSecretarias)
              );
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/users/${medico.id}`,
                "PATCH",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            };
            atualizarSecretariaEmMedico();
          });
        }

        //Para role Dentista
        if (filteredSecretarias) {
          filteredSecretarias.forEach((secretaria) => {
            const atualizarMedicosEmSecretaria = async () => {
              let novaListaMedicos = [];
              if (secretaria.medicos) novaListaMedicos = secretaria.medicos;

              novaListaMedicos.push(respostaUsuario.userId);

              const formData = new FormData();
              formData.append("medicos", JSON.stringify(novaListaMedicos));
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/users/${secretaria.id}`,
                "PATCH",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            };
            atualizarMedicosEmSecretaria();
          });
        }

        toast.success("Usuário adicionado com sucesso!");
        handleCancel();
      } catch (err) {}
    }
  };

  const colorChangeHandler = (e) => {
    setColor(e.target.value);
  };

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      nome: "",
      email: "",
      password: "",
      contacto: "",
      profissao: "",
      genero: "feminino",
      especialidade: "",
      clinica: null,
    },
    validationSchema: userSchema,
    onSubmit,
  });

  const changeRole = (e) => {
    const tempRoleName = roles.filter((r) => r.id === e.target.value)[0];
    setRoleName(tempRoleName.role);
    setRole(e.target.value);
  };
  return (
    <>
      <div className="new-signup">
        <Sidebar />
        <div className="newContainer">
          <Navbar
            title="Adicionar Utilizador"
            icon={PersonOutlineOutlinedIcon}
            paths={[{ nome: "Utilizadores", link: `../../` }, { nome: "Novo" }]}
          />
          <ErrorModal error={error} onClear={clearError} />
          <div className="bottom caixa">
            <div className="bottom-left">
              <img src="/images/medico-ficha.png" alt="" />
            </div>
            <div className="bottom-right">
              <div className="imagePicker takes1-2">
                <div className="imagePickerContainer">
                  <CloseOutlined />
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    alt=""
                  />
                  <label className="icon-file" htmlFor="file">
                    <DriveFolderUploadOutlined className="icon" />
                  </label>
                </div>

                <input
                  className="input"
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {/* Nome */}
              <div className="takes2-5 form-input">
                <input
                  id="nome"
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  value={values.nome}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nome && touched.nome ? "input-error input" : "input"
                  }
                />
                <label className="label" htmlFor="nome">
                  Nome*
                </label>

                {errors.nome && touched.nome ? (
                  <p className="error-message">{errors.nome}</p>
                ) : null}
              </div>

              {/* contacto */}
              <div className="form-input takes5-7">
                <input
                  id="contacto"
                  name="contacto"
                  type="text"
                  placeholder="5999999"
                  value={values.contacto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.contacto && touched.contacto
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="contacto">
                  Contacto*
                </label>
                {errors.contacto && touched.contacto ? (
                  <p className="error-message">{errors.contacto}</p>
                ) : null}
              </div>

              {/* Email */}
              <div className="form-input takes2-5 row2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="email">
                  Email*
                </label>
                {errors.email && touched.email ? (
                  <p className="error-message">{errors.email}</p>
                ) : null}
              </div>

              {/* Role */}
              {roles && (
                <div className="form-input takes5-7 row2">
                  <select
                    id="role"
                    name="role"
                    value={role}
                    onChange={changeRole}
                    className="input"
                  >
                    {roles.map((r, index) => (
                      <option value={r.id} key={`${r.role}-${index}`}>
                        {r.role}
                      </option>
                    ))}
                  </select>

                  <label className="label--up users__label__up">Role*</label>
                </div>
              )}

              {/* Password */}
              <div className="form-input takes1-3">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="password">
                  Password*
                </label>
                {errors.password && touched.password ? (
                  <p className="error-message">{errors.password}</p>
                ) : null}
              </div>

              {/* Genero */}
              <div className="form-input takes3-5">
                <select
                  id="genero"
                  name="genero"
                  value={values.genero}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input"
                >
                  <option value="feminino">Feminino</option>
                  <option value="masculino">Masculino</option>
                  <option value="outro">Outro</option>
                </select>
                <label className="label--up users__label__up">Genero*</label>
              </div>

              {/* Profissao */}
              <div className="form-input takes5-7">
                <input
                  id="profissao"
                  name="profissao"
                  type="text"
                  placeholder="Cirurgião"
                  value={values.profissao}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.profissao && touched.profissao
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="profissao">
                  Profissao*
                </label>
                {errors.profissao && touched.profissao ? (
                  <p className="error-message">{errors.profissao}</p>
                ) : null}
              </div>

              {/* Medicos */}
              {roleName === "Secretária(o)" && (
                <FormControl className="takes1-3" style={{ margin: "auto 0" }}>
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    style={{ backgroundColor: "white" }}
                  >
                    Dentistas a associar
                  </InputLabel>
                  <Select
                    id="demo-multiple-checkbox"
                    multiple
                    value={medicosEscolhidos}
                    onChange={medicosChangeHandler}
                    input={<OutlinedInput label="Dentistas" />}
                    renderValue={(selected) => selected.join(", ")}
                    style={{ color: "#0c53fb" }}
                  >
                    {medicos.map((med, index) => (
                      <MenuItem key={`medico-${index}`} value={med}>
                        <Checkbox
                          checked={medicosEscolhidos.indexOf(med) > -1}
                        />
                        <ListItemText primary={med} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Secretárias */}
              {roleName === "Dentista" && (
                <FormControl className="takes1-3" style={{ margin: "auto 0" }}>
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    style={{ backgroundColor: "white" }}
                  >
                    Secretarias a associar
                  </InputLabel>
                  <Select
                    id="demo-multiple-checkbox"
                    multiple
                    value={secretariasEscolhidas}
                    onChange={secretariasChangeHandler}
                    input={<OutlinedInput label="Dentistas" />}
                    renderValue={(selected) => selected.join(", ")}
                    style={{ color: "#0c53fb" }}
                  >
                    {secretarias.map((sec, index) => (
                      <MenuItem key={`secretaria-${index}`} value={sec}>
                        <Checkbox
                          checked={secretariasEscolhidas.indexOf(sec) > -1}
                        />
                        <ListItemText primary={sec} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Especialidade */}
              {roleName === "Dentista" && (
                <div className="form-input takes3-5">
                  <input
                    id="especialidade"
                    name="especialidade"
                    type="text"
                    placeholder="Cirurgião"
                    value={values.especialidade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.especialidade && touched.especialidade
                        ? "input-error input"
                        : "input"
                    }
                  />
                  <label className="label" htmlFor="especialidade">
                    Especialidade*
                  </label>
                  {errors.especialidade && touched.especialidade ? (
                    <p className="error-message">{errors.especialidade}</p>
                  ) : null}
                </div>
              )}

              {roleName === "Dentista" && (
                <div className="colorPickerDiv takes3-5">
                  <span>Cor do médico</span>
                  <ColorPicker
                    colorChangeHandler={colorChangeHandler}
                    color={color}
                  />
                </div>
              )}

              <div className="botoes">
                <button className="cancel-btn" onClick={handleCancel}>
                  Cancelar
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={handleSubmit}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default New;
