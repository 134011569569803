import React, { useEffect, useState } from "react";

import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import "./DashboardAdministrativoFinanceiro.scss";

function DashboardAdministrativoFinanceiro({ cCorrentes, desp }) {
  const [totalRecebido, setTotalRecebido] = useState(0);
  const [totalReceber, setTotalReceber] = useState(0);
  const [totalPrevistoReceber, setTotalPrevistoReceber] = useState(0);

  const [totalDespesas, setTotalDespesas] = useState(0);
  const [totalPorPagar, setTotalPorPagar] = useState(0);
  const [totalPrevistoDespesa, setTotalPrevistoDespesa] = useState(0);

  const [saldo, setSaldo] = useState(0);
  const [saldoTotalPrevisto, setSaldoTotalPrevisto] = useState(0);

  useEffect(() => {
    let recebido = 0;
    let porReceber = 0;
    let totalDespesas = 0;
    let aPagar = 0;

    if (cCorrentes) {
      cCorrentes.forEach((contaCorrente) => {
        contaCorrente.contas.forEach((conta) => {
          if (conta.aprovado) {
            conta.parcelas.forEach((parcela) => {
              recebido += parcela.quantiaPaga;
              porReceber += parcela.quantiaPorPagar;
            });
          }
        });
      });
    }

    if (desp && desp.length > 0) {
      desp.forEach((despesa) => {
        if (despesa.despesaPaga) totalDespesas += despesa.valor;
        else aPagar += despesa.valor;
      });
    }
    setTotalRecebido(recebido.toLocaleString("pt-BR"));
    setTotalReceber(porReceber.toLocaleString("pt-BR"));
    setTotalPrevistoReceber((porReceber + recebido).toLocaleString("pt-BR"));

    setTotalDespesas(totalDespesas.toLocaleString("pt-BR"));
    setTotalPorPagar(aPagar.toLocaleString("pt-BR"));
    setTotalPrevistoDespesa((aPagar + totalDespesas).toLocaleString("pt-BR"));

    setSaldo((recebido - totalDespesas).toLocaleString("pt-BR"));
    setSaldoTotalPrevisto(
      (porReceber + recebido - (aPagar + totalDespesas)).toLocaleString("pt-BR")
    );
  }, [cCorrentes, desp]);

  return (
    <div className="caixa dashboardAdministrativoFinanceiro__container">
      <span className="dashboardAdministrativoFinanceiro__titulo">
        Financeiro
      </span>
      {/* Receitas */}
      <div className="dashboardAdministrativoFinanceiro__receitasDespesas">
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__tituloContainer">
          <span className="dashboardAdministrativoFinanceiro__receitasDespesas__tituloContainer__titulo">
            Receitas
          </span>
          <CallReceivedIcon className="dashboardAdministrativoFinanceiro__receitasDespesas__tituloContainer__iconReceita" />
        </div>
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__linha1">
          <span>{totalRecebido}$00</span>
          <span>A receber: {totalReceber}$00</span>
        </div>
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__linha2">
          <span>Total Previsto: {totalPrevistoReceber}$00</span>
        </div>
      </div>
      {/* Despesas */}
      <div className="dashboardAdministrativoFinanceiro__receitasDespesas">
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__tituloContainer">
          <span className="dashboardAdministrativoFinanceiro__receitasDespesas__tituloContainer__titulo">
            Despesas
          </span>
          <CallMadeIcon className="dashboardAdministrativoFinanceiro__receitasDespesas__tituloContainer__iconDespesa" />
        </div>
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__linha1">
          <span>{totalDespesas}$00</span>
          <span>A Pagar: {totalPorPagar}$00</span>
        </div>
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__linha2">
          <span>Total Previsto: {totalPrevistoDespesa}$00</span>
        </div>
      </div>
      {/* Saldo */}
      <div className="dashboardAdministrativoFinanceiro__receitasDespesas">
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__tituloContainer">
          <span className="dashboardAdministrativoFinanceiro__receitasDespesas__tituloContainer__titulo">
            Saldo
          </span>
        </div>
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__linha1">
          <span>{saldo}$00</span>
        </div>
        <div className="dashboardAdministrativoFinanceiro__receitasDespesas__linha2">
          <span>Total Previsto: {saldoTotalPrevisto}$00</span>
        </div>
      </div>
    </div>
  );
}

export default DashboardAdministrativoFinanceiro;
