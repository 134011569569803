import React from "react";
import { useState, useEffect, memo, useCallback } from "react";
import { Popover } from "antd";
import { Popconfirm } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ModalUpdateTask from "../ModalUpdateTask";
import ModalNewTask from "../ModalNewTask";
import moment from "moment";

import "./Tasks.scss";
import ModalViewTask from "../ModalViewTask";
import toast from "react-hot-toast";

function Tasks({ sendRequest, auth, clinica }) {
  const [tasks, setTasks] = useState([]);
  const [filterChosen, setFilterChosen] = useState("minhas tarefas");
  const [permaTasks, setPermaTasks] = useState([]);
  const [taskId, setTaskId] = useState();
  const [users, setUsers] = useState();
  const [openModal, setOpenModal] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const tempUsers = [];
        responseData.users.forEach((u) => {
          tempUsers.push({ name: u.name, id: u.id });
        });
        setUsers(tempUsers);
      } catch (err) {}
    };
    fetchUsers();

    const fetchTasks = async () => {
      try {
        let linkToTasks;
        linkToTasks = `${process.env.REACT_APP_BACKEND_LINK}/api/tasks/user/${auth.userId}`;

        const responseData = await sendRequest(linkToTasks, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        setPermaTasks(responseData.tasks);
        setTasks(
          responseData.tasks.filter((dt) => dt.delegatario.id === auth.userId)
        );
      } catch (err) {}
    };
    fetchTasks();
  }, [auth.perm, auth.token, auth.userId, clinica.clinica._id, sendRequest]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setTaskId(null);
  }, []);

  const atualizarTarefas = (tarefa, operacao) => {
    if (operacao === "adicionar") {
      const tempPermaTasks = [...permaTasks, tarefa];
      handle_task_filter_change(filterChosen, tempPermaTasks);
      setPermaTasks(tempPermaTasks);
    }

    if (operacao === "atualizar") {
      let tempPermaTasks = [];
      permaTasks.forEach((task) => {
        if (task._id === tarefa.id) {
          tempPermaTasks.push(tarefa);
        } else {
          tempPermaTasks.push(task);
        }
      });

      setPermaTasks(tempPermaTasks);
      handle_task_filter_change(filterChosen, tempPermaTasks);
    }
  };

  const handleUpdateTask = useCallback((tid) => {
    setTaskId(tid);
    setOpenModal("updateTaskModal");
  }, []);

  const handleViewTask = useCallback((tid) => {
    setTaskId(tid);
    setOpenModal("viewTaskModal");
  }, []);

  const handleApagarTask = async (tid) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/tasks/${tid}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const tempPermaTasks = permaTasks.filter((task) => task._id !== tid);
      setPermaTasks(tempPermaTasks);
      handle_task_filter_change(filterChosen, tempPermaTasks);
      toast.success("Tarefa eliminada com sucesso");
    } catch (err) {
      console.error("err", err);
      toast.error("Não foi possivel eliminar a tarefa");
    }
  };

  const handleEfetuarTask = async (tid) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/tasks/efetuar/${tid}`,
        "PATCH",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const tempPermaTasks = permaTasks.filter((task) => task._id !== tid);
      setPermaTasks(tempPermaTasks);
      handle_task_filter_change(filterChosen, tempPermaTasks);
      toast.success("Tarefa efetuada com sucesso");
    } catch (err) {
      console.error("err", err);
      toast.error("Não foi possivel efetuar a tarefa");
    }
  };

  const handle_task_filter_change = (filter, Alltasks) => {
    if (filter === "todas") {
      setTasks([...Alltasks]);
      setFilterChosen("todas");
    } else if (filter === "atribuidas") {
      setTasks([...Alltasks].filter((dt) => dt.delegatario.id !== auth.userId));
      setFilterChosen("atribuidas");
    } else {
      setTasks([...Alltasks].filter((dt) => dt.delegatario.id === auth.userId));
      setFilterChosen("minhas tarefas");
    }
  };

  return (
    <>
      <div className="todo__tabs-header">
        <div className="todo__tabs-container">
          <span
            className={`todo__tabs-container__tab  box-title ${
              filterChosen === "minhas tarefas"
                ? "todo__tabs-container__tab--active"
                : ""
            }`}
            onClick={() =>
              handle_task_filter_change("minhas tarefas", permaTasks)
            }
          >
            Minhas Tarefas
          </span>
          {auth.perm.includes("c-tar") && (
            <span
              className={`todo__tabs-container__tab  box-title ${
                filterChosen === "atribuidas"
                  ? "todo__tabs-container__tab--active"
                  : ""
              }`}
              onClick={() =>
                handle_task_filter_change("atribuidas", permaTasks)
              }
            >
              Atribuídas
            </span>
          )}

          {auth.perm.includes("c-tar") && (
            <span
              className={`todo__tabs-container__tab  box-title ${
                filterChosen === "todas"
                  ? "todo__tabs-container__tab--active"
                  : ""
              }`}
              onClick={() => handle_task_filter_change("todas", permaTasks)}
            >
              Todas
            </span>
          )}
        </div>
        <button
          className="todo__tabs-header__adicionar-tarefa add-btn"
          onClick={(e) => setOpenModal("newTaskModal")}
        >
          +
        </button>
      </div>
      {tasks.length === 0 && (
        <div className="no-tasks-container">Nenhuma Tarefa por mostrar</div>
      )}

      {tasks.length > 0 && (
        <div className="todo__list">
          <span className="todo__list__header-title">Titulo</span>
          <span className="todo__list__header-title">Data Finalização</span>
          <span className="todo__list__header-title">Delegador(a)</span>
          <span className="todo__list__header-title">Delegatário(a)</span>
          <span className="todo__list__header-title">Status</span>
          {tasks &&
            tasks.map((tas, index) => (
              <React.Fragment key={index}>
                <span
                  className="todo__list__row-data todo__list__row-data--title"
                  onClick={handleViewTask.bind(null, tas._id)}
                >
                  {tas.titulo}
                </span>
                <span className="todo__list__row-data">
                  {moment(tas.data).format("DD-MM-YYYY")}
                </span>
                <span className="todo__list__row-data">
                  {tas.delegador.name}
                </span>
                <span className="todo__list__row-data">
                  {tas.delegatario.name}
                </span>
                <div className="todo__list__btn-container">
                  <Popover
                    content={
                      <div className="dots__menu__popup">
                        {tas.delegatario.id === auth.userId && (
                          <Popconfirm
                            title="Efetuar tarefa"
                            description="Efetuar tarefa?"
                            icon={<DoneIcon style={{ color: "green" }} />}
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={handleEfetuarTask.bind(null, tas._id)}
                          >
                            <div className="popOverMenu--option">
                              <span>Efetuar</span>
                              <DoneIcon className="popOverMenu--option__icon" />
                            </div>
                          </Popconfirm>
                        )}

                        {tas.delegador.id === auth.userId && (
                          <div
                            className="todo__list__btn-done popOverMenu--option"
                            onClick={handleUpdateTask.bind(null, tas._id)}
                          >
                            <span>Editar</span>
                            <EditIcon className="popOverMenu--option__icon" />
                          </div>
                        )}

                        {tas.delegador.id === auth.userId && (
                          <Popconfirm
                            title="Eliminação de tarefa"
                            description="Tem a certeza que pretende apagar a tarefa?"
                            icon={<DeleteIcon style={{ color: "red" }} />}
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={handleApagarTask.bind(null, tas._id)}
                          >
                            <div className="popOverMenu--option">
                              <span>Eliminar</span>
                              <DeleteIcon className="popOverMenu--option__icon" />
                            </div>
                          </Popconfirm>
                        )}
                      </div>
                    }
                    trigger="click"
                    placement="bottom"
                  >
                    <div className="dots__menu">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </Popover>
                </div>
              </React.Fragment>
            ))}
        </div>
      )}
      {users && (
        <ModalNewTask
          open={openModal === "newTaskModal"}
          users={users}
          handleCloseModal={handleCloseModal}
          atualizarTarefas={atualizarTarefas}
          auth={auth}
          sendRequest={sendRequest}
        />
      )}
      {users && taskId && (
        <ModalUpdateTask
          open={openModal === "updateTaskModal"}
          users={users}
          handleCloseModal={handleCloseModal}
          atualizarTarefas={atualizarTarefas}
          auth={auth}
          sendRequest={sendRequest}
          taskId={taskId}
        />
      )}
      {taskId && (
        <ModalViewTask
          open={openModal === "viewTaskModal"}
          handleCloseModal={handleCloseModal}
          auth={auth}
          sendRequest={sendRequest}
          taskId={taskId}
        />
      )}
    </>
  );
}

export default memo(Tasks);
