import React, { useState, useContext, useEffect } from "react";
import "./ModalStockUpdate.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import SearchIcon from "@mui/icons-material/Search";

function ModalStockUpdate({
  handleSubmitUpdateStock,
  handleCloseModalAdicionarStock,
  stock,
  categoriasStock,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newStockInfo, setNewStockInfo] = useState(stock.stock);
  const [erro, setErro] = useState("");
  const [filteredStock, setFilteredStock] = useState();
  const [textQuery, setTextQuery] = useState("");
  const [categoria, setCategoria] = useState(categoriasStock[0]);
  const auth = useContext(AuthContext);

  const handleQtddChange = (value, idProduto) => {
    const copyStock = [...stock.stock];
    const indexStockToChange = copyStock.findIndex(
      (st) => st.produto.id === idProduto
    );

    const toChange = copyStock[indexStockToChange];

    const updatedObject = {
      ...toChange,
      quantidade: toChange.quantidade + Number(value),
    };

    const copyStockInfo = [...newStockInfo];
    copyStockInfo[indexStockToChange] = updatedObject;
    setNewStockInfo(copyStockInfo);
  };

  const preSubmit = () => {
    let haErro = false;

    newStockInfo.forEach((st) => {
      if (st.quantidade < 0) {
        setErro(`A quantidade não pode ser um número negativo!`);
        haErro = true;
        return;
      }
    });

    if (!haErro && !isSubmitting) {
      setIsSubmitting(true);
      const hoje = new Date();
      const movimentos = [];
      newStockInfo.forEach((st, index) => {
        if (st.quantidade !== stock.stock[index].quantidade) {
          movimentos.push({
            produto: st.produto.id,
            quantidade:
              Number(st.quantidade) - Number(stock.stock[index].quantidade),
            data: hoje,
            saida: false,
            responsavelMovimentoUser: auth.userId,
          });
        }
      });
      handleSubmitUpdateStock(newStockInfo, movimentos);
    }
  };

  useEffect(() => {
    const organizadoAlfabeticamente = stock.stock;
    organizadoAlfabeticamente.sort((a, b) => {
      if (a.produto.produto < b.produto.produto) {
        return -1;
      }
      if (a.produto.produto > b.produto.produto) {
        return 1;
      }
      return 0;
    });
    setFilteredStock(organizadoAlfabeticamente);
  }, [stock]);

  const handleFilter = (text, cat) => {
    let firstFiltragem;
    let resultadoFiltragem;

    if (cat === "todas") {
      firstFiltragem = stock.stock;
    } else {
      const tempFiltragem = stock.stock.filter((st) => {
        return st.produto.categoriaStock === cat;
      });
      firstFiltragem = tempFiltragem;
    }

    resultadoFiltragem = firstFiltragem.filter((perma) => {
      return perma.produto.produto.toLowerCase().includes(text.toLowerCase());
    });

    setFilteredStock(resultadoFiltragem);
  };

  const categoriaChangeHandler = (e) => {
    setCategoria(e.target.value);
    handleFilter(textQuery, e.target.value);
  };

  const textChangeHandler = (e) => {
    setTextQuery(e.target.value);
    handleFilter(e.target.value, categoria);
  };

  return (
    <div className="stockCadastrar__container">
      <div className="stockUpdate__table">
        <div className="stockUpdate__select-container">
          <select
            onChange={categoriaChangeHandler}
            className="select__filter-categoria"
            value={categoria}
          >
            <option value="todas">Todas Categorias</option>
            {categoriasStock.map((cat, index) => (
              <option value={cat.id} key={index}>
                {cat.categoria}
              </option>
            ))}
          </select>

          <div className="stock__header__container2__container2 textBoxSearch__container">
            <input
              type="text"
              className="textBoxSearch__input"
              placeholder="O que está procurando?"
              value={textQuery}
              onChange={textChangeHandler}
            />
            <SearchIcon className="financeiro__filtros__search-icon" />
          </div>
        </div>
        <div className="stockUpdate__table__row stockUpdate__table__row--header">
          <span>Produto</span>
          <span>Qtd Atual</span>
          <span>Qtd Entrada</span>
        </div>

        {filteredStock &&
          filteredStock.map((st, index) => (
            <div className="stockUpdate__table__row" key={index}>
              <span>{st.produto.produto}</span>
              <span>{st.quantidade}</span>
              <input
                type="number"
                name=""
                id=""
                min={0}
                defaultValue={0}
                className="customInput"
                onChange={(e) =>
                  handleQtddChange(e.target.value, st.produto.id)
                }
              />
            </div>
          ))}
      </div>
      {erro && <div className="stockUpdate__erro">{erro}</div>}
      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalAdicionarStock}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        <span
          disabled={isSubmitting}
          className="blue-button"
          onClick={preSubmit}
        >
          {isSubmitting ? "Guardando..." : "Alterar Stock"}
        </span>
      </div>
    </div>
  );
}

export default ModalStockUpdate;
