import { useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import ErrorModal from "../../../../../shared/components/UIElements/ErrorModal";
import { AuthContext } from "../../../../../shared/context/auth-context";

import toast from "react-hot-toast";

//Mui icons
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import "../Anamnese.scss";
import "../../../../../shared/css/Checkbox.scss";
import { useEffect } from "react";

function NewAnamnese() {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [showAlergiaInput, setShowAlergiaInput] = useState(false);
  const [showMedicamentos, setShowMedicamentos] = useState(false);
  const [showDoencas, setShowDoencas] = useState(false);
  const { error, sendRequest, clearError } = useHttpClient();
  const [clienteData, setClienteData] = useState();

  const clienteId = useParams().clienteId;
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //REFS
  const cuidadosMedicosRef = useRef(null);
  const gravidezRef = useRef(null);
  const hospitalizadoRef = useRef(null);
  const medicamentosRef = useRef(null);
  const alergiasRef = useRef(null);
  const doencasRef = useRef(null);
  const estadoGeralRef = useRef(null);

  const handleCancel = () => {
    navigate(`/clientes/${clienteId}`);
  };

  useEffect(() => {
    startLoading();
    const fetchCliente = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setClienteData(response.cliente);
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Não foi possível encontrar os dados do cliente. Por favor, tente Novamente"
        );
      } finally {
        stopLoading();
      }
    };

    fetchCliente();
  }, [sendRequest, clienteId, auth.token]);

  const onSubmit = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("estadoGeralSaude", estadoGeralRef.current.value);
      formData.append("sobCuidadosMedicos", cuidadosMedicosRef.current.checked);
      if (clienteData.genero === "feminino")
        formData.append("gravidez", gravidezRef.current.checked);
      formData.append("foiHospitalizado", hospitalizadoRef.current.checked);
      if (showDoencas) formData.append("doencas", doencasRef.current.value);
      if (showAlergiaInput)
        formData.append("alergias", alergiasRef.current.value);
      if (showMedicamentos)
        formData.append("medicamentos", medicamentosRef.current.value);
      formData.append("medico_responsavel", auth.userId);
      formData.append("clienteId", clienteData._id);
      let tipoOperacao = "PATCH";
      if (!clienteData.anamnese) {
        tipoOperacao = "POST";
      }

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/anamneses`,
          tipoOperacao,
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        toast.success("Anamnese adicionada com sucesso!");
        handleCancel();
      } catch (err) {
        toast.error(
          "Houve um erro ao guardar a anamnese! Por favor, tente novamente."
        );
        console.error("err", err);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const preSubmitHandler = () => {
    setIsSubmiting(true);
    onSubmit();
  };

  return (
    <>
      <div className="new-anamnese">
        <Sidebar />
        <div className="newContainer">
          {clienteData && (
            <Navbar
              title="Anamnese"
              icon={ArticleOutlinedIcon}
              paths={[
                {
                  nome: clienteData.nome,
                  link: `../../../clientes/${clienteData.id}`,
                },
                { nome: "Novo" },
              ]}
            />
          )}
          {clienteData && !clienteData.anamnese && (
            <>
              <ErrorModal error={error} onClear={clearError} />
              <div className="bottom caixa">
                <div className="bottom-left">
                  <img src="/images/medica-ficha.png" alt="" />
                </div>
                <div className="bottom-right">
                  <div className="form-input takes1-7">
                    <textarea
                      rows="4"
                      cols="50"
                      type="textarea"
                      placeholder="Estado geral de saúde"
                      ref={estadoGeralRef}
                      style={{ resize: "none" }}
                    />
                  </div>
                  <div className="bottom-right-with-grid">
                    <div className="formGroup two-rows">
                      <div className="checkbox-wrapper-51">
                        <span className="checkbox-text">
                          Sob cuidados médicos?
                        </span>
                        <input
                          type="checkbox"
                          id="cuidadosMedicos"
                          ref={cuidadosMedicosRef}
                        />
                        <label htmlFor="cuidadosMedicos" className="toggle">
                          <span>
                            <svg width="10px" height="10px" viewBox="0 0 10 10">
                              <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                            </svg>
                          </span>
                        </label>
                      </div>
                    </div>

                    {clienteData && clienteData.genero === "feminino" && (
                      <div className="formGroup two-rows">
                        <div className="checkbox-wrapper-51">
                          <span className="checkbox-text">Gravidez?</span>
                          <input
                            type="checkbox"
                            id="gravidez"
                            ref={gravidezRef}
                          />
                          <label htmlFor="gravidez" className="toggle">
                            <span>
                              <svg
                                width="10px"
                                height="10px"
                                viewBox="0 0 10 10"
                              >
                                <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                              </svg>
                            </span>
                          </label>
                        </div>
                      </div>
                    )}

                    <div className="formGroup two-rows">
                      <div className="checkbox-wrapper-51">
                        <span className="checkbox-text">
                          Foi hospitalizado?
                        </span>
                        <input
                          type="checkbox"
                          id="hospitalizado"
                          ref={hospitalizadoRef}
                        />
                        <label htmlFor="hospitalizado" className="toggle">
                          <span>
                            <svg width="10px" height="10px" viewBox="0 0 10 10">
                              <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                            </svg>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="formGroup two-rows">
                      <div className="checkbox-wrapper-51">
                        <span className="checkbox-text">
                          Tomando medicamentos?
                        </span>
                        <input
                          type="checkbox"
                          id="showMedicamentos"
                          value={showMedicamentos}
                          onChange={(e) =>
                            setShowMedicamentos(e.target.checked)
                          }
                        />
                        <label htmlFor="showMedicamentos" className="toggle">
                          <span>
                            <svg width="10px" height="10px" viewBox="0 0 10 10">
                              <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                            </svg>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="formGroup two-rows">
                      <div className="checkbox-wrapper-51">
                        <span className="checkbox-text">Alergias?</span>
                        <input
                          type="checkbox"
                          id="showAlergias"
                          value={showAlergiaInput}
                          onChange={(e) =>
                            setShowAlergiaInput(e.target.checked)
                          }
                        />
                        <label htmlFor="showAlergias" className="toggle">
                          <span>
                            <svg width="10px" height="10px" viewBox="0 0 10 10">
                              <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                            </svg>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="formGroup two-rows">
                      <div className="checkbox-wrapper-51">
                        <span className="checkbox-text">
                          Teve Doença grave?
                        </span>
                        <input
                          type="checkbox"
                          id="showDoencaGrave"
                          value={showDoencas}
                          onChange={(e) => setShowDoencas(e.target.checked)}
                        />
                        <label htmlFor="showDoencaGrave" className="toggle">
                          <span>
                            <svg width="10px" height="10px" viewBox="0 0 10 10">
                              <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                            </svg>
                          </span>
                        </label>
                      </div>
                    </div>

                    {showDoencas && (
                      <div className="form-input two-rows row-3">
                        <input
                          id="doencas"
                          name="doencas"
                          type="text"
                          placeholder="1333333"
                          ref={doencasRef}
                        />
                        <label className="label" htmlFor="doencas">
                          Doenças
                        </label>
                      </div>
                    )}

                    {showAlergiaInput && (
                      <div className="form-input two-rows row-3">
                        <input
                          id="alergias"
                          name="alergias"
                          type="text"
                          placeholder="1333333"
                          ref={alergiasRef}
                        />
                        <label className="label" htmlFor="alergias">
                          Alergias
                        </label>
                      </div>
                    )}

                    {showMedicamentos && (
                      <div className="form-input two-rows row-3">
                        <input
                          id="medicamentos"
                          name="medicamentos"
                          type="text"
                          placeholder="1333333"
                          ref={medicamentosRef}
                        />
                        <label className="label" htmlFor="medicamentos">
                          Medicamentos
                        </label>
                      </div>
                    )}

                    <div className="botoes">
                      {clienteData && (
                        <button
                          type="submit"
                          className="blue-button"
                          disabled={isSubmitting}
                          onClick={preSubmitHandler}
                        >
                          Guardar
                        </button>
                      )}
                      <button
                        className="cancel-btn botao"
                        onClick={handleCancel}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {clienteData && clienteData.anamnese && (
            <div
              style={{
                width: "80%",
                margin: "0 auto",
                fontSize: 25,
                marginTop: 50,
                lineHeight: 2,
              }}
            >
              O cliente já possui a anamnese criada. Se quiser modifica-la, vá à
              pagina do cliente, clique no botão anamnese. Aparecerá no lado
              direito uma àrea de informações da anamnese. No canto superior
              direito, clique em editar.
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default NewAnamnese;
