import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import ClearIcon from "@mui/icons-material/Clear";
import Backdrop from "./Backdrop";
import "./Modal.scss";

const ModalOverlay = (props) => {
  const content = (
    <div className={`modal ${props.className}`} style={props.style}>
      <form
        onSubmit={
          props.onSubmit ? props.onSubmit : (event) => event.preventDefault()
        }
      >
        <div className={`modal__content ${props.contentClass}`}>
          {props.icon === "error" && (
            <div className="modal__content__icon__container">
              <div className="modal__content__icon__container__container">
                <ClearIcon className="modalErrorIcon" />
              </div>
            </div>
          )}
          {props.children}
        </div>
        <footer className={`modal__footer ${props.footerClass}`}>
          {props.footer}
        </footer>
      </form>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
