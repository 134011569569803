import PdfOrcamentoParticular from "./PdfOrcamentoParticular";

function PdfOrcamentoParticularWrapper({ showSinglePage, orcamentoData }) {
  const showSingleP = () => {
    showSinglePage();
  };
  return (
    <div>
      <PdfOrcamentoParticular
        orcamentoData={orcamentoData}
        showSingleP={showSingleP}
      />
    </div>
  );
}

export default PdfOrcamentoParticularWrapper;
