import React from "react";
import { Link } from "react-router-dom";

const TooltipViewOnly = ({ event, onClose }) => {
  return (
    <div className="tooltip">
      <h3 className="tooltip__title">
        {event.extendedProps.clientId ? (
          <React.Fragment>
            <Link
              to={`/clientes/${event.extendedProps.clientId}`}
              className="tooltip__client-link"
            >
              {event.title.split(" - ")[0]}
            </Link>
            {" - "}
            {event.title.split(" - ")[1]}
          </React.Fragment>
        ) : (
          event.title
        )}
      </h3>
      <p className="tooltip__time">
        Início: {event.start.toLocaleTimeString()}
      </p>
      <p className="tooltip__time">Fim: {event.end.toLocaleTimeString()}</p>
    </div>
  );
};

export default TooltipViewOnly;
