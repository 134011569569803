import * as yup from "yup";

export const funcionarioSchema = yup.object().shape({
  nome: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    )
    .required("Campo Obrigatório"),
  genero: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    )
    .required("Campo Obrigatório"),
  email: yup.string().email("Email Inválido"),
  contacto2: yup
    .string()
    .min(7, "Contacto tem que ter pelo menos 7 caracteres"),
  contacto: yup
    .string()
    .min(7, "Contacto tem que ter pelo menos 7 caracteres")
    .required("Campo Obrigatório"),
  endereco: yup.string(),
  cargo: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    ),
  nif: yup.number().typeError("Apenas Números").required("Campo Obrigatório"),
  identificacao: yup.string().required("Campo Obrigatório"),
  nr_beneficiario: yup.string(),
});

export const clienteSchema = yup.object().shape({
  // nome: yup
  //   .string()
  //   .matches(
  //     /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
  //     "Apenas letras são permitidas"
  //   )
  //   .required("Campo Obrigatório"),
  genero: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    )
    .required("Campo Obrigatório"),
  email: yup.string().email("Email Inválido"),
  contacto2: yup
    .string()
    .min(7, "Contacto tem que ter pelo menos 7 caracteres"),
  contacto: yup
    .string()
    .min(7, "Contacto tem que ter pelo menos 7 caracteres")
    .required("Campo Obrigatório"),
  tel_trabalho: yup
    .string()
    .min(7, "Telefone tem que ter pelo menos 7 caracteres"),
  endereco: yup.string(),
  profissao: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    ),
  responsavel: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    ),
  nif: yup.number().typeError("Apenas Números"),
  identificacao: yup.string(),
  nr_beneficiario: yup.string(),
  sms: yup.boolean().required("Campo Obrigatório"),
  com_email: yup.boolean().required("Campo Obrigatório"),
  com_whatsapp: yup.boolean().required("Campo Obrigatório"),
});

export const tratamentoSchema = yup.object().shape({
  codigo: yup.string(),
  designacao: yup.string().required("Campo Obrigatório"),
  precoParticular: yup.number().required("Campo Obrigatório"),
  precoBeneficiario: yup.number().required("Campo Obrigatório"),
  precoSegurado: yup.number().required("Campo Obrigatório"),
  comparticipacaoInps: yup.number().required("Campo Obrigatório"),
});

export const procedimentoSchema = yup.object().shape({
  codigo: yup.string().required("Campo Obrigatório"),
  nome: yup
    .string()
    // .matches(
    //   /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
    //   "Apenas letras são permitidas"
    // )
    .required("Campo Obrigatório"),
  precoParticular: yup
    .number()
    .typeError("Apenas Números")
    .required("Campo Obrigatório"),
  precoBeneficiario: yup
    .number()
    .typeError("Apenas Números")
    .required("Campo Obrigatório"),
});

export const especialidadeSchema = yup.object().shape({
  nome: yup.string().required("Campo Obrigatório"),
});

export const consultaSchema = yup.object().shape({
  cliente: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    )
    .required("Campo Obrigatório"),
  data: yup.date("Data incorreta").required("Campo Obrigatório"),
  hora: yup.string("Hora incorreta").required("Campo Obrigatório"),
  observacao: yup.string(),
});

export const userSchema = yup.object().shape({
  nome: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    ),
  // .required("Campo Obrigatório"),
  email: yup.string().email("Email Inválido"),
  contacto: yup.string().min(7, "Contacto tem que ter pelo menos 7 caracteres"),
  // .required("Campo Obrigatório"),
  profissao: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    ),
  especiaidade: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    ),
  password: yup.string().min(7, "Passowrd com minimo 7 caracteres"),
  // .required("Campo Obrigatório"),
});

export const edituserSchema = yup.object().shape({
  nome: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    )
    .required("Campo Obrigatório"),
  email: yup.string().email("Email Inválido"),
  contacto: yup
    .string()
    .min(7, "Contacto tem que ter pelo menos 7 caracteres")
    .required("Campo Obrigatório"),
});

export const clinicaSchema = yup.object().shape({
  nome: yup
    .string()
    // .matches(
    //   /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
    //   "Apenas letras são permitidas"
    // )
    .required("Campo Obrigatório"),
  cidade: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    )
    .required("Campo Obrigatório"),
  endereco: yup.string(),
  ilha: yup
    .string()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
      "Apenas letras são permitidas"
    )
    .required("Campo Obrigatório"),
  contacto: yup
    .string()
    .min(7, "Contacto tem que ter pelo menos 7 caracteres")
    .required("Campo Obrigatório"),
  email: yup.string().email("Email Inválido"),
  nif: yup.number().typeError("Apenas Números").required("Campo Obrigatório"),
  nrCertidao: yup.string().required("Campo Obrigatório"),
});
