import React, { useContext } from "react";

import Input from "../../../shared/components/FormElements/Input";
import Button from "../../../shared/components/FormElements/Button";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../../shared/util/validators";
import { useForm } from "../../../shared/hooks/form-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import "./Auth.scss";
import logo from "./ht-logo.png";

import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";

const Auth = () => {
  const { startLoading, stopLoading } = useLoading();

  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const authSubmitHandler = async (event) => {
    event.preventDefault();
    startLoading();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/users/login`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      let usuariosAssociados = null;
      if (responseData.medicos) {
        usuariosAssociados = responseData.medicos.map((m) => {
          return {
            id: m._id,
            nome: m.name,
            cor: m.cor,
            duracaoConsulta: m.duracaoConsulta,
          };
        });
      }

      if (responseData.secretarias) {
        usuariosAssociados = responseData.secretarias.map((m) => {
          return { id: m._id, nome: m.name };
        });
      }
      let permissoes = [];
      responseData.role.permissoes.forEach((per) => {
        if (per.check) {
          permissoes.push(per.permissao.shortCode);
        }
      });

      if (
        responseData.role.role.role === "Dentista" &&
        usuariosAssociados.length === 0
      ) {
        toast.error(
          "O administrador deve associar uma secretária a esse médico para que esse possa fazer login!"
        );
      } else {
        auth.login(
          responseData.userId,
          responseData.token,
          responseData.image,
          responseData.role.role.role,
          responseData.clinica,
          usuariosAssociados,
          permissoes,
          responseData.nome
        );
      }
      // auth.image = responseData.image;
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro efetuando o login. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="auth-main-container">
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
        <div className="circle circle-3"></div>
        <div className="circle circle-4"></div>
        <div className="circle circle-5"></div>
        <div className="auth-container">
          <div className="auth-container-containers auth-container-1">
            {/* <img src={hero} alt="" className="src" /> */}
          </div>

          <div className="auth-container-containers auth-container-2">
            <img src={logo} alt="" className="ht-logo" />
            <h2>Seja Bem-Vindo!</h2>
            <hr />
            <form onSubmit={authSubmitHandler} className="form-auth">
              <Input
                element="input"
                id="email"
                type="email"
                placeholder="Email"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Por favor introduza um email válido."
                onInput={inputHandler}
              />
              <Input
                element="input"
                id="password"
                type="password"
                placeholder="Palavra-passe"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Por favor introduza uma palavra-passe válida, mínimo 6 caracteres."
                onInput={inputHandler}
                autoComplete="on"
              />
              <Button type="submit" disabled={!formState.isValid}>
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Auth;
