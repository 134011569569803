import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";

import toast from "react-hot-toast";

import { useHttpClient } from "../../../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";

import "./ModalEditarDesconto.scss";
function ModalEditarDesconto({
  open,
  handleCloseModalEditarDesconto,
  modalData,
  updateContaCorrente,
  contaCorrente,
  auth,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tipoDesconto, setTipoDesconto] = useState(
    modalData.orcamento.tipoDesconto
  );
  const [desconto, setDesconto] = useState(
    modalData.orcamento.tipoDesconto === "$"
      ? modalData.orcamento.desconto
      : (modalData.orcamento.desconto / modalData.orcamento.subtotal) * 100
  );
  const [descontoEmEscudos, setDescontoEmEscudos] = useState(
    modalData.orcamento.desconto
  );

  const porReceber = modalData.parcelas.reduce(
    (accumulator, currentValue) =>
      currentValue.parcelaPaga ? 0 : accumulator + currentValue.quantiaPorPagar,
    0
  );

  const porReceberMaisDesconto = porReceber + modalData.orcamento.desconto;

  const submit = async () => {
    startLoading();
    try {
      if (descontoEmEscudos == modalData.orcamento.desconto) {
        toast.error("O desconto permanece o mesmo. Altere-o para continuar");
        setIsSubmitting(false);
      } else {
        const contas = contaCorrente.contas.filter(
          (conta) => conta._id !== modalData._id
        );

        let contaModificada;
        //Caso o valor do desconto for > do que o inicial
        if (descontoEmEscudos > modalData.orcamento.desconto) {
          /////////////////////////////////////////////////////////////////////////////////////////////
          //Aqui existe uma logica complexa
          contaModificada = aplicarDesconto(
            descontoEmEscudos - modalData.orcamento.desconto
          );

          contaModificada.orcamento = {
            ...contaModificada.orcamento,
            desconto: descontoEmEscudos,
            total: modalData.orcamento.subtotal - Number(descontoEmEscudos),
            tipoDesconto: tipoDesconto,
          };
        } else {
          /////////////////////////////////////////////////////////////////////////////////////////////
          //Aqui simplismente criamos uma nova parcela com o valor de descontoAntigo - descontoNovo
          //A conta fica liquidada a false
          const novaParcela = {
            quantiaPaga: 0,
            quantiaPorPagar: modalData.orcamento.desconto - descontoEmEscudos,
            dataLimitePagamento: new Date(), // Você pode ajustar isso conforme necessário
            parcelaPaga: false,
            cancelamentosDocumentosEletronicos: [],
            // Adicione outros campos necessários aqui
          };

          contaModificada = { ...modalData };

          contaModificada.orcamento = {
            ...contaModificada.orcamento,
            desconto: Number(descontoEmEscudos),
            total: modalData.orcamento.subtotal - Number(descontoEmEscudos),
            tipoDesconto: tipoDesconto,
          };

          contaModificada.parcelas.push(novaParcela);
          contaModificada.nrParcelas = contaModificada.parcelas.length;
          contaModificada.contaLiquidada = false;

          renumberParcelas(contaModificada);
        }
        ////////////////////////////////////////////////////////////////////////////////////
        ///Guardar na BD

        contas.push(contaModificada);

        //Atualizamos os dados do desconto no orcamento em questao
        const formData = new FormData();
        formData.append("desconto", Number(descontoEmEscudos));
        formData.append("tipoDesconto", tipoDesconto);
        formData.append(
          "total",
          modalData.orcamento.subtotal - Number(descontoEmEscudos)
        );

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/dadosdesconto/${modalData.orcamento._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          toast.error(
            "Ocorreu ao atualizar os dados do desconto. Por favor tente novamente"
          );
          console.error("err", err);
        }

        const formData2 = new FormData();
        formData2.append("contas", JSON.stringify(contas));
        let contaCorrenteParaState;
        try {
          contaCorrenteParaState = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente._id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          toast.error(
            "Ocorreu um erro ao atualizar a conta corrente. Por favor tente novamente"
          );
          console.error("err", err);
        }
        toast.success(
          `Desconto atualizado com sucesso!${
            contaModificada.contaLiquidada ? " Conta Liquidada!" : ""
          }`
        );

        updateContaCorrente(contaCorrenteParaState.contaCorrente);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      handleCloseModalEditarDesconto();
      stopLoading();
    }
  };

  const preSubmitHandler = () => {
    // setIsSubmitting(true);
    submit();
  };

  const tipoDescontoChangeHandler = (e) => {
    if (e.target.value === "$") {
      if (desconto >= porReceberMaisDesconto) {
        setDesconto(porReceberMaisDesconto);
        setDescontoEmEscudos(porReceberMaisDesconto);
      } else {
        setDescontoEmEscudos(desconto);
      }
    } else {
      if (desconto >= 100) {
        setDesconto(100);
        setDescontoEmEscudos(porReceberMaisDesconto);
      } else {
        setDescontoEmEscudos((modalData.orcamento.subtotal * desconto) / 100);
      }
    }
    setTipoDesconto(e.target.value);
  };

  const descontoChangeHandler = (e) => {
    if (tipoDesconto === "$") {
      if (e.target.value >= porReceberMaisDesconto) {
        setDesconto(porReceberMaisDesconto);
        setDescontoEmEscudos(porReceberMaisDesconto);
      } else {
        setDescontoEmEscudos(e.target.value);
        setDesconto(e.target.value);
      }
    } else {
      if (e.target.value >= 100) {
        setDesconto(100);
        setDescontoEmEscudos(porReceberMaisDesconto);
      } else {
        setDescontoEmEscudos(
          (modalData.orcamento.subtotal * e.target.value) / 100
        );
        setDesconto(e.target.value);
      }
    }
  };

  function encontrarCombinacoes(parcelas, quantiaPaga) {
    const combinacoes = [];
    encontrarCombinacoesRecursivo(parcelas, quantiaPaga, [], 0, combinacoes);
    return combinacoes;
  }

  function encontrarCombinacoesRecursivo(
    parcelas,
    quantiaPaga,
    combinacaoAtual,
    index,
    combinacoes
  ) {
    const somaQuantiaPorPagar = combinacaoAtual.reduce(
      (total, parcela) => total + parcela.quantiaPorPagar,
      0
    );

    if (somaQuantiaPorPagar === quantiaPaga) {
      combinacoes.push(combinacaoAtual);
      return;
    }

    if (index === parcelas.length || somaQuantiaPorPagar > quantiaPaga) {
      return;
    }

    const parcela = parcelas[index];

    // Include the current parcela in the combination
    encontrarCombinacoesRecursivo(
      parcelas,
      quantiaPaga,
      [...combinacaoAtual, parcela],
      index + 1,
      combinacoes
    );

    // Exclude the current parcela from the combination
    encontrarCombinacoesRecursivo(
      parcelas,
      quantiaPaga,
      combinacaoAtual,
      index + 1,
      combinacoes
    );
  }

  function encontrarMelhorCombinacao(parcelas, valorAlvo) {
    const n = parcelas.length;
    const dp = Array(n + 1)
      .fill(null)
      .map(() => Array(valorAlvo + 1).fill(false));
    dp[0][0] = true;

    for (let i = 1; i <= n; i++) {
      for (let j = 0; j <= valorAlvo; j++) {
        if (dp[i - 1][j]) {
          dp[i][j] = true;
        } else if (j >= parcelas[i - 1].quantiaPorPagar) {
          dp[i][j] = dp[i - 1][j - parcelas[i - 1].quantiaPorPagar];
        }
      }
    }

    let melhorCombinacao = [];
    let melhorDiferenca = Infinity;
    let melhorSoma = 0;

    for (let j = valorAlvo; j >= 0; j--) {
      if (dp[n][j]) {
        const diferenca = Math.abs(valorAlvo - j);
        if (diferenca < melhorDiferenca) {
          melhorDiferenca = diferenca;
          melhorSoma = j;
        }
      }
    }

    let i = n;
    let j = melhorSoma;
    while (i > 0 && j > 0) {
      if (dp[i - 1][j]) {
        i--;
      } else {
        melhorCombinacao.push(parcelas[i - 1]);
        j -= parcelas[i - 1].quantiaPorPagar;
        i--;
      }
    }

    return [melhorCombinacao];
  }

  //Funcao para rearanjar a numeracao das parcelas ignorando a parcela de entrada caso ela exista
  const renumberParcelas = (contaFiltrada) => {
    let regularParcelaCount = 0;
    const regularParcelas = contaFiltrada.parcelas.filter((p) => !p.entrada);

    contaFiltrada.parcelas.forEach((p) => {
      if (!p.entrada) {
        regularParcelaCount++;
        p.nrParcela = `${regularParcelaCount}/${regularParcelas.length}`;
      } else {
        delete p.nrParcela; // Ensure entrada parcels don't have nrParcela
      }
    });

    contaFiltrada.nrParcelas = regularParcelas.length;
  };

  const aplicarDesconto = (diferencaDesconto) => {
    const contaFiltrada = { ...modalData };
    const parcelasNaoPagas = contaFiltrada.parcelas.filter(
      (parcela) => !parcela.parcelaPaga
    );

    let combinacoesPossiveis = encontrarCombinacoes(
      parcelasNaoPagas,
      diferencaDesconto
    );

    if (combinacoesPossiveis.length === 0) {
      combinacoesPossiveis = encontrarMelhorCombinacao(
        parcelasNaoPagas,
        diferencaDesconto
      );
    }

    if (combinacoesPossiveis.length > 0) {
      const parcelasParaDescontar = combinacoesPossiveis[0];
      let descontoRestante = diferencaDesconto;

      parcelasParaDescontar.forEach((parcela) => {
        const index = contaFiltrada.parcelas.findIndex(
          (p) => p._id === parcela._id
        );

        if (descontoRestante >= parcela.quantiaPorPagar) {
          // Eliminar a parcela completamente
          contaFiltrada.parcelas.splice(index, 1);
          descontoRestante -= parcela.quantiaPorPagar;
        } else {
          // Subtrair o desconto do valor da parcela
          contaFiltrada.parcelas[index].quantiaPorPagar -= descontoRestante;
          descontoRestante = 0;
        }
      });

      // Se ainda houver desconto restante, aplicar à próxima parcela não paga
      if (descontoRestante > 0) {
        const proximaParcelaNaoPaga = contaFiltrada.parcelas.find(
          (p) => !p.parcelaPaga
        );
        if (proximaParcelaNaoPaga) {
          const index = contaFiltrada.parcelas.indexOf(proximaParcelaNaoPaga);
          if (descontoRestante >= proximaParcelaNaoPaga.quantiaPorPagar) {
            contaFiltrada.parcelas.splice(index, 1);
          } else {
            contaFiltrada.parcelas[index].quantiaPorPagar -= descontoRestante;
          }
        }
      }

      // Renumerar as parcelas após as alterações
      renumberParcelas(contaFiltrada);

      // Atualizar o número total de parcelas
      contaFiltrada.nrParcelas = contaFiltrada.parcelas.length;

      // Verificar se a conta foi totalmente liquidada pelo desconto
      const parcelasRestantes = contaFiltrada.parcelas.filter(
        (p) => !p.parcelaPaga
      );
      if (parcelasRestantes.length === 0) {
        contaFiltrada.contaLiquidada = true;
      }

      // Aqui você pode adicionar a lógica para atualizar o estado e enviar as alterações para o backend
      // Por exemplo:
      // updateContaCorrente(contaFiltrada);
      // enviarAlteracoesParaBackend(contaFiltrada);

      return contaFiltrada;
    }

    return null; // Retorna null se não foi possível aplicar o desconto
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModalEditarDesconto}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box aprovar-orcamento__modal"
          id="modal__cinquentaPorCento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Editar Desconto do Orçamento {modalData.orcamento.codigo}
          </h1>

          <div className="modalEditarDesconto__container">
            <FormControl className="relatorio__filtros__container__selectContainer">
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tipoDesconto}
                label="Periodo"
                onChange={tipoDescontoChangeHandler}
              >
                <MenuItem value="%">%</MenuItem>
                <MenuItem value="$">$</MenuItem>
              </Select>
            </FormControl>

            <TextField
              className="modalEditarDesconto__container__inputText"
              label="Desconto"
              value={desconto}
              onChange={descontoChangeHandler}
            />

            <span className="modalEditarDesconto__container__valor">
              Valor: {descontoEmEscudos.toLocaleString("pt-br")}$00
            </span>
          </div>

          <div className="modalEditarDesconto__dadosOrcamento">
            <span className="modalEditarDesconto__dadosOrcamento__titulo">
              Dados do Orçamento
            </span>
            <span className="modalEditarDesconto__dadosOrcamento__subtitulo">
              Antes
            </span>
            <span className="modalEditarDesconto__dadosOrcamento__subtitulo">
              Depois
            </span>

            {/* Subtotal antes */}
            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Subtotal
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {modalData.orcamento.subtotal.toLocaleString("pt-Br")}
              </span>
            </div>

            {/* Subtotal depois */}
            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Subtotal
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {modalData.orcamento.subtotal.toLocaleString("pt-Br")}
              </span>
            </div>
            {/* Desconto antes */}
            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Desconto
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {modalData.orcamento.desconto.toLocaleString("pt-Br")}
              </span>
            </div>
            {/* Desconto depois */}
            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Desconto
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {descontoEmEscudos.toLocaleString("pt-Br")}
              </span>
            </div>
            {/* Total antes */}
            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Total
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {modalData.orcamento.total.toLocaleString("pt-Br")}
              </span>
            </div>
            {/* Total depois */}
            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Total
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {modalData.orcamento.subtotal - descontoEmEscudos}
              </span>
            </div>

            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Por Receber
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {porReceber.toLocaleString("pt-Br")}
              </span>
            </div>

            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Por Receber
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {porReceber + modalData.orcamento.desconto - descontoEmEscudos}
              </span>
            </div>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalEditarDesconto}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={preSubmitHandler}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditarDesconto;
