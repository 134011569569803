const unidades = [
  "",
  "um",
  "dois",
  "três",
  "quatro",
  "cinco",
  "seis",
  "sete",
  "oito",
  "nove",
];
const especiais = [
  "dez",
  "onze",
  "doze",
  "treze",
  "catorze",
  "quinze",
  "dezesseis",
  "dezessete",
  "dezoito",
  "dezenove",
];
const dezenas = [
  "",
  "",
  "vinte",
  "trinta",
  "quarenta",
  "cinquenta",
  "sessenta",
  "setenta",
  "oitenta",
  "noventa",
];
const centenas = [
  "",
  "cem",
  "duzentos",
  "trezentos",
  "quatrocentos",
  "quinhentos",
  "seiscentos",
  "setecentos",
  "oitocentos",
  "novecentos",
];

function converteDezena(numero) {
  if (numero < 10) return unidades[numero];
  if (numero < 20) return especiais[numero - 10];
  const unidade = numero % 10;
  const dezena = Math.floor(numero / 10);
  return `${dezenas[dezena]}${unidade ? " e " + unidades[unidade] : ""}`;
}

function converteCentena(numero) {
  if (numero < 100) return converteDezena(numero);
  const dezena = numero % 100;
  const centena = Math.floor(numero / 100);
  if (centena === 1 && dezena === 0) return "cem";
  return `${centenas[centena]}${dezena ? " e " + converteDezena(dezena) : ""}`;
}

function converteMilhar(numero) {
  if (numero < 1000) return converteCentena(numero);
  const centena = numero % 1000;
  const milhar = Math.floor(numero / 1000);
  const milharExtenso = milhar === 1 ? "mil" : `${converteCentena(milhar)} mil`;
  return `${milharExtenso}${centena ? " e " + converteCentena(centena) : ""}`;
}

function numeroParaExtenso(numero) {
  if (numero === 0) return "zero";
  if (numero < 0 || numero >= 1000000)
    return "Número fora do intervalo suportado";
  return converteMilhar(numero);
}

// Exemplo de uso:
export default numeroParaExtenso; // "cento e vinte e três mil quatrocentos e cinquenta e seis"
