import React, { useState, useEffect, useContext, useCallback } from "react";
import ListOrcamento from "../Lists/ListOrcamento/ListOrcamento";
import moment from "moment/moment";
import { AuthContext } from "../../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../../shared/context/clinica-context";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./OrcamentosComponent.scss";
import ModalAprovarOrcamento from "./components/ModalAprovarOrcamento";

import toast from "react-hot-toast";
import { useLoading } from "../../../../../shared/context/LoadingContext";
function OrcamentosComponent({
  clienteId,
  sendRequest,
  token,
  dadosOrcamento,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [orcamentos, setOrcamentos] = useState([]);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [contaCorrente, setContaCorrente] = useState([]);

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  //Modal Code
  const [openModal, setOpenModal] = useState(false);
  const [openModalAprovarOrcamento, setOpenModalAprovarOrcamento] =
    useState(false);
  const [modalData, setModalData] = useState([]);

  const handleOpenModal = useCallback(
    (id, tipo) => {
      let dadosFiltrados;
      const orcamentoFiltrado = orcamentos.filter((or) => or.id === id)[0];

      if (tipo === "orcamento") dadosFiltrados = orcamentoFiltrado;
      else
        dadosFiltrados = avaliacoes.filter(
          (av) => av.id === orcamentoFiltrado.avaliacao
        )[0];

      setModalData(dadosFiltrados);
      setOpenModal(true);
    },
    [avaliacoes, orcamentos]
  );

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData([]);
  };

  const handleCloseModalAprovarOrcamento = useCallback(() => {
    setOpenModalAprovarOrcamento(false);
  }, []);
  ///////////////////////////////////////

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [orcamentosResponse, avaliacoesResponse, contaCorrenteResponse] =
          await Promise.all([
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/cliente/${clienteId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/cliente/comtratamento/${clienteId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/cliente/${clienteId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
          ]);

        // Process orcamentos
        const orcamentos = processOrcamentos(orcamentosResponse.orcamentos);
        setOrcamentos(filterByUserRole(orcamentos, auth));

        // Process avaliacoes
        const avaliacoes = processAvaliacoes(avaliacoesResponse.avaliacoes);
        setAvaliacoes(filterByUserRole(avaliacoes, auth));

        // Set conta corrente
        setContaCorrente(contaCorrenteResponse.contaCorrente);
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        toast.error(
          "Ocorreu um erro na busca dos dados do cliente. Por favor tente novamente."
        );
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, clienteId, auth]);

  // Helper functions
  const processOrcamentos = (orcamentos) => {
    return orcamentos
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((orcamento) => ({
        ...orcamento,
        data_orcamento: moment(orcamento.created_at).format("DD-MM-YYYY"),
      }));
  };

  const processAvaliacoes = (avaliacoes) => {
    return avaliacoes
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((avaliacao) => ({
        ...avaliacao,
        data_avaliacao: moment(avaliacao.created_at).format("DD-MM-YYYY"),
      }));
  };

  const filterByUserRole = (items, auth) => {
    switch (auth.role) {
      case "Médico/Administrador":
      case "Dentista":
        return items.filter(
          (item) => item.medico_responsavel.id === auth.userId
        );
      case "Secretária(o)":
        return items.filter((item) =>
          auth.usuariosAssociados.some(
            (medico) => medico.id === item.medico_responsavel.id
          )
        );
      case "Administrador Não Médico":
        return items;
      default:
        return [];
    }
  };
  ///////////////////////////////////////////////////////////////////

  const handleDesativar = useCallback(
    async (id) => {
      startLoading();
      try {
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/ativardesativar/${id}`,
            "PATCH",
            {},
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro na desativação do orcamento. Por favor tente novamente."
          );
        }

        //filtramos o orcamento a desativar para obter o id da avaliacao que o gerou
        const orcamentoFiltrado = orcamentos.filter((or) => or.id === id)[0];

        let avaliacaoDoOrcamentoDesativado = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${orcamentoFiltrado.avaliacao}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );

        avaliacaoDoOrcamentoDesativado =
          avaliacaoDoOrcamentoDesativado.avaliacao;

        //Alteramos os procedimentos da avaliacao que geraram o orcamento para não orçamentados
        avaliacaoDoOrcamentoDesativado.procedimentos.forEach((avPr) => {
          orcamentoFiltrado.procedimentos.forEach((orPr) => {
            if (avPr.id === orPr.id) {
              avPr.orcamentado = false;
            }
          });
        });

        //Obtemos a lista dos procedimentos do orçamento para depois compararmos com os procedimentos
        //orcamentados da avaliacao para podermos definir o estado da avaliação
        const procedimentosDaAvaliacaoOrcamentados =
          avaliacaoDoOrcamentoDesativado.procedimentos.filter(
            (pr) => pr.orcamentado
          );

        const formData = new FormData();
        formData.append(
          "procedimentos",
          JSON.stringify(avaliacaoDoOrcamentoDesativado.procedimentos)
        );
        formData.append("terminado", false);

        //Se todos os procedimentos da avaliacao passaram a ser não orcamentados os não
        if (procedimentosDaAvaliacaoOrcamentados.length > 0)
          formData.append("transformado", true);
        else formData.append("transformado", false);

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${orcamentoFiltrado.avaliacao}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro na atualização da avaliação que gerou esse orçamento. Por favor tente novamente."
          );
        }

        setOrcamentos((currentOrcamento) => {
          return currentOrcamento.filter((el) => {
            return el.id !== id;
          });
        });

        toast.success("Desativação de Orçamento efetuada com sucesso!");
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [orcamentos, sendRequest, token]
  );

  const gerarPlanoTratamento = useCallback(
    async (dadosConta, parcelas) => {
      startLoading();
      try {
        const id = dadosConta.orcamento;

        const orcamentoFiltrado = orcamentos.filter((or) => or.id === id)[0];
        const procedimentosFiltrados = [];

        orcamentoFiltrado.procedimentos.forEach((pr) => {
          procedimentosFiltrados.push({
            tratamento: pr.tratamento.id,
            dente: pr.dente,
            faces: pr.faces,
            medico: orcamentoFiltrado.medico_responsavel,
            procedimento_completo: false,
          });
        });
        const formData = new FormData();
        formData.append("ativo", true);
        formData.append(
          "procedimentos",
          JSON.stringify(procedimentosFiltrados)
        );
        formData.append("cliente", orcamentoFiltrado.cliente.id);
        formData.append("clinica", orcamentoFiltrado.clinica.id);
        formData.append("orcamento", id);
        formData.append("canDelete", true);
        formData.append(
          "medico_responsavel",
          orcamentoFiltrado.medico_responsavel.id
        );

        //Gravando plano de tratamento
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro gerando o plano de tratamento. Por favor tente novamente."
          );
        }
        //Gravando plano de tratamento, transformando orcamento
        const formData2 = new FormData();
        formData2.append("transformado", true);
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/transformar/${id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro marcando o orcamento correspondente como transformado. Por favor tente novamente."
          );
        }
        //Atualizando a conta corrente

        const formData3 = new FormData();

        const filteredDadosConta = {
          ...dadosConta,
          aprovado: true,
          contaLiquidada:
            parcelas[0].entrada &&
            parcelas[0].quantiaPorPagar === dadosConta.total
              ? true
              : false,
          nrParcelas: parcelas[0].entrada
            ? parcelas.length - 1
            : parcelas.length,
          parcelas: parcelas,
        };

        const contasDaContaCorrente = contaCorrente.contas.filter(
          (conta) => conta.id !== dadosConta.id
        );
        contasDaContaCorrente.push(filteredDadosConta);

        formData3.append("contas", JSON.stringify(contasDaContaCorrente));
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
            "PATCH",
            formData3,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro atualizando a conta corrente do cliente. Por favor tente novamente."
          );
        }

        /////////////////////////////////////////////////////
        let tempOrcamentos = orcamentos.map((or) => {
          if (or.id === id) {
            return {
              ...or,
              transformado: true,
            };
          } else {
            return or;
          }
        });
        setOrcamentos(tempOrcamentos);
        setOpenModalAprovarOrcamento(false);
        toast.success(
          `Orçamento ${
            dadosConta.total === 0 ? "isento de pagamento " : " "
          }aprovado com sucesso!`
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [contaCorrente, orcamentos, sendRequest, token]
  );

  const aprovarOrcamento = useCallback(
    async (id) => {
      startLoading();
      try {
        const filteredConta = contaCorrente.contas.filter(
          (conta) => conta.orcamento === id
        )[0];

        //Caso o orçamento foi isento de pagamento
        if (filteredConta.total === 0) {
          let responseCaixa;
          try {
            responseCaixa = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinica.clinica._id}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + token,
              }
            );
          } catch (err) {
            console.error("err", err);
            toast.error(
              "Ocorreu um erro na busca das caixas da clinica. Por favor tente novamente."
            );
          }

          const caixaClinicaFiltrada = responseCaixa.caixas.filter(
            (caixa) => caixa.caixa.toLowerCase() === "clínica"
          )[0];

          gerarPlanoTratamento(filteredConta, [
            {
              ...filteredConta.parcelas[0],
              parcelaPaga: true,
              metodoPagamento: "Dinheiro",
              dataPagamento: new Date(),
              caixa: caixaClinicaFiltrada._id,
            },
          ]);
        } else {
          setOpenModalAprovarOrcamento(true);
        }
        setModalData(filteredConta);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [
      clinica.clinica._id,
      contaCorrente,
      gerarPlanoTratamento,
      sendRequest,
      token,
    ]
  );

  const orcamentoData = (
    tipo,
    serviceList,
    cliente,
    clinica,
    medico_responsavel,
    numero,
    desconto,
    tipoDesconto
  ) => {
    const dados = {
      tipo,
      serviceList,
      cliente,
      clinica,
      medico_responsavel,
      numero,
      desconto,
      tipoDesconto,
    };
    dadosOrcamento(dados);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box avaliacoes__modal">
          <h1 className="fluxo-atendimento__modal__titulo">Avaliação</h1>
          <span className="fluxo-atendimento__modal__designacao">
            Tratamento
          </span>
          <span className="fluxo-atendimento__modal__designacao">Dentes</span>
          <span className="fluxo-atendimento__modal__designacao">Faces</span>
          {modalData.procedimentos &&
            modalData.procedimentos.map((proc, index) => (
              <React.Fragment key={index}>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.tratamento.designacao}
                </span>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.dente}
                </span>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.faces.join(", ")}
                </span>
              </React.Fragment>
            ))}
          {modalData.observacoes && (
            <>
              <span className="fluxo-atendimento__modal__observacao-titulo">
                Observação
              </span>
              <textarea
                className="fluxo-atendimento__modal__textarea"
                cols="30"
                rows="4"
                readOnly
                defaultValue={modalData.observacoes}
              ></textarea>
            </>
          )}
          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span className="cancel-btn" onClick={() => setOpenModal(false)}>
              Fechar
            </span>
          </div>
        </Box>
      </Modal>

      {orcamentos.length !== 0 && (
        <>
          {/* Modal orcamento e Avaliacao */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {/* Verificar se nos dados da modal conter avaliacao. Se sim, estamos lidando com dados do orcamento */}
            <Box
              className={`fluxo-atendimento__modal__box ${
                modalData.avaliacao
                  ? modalData.beneficio === "Particular"
                    ? "orcamento__modal"
                    : "orcamento__modal--beneficiario"
                  : "avaliacao__modal"
              }`}
            >
              <h1 className="fluxo-atendimento__modal__titulo">
                {modalData.avaliacao
                  ? `Orçamento nr ${modalData.numero} ${
                      modalData.beneficio === "Particular"
                        ? "- Particular"
                        : "- INPS"
                    }`
                  : "Avaliação"}
              </h1>
              <span className="fluxo-atendimento__modal__designacao">
                Tratamento
              </span>
              <span className="fluxo-atendimento__modal__designacao">
                Dentes/Região
              </span>
              {!modalData.avaliacao && (
                <span className="fluxo-atendimento__modal__designacao">
                  Faces
                </span>
              )}
              {modalData.avaliacao && (
                <span className="fluxo-atendimento__modal__designacao">
                  Valor
                </span>
              )}

              {modalData.avaliacao && modalData.beneficio !== "Particular" && (
                <span className="fluxo-atendimento__modal__designacao">
                  INPS
                </span>
              )}
              {modalData.procedimentos &&
                modalData.procedimentos.map((proc, index) => (
                  <React.Fragment key={index}>
                    <span className="fluxo-atendimento__modal__cell">
                      {proc.tratamento.designacao}
                      {modalData.avaliacao &&
                        proc.faces.length > 0 &&
                        `- face(s) ${proc.faces.join(", ")}`}
                    </span>
                    <span className="fluxo-atendimento__modal__cell">
                      {proc.dente}
                    </span>
                    {!modalData.avaliacao && (
                      <span className="fluxo-atendimento__modal__cell">
                        {proc.faces.join(", ")}
                      </span>
                    )}

                    {modalData.avaliacao && (
                      <span className="fluxo-atendimento__modal__cell">
                        {proc.pr_total}
                      </span>
                    )}

                    {modalData.avaliacao &&
                      modalData.beneficio !== "Particular" && (
                        <span className="fluxo-atendimento__modal__cell">
                          {proc.tratamento.comparticipacao_inps}
                        </span>
                      )}
                  </React.Fragment>
                ))}
              {modalData.observacoes &&
                modalData.observacoes !== "undefined" && (
                  <>
                    <span className="fluxo-atendimento__modal__observacao-titulo">
                      Observação
                    </span>
                    <textarea
                      className="fluxo-atendimento__modal__textarea"
                      cols="30"
                      rows="4"
                      readOnly
                      defaultValue={
                        modalData.observacoes ? modalData.observacoes : ""
                      }
                    />
                  </>
                )}
              {modalData.avaliacao && (
                <div className="orcamento__modal__valores">
                  <div className="orcamento__modal__valores--container orcamento__modal__valores--container-1">
                    <span className="orcamento__modal__valores__descricao">
                      Subtotal
                    </span>
                    <span className="orcamento__modal__valores__descricao">
                      Desconto
                    </span>

                    <span className="orcamento__modal__valores__descricao">
                      Total
                    </span>
                  </div>
                  <div className="orcamento__modal__valores--container orcamento__modal__valores--container-2">
                    <span className="orcamento__modal__valores__valor">
                      {modalData.subtotal}$00
                    </span>

                    <span className="orcamento__modal__valores__valor">
                      {modalData.desconto}$00
                    </span>
                    <span className="orcamento__modal__valores__valor orcamento__modal__valores__valor--total">
                      {modalData.total}$00
                    </span>
                  </div>
                </div>
              )}
              <div
                style={{
                  gridColumn: "1/-1",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  className="cancel-btn"
                  onClick={() => setOpenModal(false)}
                >
                  Fechar
                </span>
              </div>
            </Box>
          </Modal>

          {/* Modal Aprovar Orcamento */}
          <ModalAprovarOrcamento
            open={openModalAprovarOrcamento}
            modalData={modalData}
            handleCloseModalAprovarOrcamento={handleCloseModalAprovarOrcamento}
            gerarPlanoTratamento={gerarPlanoTratamento}
          />
          <ListOrcamento
            orcamentoData={orcamentoData}
            data={orcamentos}
            gerarPlanoTratamento={gerarPlanoTratamento}
            handleOpenModal={handleOpenModal}
            aprovarOrcamento={aprovarOrcamento}
            handleDesativar={handleDesativar}
          />
        </>
      )}

      {orcamentos.length === 0 && (
        <div className="no_data_div">Nenhum Orçamento encontrado.</div>
      )}
    </>
  );
}

export default OrcamentosComponent;
