import { useState, useEffect } from "react";
import "./InputSelect.scss";
import "../customInputs.scss";

function InputSelect({ initialValue, label, handleChange, items, className }) {
  const [value, setValue] = useState(initialValue);
  const [menuItems, setMenuItems] = useState(items);
  useEffect(() => {
    setMenuItems(items);
  }, [items]);

  const preChange = (e) => {
    setValue(e.target.value);
    handleChange(e.target.value);
  };

  return (
    <div className={`customInputContainer ${className ? className : ""}`}>
      <select onChange={preChange} className="customInputSelect" value={value}>
        {menuItems &&
          menuItems.map((item, index) => <option key={index}>{item}</option>)}
      </select>
      <label className="customInputLabelSelect">{label}</label>
    </div>
  );
}

export default InputSelect;
