import { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./MenuNotifications.scss";

import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

function MenuNotifications({
  anchorEl,
  handleCloseNotification,
  openNotification,
  notificacoes,
  handleDispensarNotificacao,
  handleClickNotificacao,
  handleApagarTodasNotificacoes,
}) {
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    setNotifications(notificacoes);
  }, [notificacoes]);

  return (
    <>
      {notifications && (
        <>
          <Menu
            id="menu-notifications"
            anchorEl={anchorEl}
            open={openNotification}
            onClose={handleCloseNotification}
          >
            {notificacoes.length > 0 && (
              <div className="menuNotificacao__cabecalho">
                <span className="menuNotificacao__cabecalho__titulo">
                  Notificações
                </span>

                <div className="menuNotificacao__cabecalho__limparTudo__container">
                  <MarkEmailReadIcon className="menuNotificacao__cabecalho__limparTudo__icon" />

                  <span
                    className="menuNotificacao__cabecalho__limparTudo"
                    onClick={handleApagarTodasNotificacoes}
                  >
                    Limpar tudo
                  </span>
                </div>
              </div>
            )}
            {notifications.map((n) => (
              <MenuItem className="MenuItemNotifications" key={n.id}>
                <span
                  className="MenuItemNotifications__message"
                  onClick={handleClickNotificacao.bind(
                    null,
                    n.id,
                    n.tipo,
                    n.cliente.id
                  )}
                >
                  {n.mensagem}
                </span>
                <span
                  className="MenuItemNotifications__closeIcon"
                  onClick={handleDispensarNotificacao.bind(null, n.id)}
                ></span>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
}

export default MenuNotifications;
