import React, { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfMapaFinanceiro.scss";

const printStyles = `
    @media print {
      @page {
        size: landscape;
      }
    }
  `;

function PdfMapaFinanceiro({ mapaData, showSingleP }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Mapa de receituário`,
    onAfterPrint: () => {
      showSingleP();
    },
  });

  const normalizarNomes = (nome) => {
    // normalizar os nomes
    const words = nome.toLowerCase().split(" ");
    // Uppercase the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  };

  const total = mapaData.dados.reduce(
    (accumulator, currentValue) => accumulator + currentValue.valor,
    0
  );

  mapaData.dados.sort((a, b) => {
    const dateA = a.data.split("/").reverse().join("");
    const dateB = b.data.split("/").reverse().join("");
    return dateA.localeCompare(dateB);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  return (
    <>
      <div ref={componentRef} className="pdf-mapaFinanceiro-wrapper">
        {Array.from(
          { length: Math.ceil(mapaData.dados.length / 21) },
          (_, index) => (
            <React.Fragment key={index}>
              <style>{printStyles}</style>
              <div className="pdf-mapaFinanceiro-cabecalho">
                <img
                  src={`${process.env.REACT_APP_BACKEND_LINK}/${mapaData.clinicaData.logotipo}`}
                  alt=""
                />
                <div className="pdf-mapaFinanceiro-cabecalho--infos">
                  <span>{normalizarNomes(mapaData.clinicaData.nome)}</span>
                  <span>Telef: {mapaData.clinicaData.contacto}</span>
                  <span>{mapaData.clinicaData.endereco}</span>
                  <span>NIF: {mapaData.clinicaData.nif}</span>
                </div>
              </div>
              <span className="pdf-mapaFinanceiro__titulo">
                Mapa Movimentos Mensal, de {mapaData.dataInicial} a{" "}
                {mapaData.dataFinal}
              </span>
              <div className="pdf-mapaFinanceiro__tabela">
                <table>
                  <tbody>
                    <tr className="headingLine">
                      <th className="pdf-orc-left">Origem</th>
                      <th className="pdf-orc-left">Nif Entidade</th>
                      <th className="pdf-orc-left">Designação Entidade</th>
                      <th className="pdf-orc-left">Serie</th>
                      <th className="pdf-orc-left">Tipo Doc</th>
                      <th className="pdf-orc-left">Nr Doc</th>
                      <th className="pdf-orc-left">Data</th>
                      <th>Valor</th>
                    </tr>
                    {mapaData.dados
                      .slice(index * 21, (index + 1) * 21)
                      .map((dt, i) => (
                        <tr
                          className={`${
                            i === 0 ? "primeiraLinha normalLine" : "normalLine"
                          }`}
                          key={i}
                        >
                          <td className="pdf-orc-left">CV</td>
                          <td className="pdf-orc-left">{dt.nifEntidade}</td>
                          <td className="pdf-orc-left">
                            {normalizarNomes(dt.entidade)}
                          </td>
                          <td className="pdf-orc-left">{dt.serie}</td>
                          <td className="pdf-orc-left">{dt.tipoDocumento}</td>
                          <td className="pdf-orc-left">{dt.nrDocumento}</td>
                          <td className="pdf-orc-left">{dt.data}</td>
                          <td>{dt.valor.toLocaleString("PT-BR")}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {index === Math.ceil(mapaData.dados.length / 21) - 1 && (
                <div className="pdf-mapaFinanceiro__resumo">
                  <span className="pdf-mapaFinanceiro__resumo__titulo">
                    Quadro Resumo Iva: {mapaData.dataInicial} a{" "}
                    {mapaData.dataFinal}
                  </span>
                  <div className="pdf-mapaFinanceiro__resumo__tabela">
                    <span>Valor total</span>
                    <span>{total.toLocaleString("PT-BR")}</span>
                  </div>
                </div>
              )}
              <div className="pdf-mapaFinanceiro__rodape">{index + 1}</div>
            </React.Fragment>
          )
        )}
      </div>
    </>
  );
}

export default PdfMapaFinanceiro;
