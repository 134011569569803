import React from "react";
import "./Chart.scss";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

function ChartAdministradorNaoMedico({ nome, data }) {
  return (
    <div className="wholeChartContainer">
      <div className="chart__title">
        <div className="chart__title__div">
          <span className="chart__title__title">Olá, </span>
          <span className="chart__title__title2">{nome}</span>
        </div>
        <span className="chart__title__subtitle">
          Estatística de lucro desse ano
        </span>
      </div>
      <div className="chart">
        <BarChart
          className="barchart"
          width={730}
          height={250}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" tick={{ fill: "white" }} />
          <YAxis tick={{ fill: "white" }} axisLine={false} />
          <Tooltip />
          <Bar dataKey="lucro" fill="#377bcc" barSize={7} />
        </BarChart>
      </div>
    </div>
  );
}
export default ChartAdministradorNaoMedico;
