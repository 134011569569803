import { useEffect, useContext, useState } from "react";
import { Collapse } from "antd";
import TodayIcon from "@mui/icons-material/Today";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import "./Permissoes.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";

function Permissoes() {
  const { startLoading, stopLoading } = useLoading();

  const [grupos, setGrupos] = useState();
  const [roles, setRoles] = useState();
  const [clinicaRoles, setClinicaRoles] = useState();
  const [clinicaRole, setClinicaRole] = useState();
  const [roleId, setRoleId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const { Panel } = Collapse;

  useEffect(() => {
    const getListaPermissoesAndRoles = async () => {
      startLoading();
      try {
        const responseDataPermissoes = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/permissoes`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseDataClinicaRoles = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicaRoles/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        //Criando os grupos com set para nao repetirem
        //Criando uma lista geral com check predefinido a false para usuarios cujas
        //Permissoes ainda não estão definidas
        const groups = new Set();
        let tempListaPermissoesGeral = [];
        responseDataPermissoes.permissoes.forEach((el) => {
          tempListaPermissoesGeral.push({ ...el, check: false });
          groups.add(el.grupo);
        });

        const tempRoles = responseDataClinicaRoles.roles.map((r) => {
          return { id: r.id, role: r.role.role };
        });

        setClinicaRoles(responseDataClinicaRoles.roles);
        setClinicaRole(responseDataClinicaRoles.roles[0]);
        setRoleId(tempRoles[0].id);
        setRoles(tempRoles);
        setGrupos(Array.from(groups));
      } catch (err) {
        console.error("err", err);
        toast.error(
          "Ocorreu um erro na busca dos dados da página. Por favor tente novamente."
        );
      } finally {
        stopLoading();
      }
    };

    getListaPermissoesAndRoles();
  }, [sendRequest, auth, clinica]);

  const onChangeCollapse = (key) => {};

  const handleRoleChange = (e) => {
    const filteredRolesDefinitions = clinicaRoles.filter(
      (r) => r.id === e.target.value
    )[0];
    setRoleId(e.target.value);
    setClinicaRole({ ...filteredRolesDefinitions });
  };

  const changePermissionCheck = (id, e) => {
    const tempPermissoes = [...clinicaRole.permissoes];
    const indexPermissao = tempPermissoes.findIndex((per) => per.id === id);
    tempPermissoes[indexPermissao].check = e.target.checked;
    setClinicaRole((prev) => {
      return {
        ...prev,
        permissoes: tempPermissoes,
      };
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    startLoading();
    try {
      const formData = new FormData();
      const tempPermissoes = clinicaRole.permissoes.map((p) => {
        return {
          permissao: p.permissao.id,
          check: p.check,
        };
      });
      formData.append("permissoes", JSON.stringify(tempPermissoes));

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clinicaroles/${roleId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      toast.success("Permissoes atualizadas com sucesso");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro atualizando as permissões. Por favor tente novamente."
      );
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const checkAllHandler = (gr, ch) => {
    const tempClinicaRole = { ...clinicaRole };
    const tempPermissoes = tempClinicaRole.permissoes.map((per) => {
      if (per.permissao.grupo === gr) {
        return {
          ...per,
          check: ch,
        };
      } else {
        return per;
      }
    });
    tempClinicaRole.permissoes = tempPermissoes;
    setClinicaRole({ ...tempClinicaRole });
  };

  return (
    <>
      <ErrorModal />
      <div className="normalPage__container">
        {roles && (
          <div className="permissoes__select__container">
            <span>Role: </span>
            <select
              className="select__filter-categoria"
              onChange={handleRoleChange}
            >
              {roles.map((u, index) => (
                <option value={u.id} key={index}>
                  {u.role}
                </option>
              ))}
            </select>
          </div>
        )}
        <Collapse defaultActiveKey={["0"]} onChange={onChangeCollapse}>
          {grupos &&
            grupos.map((grupo, index) => (
              <Panel
                header={
                  <div className="collapse__title">
                    <TodayIcon className="collapse__title__icon" />
                    <span className="collapse__title__text">{grupo}</span>
                  </div>
                }
                key={index}
              >
                <div className="collapse__content__container" key={index}>
                  {clinicaRole &&
                    clinicaRole.permissoes &&
                    clinicaRole.permissoes.map((per, i) => {
                      if (per.permissao.grupo === grupo) {
                        return (
                          <div key={i}>
                            <label
                              htmlFor={per.id}
                              className="collapse__labelCheckbox"
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={per.id}
                                checked={per.check}
                                onChange={changePermissionCheck.bind(
                                  null,
                                  per.id
                                )}
                              />
                              {per.permissao.permissao}
                            </label>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}

                  <div
                    key={clinicaRole.permissoes.length}
                    className="collapse__checkbox--all__container"
                  >
                    <label
                      htmlFor={`checkbox--all__${grupo}`}
                      className="collapse__labelCheckbox collapse__labelCheckbox--all"
                    >
                      <input
                        type="checkbox"
                        name=""
                        id={`checkbox--all__${grupo}`}
                        onChange={(e) =>
                          checkAllHandler(grupo, e.target.checked)
                        }
                      />
                      Marcar todos
                    </label>
                  </div>
                </div>
              </Panel>
            ))}
        </Collapse>
        <div className="permissoes__btn__container">
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {!isSubmitting ? "Guardar" : "Guardando"}
          </span>
        </div>
      </div>
    </>
  );
}

export default Permissoes;
