import React, { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";

import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import "./InformacoesDaClinica.scss";

function InformacoesDaClinica({ sendRequest, clinica, auth }) {
  const [clinicaData, setClinicaData] = useState();

  useEffect(() => {
    const fetchClinica = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setClinicaData(responseData.clinica);
      } catch (err) {}
    };
    fetchClinica();
  }, [auth.token, clinica.clinica._id, sendRequest]);
  return (
    <div className="dashboard__right__bottom">
      <span className="box-title">Informações da Clinica</span>
      {clinicaData && (
        <>
          <div className="clinic-info">
            <span className="medium-gray-text">
              <LocationOnIcon style={{ marginRight: 10 }} />
              {clinicaData.endereco}
              {/* 7898 Marsh Ln indefined Richardson, Wisconson 35697 Cabo Verde */}
            </span>
            <span className="medium-gray-text">
              <PhoneInTalkIcon style={{ marginRight: 10 }} />
              {clinicaData.contacto ? clinicaData.contacto : ""}
              {clinicaData.contacto2 ? " / " + clinicaData.contacto2 : ""}
            </span>
          </div>
          <Link
            to={`/clinica/${clinica.clinica._id}`}
            className="link__dashboard"
          >
            <div className="button-more-div">
              <span className="button-more">Mais</span>

              <span className="more-arrow"> {">"} </span>
            </div>
          </Link>
        </>
      )}
    </div>
  );
}

export default memo(InformacoesDaClinica);
