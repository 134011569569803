import React from "react";
import { useContext, useEffect, useState } from "react";
import "./home.scss";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import ChartAdministradorNaoMedico from "../../../shared/components/chart/ChartAdministradorNaoMedico";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import DashboardIcon from "@mui/icons-material/Dashboard";

import Tasks from "../components/tasks/Tasks";
import LucroDaClinica from "../components/lucroDaClinica/LucroDaClinica";
import InformacoesDaClinica from "../components/informacoesDaClinica/InformacoesDaClinica";
import Debitos from "../components/debitos/Debitos";
import ControleStock from "../components/controleStock/ControleStock";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

import "../../../shared/css/Checkbox.scss";
function HomeAdministradorNaoMedico() {
  const { startLoading, stopLoading } = useLoading();
  const { error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const [userData, setUserData] = useState();

  const [lucroAno, setLucroAno] = useState([]);
  ///////////////////

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [userResponse, lucroResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/${auth.userId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/lucroano/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process user data
        setUserData({
          nome: userResponse.user.name,
          genero: userResponse.user.genero,
        });

        // Process lucro anual
        const meses = [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ];
        const lucroFormatted = meses.map((name, index) => ({
          name,
          lucro: lucroResponse.lucro[index] || 0,
        }));
        setLucroAno(lucroFormatted);
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        toast.error(
          "Ocorreu um erro ao carregar os dados. Por favor, tente novamente."
        );
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, auth.userId, clinica.clinica._id, auth.token]);

  return (
    <>
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <Navbar title="dashboard" icon={DashboardIcon} />
          <ErrorModal error={error} onClear={clearError} />
          <div className="dashboard__top">
            {userData && lucroAno && (
              <ChartAdministradorNaoMedico
                nome={userData.nome}
                data={lucroAno}
              />
            )}

            <div className="caixa dashboard__right">
              <div className="dashboard__right__top"></div>
              <hr />
              <InformacoesDaClinica
                sendRequest={sendRequest}
                auth={auth}
                clinica={clinica}
              />
            </div>
          </div>

          <div className="dashboard__middle">
            <ControleStock
              sendRequest={sendRequest}
              auth={auth}
              clinica={clinica}
            />

            <Debitos sendRequest={sendRequest} auth={auth} clinica={clinica} />

            <LucroDaClinica
              sendRequest={sendRequest}
              auth={auth}
              clinica={clinica}
            />
          </div>
          <div className="dashboard__bottom caixa tasks__container">
            <Tasks clinica={clinica} auth={auth} sendRequest={sendRequest} />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeAdministradorNaoMedico;
