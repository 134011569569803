import React from "react";
import QRCode from "qrcode.react";
import "./QrCode.scss";
function QrCode({ link, estilo }) {
  return (
    <div className="qrCode__container" style={estilo}>
      <span className="qrCode__link">
        IUD: <span className="qrCode__link--link">{link}</span>
      </span>
      <QRCode value={"https://pe.efatura.cv/dfe/view/" + link} size={80} />
    </div>
  );
}

export default QrCode;
