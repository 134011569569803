import { React, useEffect, useState, useContext } from "react";
import "../../../../shared/css/Datatable.scss";
import { Link, useNavigate } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";

//Mui icons
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon
//Delete icon
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";

import "../../../../shared/css/Datatable.scss";
import { useLoading } from "../../../../shared/context/LoadingContext";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";

function Datatable() {
  const { startLoading, stopLoading } = useLoading();

  const [data, setData] = useState();
  const [allData, setAllData] = useState();
  const { error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTratamentos = async () => {
      startLoading();
      try {
        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/tratamentos/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Ocorreu um erro buscando os dados dos tratamentos. Por favor, tente novamente."
          );
        }
        const todosTratamentos = responseData.tratamentos.map((tratamento) => {
          return {
            ...tratamento,
            preco_particular:
              Intl.NumberFormat("pt-BR").format(tratamento.preco_particular) +
              "$00",
            preco_segurado:
              Intl.NumberFormat("pt-BR").format(tratamento.preco_segurado) +
              "$00",
            comparticipacao_inps:
              Intl.NumberFormat("pt-BR").format(
                tratamento.comparticipacao_inps
              ) + "$00",
            preco_beneficiario:
              Intl.NumberFormat("pt-BR").format(tratamento.preco_beneficiario) +
              "$00",
          };
        });

        function naturalCompare(a, b) {
          const ax = [];
          const bx = [];

          a.codigo.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
            ax.push([$1 || Infinity, $2 || ""]);
          });
          b.codigo.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
            bx.push([$1 || Infinity, $2 || ""]);
          });

          while (ax.length && bx.length) {
            const an = ax.shift();
            const bn = bx.shift();
            const nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
            if (nn) return nn;
          }

          return ax.length - bx.length;
        }

        const tratamentosOrganizadosAlfabeticamente = todosTratamentos.sort(
          (a, b) => naturalCompare(a, b)
        );

        setData(tratamentosOrganizadosAlfabeticamente);
        setAllData(tratamentosOrganizadosAlfabeticamente);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchTratamentos();
  }, [sendRequest, clinica, auth.token]);

  const handleDesativar = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/tratamentos/ativardesativar/${id}`,
        "PATCH",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setData(
        data.filter((el) => {
          return el.id !== id;
        })
      );

      setAllData(
        data.filter((el) => {
          return el.id !== id;
        })
      );

      toast.success("Tratamento eliminado com sucesso!");
    } catch (err) {
      console.error("err", err);
      toast.error(
        "Ocorreu um erro na desativacao do tratamento. Por favor, tente novamente."
      );
    } finally {
      stopLoading();
    }
  };

  const tratamentoColumns = [
    {
      field: "codigo",
      headerName: "Codigo",
      flex: 2,
    },
    {
      field: "designacao",
      headerName: "Tratamento",
      flex: 8,
      renderCell: (params) => {
        return (
          <span className="nomeDataTable">
            <Link to={`/tratamentos/${params.row.id}/edit`}>
              {params.row.designacao}
            </Link>
          </span>
        );
      },
    },
    {
      field: "preco_segurado",
      headerName: "Segurado",
      flex: 3,
    },
    {
      field: "comparticipacao_inps",
      headerName: "Comp. INPS",
      flex: 3,
    },
    {
      field: "preco_beneficiario",
      headerName: "Beneficiario",
      flex: 3,
    },

    {
      field: "preco_particular",
      headerName: "Particular",
      flex: 3,
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 1.5,
      renderCell: (params) => {
        const content = (
          <div className="dots__menu__popup">
            {auth.perm.includes("u-tr") && (
              <Link
                to={`/tratamentos/${params.row.id}/edit`}
                style={{ textDecoration: "none" }}
              >
                <div className="popOverMenu--option">
                  <span>Editar</span>
                  <EditIcon className="popOverMenu--option__icon" />
                </div>
              </Link>
            )}

            {auth.perm.includes("d-tr") && (
              <Popconfirm
                title={`Excluir Tratamento`}
                description={`Pretende excluir ${params.row.designacao}?`}
                icon={<ErrorIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={handleDesativar.bind(null, params.row.id)}
              >
                <div className="popOverMenu--option">
                  <span>Eliminar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );

        return (
          <div className="cellAction">
            <Space wrap>
              <Popover content={content} trigger="click" placement="bottom">
                <div className="dots__menu">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Popover>
            </Space>
          </div>
        );
      },
    },
  ];

  const normalizeString = (str) => {
    return str
      .normalize("NFD") // Normalize to decomposed form
      .replace(/[\u0300-\u036f]/g, ""); // Remove combining diacritical marks
  };

  const searchHandler = (e) => {
    if (allData) {
      const searchQuery = normalizeString(e.target.value.trim().toLowerCase()); // Normalize and make the search query lowercase

      // Filter items that start with the search query
      const startsWithQuery = allData.filter((dt) =>
        normalizeString(dt.designacao).toLowerCase().startsWith(searchQuery)
      );

      // Filter remaining items that contain the search query
      const containsQuery = allData.filter(
        (dt) =>
          !normalizeString(dt.designacao)
            .toLowerCase()
            .startsWith(searchQuery) &&
          normalizeString(dt.designacao).toLowerCase().includes(searchQuery)
      );

      // Concatenate the results, with items starting with the search query first
      const result = startsWithQuery.concat(containsQuery);

      setData(result);
    }
  };

  return (
    <>
      {!error && data && (
        <>
          <div className="datatable caixa">
            {auth.perm.includes("c-tr") && (
              <div className="datatable__add-button-div datatable__add-button-div--clientes">
                <div className="search__datatable__container">
                  <span>Procurar</span>
                  <input
                    type="search"
                    className="search__datatable__input"
                    placeholder="Procurar"
                    onChange={searchHandler}
                  />
                </div>

                <span
                  className="blue-button"
                  onClick={() => navigate("/tratamentos/new")}
                >
                  Adicionar Tratamento
                </span>
              </div>
            )}
            <DataGrid
              autoWidth
              className="datagrid"
              rows={data}
              columns={tratamentoColumns.concat(actionColumn)}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Datatable;
