import React from "react";
import Sidebar from "../../shared/components/sidebar/Sidebar";
import Navbar from "../../shared/components/navbar/Navbar";
import "../../shared/css/lists.scss";
import Datatable from "./components/datatable/Datatable";
import PeopleIcon from "@mui/icons-material/People";

function Users() {
  const people = PeopleIcon;
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title="Utilizadores" icon={people} />
        <Datatable />
      </div>
    </div>
  );
}

export default Users;
