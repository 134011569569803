import { useState, useEffect } from "react";
import "./InputTextArea.scss";
import "../customInputs.scss";

import uuid from "react-uuid";

const idInput = uuid();

function InputTextArea({
  initialValue,
  label,
  handleChange,
  className,
  cols,
  rows,
}) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const preChange = (e) => {
    setValue(e.target.value);
    handleChange(e.target.value);
  };

  return (
    <div className="container">
      <div className="customInputContainer">
        <textarea
          cols={cols}
          rows={rows}
          placeholder={label}
          onChange={preChange}
          className={`customInput inputNumber ${className ? className : ""}`}
          id={idInput}
        ></textarea>
        <label className="customInputLabel" htmlFor={idInput}>
          {label}
        </label>
      </div>
    </div>
  );
}

export default InputTextArea;
