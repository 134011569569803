import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import moment from "moment";
import "../List.scss";

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined"; //gerar Pdf
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon

//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";

function ListAtestado({ data, handleApagar, dadosAtestado }) {
  const [atestados, setAtestados] = useState(data);
  useEffect(() => {
    setAtestados(data);
  }, [data]);
  const auth = useContext(AuthContext);

  const verPdf = (id) => {
    const filteredPdf = atestados.filter((pres) => {
      return pres.id === id;
    });
    dadosAtestado(filteredPdf[0]);
  };

  let content = [];
  if (atestados) {
    atestados.forEach((row) => {
      content.push(
        <div className="dots__menu__popup">
          {auth.perm.includes("d-ates") && (
            <Popconfirm
              title="Apagar atestado"
              description="Tem a certeza que pretende apagar o atestado?"
              icon={<DeleteIcon style={{ color: "red" }} />}
              okText="Sim"
              cancelText="Não"
              onConfirm={handleApagar.bind(null, row.id)}
            >
              <div className="popOverMenu--option">
                <span>Apagar</span>
                <DeleteIcon className="popOverMenu--option__icon" />
              </div>
            </Popconfirm>
          )}
        </div>
      );
    });
  }

  return (
    <>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell tableCell--heading">
                Médico Responsável
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Tipo
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Finalidade
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data do Atestado
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          {atestados && (
            <TableBody>
              {atestados.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">{row.medico.name}</TableCell>
                  <TableCell className="tableCell">{row.tipo}</TableCell>
                  <TableCell className="tableCell">{row.finalidade}</TableCell>
                  <TableCell className="tableCell">
                    {moment(row.data_atestado).format("DD-MM-YYYY / HH:mm")}
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="botoes-action">
                      <Tooltip title="Gerar Pdf">
                        <PictureAsPdfOutlinedIcon
                          className="action-button-with-tooltip"
                          onClick={verPdf.bind(null, row.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>

                      {content[index] && (
                        <Popover
                          content={content[index]}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default ListAtestado;
