import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Autocomplete from "@mui/material/Autocomplete";
import toast from "react-hot-toast";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment";
import "./AppointmentForm.scss";

const AppointmentForm = ({
  isOpen,
  onClose,
  date,
  event,
  clients,
  clientesProcedimento,
  allCategoriasTratamento,
  procedimentosPorMarcar,
  auth,
  sendRequest,
  clinicaId,
  agendaId,
  updateEvents,
  medicoId,
  duracaoConsulta,
  startLoading,
  stopLoading,
}) => {
  const [startDate, setStartDate] = useState(date || moment());
  const [endDate, setEndDate] = useState(
    date
      ? moment(date).add(duracaoConsulta, "minutes")
      : moment().add(duracaoConsulta, "minutes")
  );
  const [appointmentType, setAppointmentType] = useState("procedimento");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedProcedure, setSelectedProcedure] = useState("");
  const [procedimentos, setProcedimentos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [compromisso, setCompromisso] = useState("");
  const [menssagemDeErro, setMensagemDeErro] = useState("");

  const validarAgendamento = () => {
    let erro = "";

    if (!moment(startDate).isValid() || !startDate) {
      erro = "A data inicial é inválida. ";
    }

    if (!moment(endDate).isValid() || !endDate) {
      erro += "A data final é inválida. ";
    }

    if (
      (appointmentType === "procedimento" ||
        appointmentType === "emTransicao") &&
      (!selectedClient || !selectedProcedure)
    ) {
      erro += `Por favor, preencha todos os campos: ${
        !selectedClient ? "Cliente " : ""
      }${!selectedProcedure ? "Procedimento " : ""}`;
    }

    if (appointmentType === "avaliacao" && !selectedClient) {
      erro += `Por favor, preencha todos os campos: ${
        !selectedClient ? "Cliente " : ""
      }${!startDate ? "Data Inicial " : ""}${!endDate ? "Data Final " : ""}`;
    }

    if (appointmentType === "compromisso" && !compromisso) {
      erro += `Por favor, preencha todos os campos: ${
        !compromisso ? "Compromisso " : ""
      }${!startDate ? "Data Inicial " : ""}${!endDate ? "Data Final " : ""}`;
    }
    return erro;
  };

  useEffect(() => {
    if (date) {
      setStartDate(new Date(date.getTime()));
      setEndDate(new Date(date.getTime() + duracaoConsulta * 60000));
    }

    if (event) {
      setStartDate(new Date(event.start.getTime()));
      setEndDate(new Date(event.end.getTime()));
      setAppointmentType(
        event.extendedProps.isEvaluation
          ? "avaliacao"
          : event.extendedProps.inTransition
          ? "emTransicao"
          : event.extendedProps.isCompromisso
          ? "compromisso"
          : "procedimento"
      );
      if (event.extendedProps.clientId) {
        setSelectedClient(
          clientesProcedimento.find(
            (c) => c.id === event.extendedProps.clientId
          )
        );
      } else {
        setSelectedClient("");
      }

      //Caso se tratar de uma edicao de uma marcacao e nao uma marcacao nova
      if (event.extendedProps.procedureId) {
        setSelectedProcedure(
          event.extendedProps.procedureId + "-" + event.extendedProps.planoId
        );

        const tempProcedimentos = [];
        procedimentosPorMarcar.forEach((el) => {
          if (el.cliente?.id === event.extendedProps.clientId)
            tempProcedimentos.push({
              label: el.procedimento.label,
              id: el.procedimento.id + "-" + el.id_plano,
            });
        });

        setProcedimentos(tempProcedimentos);
      } else {
        setSelectedProcedure("");
      }
    } else {
      setClientes(clientesProcedimento);
      setProcedimentos([]);
      setSelectedClient("");
    }
  }, [
    clientesProcedimento,
    date,
    duracaoConsulta,
    event,
    procedimentosPorMarcar,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //Se for a adição de uma marcacao
    const erro = validarAgendamento();

    if (erro) {
      setMensagemDeErro(erro);
    } else {
      setMensagemDeErro("");
      try {
        if (!event) {
          if (appointmentType === "compromisso") {
            //Caso for uma adicao de um compromisso
            const marcacao = {
              compromisso: compromisso,
              data_inicio_execucao: startDate,
              data_fim_execucao: endDate,
              agendadoPor: auth.userId,
            };

            const formData = new FormData();
            formData.append("medico", medicoId);
            formData.append("marcacao", JSON.stringify(marcacao));
            startLoading();
            let idMarcacaoRetornada;
            try {
              idMarcacaoRetornada = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/compromissos/efetuarmarcacao/${clinicaId}`,
                "PATCH",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              toast.error(
                "Houve um erro ao efetuar a marcação do compromisso. Por favor tente novamente."
              );
            }
            stopLoading();

            const newEvent = {
              id: idMarcacaoRetornada.addedMarcacaoId,
              start: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
              end: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
              title: " " + compromisso,
              backgroundColor: "#F3F3F3",
              borderColor: "#868A8F",
              textColor: "#868A8F",
              extendedProps: {
                isCompromisso: true,
              },
            };

            updateEvents(newEvent);
          } else if (appointmentType === "avaliacao") {
            //Caso for uma adicao de uma avaliacao
            const marcacao = {
              cliente: selectedClient.id,
              data_inicio_execucao: startDate,
              data_fim_execucao: endDate,
              avaliacao: true,
              agendadoPor: auth.userId,
            };
            const formData = new FormData();
            formData.append("medico", medicoId);
            formData.append("marcacao", JSON.stringify(marcacao));
            startLoading();
            let idMarcacaoRetornada;
            try {
              idMarcacaoRetornada = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/efetuarmarcacao/${clinicaId}`,
                "PATCH",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              toast.error(
                "Houve um erro ao efetuar a marcação. Por favor tente novamente."
              );
            }
            stopLoading();

            const newEvent = {
              id: idMarcacaoRetornada.addedMarcacaoId,
              start: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
              end: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
              title: ` ${selectedClient.label} - Avaliação`,
              backgroundColor: "#FFF8EE",
              borderColor: "#FFCF2E",
              textColor: "#FFCF2E",
              extendedProps: {
                clientId: selectedClient.id,
                procedureId: undefined,
                isEvaluation: true,
                isAttended: undefined,
                isMissed: undefined,
                inTransition: false,
                isCompromisso: false,
                planoId: undefined,
              },
            };

            updateEvents(newEvent);
          } else {
            //Caso for uma adicao de uma marcacao emtransicao ou procedimento
            const marcacao = {
              cliente: selectedClient.id,
              data_inicio_execucao: startDate,
              data_fim_execucao: endDate,
              categoriaTratamento:
                appointmentType === "procedimento"
                  ? selectedProcedure.split("-")[0]
                  : selectedProcedure,
              agendadoPor: auth.userId,
            };

            if (appointmentType === "procedimento") {
              marcacao.planoTratamento = selectedProcedure.split("-")[1];
            }

            const formData = new FormData();
            formData.append("medico", medicoId);
            formData.append("marcacao", JSON.stringify(marcacao));
            startLoading();
            let idMarcacaoRetornada;
            try {
              idMarcacaoRetornada = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/${
                  appointmentType === "procedimento"
                    ? "agenda"
                    : "agendamentosemtransicao"
                }/efetuarmarcacao/${clinicaId}`,
                "PATCH",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              toast.error(
                "Houve um erro ao efetuar a marcação. Por favor tente novamente."
              );
            }
            stopLoading();

            const newEvent = {
              id: idMarcacaoRetornada.addedMarcacaoId,
              start: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
              end: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
              title: ` ${selectedClient.label} - ${
                allCategoriasTratamento.find(
                  (cat) => cat.id === selectedProcedure.split("-")[0]
                ).label
              }`,
              backgroundColor:
                appointmentType === "procedimento" ? "#F4F7FF" : "#F4EEFE",
              borderColor:
                appointmentType === "procedimento" ? "#0871EF" : "#724CB4",
              textColor:
                appointmentType === "procedimento" ? "#0871EF" : "#724CB4",
              extendedProps: {
                clientId: selectedClient.id,
                procedureId: selectedProcedure.split("-")[0],
                isEvaluation: undefined,
                isAttended: undefined,
                isMissed: undefined,
                inTransition: false,
                isCompromisso: false,
                planoId:
                  appointmentType === "procedimento"
                    ? selectedProcedure.split("-")[1]
                    : undefined,
              },
            };

            updateEvents(newEvent);
          }
        } else {
          const formData = new FormData();
          formData.append("agendaId", agendaId);
          formData.append("cliente", selectedClient.id);
          formData.append(
            "categoriaTratamento",
            selectedProcedure.split("-")[0]
          );
          formData.append("planoTratamento", selectedProcedure.split("-")[1]);
          formData.append("data_inicio_execucao", startDate);
          formData.append("data_fim_execucao", endDate);
          formData.append("agendadoPor", auth.userId);

          startLoading();
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/alterarparanovamarcacao/${event.id}`,
              "PATCH",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            toast.error(
              "Houve um erro ao editar a marcação. Por favor tente novamente."
            );
          }

          stopLoading();

          const newEvent = {
            start: startDate,
            end: endDate,
            title: ` ${selectedClient.label} - ${
              allCategoriasTratamento.find(
                (cat) => cat.id === selectedProcedure.split("-")[0]
              ).label
            }`,
            backgroundColor: appointmentType === "#F4F7FF",
            borderColor: "#D96C60",
            textColor: "#D96C60",
            extendedProps: {
              clientId: selectedClient.id,
              procedureId: selectedProcedure.split("-")[0],
              isEvaluation: undefined,
              isAttended: undefined,
              isMissed: undefined,
              inTransition: false,
              isCompromisso: false,
              planoId:
                appointmentType === "procedimento"
                  ? selectedProcedure.split("-")[1]
                  : undefined,
            },
          };

          updateEvents(newEvent, event.id);
        }
      } catch (err) {
        console.error(err);
        stopLoading();
      } finally {
        onClose();
      }
    }
  };

  const clienteChangeHandler = (e, newValue) => {
    setSelectedClient(newValue);
    setSelectedProcedure("");

    if (appointmentType === "procedimento") {
      const tempProcedimentos = [];
      procedimentosPorMarcar.forEach((el) => {
        if (el.cliente?.id === newValue.id)
          tempProcedimentos.push({
            label: el.procedimento.label,
            id: el.procedimento.id + "-" + el.id_plano,
          });
      });

      setProcedimentos(tempProcedimentos);
    } else {
      setProcedimentos(allCategoriasTratamento);
    }
  };

  const appointmentTypeChangeHandler = (e) => {
    setAppointmentType(e.target.value);
    setSelectedClient("");
    setSelectedProcedure("");

    if (e.target.value === "emTransicao") {
      setProcedimentos(allCategoriasTratamento);
    }

    if (e.target.value === "procedimento") {
      setClientes(clientesProcedimento);
      setProcedimentos([]);
    } else if (
      e.target.value === "avaliacao" ||
      e.target.value === "emTransicao"
    ) {
      setClientes(clients);
    }
  };

  return (
    <div className={`appointment-form ${isOpen ? "open" : ""}`}>
      <div className="appointment-form__content">
        <h2 className="appointment-form__title">
          {event ? "Editar Agendamento" : "Novo Agendamento"}
        </h2>
        <form onSubmit={handleSubmit}>
          {!event && (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Tipo Agendamento
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={appointmentType}
                label="Tipo Agendamento"
                onChange={appointmentTypeChangeHandler}
              >
                <MenuItem value="procedimento">Procedimento</MenuItem>
                <MenuItem value="avaliacao">Avaliação</MenuItem>
                <MenuItem value="emTransicao">Em Transição</MenuItem>
                <MenuItem value="compromisso">Compromisso</MenuItem>
              </Select>
            </FormControl>
          )}
          <div className="appointment-form__datasContainer">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Início"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Fim"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          {appointmentType !== "compromisso" && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              label="Cliente"
              options={clientes}
              value={selectedClient}
              onChange={clienteChangeHandler}
              renderInput={(params) => (
                <TextField {...params} label="Cliente" />
              )}
            />
          )}
          {(appointmentType === "procedimento" ||
            appointmentType === "emTransicao") &&
            procedimentos && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Procedimento
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedProcedure}
                  label="Procedimento"
                  onChange={(e) => setSelectedProcedure(e.target.value)}
                >
                  <MenuItem value="">Selecione um procedimento</MenuItem>
                  {procedimentos.map((procedure, index) => (
                    <MenuItem value={procedure.id} key={index + procedure.id}>
                      {procedure.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          {appointmentType === "compromisso" && (
            <div className="appointment-form__field">
              <label>Descrição do Compromisso</label>
              <textarea
                rows={4}
                value={compromisso}
                onChange={(e) => setCompromisso(e.target.value)}
                className="appointment-form__compromissoTextarea"
              />
            </div>
          )}
          <div className="appointment-form__errorContainer">
            {menssagemDeErro}
          </div>
          <div className="appointment-form__actions">
            <button
              type="button"
              onClick={onClose}
              className="appointment-form__close-btn"
            >
              Cancelar
            </button>
            <button type="submit" className="appointment-form__submit-btn">
              {event ? "Atualizar" : "Agendar"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AppointmentForm;
