import { useEffect, useState } from "react";
import "../../../../shared/css/ElementoPlanoOrcamentoAvaliacao.scss";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";

import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#0c53fb",
    },
  },
};

const facesDentes = ["D", "M", "O/I", "P/L", "V"];

function ElementoAvaliacao(props) {
  const [dentes, setDentes] = useState(props.dentesTratados);
  const [faces, setFaces] = useState(props.faces);
  const [showFaces, setShowFaces] = useState(true);
  const [showDentes, setShowDentes] = useState(false);
  const [erroDentes, setErroDentes] = useState(false);
  const [erroFaces, setErroFaces] = useState(false);
  const [listaDentes, setListaDentes] = useState([]);
  const [tratamentoAutocomplete, setTratamentoAutocomplete] = useState();
  const [tratamento, setTratamento] = useState();

  useEffect(() => {
    const tratamentoFiltrado = props.tratamentos.filter(
      (tr) => tr.id === props.tratamento
    )[0];
    if (
      !tratamentoFiltrado.aceitaFaces ||
      props.dentesTratados.includes("Arcada Inferior") ||
      props.dentesTratados.includes("Arcada Superior") ||
      props.dentesTratados.includes("Arcadas")
    ) {
      setShowFaces(false);
    }

    setListaDentes(
      tratamentoFiltrado.dentesEspecificos
        ? tratamentoFiltrado.dentesEspecificos.split(", ")
        : props.dentes
    );

    setShowDentes(tratamentoFiltrado.aceitaDentes);

    setTratamentoAutocomplete(
      props.tratamentosAutocomplete.filter((t) => t.id === props.tratamento)[0]
    );

    setTratamento(tratamentoFiltrado);
  }, []);

  const dentesChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    let erro = "";
    if (tratamento.multiDentes && value.length !== tratamento.qtdDentes) {
      erro = `O tratamento ${tratamento.designacao} exige que selecione ${tratamento.qtdDentes} dentes. Foram selecionados ${value.length}.`;
      setErroDentes(true);
    } else {
      erro = "";
      setErroDentes(false);
    }

    if (
      event.target.value.includes("Arcada Inferior") ||
      event.target.value.includes("Arcada Superior") ||
      event.target.value.includes("Arcadas") ||
      !tratamento.aceitaFaces
    ) {
      setFaces([]);
      setShowFaces(false);
      props.facesChangeHandler(props.id, []);
    } else {
      setShowFaces(true);
    }

    if (
      event.target.value.includes("Arcada Inferior") ||
      event.target.value.includes("Arcada Superior") ||
      event.target.value.includes("Arcadas")
    ) {
      if (
        event.target.value.includes("Arcada Inferior") ||
        event.target.value.includes("Arcada Superior")
      ) {
        const apenasArcadas = event.target.value.filter(
          (arcadas) =>
            arcadas === "Arcada Inferior" || arcadas === "Arcada Superior"
        );
        setDentes(apenasArcadas);
        props.dentesChangeHandler(props.id, apenasArcadas);
      }
      if (event.target.value.includes("Arcadas")) {
        setDentes(["Arcadas"]);
        props.dentesChangeHandler(props.id, ["Arcadas"]);
      }
    } else {
      setDentes(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
      props.dentesChangeHandler(props.id, event.target.value, erro);
    }
  };

  const facesChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    let erro = "";
    if (value.length > tratamento.qtdFaces) {
      erro = `O tratamento ${tratamento.designacao} exige que selecione no máximo ${tratamento.qtdFaces} faces. Foram selecionadas ${value.length}.`;
      setErroFaces(true);
    } else {
      erro = "";
      setErroFaces(false);
    }

    setFaces(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    props.facesChangeHandler(props.id, event.target.value, erro);
  };

  const tratamentoChangeHandler = (e, value) => {
    const tratamento_filtrado = props.tratamentos.filter(
      (tr) => tr.id === value.id
    )[0];

    if (tratamento_filtrado.dentesEspecificos) {
      const tempDentes = [...dentes];

      setListaDentes(tratamento_filtrado.dentesEspecificos.split(", "));

      if (tratamento_filtrado.dentesEspecificos.split(",").length === 1) {
        dentesChangeHandler({
          target: { value: tratamento_filtrado.dentesEspecificos.split(",") },
        });
      } else if (
        //Se cada dente escolhido estiver na lista de dentesEspecificos, permitimos que os dentes escolhidos não mudem
        //Caso contrário resetamos a lista de dentes escolhidos
        !tempDentes.every((element) =>
          tratamento_filtrado.dentesEspecificos.split(",").includes(element)
        )
      ) {
        dentesChangeHandler({
          target: { value: [] },
        });
      }
    } else {
      setListaDentes(props.dentes);
    }

    let aceitaFaces = tratamento_filtrado.aceitaFaces;

    if (
      !aceitaFaces ||
      dentes.includes("Arcada Inferior") ||
      dentes.includes("Arcada Superior") ||
      dentes.includes("Arcadas") ||
      (tratamento_filtrado.dentesEspecificos?.split(",").length === 1 &&
        tratamento_filtrado.dentesEspecificos === "Arcadas") ||
      tratamento_filtrado.dentesEspecificos === "Arcada Superior" ||
      tratamento_filtrado.dentesEspecificos === "Arcada Inferior"
    ) {
      setFaces([]);
      props.facesChangeHandler(props.id, []);
      setShowFaces(false);
    } else {
      setShowFaces(true);
    }

    let aceitaDentes = tratamento_filtrado.aceitaDentes;

    if (!aceitaDentes) {
      setDentes([]);
      props.dentesChangeHandler(props.id, []);
      setShowDentes(false);
    } else {
      setShowDentes(true);
    }

    let erro = "";
    if (
      tratamento_filtrado.multiDentes &&
      dentes.length !== tratamento_filtrado.qtdDentes
    ) {
      erro = `O tratamento ${tratamento_filtrado.designacao} exige ${tratamento_filtrado.qtdDentes}. ${dentes.length} dentes selecionados.`;
      setErroDentes(true);
    } else {
      erro = "";
      setErroDentes(false);
    }

    if (faces.length > tratamento_filtrado.qtdFaces) {
      erro = `O tratamento ${tratamento.designacao} exige que selecione no máximo ${tratamento.qtdFaces} faces. Foram selecionadas ${value.length}.`;
      setErroFaces(true);
    } else {
      erro = "";
      setErroFaces(false);
    }

    props.tratamentoChangeHandler(props.id, value.id, aceitaDentes, erro);
    setTratamento(tratamento_filtrado);
    setTratamentoAutocomplete(value);
  };

  // Custom option rendering function
  const renderOption = (props, option) => (
    <Box
      component="span"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "8px",
      }}
      {...props}
    >
      <Typography variant="subtitle1">{option.label}</Typography>
      <Typography variant="body2" color="textSecondary">
        {option.categoria}
      </Typography>
    </Box>
  );

  return (
    <>
      {tratamentoAutocomplete && (
        <FormControl sx={{ m: 1 }}>
          <Autocomplete
            id="treatment-autocomplete"
            value={tratamentoAutocomplete}
            onChange={tratamentoChangeHandler}
            options={props.tratamentosAutocomplete}
            getOptionLabel={(option) => `${option.label} - ${option.categoria}`}
            renderOption={renderOption}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tratamento"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </FormControl>
      )}
      {showDentes && listaDentes && (
        <FormControl sx={{ m: 1 }} className={erroDentes ? "erroDentes" : ""}>
          <InputLabel id="demo-multiple-checkbox-label">Dentes</InputLabel>
          <Select
            id="demo-multiple-checkbox"
            multiple
            value={dentes}
            onChange={dentesChangeHandler}
            input={<OutlinedInput label="Dentes" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{ color: "#0c53fb" }}
          >
            {listaDentes.map((dente) => (
              <MenuItem key={dente} value={dente}>
                <Checkbox checked={dentes.indexOf(dente) > -1} />
                <ListItemText primary={dente} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {showFaces && (
        <FormControl sx={{ m: 1 }} className={erroFaces ? "erroDentes" : ""}>
          <InputLabel id="demo-multiple-checkbox-label">Faces</InputLabel>
          <Select
            id="demo-multiple-checkbox"
            multiple
            value={faces}
            onChange={facesChangeHandler}
            input={<OutlinedInput label="Faces" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{ color: "#0c53fb" }}
          >
            {facesDentes.map((face) => (
              <MenuItem key={face} value={face}>
                <Checkbox checked={faces.indexOf(face) > -1} />
                <ListItemText primary={face} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {!showFaces && <span></span>}
      {!showDentes && <span></span>}

      <div className="elemento-avaliacao-btns-container">
        <Tooltip title="Duplicar">
          <button
            className="btn-acoes-avaliacao btn-adicionar"
            onClick={() =>
              props.replicarTratamentoHandler({
                id: props.id,
                show: true,
                tratamento: tratamentoAutocomplete,
                erro: "",
                faces: faces,
                dentesTratados: dentes,
                aceitaDentes: true,
              })
            }
          >
            <AddIcon className="mdi mdi-adicionar" />
            <AddCircleOutlineIcon className="mdi mdi-adicionar-empty" />
          </button>
        </Tooltip>
        {props.id !== "1" && (
          <Tooltip title="Remover">
            <button
              className="btn-acoes-avaliacao btn-delete"
              onClick={() => props.removeService(props.id)}
            >
              <DeleteIcon className="mdi mdi-delete" />
              <DeleteForeverIcon className="mdi mdi-delete-empty" />
              {/* <span>Apagar</span> */}
            </button>
          </Tooltip>
        )}
      </div>
    </>
  );
}

export default ElementoAvaliacao;
