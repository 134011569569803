import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
//MUI MODAL
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
//Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function AvaliacoesPorEfetuarModal({
  avaliacoesPorEfetuar,
  handleClose,
  openModalAvaliacoesPorEfetuar,
}) {
  return (
    <div>
      {avaliacoesPorEfetuar && (
        //Modal Avaliacoes
        <Modal
          open={openModalAvaliacoesPorEfetuar}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div className="modalAvaliacoesPorEfetuarList">
              <span className="urgencia-list-title">Paciente</span>
              <span className="urgencia-list-title">Hora de Chegada</span>
              <span className="urgencia-list-title"></span>

              {avaliacoesPorEfetuar.map((av, index) => (
                <React.Fragment key={index}>
                  <Link to={`clientes/${av.idCliente}`}>
                    <span>{av.cliente}</span>
                  </Link>
                  <span>{moment(av.created_at).format("HH:mm:ss")}</span>
                  <Link
                    to={`avaliacao/new/${av.idCliente}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className="blue-button">Criar Avaliação</span>
                  </Link>
                </React.Fragment>
              ))}
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default AvaliacoesPorEfetuarModal;
