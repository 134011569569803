import React, { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
function ControleStock({ sendRequest, clinica, auth }) {
  const [produtosStock, setProdutosStock] = useState([]);

  useEffect(() => {
    const fetchStockOnly = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/stockonly/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        let produtosStockTemp = responseData.stock[0].stock.filter(
          (s) => s.quantidade <= s.quantidadeIdeal
        );

        setProdutosStock(produtosStockTemp.slice(0, 4));
      } catch (err) {}
    };

    fetchStockOnly();
  }, [auth.token, clinica.clinica._id, sendRequest]);

  return (
    <div className="caixa dashboard__middle__topTreatments">
      <div className="stockControle">
        <span className="box-title">Controle de Stock</span>
        <div className="stockControle__row">
          <span className="box-subtitle">Nome do Produto</span>
          <span className="box-subtitle">Stock</span>
          <span className="box-subtitle">Estado</span>
        </div>
        {produtosStock &&
          produtosStock.map((p, index) => (
            <div className="stockControle__row" key={index}>
              <span className="stockControle__row__element">
                {p.produto.produto}
              </span>
              <span className="stockControle__row__element">
                {p.quantidade}
              </span>
              <span
                className={
                  p.quantidade < p.quantidadeIdeal
                    ? "stock__danger"
                    : "stock__warning"
                }
              >
                {p.quantidade === 0
                  ? "Esgotado"
                  : p.quantidade < p.quantidadeIdeal
                  ? "Crítico"
                  : "A Esgotar"}
              </span>
            </div>
          ))}

        {produtosStock.length === 0 && (
          <div className="stock-sob-controle">
            <span>Stock sob controle!</span>
          </div>
        )}
      </div>

      <Link to={`stock`} className="link__dashboard">
        <div className="button-more-div">
          <span className="button-more">Mais</span>
          <span className="more-arrow"> {">"} </span>
        </div>
      </Link>
    </div>
  );
}

export default memo(ControleStock);
