import React from "react";
import "./StockControllAdministrativo.scss";
function StockControllAdministrativo({ stock }) {
  return (
    <div className="caixa stockControllAdministrativo__container">
      <span className="dashboardAdministrativoFinanceiro__titulo">
        Stock: Controle de reposição
      </span>
      <div className="stockControllAdministrativo__content">
        {stock && stock.length > 0 && (
          <ul>
            {stock.map((s, index) => (
              <li
                key={index}
                className={`stockControllAdministrativo__li ${
                  s.quantidade === 0
                    ? "stockControllAdministrativo__li--danger"
                    : s.quantidade === s.quantidadeIdeal
                    ? "stockControllAdministrativo__li--warning"
                    : ""
                }`}
              >
                {s.produto.produto}
                {s.quantidade === 0 && " - ESGOTADO"}
              </li>
            ))}
          </ul>
        )}
        {!stock || (stock.length === 0 && <span>Sem dados por mostrar</span>)}
      </div>
    </div>
  );
}

export default StockControllAdministrativo;
