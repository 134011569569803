import React from "react";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import Datatable from "../components/datatable/Datatable";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
function Tratamentos() {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title="Tratamentos" icon={MedicationOutlinedIcon} />
        <Datatable />
      </div>
    </div>
  );
}

export default Tratamentos;
