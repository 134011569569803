import React from "react";
import PdfRecibo from "./PdfRecibo";

function PdfReciboWrapper({ showPreviousPage, reciboData }) {
  const showPreviousP = () => {
    showPreviousPage();
  };

  return (
    <div>
      <PdfRecibo reciboData={reciboData} showPreviousPage={showPreviousP} />
    </div>
  );
}

export default PdfReciboWrapper;
