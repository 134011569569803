import React from "react";
import PdfAtestado from "./PdfAtestado";

function PdfAtestadoWrapper({ showPreviousPage, atestadoData }) {
  const showPreviousP = () => {
    showPreviousPage();
  };

  return (
    <div>
      <PdfAtestado
        atestadoData={atestadoData}
        showPreviousPage={showPreviousP}
      />
    </div>
  );
}

export default PdfAtestadoWrapper;
