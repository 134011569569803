import { useState, useCallback, useRef, useEffect } from "react";
// import { useLoading } from "../context/LoadingContext";

export const useHttpClient = () => {
  // const { startLoading, stopLoading } = useLoading();
  const [error, setError] = useState();

  //a ref will be a peace of data that will not change when this function runs again
  //and this function runs again whenver the component that uses this hook rerenders

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      // startLoading();
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });

        const responseData = await response.json();

        // a response that comes with an error atached to it doesnt make it to the catch block
        // because there is a response and the error is an atachmente to the response
        // we check if response is ok, that is true if we have a 200ish code
        //if we have a 400ish or 500ish the ok returns false

        //clear the abort controllers that belong to the request that completed
        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );

        if (!response.ok) {
          throw new Error(responseData.message);
        }

        // stopLoading();
        return responseData;
      } catch (err) {
        setError(err.message);
        // stopLoading();
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  //useEffect can be used to clean up when the component unmounts
  useEffect(() => {
    //when you return a function, this function is executed before useEffect runs again
    //or  when the component uses useEffect unmounts. This is a cleanup function
    //this logic is so we never continue with a request that is on its way out
    //if we switch form a component that triggers the request
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { error, sendRequest, clearError };
};
