import React from "react";
import PdfRce from "./PdfRce";

function PdfRceWrapper({ showPreviousPage, reciboData }) {
  const showPreviousP = () => {
    showPreviousPage();
  };

  return (
    <div>
      <PdfRce reciboData={reciboData} showPreviousPage={showPreviousP} />
    </div>
  );
}

export default PdfRceWrapper;
