import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import dataGridData from "./components/datagridData";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import FiltrosDfe from "./components/filtrosDfe/FiltrosDfe";
import moment from "moment";
import { Link } from "react-router-dom";

import toast from "react-hot-toast";
import "./EnvioDfe.scss";
import ModalErrosDfe from "./components/modalErrosDfe/ModalErrosDfe";
import ModalMotivoDveErrosDfe from "./components/ModalMotivoDve/ModalMotivoDveErrosDfe";
import { ClinicaContext } from "../../../shared/context/clinica-context";

function EnvioDfe({ clinicaId, auth }) {
  const { sendRequest } = useHttpClient();
  const [documentosEletronicos, setDocumentoEletronicos] = useState([]);
  const [documentosEletronicosAbsolut, setDocumentoEletronicosAbsolut] =
    useState([]);
  const [rows, setRows] = useState();
  const [dadosModal, setDadosModal] = useState({});

  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    const fetchDocumentosEletronicoHoje = async () => {
      try {
        const responseData2 = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/comerro/clinica/hoje/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDocumentoEletronicos(responseData2.documentosEletronicos);
      } catch (err) {
        console.error("err", err);
      }
    };
    fetchDocumentosEletronicoHoje();

    const fetchDocumentosEletronicos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/comerro/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setDocumentoEletronicosAbsolut(responseData.documentosEletronicos);
      } catch (err) {
        console.error("err", err);
      }
    };

    fetchDocumentosEletronicos();
  }, [auth.perm, auth.token, clinicaId, sendRequest]);

  useEffect(() => {
    let dataParaDataGrid = [];

    if (documentosEletronicos && documentosEletronicos.length > 0) {
      documentosEletronicos.forEach((documentoEletronico) => {
        if (documentoEletronico.entrada) {
          //Entrada
          dataParaDataGrid.push({
            id: documentoEletronico.id,
            tipo: documentoEletronico.tipoDocumento,
            data: moment(documentoEletronico.created_at).format("DD-MM-YYYY"),
            hora: moment(documentoEletronico.created_at).format("HH:mm:ss"),
            nrDocumento: documentoEletronico.nrDocumento,
            serie: documentoEletronico.serie,
            messages: documentoEletronico.messages,
            estado: documentoEletronico.succeeded ? "sucesso" : "erro",
            IUD: documentoEletronico.entryName.replace(/\.xml$/, ""),
            usuarioEmissor: documentoEletronico.usuarioEmissor,
            clienteFornecedor: documentoEletronico.entrada.clienteFornecedor,
            metodoPagamento: documentoEletronico.entrada.metodoPagamento,
            valor: documentoEletronico.entrada.valor,
            dataLimitePagamento:
              documentoEletronico.entrada.dataLimitePagamento,
            IUDReceber: documentoEletronico.IUDReceber,
            IUDCancelar: documentoEletronico.IUDCancelar,
          });
        } else {
          //Parcela
          dataParaDataGrid.push({
            id: documentoEletronico.id,
            tipo: documentoEletronico.tipoDocumento,
            data: moment(documentoEletronico.created_at).format("DD-MM-YYYY"),
            hora: moment(documentoEletronico.created_at).format("HH:mm"),
            nrDocumento: documentoEletronico.nrDocumento,
            serie: documentoEletronico.serie,
            messages: documentoEletronico.messages,
            estado: documentoEletronico.succeeded ? "sucesso" : "erro",
            IUD: documentoEletronico.entryName.replace(/\.xml$/, ""),
            usuarioEmissor: documentoEletronico.usuarioEmissor,
            cliente: documentoEletronico.contaCorrente.cliente,
            parcela: documentoEletronico.parcela,
            conta: documentoEletronico.conta,
            IUDCancelar: documentoEletronico.IUDCancelar,
          });
        }
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) => new Date(b.data) - new Date(a.data)
    );

    setRows(dataGridData(dataParaDataGrid));
  }, [documentosEletronicos]);

  const handleFiltrar = (newDfes) => {
    setDocumentoEletronicos([...newDfes]);
  };

  const handleOpenModal = (id) => {
    const docEletronico = documentosEletronicos.find((d) => d._id === id);
    setDadosModal({ open: true, erros: docEletronico.messages });
  };

  const handleCloseModal = () => {
    setDadosModal();
  };

  const actionColumn = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return <span>{params.row.data}</span>;
      },
    },
    {
      field: "hora",
      headerName: "Hora",
      flex: 0.7,
      renderCell: (params) => {
        return <span>{params.row.hora}</span>;
      },
    },
    {
      field: "tipo",
      headerName: "Doc",
      flex: 0.7,
      renderCell: (params) => {
        return <span>{params.row.tipo}</span>;
      },
    },
    {
      field: "nome",
      headerName: "Cliente",
      flex: 4,
      renderCell: (params) => {
        return (
          <span>
            {params.row.clienteFornecedor ? (
              <Link
                to={`/clinica/${params.row.clinica}`}
                className="link-cliente-receita"
              >
                {params.row.clienteFornecedor.nome}
              </Link>
            ) : (
              <Link
                to={`/clientes/${params.row.cliente._id}`}
                className="link-cliente-receita"
              >
                {params.row.cliente.nome}
              </Link>
            )}
          </span>
        );
      },
    },
    {
      field: "IUD",
      headerName: "IUD",
      flex: 4,
      renderCell: (params) => {
        return (
          <span
            className="iudSpan"
            onClick={handleOpenModal.bind(null, params.row.id)}
          >
            {params.row.IUD}
          </span>
        );
      },
    },
    {
      field: "nrDocumento",
      headerName: "Nr Doc.",
      flex: 0.7,
      renderCell: (params) => {
        return <span>{params.row.nrDocumento}</span>;
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className="financeiro__ocultarBtn"
            onClick={emitirDocumento.bind(null, params.row.id)}
          >
            Emitir
          </span>
        );
      },
    },
  ];

  const checkNifError = async (nif, nome, nomePais) => {
    let erro;
    if (nomePais !== "CABO VERDE") {
      erro = false;
    } else {
      const formData = new FormData();
      nif && formData.append("nif", nif);

      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        if (response.payload.length === 0 || response.payload.length > 1) {
          erro = true;
        } else if (response.payload.length === 1) {
          if (response.payload[0].Name.toLowerCase() === nome.toLowerCase())
            erro = false;
          else erro = true;
        }
      } catch (err) {
        console.error("err", err);
      }
    }
    return erro;
  };

  const emitirDocumento = async (idDocumento) => {
    const docFiltrado = documentosEletronicos.filter(
      (d) => d._id === idDocumento
    )[0];

    //Fatura Recibo
    if (docFiltrado.tipoDocumento === "FRE") {
      if (!docFiltrado.contaCorrente.cliente.nif) {
        toast.error("O cliente não possui um nif");
      } else if (!docFiltrado.contaCorrente.cliente.contacto) {
        toast.error("O cliente não possui um número de telefone");
      } else if (!docFiltrado.contaCorrente.cliente.endereco) {
        toast.error("O cliente não possui um endereço");
      } else if (!docFiltrado.contaCorrente.cliente.pais) {
        toast.error("O cliente não possui um pais");
      } else if (
        await checkNifError(
          docFiltrado.contaCorrente.cliente.nif,
          docFiltrado.contaCorrente.cliente.nome,
          docFiltrado.contaCorrente.cliente.pais.nome
        )
      ) {
        toast.error("nif incomaptivel com o nome do cliente");
      } else {
        let contaFiltrada;
        let parcelaFiltrada;
        docFiltrado.contaCorrente.contas.forEach((conta) => {
          conta.parcelas.forEach((p) => {
            if (p._id === docFiltrado.parcela) {
              contaFiltrada = conta;
              parcelaFiltrada = p;
            }
          });
        });

        const formData = new FormData();
        formData.append("clinicaId", clinicaId);
        formData.append("nomeCliente", docFiltrado.contaCorrente.cliente.nome);
        formData.append("nifCliente", docFiltrado.contaCorrente.cliente.nif);
        formData.append("valor", parcelaFiltrada.quantiaPaga);
        formData.append("descricaoPagamento", "Tratamento dentário");
        formData.append(
          "contactoCliente",
          docFiltrado.contaCorrente.cliente.contacto
        );
        formData.append(
          "moradaCliente",
          docFiltrado.contaCorrente.cliente.endereco
        );
        formData.append(
          "codigoPaisCliente",
          docFiltrado.contaCorrente.cliente.pais.codigo
        );
        formData.append("metodoPagamento", parcelaFiltrada.metodoPagamento);
        formData.append("tipoDocEletronico", docFiltrado.tipoDocumento);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("contaCorrente", docFiltrado.contaCorrente._id);
        formData.append("conta", contaFiltrada._id);
        formData.append("parcela", parcelaFiltrada._id);
        formData.append("online", true);
        formData.append("documentoACorrigir", docFiltrado._id);

        const resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        if (resposta.respostaEfatura?.responses[0]?.succeeded) {
          setDocumentoEletronicos((prevDocs) =>
            prevDocs.map((doc) => doc._id !== idDocumento)
          );
          toast.success(docFiltrado.tipoDocumento + "Emitido com sucesso");
        } else {
          toast.error(
            "Erro ao emitir" +
              docFiltrado.tipoDocumento +
              ". " +
              resposta.respostaEfatura.responses[0].messages[0].description
          );
        }
      }
    }

    if (docFiltrado.tipoDocumento === "DVE") {
      setDadosModal({
        tipoDocumento: docFiltrado.tipoDocumento,
        modalData: {
          id: docFiltrado.parcela,
          idConta: docFiltrado.conta,
          idContaCorrente: docFiltrado.contaCorrente._id,
          contaCorrente: docFiltrado.contaCorrente,
        },
      });
    }
  };

  const handleCancelarRecebimento = async (
    id,
    idConta,
    motivoDve,
    contaCorrente
  ) => {
    const filteredConta = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];
    const oldParcela = filteredConta.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    let respostaDVE;

    if (oldParcela.documentoEletronico) {
      if (!contaCorrente.cliente.nif) {
        toast.error("O cliente não possui um nif");
      } else if (!contaCorrente.cliente.contacto) {
        toast.error("O cliente não possui um número de telefone");
      } else if (!contaCorrente.cliente.endereco) {
        toast.error("O cliente não possui um endereço");
      } else {
        const formData = new FormData();
        formData.append("clinicaId", clinica.clinica._id);
        formData.append("nomeCliente", contaCorrente.cliente.nome);
        formData.append("nifCliente", contaCorrente.cliente.nif);
        formData.append("valor", oldParcela.quantiaPaga);
        formData.append("descricaoPagamento", "Tratamento dentário");
        formData.append("contactoCliente", contaCorrente.cliente.contacto);
        formData.append("moradaCliente", contaCorrente.cliente.endereco);
        formData.append("codigoPaisCliente", contaCorrente.cliente.pais.codigo);
        formData.append("metodoPagamento", oldParcela.metodoPagamento);
        formData.append("tipoDocEletronico", "DVE");
        formData.append("IssueReasonCode", JSON.stringify(motivoDve));
        formData.append("usuarioEmissor", auth.userId);
        formData.append(
          "IUDCancelar",
          oldParcela.documentoEletronico.entryName.replace(/\.xml$/, "")
        );
        formData.append("contaCorrente", contaCorrente._id);
        formData.append("conta", filteredConta._id);
        formData.append("parcela", oldParcela._id);
        formData.append("online", true);

        respostaDVE = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }
    }

    let newParcela;

    //Se o envio do DVE for bem sucedido a parcela fica como nao paga. Nesse caso, oldParcela tem 1 documento eletronico
    if (respostaDVE?.respostaEfatura?.responses[0]?.succeeded) {
      newParcela = {
        id: oldParcela.id,
        _id: oldParcela.id,
        dataLimitePagamento: oldParcela.dataLimitePagamento,
        parcelaPaga: false,
        quantiaPaga: 0,
        quantiaPorPagar: oldParcela.quantiaPorPagar + oldParcela.quantiaPaga,
      };

      if (oldParcela.cancelamentosDocumentosEletronicos) {
        newParcela.cancelamentosDocumentosEletronicos = [
          ...oldParcela.cancelamentosDocumentosEletronicos,
          {
            documento: oldParcela.documentoEletronico._id,
            DVE: respostaDVE.respostaDocumentoEletronico._id,
          },
        ];
      } else {
        newParcela.cancelamentosDocumentosEletronicos = [
          {
            documento: oldParcela.documentoEletronico._id,
            DVE: respostaDVE.respostaDocumentoEletronico._id,
          },
        ];
      }

      if (oldParcela.nrParcela) newParcela.nrParcela = oldParcela.nrParcela;
      if (oldParcela.entrada) newParcela.entrada = oldParcela.entrada;

      const newParcelas = filteredConta.parcelas.map((parcela) => {
        if (parcela.id === newParcela.id) {
          return newParcela;
        } else {
          return parcela;
        }
      });

      filteredConta.parcelas = newParcelas;
      filteredConta.contaLiquidada = false;

      const nrParcelasPagas = filteredConta.parcelas.filter(
        (parcela) => parcela.parcelaPaga
      ).length;

      //Aqui devo fazer o update independentemente se houve ou não erro pois se houve erro no envio de dfe, ainda assim necessito guardar o doc
      //eletronico com erro na parcela
      try {
        const formData = new FormData();
        formData.append("contas", JSON.stringify(contaCorrente.contas));
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        /////////////////////////////////////////////////////

        //Atualizar o plano de tratamento para que este não possa ser apagado
        //Caso essa for a primeria parcela a ser paga. Se não, isso quer dizer que
        //O campo canDelete já está a true e nao necessita ser atualizado
        if (nrParcelasPagas === 0) {
          const planoTratamento = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/orcamento/${filteredConta.orcamento.id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          const formData = new FormData();
          formData.append("canDelete", true);
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/candelete/${planoTratamento.planosTratamento[0].id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        }

        toast.success(
          `Cancelamento efetuado! ${
            respostaDVE?.respostaEfatura?.responses[0]?.succeeded
              ? " DVE corrigido com sucesso!"
              : ""
          } `
        );
      } catch (err) {
        console.error("err", err);
      }
    } else {
      toast.error(`Erro na correcao do DVE!`);
    }
  };

  return (
    <div className="financeiro__container caixa">
      <div className="financeiro__adicionarEFiltros">
        <FiltrosDfe
          dfes={documentosEletronicosAbsolut}
          handleFiltrar={handleFiltrar}
        />
      </div>

      {rows && (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={actionColumn}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </div>
      )}

      {dadosModal?.open && (
        <ModalErrosDfe
          open={dadosModal.open}
          dadosModal={dadosModal}
          handleCloseModal={handleCloseModal}
        />
      )}

      {dadosModal?.tipoDocumento === "DVE" && (
        <ModalMotivoDveErrosDfe
          open={dadosModal?.tipoDocumento === "DVE"}
          modalData={dadosModal.modalData}
          handleCloseModal={handleCloseModal}
          handleCancelarRecebimento={handleCancelarRecebimento}
        />
      )}
    </div>
  );
}

export default EnvioDfe;
