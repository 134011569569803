import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import "./Single.scss";
import Navbar from "../../../shared/components/navbar/Navbar";
import Sidebar from "../../../shared/components/sidebar/Sidebar";

import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import toast from "react-hot-toast";
function SingleUser() {
  const [userData, setUserData] = useState();
  const antigaPalavraPassRef = useRef(null);
  const novaPalavraPassRef = useRef(null);

  const nomeRef = useRef(null);
  const emailRef = useRef(null);

  const { error, sendRequest, clearError } = useHttpClient();

  const userId = useParams().userId;
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/${userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setUserData(responseData.user);
      } catch (err) {}
    };
    fetchUser();
  }, [auth.token, sendRequest, userId]);

  const alterarPalavraPassHandler = async () => {
    let mensagemErro = "";

    if (
      novaPalavraPassRef.current.value === antigaPalavraPassRef.current.value
    ) {
      mensagemErro = "A palavra passe antiga e a atual não podem ser iguais";
    } else if (
      novaPalavraPassRef.current.value.trim() === "" ||
      antigaPalavraPassRef.current.value.trim() === ""
    ) {
      mensagemErro = "Os campos não podem ser vazios";
    } else if (novaPalavraPassRef.current.value.length < 7) {
      mensagemErro = "A palavra passe tem que ter pelo menos 7 caracters";
    }

    if (!mensagemErro) {
      try {
        const formData = new FormData();
        formData.append("newPass", novaPalavraPassRef.current.value);
        formData.append("oldPass", antigaPalavraPassRef.current.value);

        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/atualizarpalavrapasse/${userId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        if (responseData.type === "erro") {
          toast.error(responseData.msg);
        } else {
          toast.success(responseData.msg);
        }
      } catch (err) {
        toast.error(err);
      }
    } else {
      toast.error(mensagemErro);
    }
  };

  const guardarHandler = async () => {
    let mensagemErro = "";

    const reEmail = /^[\w.-]+@[\w.-]+\.[a-z]{2,4}$/;
    const reNome = /^[a-zA-Z\s-']+$/;

    if (
      nomeRef.current.value.trim() === "" ||
      emailRef.current.value.trim() === ""
    ) {
      mensagemErro = "Os campos não podem estar vazios";
    } else if (!reEmail.test(emailRef.current.value)) {
      mensagemErro = "email com formato incorreto";
    } else if (!reNome.test(nomeRef.current.value)) {
      mensagemErro = "nome com formato incorreto";
    }

    if (!mensagemErro) {
      try {
        const formData = new FormData();
        formData.append("email", emailRef.current.value);
        formData.append("name", nomeRef.current.value);

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/${userId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        toast.success("Dados atualizados com sucesso");
      } catch (err) {
        console.error("err", err);
        toast.error("ocorreu um erro atualizando os dados");
      }
    } else {
      toast.error(mensagemErro);
    }
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {!error && userData && (
        <div className="single">
          <Sidebar />
          <div className="singleContainer">
            <Navbar title={auth.nome} icon={PersonOutlineOutlinedIcon} />

            {/* Container info */}
            {userData && (
              <div className="single-user__container">
                <div className="single-user__container__info__container caixa">
                  <span className="single-user__container__title">
                    Configurações de perfil
                  </span>
                  <span className="single-user__container__subtitle">
                    Esses são detalhes pessoais, visiveis ao público
                  </span>

                  <div className="single-user__container__pass-reset__container__input-container">
                    <span>Nome Completo</span>
                    <input
                      type="text"
                      ref={nomeRef}
                      placeholder={userData.name}
                    />
                  </div>
                  <div className="single-user__container__pass-reset__container__input-container">
                    <span>Email</span>
                    <input
                      type="email"
                      ref={emailRef}
                      placeholder={userData.email}
                    />
                  </div>

                  <button
                    className="single-user__container__btn"
                    onClick={guardarHandler}
                  >
                    Guardar
                  </button>
                </div>

                {/* Container Palavra pass */}
                <div className="single-user__container__pass-reset__container caixa">
                  <span className="single-user__container__title">
                    Atualizar Palavra Passe
                  </span>
                  <span className="single-user__container__subtitle">
                    Introduza a tua palavra passe atual para efetuar a
                    atualização
                  </span>
                  <div className="single-user__container__pass-reset__container__input-container">
                    <span>Palavra Passe Atual</span>
                    <input type="password" ref={antigaPalavraPassRef} />
                  </div>
                  <div className="single-user__container__pass-reset__container__input-container">
                    <span>Nova Palavra Passe</span>
                    <input type="password" ref={novaPalavraPassRef} />
                  </div>

                  <button
                    className="single-user__container__btn"
                    onClick={alterarPalavraPassHandler}
                  >
                    Atualizar Palavra Passe
                  </button>
                </div>
              </div>
            )}
            <div className="alertaSingleUserContainer caixa">
              Estas alterações só terão efeito após o reinicio da sessão
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SingleUser;
