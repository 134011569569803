import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import validarAvaliacao from "../new/helperFunctions/validation";

import uuid from "react-uuid";
import toast from "react-hot-toast";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import ElementoAvaliacao from "./Elemento/ElementoAvaliacao";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";

import FormControl from "@mui/material/FormControl";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

import { useLoading } from "../../../shared/context/LoadingContext";

import "../../../shared/css/PlanoAvaliacaoOrcamento.scss";
import "../../../style/formError.scss";

const DENTES_CRIANCAS = [
  "51",
  "52",
  "53",
  "54",
  "55",
  "61",
  "62",
  "63",
  "64",
  "65",
  "71",
  "72",
  "73",
  "74",
  "75",
  "81",
  "82",
  "83",
  "84",
  "85",
  "Arcada Superior",
  "Arcada Inferior",
  "Arcadas",
];
const DENTES_ADULTOS = [
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  // "Arcada Superior",
  // "Arcada Inferior",
  // "Arcadas",
];

function EditAvaliacao() {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [medicoResponsavel, setMedicoResponsavel] = useState();
  const [avaliacao, setAvaliacao] = useState();
  const [tratamentos, setTratamentos] = useState();
  const [tratamentosAutocomplete, setTratamentosAutocomplete] = useState();
  const [serviceList, setServiceList] = useState();
  const [dentes, setDentes] = useState();
  const [observacoes, setObservacoes] = useState("");
  const [allClientes, setAllClientes] = useState([]);
  const [cliente, setCliente] = useState();

  const { error, sendRequest, clearError } = useHttpClient();
  const clinica = useContext(ClinicaContext);
  const auth = useContext(AuthContext);
  const avaliacaoId = useParams().avaliacaoId;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        // Fetch all data in parallel
        const [avaliacaoData, tratamentosData, clientesData] =
          await Promise.all([
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${avaliacaoId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/tratamentos/populated/${clinica.clinica._id}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/nomeid/${clinica.clinica._id}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
          ]);

        // Process avaliacao data
        const { avaliacao } = avaliacaoData;
        setMedicoResponsavel(avaliacao.medico_responsavel.id);
        setCliente({
          label: avaliacao.cliente.nome,
          id: avaliacao.cliente._id,
        });
        setAvaliacao(avaliacao);
        setObservacoes(avaliacao.observacoes);

        const mergedProcedimentos = mergeProcedimentos(avaliacao.procedimentos);
        setServiceList(formatServiceList(mergedProcedimentos));
        setDentes([...DENTES_ADULTOS, ...DENTES_CRIANCAS]);

        // Process tratamentos data
        const sortedTratamentos = sortTratamentos(tratamentosData.tratamentos);
        setTratamentos(sortedTratamentos);
        setTratamentosAutocomplete(
          formatTratamentosAutocomplete(sortedTratamentos)
        );

        // Process clientes data
        const sortedClientes = sortClientes(clientesData.clientes);
        setAllClientes(sortedClientes);
      } catch (err) {
        toast.error(
          "Erro ao buscar os dados da avaliação na base de dados. Por favor tente novamente"
        );
        console.error("Error fetching data:", err);
        // Handle error (e.g., show error message to user)
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, clinica, avaliacaoId, auth.token]);

  // Helper functions
  //////////////////////////////////////////////////////
  const mergeProcedimentos = (procedimentos) => {
    return procedimentos.reduce((acc, curr) => {
      const existingItem = acc.find(
        (item) =>
          item.tratamento.id === curr.tratamento.id &&
          item.faces[0] === curr.faces[0]
      );
      if (existingItem) {
        existingItem.dente += `,${curr.dente}`;
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  };

  const formatServiceList = (mergedProcedimentos) => {
    return mergedProcedimentos.map((pr, indice) => ({
      id: indice === 0 ? "1" : pr.id,
      tratamento: pr.tratamento.id,
      dentesTratados: pr.dente ? pr.dente.split(",") : [],
      faces: pr.faces,
      aceitaDentes: pr.tratamento.aceitaDentes,
      show: true,
      erro: "",
    }));
  };

  const sortTratamentos = (tratamentos) => {
    const naturalCompare = (a, b) => {
      const ax = [],
        bx = [];
      a.designacao.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        ax.push([$1 || Infinity, $2 || ""]);
      });
      b.designacao.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        bx.push([$1 || Infinity, $2 || ""]);
      });
      while (ax.length && bx.length) {
        const an = ax.shift(),
          bn = bx.shift();
        const nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
        if (nn) return nn;
      }
      return ax.length - bx.length;
    };
    return tratamentos.sort((a, b) => naturalCompare(a, b));
  };

  const formatTratamentosAutocomplete = (tratamentos) => {
    return tratamentos.map((t) => ({
      id: t.id,
      label: t.designacao,
      categoria: t.categoria.categoria,
    }));
  };

  const sortClientes = (clientes) => {
    return clientes
      .map((c) => ({ label: c.nome, id: c.id }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
  };
  //////////////////////////////////////////////////////////////

  //funcoes executadas pelas childs
  const tratamentoChangeHandler = (id, tratamento, acDentes, erro) => {
    let list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice].tratamento = tratamento;
    list[indice].erro = erro;
    list[indice].aceitaDentes = acDentes;
    setServiceList(list);
  };

  const dentesChangeHandler = (id, dentes, erro) => {
    let list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice].dentesTratados = dentes;
    list[indice].erro = erro;
    setServiceList(list);
  };

  const facesChangeHandler = (id, faces, erro) => {
    let list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice].faces = faces;
    list[indice].erro = erro;
    setServiceList(list);
  };

  const addService = (e) => {
    setServiceList([
      ...serviceList,
      {
        id: uuid(),
        tratamento: tratamentos[0].id,
        dentesTratados: [],
        faces: [],
        show: true,
        erro: "",
      },
    ]);
  };

  const removeService = (id) => {
    const list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice] = { show: false };
    setServiceList([...list]);
  };

  const submitHandler = async () => {
    startLoading();
    try {
      const filteredServiceList = [];
      let erroServiceList = "";
      const formErrorMessage = validarAvaliacao(serviceList, tratamentos);

      for (const service of serviceList) {
        if (service.erro) {
          erroServiceList = service.erro;
          setIsSubmitting(false);
          break; // This terminates the entire loop
        }
      }
      if (!formErrorMessage && !erroServiceList) {
        serviceList.forEach((service) => {
          if (service.show) {
            filteredServiceList.push({
              tratamento: service.tratamento,
              dentes: service.dentesTratados,
              faces: service.faces,
            });
          }
        });

        let finalServices = [];
        filteredServiceList.forEach((service) => {
          if (service.dentes?.length > 1) {
            const tratamento_filtrado = tratamentos.filter(
              (t) => t.id === service.tratamento
            )[0];

            if (tratamento_filtrado.multiDentes) {
              finalServices.push({
                tratamento: service.tratamento,
                dente: service.dentes.join(", "),
                faces: service.faces,
                orcamentado: false,
              });
            } else {
              service.dentes.forEach((d) => {
                finalServices.push({
                  tratamento: service.tratamento,
                  dente: d,
                  faces: service.faces,
                  orcamentado: false,
                });
              });
            }
          } else {
            finalServices.push({
              tratamento: service.tratamento,
              dente: service.dentes[0],
              faces: service.faces,
              orcamentado: false,
            });
          }
        });

        const formData = new FormData();
        formData.append("observacoes", observacoes);
        formData.append("cliente", cliente.id);

        if (medicoResponsavel !== avaliacao.medico_responsavel.id) {
          formData.append("medico_responsavel", medicoResponsavel);
        }

        if (auth.userId !== avaliacao.criado_por) {
          formData.append("criado_por", auth.userId);
        }

        formData.append("procedimentos", JSON.stringify(finalServices));

        //Se a avaliacao for do mesmo cliente ela é atualizada normalmente
        if (cliente.id === avaliacao.cliente.id) {
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${avaliacao.id}`,
              "PATCH",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
            toast.success("Avaliação editada com sucesso!");
            navigate(`../../../clientes/${avaliacao.cliente.id}-avaliacao`);
          } catch (err) {
            toast.error(
              "Erro ao editar a avaliação. Por favor tente novamente"
            );
            console.error("err", err);
          }
        } else {
          //Caso o cliente for modificado, é criado uma nova avaliacao no outro cliente e essa é desativada
          formData.append("clinica", clinica.clinica._id);
          formData.append("ativo", true);
          formData.append(
            "medico_responsavel",
            avaliacao.medico_responsavel.id
          );
          formData.append("criado_por", auth.userId);

          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            toast.error(
              "Erro ao editar a avaliação. Por favor tente novamente"
            );
            console.error("err", err);
          }
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/ativardesativar/${avaliacao.id}`,
              "PATCH",
              {},
              {
                Authorization: "Bearer " + auth.token,
              }
            );
            toast.success("Avaliação atualizada com sucesso!Cliente alterado.");
            navigate(`../../../clientes/${avaliacao.cliente.id}-avaliacao`);
          } catch (err) {
            toast.error(
              "Erro ao desativar a avaliação antiga. Por favor desative a avaliação antiga manualmente."
            );
            console.error("err", err);
          }
        }
      } else {
        toast.error(`${formErrorMessage || erroServiceList}`);
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const preSubmitHandler = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const replicarTratamentoHandler = (servico) => {
    const tratamentoAReplicar = servico;

    tratamentoAReplicar.aceitaDentes = tratamentos.filter(
      (t) => t.id === servico.tratamento.id
    )[0].aceitaDentes;

    tratamentoAReplicar.id = uuid();
    tratamentoAReplicar.tratamento = tratamentoAReplicar.tratamento.id;
    setServiceList((current) => [...current, tratamentoAReplicar]);
  };

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {avaliacao && (
            <Navbar
              title="Editar Avaliação"
              icon={NoteAltOutlinedIcon}
              paths={[
                {
                  nome: avaliacao.cliente.nome,
                  link: `../../../clientes/${avaliacao.cliente.id}-avaliacao`,
                },
                { nome: "Editar" },
              ]}
            />
          )}
          <ErrorModal error={error} onClear={clearError} />
          {tratamentos &&
            dentes &&
            serviceList &&
            avaliacao &&
            tratamentosAutocomplete && (
              <div className="bottom-orcamento caixa">
                <div className="editavaliacaoPickMedcoContainer">
                  {allClientes && cliente && (
                    <Autocomplete
                      value={cliente}
                      onChange={(event, newValue) => {
                        setCliente(newValue);
                      }}
                      id="controllable-states-demo"
                      options={allClientes}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Clientes" />
                      )}
                    />
                  )}
                  {auth.role === "Secretária(o)" && medicoResponsavel && (
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Médico Responsável
                      </InputLabel>
                      <Select
                        style={{ width: "300px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={medicoResponsavel}
                        label="Médico Responsável"
                        onChange={(e) => setMedicoResponsavel(e.target.value)}
                      >
                        {auth.usuariosAssociados.map((med, index) => (
                          <MenuItem value={med.id} key={index}>
                            {med.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>

                {serviceList.map(
                  (singleService, i) =>
                    singleService.show && (
                      <div key={i} className="avaliacao__item">
                        <ElementoAvaliacao
                          //single service data
                          dentesTratados={singleService.dentesTratados}
                          faces={singleService.faces}
                          tratamento={singleService.tratamento}
                          //outros
                          tratamentos={tratamentos}
                          tratamentosAutocomplete={tratamentosAutocomplete}
                          removeService={removeService}
                          id={singleService.id}
                          dentes={dentes}
                          show={singleService.show}
                          //funcoes
                          tratamentoChangeHandler={tratamentoChangeHandler}
                          dentesChangeHandler={dentesChangeHandler}
                          facesChangeHandler={facesChangeHandler}
                          replicarTratamentoHandler={replicarTratamentoHandler}
                        />
                      </div>
                    )
                )}
                <button className="add-btn" onClick={addService}>
                  +
                </button>

                <div className="form-input mb-20">
                  <textarea
                    className="input"
                    cols="30"
                    rows="5"
                    placeholder="ESCREVA AQUI OBSERVAÇÕES ACERCA DA AVALIAÇÃO SE TIVER."
                    onChange={(e) => setObservacoes(e.target.value)}
                  ></textarea>
                </div>

                <div className="botoes">
                  <button
                    disabled={isSubmitting}
                    className="blue-button"
                    onClick={preSubmitHandler}
                  >
                    {isSubmitting ? "Guardando..." : "Guardar"}
                  </button>

                  <button
                    className="cancel-btn"
                    onClick={() =>
                      navigate(
                        `../../../clientes/${avaliacao.cliente.id}-avaliacao`
                      )
                    }
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default EditAvaliacao;
