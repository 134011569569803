import React, { useContext } from "react";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import Datatable from "../components/datatable/Datatable";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import { AuthContext } from "../../../shared/context/auth-context";
import "../../../shared/css/lists.scss";

function Agendamentos() {
  const auth = useContext(AuthContext);
  const medicos =
    auth.role === "Secretária(o)" ? auth.usuariosAssociados : null;

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar
          title="Agendamentos"
          icon={CalendarMonthOutlinedIcon}
          medicos={medicos}
        />
        <Datatable medicos={medicos} />
      </div>
    </div>
  );
}

export default Agendamentos;
