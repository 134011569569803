import { useState, useEffect, useCallback } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";

import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalFiltrosReceitas.scss";

function ModalFiltrosReceitas({
  openModalFiltros,
  handleCloseModalFiltros,
  handleFiltrar,
  entradas,
  contasCorrentes,
  filtrosAplicados,
  filtroCaixa,
  filtroCategoria,
  filtroMetodoPagamento,
  clinicaId,
  auth,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [categoria, setCategoria] = useState(
    filtroCategoria ? filtroCategoria : "todas"
  );
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState(filtroCaixa ? filtroCaixa : "todas");
  const [caixas, setCaixas] = useState([]);
  const [radioPagas, setRadioPagas] = useState(
    filtrosAplicados.includes("pagas") ? true : false
  );
  const [radioNPagas, setRadioNPagas] = useState(
    filtrosAplicados.includes("naoPagas") ? true : false
  );

  const [emAtraso, setEmAtraso] = useState(
    filtrosAplicados.includes("emAtraso") ? true : false
  );

  const [showEmAtraso, setShowEmAtraso] = useState(
    !filtrosAplicados.includes("pagas") ? true : false
  );

  const [emitido, setEmitido] = useState(
    filtrosAplicados.includes("emitido") ? true : false
  );

  const [showComComprovante, setShowComComprovante] = useState(
    !filtrosAplicados.includes("naoPagas") ? true : false
  );

  const [radioEntradas, setRadioEntradas] = useState(
    filtrosAplicados.includes("entradas") ? true : false
  );

  const [radioParcelas, setRadioParcelas] = useState(
    filtrosAplicados.includes("parcelas") ? true : false
  );

  const { sendRequest } = useHttpClient();

  const formasPagamento = [
    "Todos",
    "Dinheiro",
    "Cheque",
    "Internet banking",
    "Cartão de crédito",
  ];

  const [metodoPagamento, setMetodoPagamento] = useState(
    filtroMetodoPagamento ? filtroMetodoPagamento : "Todos"
  );

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/entrada/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCategorias(
          responseData.categoriasFinanceiro.map((cat) => {
            return { id: cat.id, value: cat.categoria };
          })
        );
      } catch (err) {
        console.error("err", err);
      }
    };

    const fetchCaixas = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCaixas(
          responseData.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
      } catch (err) {
        console.error("err", err);
      }
    };
    fetchCaixas();
    fetchCategorias();
  }, [auth.token, clinicaId, sendRequest]);

  useEffect(() => {
    setOpenModal(openModalFiltros);
  }, [openModalFiltros]);

  const metodoPagamentoChangeHandler = useCallback((valor) => {
    setMetodoPagamento(valor);
  }, []);

  const handlePagasChange = useCallback((e) => {
    setRadioPagas(e.target.checked);
    setRadioNPagas(!e.target.checked);
    setEmAtraso(false);
    setShowEmAtraso(false);
    setShowComComprovante(true);
  }, []);

  const handleNPagasChange = useCallback((e) => {
    setRadioNPagas(e.target.checked);
    setRadioPagas(!e.target.checked);
    setShowEmAtraso(true);
    setShowComComprovante(false);
    setEmitido(false);
  }, []);

  const filtrarModalHandler = useCallback(() => {
    //Contas Correntes///////////////////////////////////////
    let contasCorrentesFiltradas = [];
    if (radioParcelas || (!radioEntradas && !radioParcelas)) {
      contasCorrentes.forEach((cc, index) => {
        contasCorrentesFiltradas[index] = { ...cc };
        let novasContas = [];
        cc.contas.forEach((conta, indiceConta) => {
          novasContas[indiceConta] = { ...conta };
          novasContas[indiceConta].parcelas = [];
          let novasParcelas = [];
          conta.parcelas.forEach((parcela) => {
            if (
              (parcela.parcelaPaga && radioPagas) ||
              (!parcela.parcelaPaga && radioNPagas) ||
              (!radioPagas && !radioNPagas)
            ) {
              if (
                (!parcela.parcelaPaga &&
                  emAtraso &&
                  moment(parcela.dataLimitePagamento).isBefore(
                    moment(),
                    "day"
                  )) ||
                (!emAtraso &&
                  !parcela.parcelaPaga &&
                  !moment(parcela.dataLimitePagamento).isBefore(
                    moment(),
                    "day"
                  )) ||
                (parcela.parcelaPaga && !emAtraso) ||
                (!parcela.parcelaPaga && !emAtraso)
              ) {
                if (
                  (parcela.parcelaPaga &&
                    (parcela.caixa === caixa || caixa === "todas") &&
                    (parcela.metodoPagamento === metodoPagamento ||
                      metodoPagamento === "Todos") &&
                    (parcela.categoria === categoria ||
                      categoria === "todas")) ||
                  (caixa === "todas" &&
                    categoria === "todas" &&
                    metodoPagamento === "Todos")
                ) {
                  if (
                    (parcela.documentoEletronico?.succeeded === true &&
                      emitido) ||
                    !emitido
                  ) {
                    novasParcelas.push({ ...parcela });
                  }
                }
              }
            }
          });
          novasContas[indiceConta].parcelas = [...novasParcelas];
        });
        contasCorrentesFiltradas[index].contas = [...novasContas];
      });
    } else {
      contasCorrentesFiltradas = [];
    }

    //////////////////////////////////////
    //Entradas////////////////////////////
    let entradasFiltradas = [];
    if (radioEntradas || (!radioEntradas && !radioParcelas)) {
      entradas.forEach((entrada) => {
        if (
          (entrada.entradaRecebida && radioPagas) ||
          (!entrada.entradaRecebida && radioNPagas) ||
          (!radioPagas && !radioNPagas)
        ) {
          if (
            (!entrada.entradaRecebida &&
              emAtraso &&
              moment(entrada.dataLimitePagamento).isBefore(moment(), "day")) ||
            (!emAtraso &&
              !entrada.entradaRecebida &&
              !moment(entrada.dataLimitePagamento).isBefore(moment(), "day")) ||
            (entrada.entradaRecebida && !emAtraso) ||
            (!entrada.entradaRecebida && !emAtraso)
          ) {
            if (
              (entrada.entradaRecebida &&
                (entrada.caixa._id === caixa || caixa === "todas") &&
                (entrada.metodoPagamento === metodoPagamento ||
                  metodoPagamento === "Todos") &&
                (entrada.categoria._id === categoria ||
                  categoria === "todas")) ||
              (caixa === "todas" &&
                categoria === "todas" &&
                metodoPagamento === "Todos")
            ) {
              if (
                (entrada.documentoEletronico?.succeeded === true && emitido) ||
                !emitido
              )
                entradasFiltradas.push({ ...entrada });
            }
          }
        }
      });
    } else {
      entradasFiltradas = [];
    }

    const filtrosApli = [];
    radioPagas && filtrosApli.push("pagas");
    radioNPagas && filtrosApli.push("naoPagas");
    emAtraso && filtrosApli.push("emAtraso");
    emitido && filtrosApli.push("emitido");
    emAtraso && filtrosApli.push("emAtraso");
    radioEntradas && filtrosApli.push("entradas");
    radioParcelas && filtrosApli.push("parcelas");

    handleFiltrar(
      contasCorrentesFiltradas,
      entradasFiltradas,
      filtrosApli,
      caixa,
      categoria,
      metodoPagamento,
      "",
      "modalFiltros"
    );
    handleCloseModalFiltros();
  }, [
    radioPagas,
    radioNPagas,
    emAtraso,
    emitido,
    radioEntradas,
    radioParcelas,
    caixa,
    categoria,
    metodoPagamento,
    contasCorrentes,
    entradas,
    handleFiltrar,
    handleCloseModalFiltros,
  ]);

  const resetModalHandler = useCallback(() => {
    handleFiltrar(
      contasCorrentes,
      entradas,
      [],
      null,
      null,
      null,
      "",
      "modalFiltros"
    );
    handleCloseModalFiltros();
  }, [contasCorrentes, entradas, handleFiltrar, handleCloseModalFiltros]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalFiltros}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box" id="filtros__modal">
          <div className="filtros__modal__header">
            <h1>Filtrar por</h1>
            <span onClick={resetModalHandler}>Resetar</span>
          </div>
          <div className="filtros__modal__container">
            <div className="modalFiltros__checkboxes">
              <div className="modalFiltros__checkboxes__container">
                <input
                  type="checkbox"
                  name=""
                  id="Parcelas"
                  checked={radioParcelas}
                  onChange={(e) => {
                    setRadioParcelas(e.target.checked);
                  }}
                />
                <label htmlFor="Parcelas">Parcelas</label>
              </div>

              {auth.perm.includes("r-ent") && (
                <div className="modalFiltros__checkboxes__container">
                  <input
                    type="checkbox"
                    name=""
                    id="Entradas"
                    checked={radioEntradas}
                    onChange={(e) => {
                      setRadioEntradas(e.target.checked);
                    }}
                  />
                  <label htmlFor="Entradas">Entradas</label>
                </div>
              )}
            </div>

            <div className="modalFiltros__radios">
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="estadoRadios"
                  id="radioPagas"
                  checked={radioPagas}
                  onChange={handlePagasChange}
                />
                <label htmlFor="radioPagas">Pagas</label>
              </div>
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="estadoRadios"
                  id="radioNPagas"
                  checked={radioNPagas}
                  onChange={handleNPagasChange}
                />
                <label htmlFor="radioNPagas">Não Pagas</label>
              </div>
            </div>

            <div className="modalFiltros__checkboxes2">
              {showComComprovante && (
                <div className="modalFiltros__checkboxes__container">
                  <input
                    type="checkbox"
                    name=""
                    id="comprovante"
                    checked={emitido}
                    onChange={(e) => setEmitido(e.target.checked)}
                  />
                  <label htmlFor="comprovante">Emitidos</label>
                </div>
              )}
            </div>
            <div className="modalFiltros__checkboxes2">
              {showEmAtraso && (
                <div className="modalFiltros__checkboxes__container">
                  <input
                    type="checkbox"
                    name=""
                    id="emAtraso"
                    checked={emAtraso}
                    onChange={(e) => setEmAtraso(e.target.checked)}
                  />
                  <label htmlFor="emAtraso">Em atraso</label>
                </div>
              )}
            </div>
            {radioPagas && (
              <div className="modalFiltros__selects">
                {caixas && caixa && (
                  <div className="customInputContainer">
                    <select
                      onChange={(e) => setCaixa(e.target.value)}
                      className="customInputSelect"
                      value={caixa}
                    >
                      <option key={0} value="todas">
                        Todas
                      </option>
                      {caixas &&
                        caixa &&
                        caixas.map((item, index) => (
                          <option key={index + 1} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                    </select>
                    <label className="customInputLabelSelect">Caixa</label>
                  </div>
                )}

                <InputSelect
                  label="Metodo de pagamento"
                  initialValue={metodoPagamento}
                  handleChange={metodoPagamentoChangeHandler}
                  items={formasPagamento}
                />

                {categorias && categoria && radioEntradas && (
                  <div className="customInputContainer">
                    <select
                      onChange={(e) => setCategoria(e.target.value)}
                      className="customInputSelect"
                      value={categoria}
                    >
                      <option key={0} value="todas">
                        Todas
                      </option>
                      {categorias &&
                        categoria &&
                        categorias.map((item, index) => (
                          <option key={index + 1} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                    </select>
                    <label className="customInputLabelSelect">Categoria</label>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalFiltros}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span className="blue-button" onClick={filtrarModalHandler}>
              Filtrar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalFiltrosReceitas;
