import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfImpressoStyle.scss";
import moment from "moment/moment";

const printStyles = `
    @media print {
      @page {
        size: 148mm 210mm;
      }
    }
  `;

function PdfImpresso({ impressoData, showPreviousPage }) {
  const { cliente, clinica, medico, textoImpresso } = impressoData;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Impresso-${impressoData.cliente.nome}_${moment(
      new Date()
    ).format("DD-MM-YYYY")}`,
    onAfterPrint: () => {
      showPreviousPage();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  return (
    <>
      <div className="botoes-pdf">
        <button onClick={showPreviousPage}>Voltar</button>
        <button onClick={handlePrint}>Imprimir</button>
      </div>

      <div ref={componentRef} className="pdf-wrapper">
        <style>{printStyles}</style>
        <div className="republicaDeCv">
          <img
            src="/images/republica-cv.png"
            alt=""
            className="republicaDeCv__img"
          />
          <span className="republicaDeCv__text">
            República de Cabo Verde - Receita Médica
          </span>
        </div>
        <div className="pdf-body">
          {/* TOP */}
          <div className="pdf-impresso__cabecalho">
            <div className="pdf-impresso__cabecalho--left">
              <img
                className="pdf-impresso__logo-clinica"
                src={`${process.env.REACT_APP_BACKEND_LINK}/${clinica.logotipo}`}
                alt=""
              />
              <span>{clinica.nome ? clinica.nome : ""}</span>
              <span>
                {medico.name}/{medico.profissao}
              </span>
              <span>
                Tel:{clinica.contacto ? clinica.contacto : ""}
                {clinica.contacto2 && `/${clinica.contacto2}`}
              </span>
              <span>{clinica.endereco}</span>
              <span>NIF:{clinica.nif}</span>
            </div>
            <div className="pdf-impresso__cabecalho--right">
              <span className="pdf-impresso__cabecalho--right__title">
                Identificação do Utente
              </span>
              <div className="pdf-impresso__cabecalho--right__campo">
                <span className="boldSpan">Nome:</span>
                <span className="normalSpan">{cliente.nome}</span>
              </div>
              <div className="pdf-impresso__cabecalho--right__campo">
                <span className="boldSpan">Data Nascimento:</span>
                <span className="normalSpan">{cliente.data_nasc}</span>
                <span className="boldSpan" style={{ marginLeft: "10px" }}>
                  Telefone:
                </span>
                <span className="normalSpan">{cliente.contacto}</span>
              </div>
              <div className="pdf-impresso__cabecalho--right__campo">
                <span className="boldSpan">Endreço: </span>
                <span className="normalSpan">{cliente.endereco}</span>
              </div>
              <div className="pdf-impresso__cabecalho--right__campo">
                <span className="boldSpan">Entidade Responsável:</span>
                <span className="normalSpan">________________</span>
                <span
                  className="boldSpan"
                  style={{ marginLeft: `${cliente.responsavel ? "10px" : 0}` }}
                >
                  Nr Beneficiário:
                </span>
                <span className="normalSpan">
                  {cliente.nr_beneficiario
                    ? cliente.nr_beneficiario
                    : "____________"}
                </span>
              </div>
            </div>
          </div>
          {/* MIDDLE */}
          <div className="pdf-impresso__middle">
            <div className="pdf-impresso__middle--left">
              <div className="pdf-impresso__middle__data">{textoImpresso}</div>
            </div>
          </div>
          {/* BOTOM */}
          <div className="pdf-impresso__bottom">
            <div className="pdf-impresso__bottom--left">
              <div className="pdf-impresso__bottom--left--1">
                <div className="smallDiv smallDiv--bold smallDiv--bottom">
                  Médico
                </div>
                <div className="div__carimbo">
                  <span className="pdf-impresso__bottom-top-span">
                    Carimbo ou Vinheta
                  </span>
                </div>
                <div className="pdf-impresso__assinaturaEData">
                  <hr />
                  <span className="assinatura">Assinatura</span>
                  <span className="data">
                    Data: {moment(new Date()).format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="pdf-impresso__bottom--right">
              <div
                className="smallDiv smallDiv--bold smallDiv--bottom"
                style={{ width: "100%" }}
              >
                Utente
              </div>
              <span className="pdf-impresso__bottom--right__span1">
                Declaro que manifestei o Direito de Opção
              </span>
              <span className="pdf-impresso__bottom--right__span2">
                Assinatura
              </span>
            </div>
          </div>
          {/* RODAPÉ */}
        </div>
        <div className="pdf-impresso-rodape--2">
          <span className="pdf-impresso-rodape--2__item">
            <span className="pdf-impresso--overline">
              Processado por HEALTH TECH CV
            </span>
          </span>
          <span className="pdf-impresso-rodape--2__item">
            2368 | Série - Nº 81 «BO» DA REPUBLICA DE CABO VERDE - 31 DE
            DEZEMBRO 2014
          </span>
        </div>
      </div>
    </>
  );
}

export default PdfImpresso;
